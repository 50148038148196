import React, { useState } from 'react'
import ab05 from '../assets/images/about/ab05.jpg';
import Navbar from '../components/Navbar'
import MillionsJob from '../components/Millions-job'
import Feature from '../components/Feature'
import JobCounter from '../components/Job-counter';
import QuesAnswer from '../components/Question-Answer';
import ExploreJob from '../components/Explore-job';
import Footer from '../components/Footer';
import { Link } from "react-router-dom";
import ModalVideo from 'react-modal-video'
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import{BiCheckCircle} from "../assets/icons/vander"
import JobListCompTwo from '../components/job-list-comp-two';
import { Grid } from '@mui/material';
import { CandidateList } from '../data/data';
import CountUp from 'react-countup';
import {MdOutlineArrowForward, BsTelephone, FaAtom, FiUserCheck, IoMdHourglass, BsBriefcase, TbUsersGroup} from "../assets/icons/vander"

// import AboutusCard from './AboutusCard';

export default function Aboutus() {
    const [isOpen, setOpen] = useState(false)

    const ServicesComp = [
        {
            icon: BsTelephone,
            title: '24/7 Support',
            data: 'Many desktop publishing now use and a search for job'
        },
        {
            icon: FaAtom,
            title: 'Tech & Startup Jobs',
            data: 'Many desktop publishing now use and a search for job'
        },
        {
            icon: FiUserCheck,
            title: 'Quick & Easy',
            data: 'Many desktop publishing now use and a search for job'
        },
        {
            icon: IoMdHourglass,
            title: 'Save Time',
            data: 'Many desktop publishing now use and a search for job'
        },
        {
            icon: FiUserCheck,
            title: 'Apply with confidence',
            data: 'Many desktop publishing now use and a search for job'
        },
        {
            icon: FiUserCheck,
            title: 'Reduce Hiring Bias',
            data: 'Many desktop publishing now use and a search for job'
        },
        {
            icon: BsBriefcase,
            title: 'Proactive Employers',
            data: 'Many desktop publishing now use and a search for job'
        },
        {
            icon: TbUsersGroup,
            title: 'No Missed Opportunities',
            data: 'Many desktop publishing now use and a search for job'
        },
    ]



    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">About Us</h3>

                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Home</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">About Us</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            {/* <AboutusCard/> */}
            <section className="relative md:py-24 py-16">
                <div className="container md:pb-16">
                    <MillionsJob />
                </div>

                <section className="relative md:py-24 py-16">
            <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7 md:col-span-6">
                        <div className="lg:ms-5">
                              
                              <h3 className="mb-6 text-3xl leading-normal font-semibold">Mission</h3>

                              <p className="text-slate-400 max-w-xl">Our mission is to create a simple landscape where demand and supply are free-flowing. Every demand from a Seeker gets met and Geeks get better opportunities.</p>
                              </div>
                        </div>

                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="lg:ms-5">
                              
                                    <h3 className="mb-6 text-3xl leading-normal font-semibold">Vision</h3>

                                    <p className="text-slate-400 max-w-xl mx-auto">We envision creating a worldwide presence, an environment just for IT services. This landscape will only focus on offering seamless IT services to businesses and improved job opportunities to IT professionals.</p>
                                   
                            </div>
                        </div>
                    </div>
                </div>

             
            </section>


                {/* <Feature /> */}

                <div className="container">
            <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold text-center mt-10">Team Members</h3>
                <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">


               

                    {CandidateList.map((item, index) => (
                        <div className="group relative overflow-hidden rounded-md shadow dark:shadow-gray-700 text-center p-6" key={index} style={{ backgroundColor:"#e7cc8c" }}>
                            <img src={item.image} className="h-35 w-35 rounded-full shadow dark:shadow-gray-700 mx-auto" alt="" />

                            <div className="mt-2">
                                <Link to={`/candidate-profile/${item.id}`} className="hover:text-emerald-600 font-semibold text-lg">{item.name}</Link>
                                <p className="text-sm" style={{color:"black"}}>{item.title}</p>
                            </div>

                            {/* <ul className="mt-2 list-none space-x-0.5">
                                <li className="inline"><span className="bg-emerald-600/10 inline-block text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full">{item.deta1}</span></li>
                                <li className="inline"><span className="bg-emerald-600/10 inline-block text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full">{item.deta2}</span></li>
                                <li className="inline"><span className="bg-emerald-600/10 inline-block text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full">{item.deta3}</span></li>
                            </ul> */}

                            <div className="flex justify-between mt-2">
                                <div className="block">
                                    <span style={{color:"black"}}>{item.describtion}</span>
                                    {/* <span className="block text-sm font-semibold">{item.price}</span> */}
                                </div>
                                {/* <div className="block">
                                    <span className="text-slate-400">{item.Experience}</span>
                                    <span className="block text-sm font-semibold">{item.year}</span>
                                </div> */}
                            </div>

                           

                           
                        </div>
                    ))}





                </div>

               
            </div>

                <div className="grid grid-cols-1 pb-8 text-center mt-12">
                <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">Technology stack</h3>

                <p className="text-slate-400 max-w-xl mx-auto">Search all the open positions on the web. Get your own personalized salary estimate. Read reviews on over 30000+ companies worldwide.</p>
            </div>


            <section className="relative ">
                <div className="container md:pb-16">

            <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-[30px]">


            <div  className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
                <div className="w-16 h-16 flex items-center justify-center mx-auto bg-emerald-600/5 group-hover:bg-emerald-600 dark:bg-emerald-600/10 dark:group-hover:bg-emerald-600 shadow dark:shadow-gray-700 rounded-lg transition-all duration-500">
                    <FaAtom className="text-[30px] text-slate-icon-400"/>
                </div>
                <div className="mt-4">
                    <Link to="#" className="text-lg font-semibold hover:text-emerald-600 transition-all duration-500">Node JS</Link>
                    <p className="text-slate-400 mt-3 mb-2">Backend Language</p>
                </div>
            </div>

            <div  className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
                <div className="w-16 h-16 flex items-center justify-center mx-auto bg-emerald-600/5 group-hover:bg-emerald-600 dark:bg-emerald-600/10 dark:group-hover:bg-emerald-600 shadow dark:shadow-gray-700 rounded-lg transition-all duration-500">
                    <FaAtom className="text-[30px] text-slate-icon-400 "/>
                </div>
                <div className="mt-4">
                    <Link to="#" className="text-lg font-semibold hover:text-emerald-600 transition-all duration-500">React js</Link>
                    <p className="text-slate-400 mt-3 mb-2">Its javascript Library</p>
                </div>
            </div>

            <div  className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
                <div className="w-16 h-16 flex items-center justify-center mx-auto bg-emerald-600/5 group-hover:bg-emerald-600 dark:bg-emerald-600/10 dark:group-hover:bg-emerald-600 shadow dark:shadow-gray-700 rounded-lg transition-all duration-500">
                    <FaAtom className="text-[30px] text-slate-icon-400"/>
                </div>
                <div className="mt-4">
                    <Link to="#" className="text-lg font-semibold hover:text-emerald-600 transition-all duration-500">PHP</Link>
                    <p className="text-slate-400 mt-3 mb-2">Backend Language</p>
                </div>
            </div>

            <div  className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
                <div className="w-16 h-16 flex items-center justify-center mx-auto bg-emerald-600/5 group-hover:bg-emerald-600 dark:bg-emerald-600/10 dark:group-hover:bg-emerald-600 shadow dark:shadow-gray-700 rounded-lg transition-all duration-500">
                    <FaAtom className="text-[30px] text-slate-icon-400"/>
                </div>
                <div className="mt-4">
                    <Link to="#" className="text-lg font-semibold hover:text-emerald-600 transition-all duration-500">React Native</Link>
                    <p className="text-slate-400 mt-3 mb-2"> Mobile App framework </p>
                </div>
            </div>

            
       
 

</div>
</div>
</section>



           



   

                <div className="container md:mt-24 mt-16">
                    <div className="grid grid-cols-1">
                        <div className="relative overflow-hidden bg-emerald-600 rounded-md shadow dark:shadow-gray-700">
                            <div className="grid md:grid-cols-2 items-center gap-[30px]">
                                <div className="relative">
                                    <img src={ab05} alt="" />
                                    <div className="absolute md:bottom-1/2 md:translate-y-1/2 md:-end-10 ltr:md:translate-x-0 rtl:md:translate-x-0 -bottom-10 end-1/2 ltr:translate-x-1/2 rtl:-translate-x-1/2 text-center">
                                        <Link to="#" onClick={() => setOpen(true)} data-id="S_CGed6E610" className="lightbox h-20 w-20 rounded-full inline-flex items-center justify-center " style={{ backgroundColor:"#d6a73b",color:"white" }}>
                                            <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                        </Link>
                                    </div>
                                </div>

                                <div>
                                    <div className="text-white p-4">
                                        <h4 className="leading-normal text-4xl mb-3 font-semibold">Achievements and <br /> Milestones.</h4>

                                        <p className="text-white/70 text-lg max-w-xl">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. 

</p>

                                        <ul className="list-none text-white/50 mt-4">
                                            <li className="mb-1 flex items-center"><BiCheckCircle className="text-white text-xl me-2"/> There are many variations of passages of Lorem Ipsum</li>
                                            <li className="mb-1 flex items-center"><BiCheckCircle className="text-white text-xl me-2"/>  words which don't look even slightly believable.</li>
                                            <li className="mb-1 flex items-center"><BiCheckCircle className="text-white text-xl me-2"/>  Lorem ipsum dolor sit amet, consectetur</li>
                                        </ul>

                                        <div className="relative grid md:grid-cols-3 grid-cols-1 items-center gap-[30px] z-1 mt-10">
                <div className="counter-box text-center">
                    <h1 className="lg:text-4xl text-4xl font-semibold mb-2 dark:text-white">
                        <CountUp
                            start={1010}
                            end={1548}
                            duration={3} />K+</h1>
                    <h5 className="counter-head text-sm font-semibold text-white/70 uppercase">Job Fulfillment</h5>
                </div>

                <div className="counter-box text-center">
                    <h1 className="lg:text-4xl text-4xl font-semibold mb-2 dark:text-white">
                        <CountUp
                            start={2}
                            end={25}
                            duration={4} />
                        +</h1>
                    <h5 className="counter-head text-sm font-semibold text-white/70 uppercase">Branches</h5>
                </div>

                <div className="counter-box text-center">
                    <h1 className="lg:text-4xl text-4xl font-semibold mb-2 dark:text-white">
                        <CountUp
                            start={0}
                            end={9}
                            duration={4} />
                        +</h1>
                    <h5 className="counter-head text-sm font-semibold text-white/70 uppercase">Years Experience</h5>
                </div>
            </div>
                                       
                                    </div>
                                    {/* <JobCounter /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="container md:mt-24 mt-16">
                   
                </div> */}
                {/* <QuesAnswer />
                <ExploreJob /> */}
            </section>
            <Footer />
            {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="S_CGed6E610" onClose={() => setOpen(false)} /> */}

        </>
    )
}