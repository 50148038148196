import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

let initialState = {
    services: [],
    servicesDropDown: [],
    toastData: {},
    featuredServices: [],
    candidateList: []
}

const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        setServices(state, action) {
            state.services = action.payload
        },
        setDropDownServices(state, action) {
            state.servicesDropDown = action.payload
        },
        setFeaturedServices(state, action) {
            state.featuredServices = action.payload
        },
        setCandidateList(state, action) {
            state.candidateList = action.payload
        },
        settoastData(state, action) {
            state.toastData = action.payload;
        },
    }
})

export const { setServices, settoastData, setDropDownServices, setFeaturedServices, setCandidateList } = serviceSlice.actions;

export const servicesURL = (page, limit, search) => async (dispatch) => {
    dispatch(settoastData({ status: '', message: "" }));
    const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/services/list?limit=${limit}&page=${page}&search=${search}`).then((res) => {
        //   dispatch(settoastData({ status: true, message: "You are Loged in !" }));
        dispatch(setServices(res.data.data));
        const array = []
        if (res.data.data?.length >= 1) {
            res.data.data.map((item) => {
                array.push({ label: item?.name, value: item?.name })
            })
            dispatch(setDropDownServices(array));
        }
    })
        .catch((err) => {
            dispatch(setDropDownServices([]));
            dispatch(setServices([]));
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
}

export const featuredServicesURL = (page, limit, search) => async (dispatch) => {
    dispatch(settoastData({ status: '', message: "" }));
    const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/services/featured/list?limit=${limit}`).then((res) => {
          dispatch(settoastData({ status: true, message: "You are Loged in !" }));
        dispatch(setFeaturedServices(res.data.data));
    })
        .catch((err) => {
            dispatch(setFeaturedServices([]));
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
}

export const candidateListbyService = (page, limit, search, name) => async (dispatch) => {
    dispatch(settoastData({ status: '', message: "" }));
    const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/user/geeks/listByService?service=${name}&limit=${limit}&pagenum=${page}&search=${search}`).then((res) => {
        //   dispatch(settoastData({ status: true, message: "You are Loged in !" }));
        dispatch(setCandidateList(res.data.data));
    })
        .catch((err) => {
            dispatch(setCandidateList([]));
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
}

const serviceReducer = serviceSlice.reducer
export default serviceReducer;