import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

let initialState = {
    currentUser: {},
    isLogin: false,
    toastData : {}
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCurrentUser(state, action) {
            state.currentUser = action.payload
            state.isLogin = true
        },
        settoastData(state, action) {
            state.toastData = action.payload;
          },
    }
})

export const { setCurrentUser, settoastData } = authSlice.actions;

export const LoginURL = (values) => async (dispatch) => {
  dispatch(settoastData({ status: '', message: "" }));
    const response = await axios.post(`${process.env.REACT_APP_API_BASEURL}/user/login`, values).then((res) => {
      dispatch(settoastData({ status: true, message: "You are Loged in !" }));
      dispatch(setCurrentUser(res.data.data));
      localStorage.setItem("user", JSON.stringify(res.data.data))
    })
      .catch((err) => {
        dispatch(settoastData({ status: false, message: err.response.data }));
      })
  }

  export const UserDetailesSave = (values) => async (dispatch) => {
    if(values){
      dispatch(setCurrentUser(values));
    }
    else{
    dispatch(setCurrentUser({}));
    }
  }

  export const LogoutUrl = () => async (dispatch) => {
    dispatch(setCurrentUser({}));
  }

const authReducer = authSlice.reducer
export default authReducer;