import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar'
import Select from "react-select";
import Footer from '../components/Footer'
import team1 from "../assets/images/team/01.jpg";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import pencil from "../assets/images/pencil.png";
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import { Bookmark } from "react-feather";
import { CandidateList, jobData } from "../data/data";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { PieChart } from '@mui/x-charts/PieChart';
import ReactApexChart from "react-apexcharts";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { servicesURL } from "../Redux/services/servicesSlice";
import {
    Settings,
    FileText,
    Gift,
    Globe,
    Home,
    Mail,
    MapPin,
    Phone,
    Server
   
  } from "react-feather";

import {
    LuMail,
    BiLogoDribbble,
    AiOutlineBehance,
    BiLogoLinkedin,
    FaFacebookF,
    IoLogoTwitter,
    FaInstagram,
    FiFileText, BsTelephone,
    PiMapPin
  } from "../assets/icons/vander";
import Form from "../components/Form";
import { Grid } from "@mui/material";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from "react-redux";
import { allJobsList, GeeksApplyJobPendingList } from "../Redux/geeks/jobSlice";
import axios from "axios";
import "./dashboard.css";
import { Routes, useParams } from 'react-router-dom/dist';
import bg5 from "../assets/images/hero/bg5.jpg";


export default function GeeksCorpProfileSet() {
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [details, setDetails] = useState("");
    console.log(details, "dsgjcjhdschjs");
    const [hireddetails, setHiredDetails] = useState("");
    console.log(hireddetails, "hireddetails");
  
    const { pendingJobList } = useSelector((state) => state.alljobs);
  
    const { currentUser } = useSelector((state) => state.auth);
  
    console.log(currentUser, "currentUser");
  
    console.log(pendingJobList, "pendingJobList");
  
    useEffect(() => {
      dispatch(GeeksApplyJobPendingList(page, 10, "", currentUser?.token));
    }, []);
  
    // Function to calculate how many hours or days ago a date came
    function calculateTimeAgo(targetDate) {
      // Get the current date and time
      const currentDate = new Date();
  
      // Convert the target date string to a Date object
      const targetDateObj = new Date(targetDate);
  
      // Calculate the time difference in milliseconds
      const timeDifference = currentDate - targetDateObj;
  
      if (timeDifference < 0) {
        // The target date is in the future
        const daysAgo = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        return `${daysAgo} days in the future`;
      } else if (currentDate.toDateString() === targetDateObj.toDateString()) {
        // The target date is today
        const hoursAgo = Math.floor(timeDifference / (1000 * 60 * 60));
        return `${hoursAgo} hours ago`;
      } else if (timeDifference < 24 * 60 * 60 * 1000) {
        // The target date is exactly one day ago
        return "1 day ago";
      } else {
        // The target date is in the past, more than one day ago
        const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        return `${daysAgo} days ago`;
      }
    }
  
    const jobApply = (jobId) => {
      const payload = {
        job_uuid: jobId,
      };
      axios
        .post(`${process.env.REACT_APP_API_BASEURL}/job/apply`, payload, {
          headers: {
            "x-auth-token": currentUser?.token,
          },
        })
        .then((res) => {
          console.log(res,"hhhjk")
          toast.success("Job Applied !", {
            position: "top-right",
          })
          dispatch(GeeksApplyJobPendingList(page, 10, "", currentUser?.token));
          HiredListFunction();
        })
        .catch((err) => {
          console.log("err", err);
        });
    };
  
    const checkapplyornot = (array) => {
      const data = array?.includes(currentUser?.data?.uuid);
      console.log(data, "datadata");
      return data;
    };
  
    const HiredListFunction = () => {
      axios
        .get(
          `${process.env.REACT_APP_API_BASEURL}/job/hiredListGeeks?pagenum=0&limit=10&search=`,
          {
            headers: { "x-auth-token": currentUser?.token },
          }
        )
        .then((res) => {
          console.log(res, "ghdfgjzshdhshj");
          setHiredDetails(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
    useEffect(() => {
      HiredListFunction();
    }, []);
  
    const staticPart = "${process.env.REACT_APP_IMAGE_BASEURL}/";
    // const profilepart = hireddetails?.image[0];
  
    const Image = staticPart;
  
    function truncate(source, size) {
      return source?.length > size ? source?.slice(0, size - 1) + "…" : source;
    }
  
  

          //   dashboard    integration 
          const [barchart1, setBarchart1] = useState([]);
          const [chartKey,setChartKey] = useState([]);
          const [chartValue, setChartValue] = useState([]);
          console.log(barchart1, "barchart1")
          const [dashboard, setDashboard] = useState("");
   
  
          const GetProfileDashboard = () => {
            axios
              .get(
                `${process.env.REACT_APP_API_BASEURL}/job/geeksDashboard`,
                {
                  headers: {
                    "x-auth-token": currentUser?.token,
                  },
                }
              )
              .then((res) => {
                console.log(res?.data, "qweqwewqe");
                if(res?.data?.data){
                  setBarchart1([])
                  let data = [];
                  let key = Object.keys(res?.data?.data);
                  setChartKey(key);
                  let value = Object.values(res?.data?.data);
                  setChartValue(value);
                  console.log("Key:",key)
                  key.forEach((key,index)=>{
                    data?.push({
                       label : key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),
                       value : value[index]
                    })
                  })
                  setBarchart1(data)
                  
                }
                setDashboard(res?.data);
              })
              .catch((err) => {
                console.log(err?.data , "asdasasd");
              });
          };
        
          useEffect(() => {
            GetProfileDashboard();
          }, []);



          const state1 ={
            series: [
              {
                name: "Jobs Detail",
                data: chartValue
              },
            
            ],
          };
        
          const options = {
            colors: ["#3C50E0", "#80CAEE"],
            chart: {
              fontFamily: "Satoshi, sans-serif",
              type: "bar",
              height: 335,
              stacked: true,
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
              },
            },
            responsive: [
              {
                breakpoint: 1536,
                options: {
                  plotOptions: {
                    bar: {
                      borderRadius: 0,
                      columnWidth: "25%",
                    },
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 0,
                columnWidth: "25%",
                borderRadiusApplication: "end",
                borderRadiusWhenStacked: "last",
              },
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
                categories: chartKey
              },
            legend: {
              position: "top",
              horizontalAlign: "left",
              fontFamily: "Satoshi",
              fontWeight: 500,
              fontSize: "14px",
              markers: {
                radius: 99,
              },
            },
            fill: {
              opacity: 1,
            },
          };
        
          const [chartData, setChartData] = useState({
            series: [1, 3, 5, 7, 2],
            options: {
              chart: {
                type: "donut",
              },
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 250,
                    },
                    legend: {
                      position: "bottom",
                    },
                  },
                },
              ],
            },
          });


     
      
      
      
      
        const staticPart1 = "${process.env.REACT_APP_IMAGE_BASEURL}/";
        const profilepart = details?.geeks_details?.personal_details?.profile_pic;
        const coverpart = details?.geeks_details?.personal_details?.cover_pic;
      
        const profile = staticPart1 + profilepart;
        const cover = staticPart + coverpart;
      
        function truncate(source, size) {
          return source?.length > size ? source.slice(0, size - 1) + "…" : source;
        }



        // profile setting web page 

        const params = useParams();
        const id = params.id;
        const [data, setData] = useState("");
      
        // const TextEditor = (editor) => {
        //     const data = editor.getData();
        //     setData(data)
        // }
      
      
        const [compProfile, setCompProfile] = useState("");
      
      
        const ImageUpload = (event, type) => {
          const data1 = event.target.files[0];
          const formData = new FormData();
          formData.append("file", data1);
          axios
            .post(`${process.env.REACT_APP_API_BASEURL}/upload/image`, formData)
            .then((res) => {
              if (type === "compProfile") {
                setCompProfile(res.data.image.filename);
              }
            })
            .catch((err) => {
              console.log(err.response);
            });
        };
      

      
        const GetProfileDetails = () => {
          axios
            .get(
              `${process.env.REACT_APP_API_BASEURL}/user/geeks/profile/${currentUser?.data?.uuid}`,
              {
                headers: {
                  "x-auth-token": currentUser?.token,
                },
              }
            )
            .then((res) => {
              console.log(res.data?.data, "hdsfcgshgfjs");
              setDetails(res.data?.data);
              setCompProfile(res?.data?.data?.geeks_details?.about?.company_profile);
              setData(
                res?.data?.data?.geeks_details?.about
                  ?.description_about_company_offering
              );
            })
            .catch((err) => {
              console.log(err);
            });
        };
      
        useEffect(() => {
          GetProfileDetails();
        }, []);
      
        // const [country, setCountry] = useState([{ country: '', reading: false, writing: false, listening: false }]);
      
        // const addCountry = () => {
        //     setCountry([...country, { language: '', reading: false, writing: false, listening: false }]);
        // };
      
        // const removeCountry = (index) => {
        //     const updatedLanguages = [...country];
        //     updatedLanguages.splice(index, 1);
        //     setCountry(updatedLanguages);
        // };
      
        // const handleCountryChange = (index, selectedLanguage) => {
        //     const updatedLanguages = [...country];
        //     updatedLanguages[index].language = selectedLanguage;
        //     setCountry(updatedLanguages);
        // };
      
        // const handleCountryChange = (index, type) => {
        //     const updatedLanguages = [...country];
        //     updatedLanguages[index][type] = !updatedLanguages[index][type];
        //     setCountry(updatedLanguages);
        // };
      
        
      
        const UpdateProfileCorporate = (e, Steptype) => {
          e.preventDefault();
          var payload = {};
          const dat = e.target.elements;
          console.log(dat, "dgjshfhgsjkhs");
      
          if (Steptype === "company_details") {
            payload = {
              company_name: dat?.company_name?.value,
              type_of_company: dat?.type_of_company?.value,
              company_incorporation_number: dat?.company_incorporation_number?.value,
              date_of_incorporation: dat?.date_of_incorporation?.value,
              pan_number: dat?.pan_number?.value,
              gstin: dat?.gstin?.value,
              company_website_url: dat?.company_website_url?.value,
              address_line1: dat?.address_line1?.value,
              address_line2: dat?.address_line2?.value,
              // country: dat?.country?.value,
              // city: dat?.city?.value,
              // state: dat?.state?.value,
              pincode: dat?.pincode?.value,
              city: city?.label,
              state: state?.label,
              country: country?.label,
            };
          } else if (Steptype === "technical_expertise") {
            const skilssData =
              details?.geeks_details?.technical_expertise?.skills?.length > 0
                ? details?.geeks_details?.technical_expertise?.skills?.map((item) => {
                    return item?.value ? item?.value : item;
                  })
                : [];
      
            const languageData =
              details?.geeks_details?.technical_expertise?.language?.length > 0
                ? details?.geeks_details?.technical_expertise?.language?.map(
                    (item) => {
                      return item?.value ? item?.value : item;
                    }
                  )
                : [];
      
            const certifiedskilssData =
              details?.geeks_details?.technical_expertise?.certified_skills?.length >
              0
                ? details?.geeks_details?.technical_expertise?.certified_skills?.map(
                    (item) => {
                      return item?.value ? item?.value : item;
                    }
                  )
                : [];
      
            //   const servicesSkillData = servicesSkil?.length > 0 ? servicesSkil?.map((item) => {
            //       return item?.value ? item?.value : item
            //   }) : []
      
            payload = {
              skills: skilssData,
              level_of_specialization: dat?.level_of_specialization?.value,
              // years_of_experience: dat?.experience?.value,
              certification_awards_recognition:
                dat?.certification_awards_recognition?.value,
              language: languageData,
              certified_skills: certifiedskilssData,
              // servicesSkillData: servicesSkil
            };
          } else if (Steptype === "about") {
            console.log(details?.geeks_details?.about?.primary_contact_number , "sasdasdas");
            payload = {
              primary_contact_name: dat?.primary_contact_name?.value,
              primary_contact_number: dat?.primary_contact_number?.value.replace(
                /[+\-\s]/g,
                ""
              ),
              
              primary_contact_email_id: dat?.primary_contact_email_id?.value,
              company_profile: compProfile,
              // description_about_company_offering: dat?.offerings?.value
              description_about_company_offering:
                dat?.description_about_company_offering?.value,
            };
          }
      
          // else if (activeStep >= 1) {
          const originalPayload = {
            geeks_type:
              details?.geeks_details?.geeks_type === "individual"
                ? "individual"
                : "corporate",
            type: Steptype,
            data: payload,
          };
          axios
            .put(
              `${process.env.REACT_APP_API_BASEURL}/user/geeks/profile/update/${currentUser?.data?.uuid}`,
              originalPayload,
              {
                headers: {
                  "x-auth-token": currentUser?.token,
                },
              }
            )
            .then((res) => {
              console.log(res, "dghsfdsjhkjshakj");
              toast.success(res?.data?.message, {
                position: "top-right",
              });
            })
            .catch((err) => {
              toast.error(err?.response?.message, {
                position: "top-right",
              });
              console.log(err.response, "yhikhh");
            });
          // }
        };
      
        const StyledBadge = styled(Badge)(({ theme }) => ({
          "& .MuiBadge-badge": {
            backgroundColor: "#44b700",
            color: "#44b700",
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            "&::after": {
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              animation: "ripple 1.2s infinite ease-in-out",
              border: "1px solid currentColor",
              content: '""',
            },
          },
          "@keyframes ripple": {
            "0%": {
              transform: "scale(.8)",
              opacity: 1,
            },
            "100%": {
              transform: "scale(2.4)",
              opacity: 0,
            },
          },
        }));
      
        const SmallAvatar = styled(Avatar)(({ theme }) => ({
          width: 50,
          height: 50,
          border: `2px solid ${theme.palette.background.paper}`,
        }));
      
        const [phone, setPhone] = useState("");
        const [special, setSpecial] = useState([]);
        const [activeStep, setActiveStep] = useState(0);
      
        const [step1Data, setStep1Data] = useState({});
        const [step2Data, setStep2Data] = useState({});
        const [step3Data, setStep3Data] = useState({});
        const [step4Data, setStep4Data] = useState({});
        const [step5Data, setStep5Data] = useState({});
      
        const [languagestep3, setLangStep3] = useState("NY");
        const [servicestep4, setServicestep4] = useState("1");
        const [check, setCheck] = useState(false);
        const [selectedTab, setSelectedTab] = useState(0);
      
        const [profileData, setProfileData] = useState("");
        console.log(profileData, "dsjvjssdjvg");
        // individual
        const [skills, setSkill] = useState("");
        const [level_of_specialization, setlevel_of_specialization] = useState("");
        const [bio123, setBio] = useState("");
        const [certifiedskills, setCertifiedSkill] = useState("");
        const [servicesSkil, setServicesSkill] = useState("");
        const [language, setLanguagesIndividual] = useState("");
        const [profileImage, setProfileImage] = useState("");
        const [coverimage, setcoverImage] = useState("");
        const [cv, setcv] = useState("");
        const [state, setState] = useState({
          label: profileData?.geeks_details?.company_details?.state,
          value: profileData?.geeks_details?.company_details?.state,
        });
        const [country, setCountry] = useState({
          label: profileData?.geeks_details?.company_details?.country,
          value: profileData?.geeks_details?.company_details?.country,
        });
        console.log(country, "dasasdasda");
        const [city, setCity] = useState({
          label: profileData?.geeks_details?.company_details?.city,
          value: profileData?.geeks_details?.company_details?.city,
        });
      
        // corporate
        // const [compProfile, setCompProfile] = useState('');
      
        const optionsLanguages = [
          { value: "ENGLISH", label: "ENGLISH" },
          { value: "TELUGU", label: " TELUGU" },
          { value: "HINDI", label: "HINDI" },
        ];
      
      
        const [limit, setlimit] = useState(0);
        // const [search, setsearch] = useState(0);
        const [search, setSearch] = useState("");
        const { servicesDropDown, services } = useSelector((state) => state.service);
      
        const changeScroll = (data, sarch) => {
          console.log(sarch, "data...");
          if (data === "search") {
            dispatch(servicesURL(page, 10, sarch));
          } else if (data === "inc" && page >= 0) {
            dispatch(servicesURL(page + 1, 10, search));
            setPage(page + 1);
          } else if (page >= 1) {
            dispatch(servicesURL(page - 1, 10, search));
            setPage(page - 1);
          }
        };
      
        console.log(services, servicesDropDown, "services");
      
        useEffect(() => {
          dispatch(servicesURL(page, 10, search));
          getProfileDetailes();
          getCompanyList();
          getSkillsList();
          // getstateList();
          // getcityList();
          getcountryList();
        }, [activeStep]);
      
        // step 0
        const [type, setType] = useState(false);
      
        const changeGeek = (data) => {
          setType(data);
        };
      
        const [data1, setData1] = useState("");
        const [ckeditor, setCkeditor] = useState("");
      
        const TextEditor = (editor) => {
          const ckeditor = editor?.getData();
          setData(ckeditor);
        };
        // const TextEditor1 = (editor) => {
        //     const data = editor?.getData();
        //     setData1(data)
        // }
      
        // if (editor) {
        //     // Access properties or methods on the editor
        // } else {
        //     console.error('CKEditor instance is null or undefined.');
        // }
      
        useEffect(() => {
          setServicesSkill(profileData?.geeks_details?.technical_expertise?.services);
          setCertifiedSkill(profileData?.geeks_details?.technical_expertise?.skills);
          setlevel_of_specialization(
            profileData?.geeks_details?.technical_expertise?.level_of_specialization
          );
          setLanguagesIndividual(
            profileData?.geeks_details?.technical_expertise?.language
          );
          setSkill(profileData?.geeks_details?.technical_expertise?.skills);
          setData(profileData?.geeks_details?.about?.bio);
          setData1(
            profileData?.geeks_details?.about?.description_about_company_offering
          );
          setState({
            label: profileData?.geeks_details?.company_details?.state,
            value: profileData?.geeks_details?.company_details?.state,
          });
          setCity({
            label: profileData?.geeks_details?.company_details?.city,
            value: profileData?.geeks_details?.company_details?.city,
          });
          console.log(profileData?.geeks_details?.company_details?.city,"fgdsgadagdsafga")
          setCountry({
            label: profileData?.geeks_details?.company_details?.country,
            value: profileData?.geeks_details?.company_details?.country,
          });
      
          // setProfileImage()
          // setcoverImage()
          // setcv()
          // setCompProfile()
        }, [profileData]);
      
        useEffect(() => {
          setType(
            profileData?.geeks_details?.geeks_type === "corporate" ? true : false
          );
        }, []);
      
        const getProfileDetailes = () => {
          axios
            .get(
              `${process.env.REACT_APP_API_BASEURL}/user/geeks/profile/${currentUser?.data?.uuid}`,
              {
                headers: {
                  "x-auth-token": currentUser?.token,
                },
              }
            )
            .then((res) => {
              console.log(res.data?.data, "dsjvjssdjvg");
              setProfileData(res?.data?.data);
            })
            .catch((err) => {
              console.log(err, "err");
            });
        };
      
        const [companyList, setCompanyList] = useState([]);
        const [companySelected, setCompanySelected] = useState("");
        const [optionsOne, setSkillsList] = useState([]);
        const [stateList, setStateList] = useState([]);
        const [cityList, setCityList] = useState([]);
        const [countryList, setCountryList] = useState([]);
        console.log(countryList, "sdasdadasda");
      
        console.log(companySelected, "companySelected");
        const getCompanyList = () => {
          axios
            .get(`${process.env.REACT_APP_API_BASEURL}/addondata/companyType`)
            .then((res) => {
              // console.log(res?.data?.data)
              setCompanyList(res?.data?.data);
            })
            .catch((err) => {
              console.log(err, "err");
            });
        };
      
        const getSkillsList = () => {
          axios
            .get(`${process.env.REACT_APP_API_BASEURL}/addondata/skills`)
            .then((res) => {
              console.log(res?.data?.data);
              setSkillsList(res?.data?.data);
            })
            .catch((err) => {
              console.log(err, "err");
            });
        };
      
        const getstateList = () => {
          axios
            .get(`${process.env.REACT_APP_API_BASEURL}/state/list`)
            .then((res) => {
              console.log(res?.data?.data, "tertetrerte");
              setStateList(res?.data?.data);
            })
            .catch((err) => {
              console.log(err, "err");
            });
        };
      
        const getcityList = (page, limit, search) => {
          axios
            .get(
              `${process.env.REACT_APP_API_BASEURL}/city/list?/city/list?page=&limit=&search=`
            )
            .then((res) => {
              console.log(res?.data?.data, "dfsdfdsfs");
              setCityList(res?.data?.data);
            })
            .catch((err) => {
              console.log(err, "err");
            });
        };
      
        const getcountryList = () => {
          axios
            .get(`${process.env.REACT_APP_API_BASEURL}/country/list`)
            .then((res) => {
              console.log(res?.data?.data, "erwerwre");
              setCountryList(res?.data?.data);
            })
            .catch((err) => {
              console.log(err, "err");
            });
        };
      
        const [statevalues, setStatevalues] = useState("");
        const [cityvalues, setCityvalues] = useState("");
      
        const GetStateDropdown = (state) => {
          axios
            .get(
              `${process.env.REACT_APP_API_BASEURL}/state/listByCountry/${state}`
            )
            .then((res) => {
              console.log(res);
              setStatevalues(res?.data?.data);
            })
            .catch((err) => {
              console.log(err, "err");
            });
        };
      
        useEffect(() => {
          GetStateDropdown(country?.value);
          GetCityDropdown(state?.value);
        }, []);
      
        const GetCityDropdown = (stateid) => {
          axios
            .get(`${process.env.REACT_APP_API_BASEURL}/city/listByState/${stateid}`)
            .then((res) => {
              console.log(res, "rafi123");
              setCityvalues(res?.data?.data);
            })
            .catch((err) => {
              console.log(err, "err");
            });
        };
      
        const CountryOption = [];
        countryList?.length > 0 &&
          countryList?.map((items) => {
            return CountryOption?.push({ label: items?.name, value: items?.uuid });
          });
      
        const CountryChangeFunction = (event) => {
          console.log(event, "event");
          setState("");
          setCountry(event);
          GetStateDropdown(event?.value);
        };
      
        const StateOption = [];
        statevalues?.length > 0 &&
          statevalues?.map((items) => {
            return StateOption?.push({ label: items?.name, value: items?.uuid });
          });
      
        const StateChangeFunction = (event) => {
          console.log(event, "event");
          setState(event);
          setCity("");
          GetCityDropdown(event?.value);
        };
      
        // state
      
        const CityOption = [];
        cityvalues?.length > 0 &&
          cityvalues?.map((items) => {
            return CityOption?.push({ label: items?.name, value: items?.uuid });
          });
      
        const CityChangeFunction = (event) => {
          console.log(event, "event");
          setCity(event);
        };
      
        // useEffect(() => {
        //   GetStateDropdown();
        //   GetCityDropdown(state?.value);
        // }, []);
      
        // const [servicesSkil, setServicesSkill] = useState('');
      
        const [GenderValue, setGenderValue] = useState(
          details?.geeks_details?.personal_details?.gender
        );
      
        const Skillsoptions = [];
      
        if (optionsOne?.length > 0) {
          optionsOne?.map((items) => {
            return Skillsoptions?.push({ value: items?.value, label: items?.label });
          });
        }
      
        const optionsLevels = [
          { value: "Beginer", label: "Beginer" },
          { value: "Intermediate", label: "Intermediate" },
          { value: "Expert", label: "Expert" },
        ];
      
        const UpdateProfileIndiviudal = (e, Steptype) => {
          e.preventDefault();
          var payload = {};
          const dat = e.target.elements;
          console.log(dat, "dgjshfhgsjkhs");
      
          if (Steptype === "personal_details") {
            payload = {
              gender: GenderValue,
              dob: dat?.dob?.value,
              address: dat?.address?.value,
              city: dat?.city?.value,
              state: dat?.state?.value,
              pincode: dat?.pincode?.value,
              adhar_number: dat?.aadhar?.value,
              pan_number: dat?.pan_number?.value,
              profile_pic: profileImage,
              cover_pic: coverimage,
            };
          } else if (Steptype === "technical_expertise") {
            const skilssData =
              details?.geeks_details?.technical_expertise?.skills?.length > 0
                ? details?.geeks_details?.technical_expertise?.skills?.map((item) => {
                    return item?.value ? item?.value : item;
                  })
                : [];
      
            const languageData =
              details?.geeks_details?.technical_expertise?.language?.length > 0
                ? details?.geeks_details?.technical_expertise?.language?.map(
                    (item) => {
                      return item?.value ? item?.value : item;
                    }
                  )
                : [];
      
            const certifiedskilssData =
              details?.geeks_details?.technical_expertise?.certified_skills?.length >
              0
                ? details?.geeks_details?.technical_expertise?.certified_skills?.map(
                    (item) => {
                      return item?.value ? item?.value : item;
                    }
                  )
                : [];
      
            // const servicesSkillData = details?.geeks_details?.technical_expertise?.servicesSkil?.length > 0 ? details?.geeks_details?.technical_expertise?.servicesSkil?.map((item) => {
            //     return item?.value ? item?.value : item
            // }) : []
      
            payload = {
              skills: skilssData,
              level_of_specialization: dat?.levelofspecialization?.value,
              // years_of_experience: dat?.experience?.value,
              language: languageData,
              certified_skills: certifiedskilssData,
              // services: servicesSkillData,
              certification_awards_recognition:
                dat?.certification_awards_recognition?.value,
            };
          } else if (Steptype === "about") {
            payload = {
              nic_name: dat?.nicname?.value,
              highest_qualification: dat?.qualification?.value,
              academic_certification_award_testimonials:
                dat?.academic_qualification?.value,
              cv: cv,
              // bio: dat?.bio?.value
              bio: data1,
            };
          }
      
          // else if (activeStep >= 1) {
          const originalPayload = {
            geeks_type:
              details?.geeks_details?.geeks_type === "individual"
                ? "individual"
                : "corporate",
            type: Steptype,
            data: payload,
          };
          axios
            .put(
              `${process.env.REACT_APP_API_BASEURL}/user/geeks/profile/update/${currentUser?.data?.uuid}`,
              originalPayload,
              {
                headers: {
                  "x-auth-token": currentUser?.token,
                },
              }
            )
            .then((res) => {
              console.log(res, "dghsfdsjhkjshakj");
              toast.success(res?.data?.message, {
                position: "top-right",
              });
            })
            .catch((err) => {
              console.log(err.response, "ujjju");
              toast.error(err?.response?.data?.message, {
                position: "top-right",
              });
            });
          // }
        };
      


    return (
        <>
            <Navbar navClass='justify-end nav-light'/>
            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Dashboard</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Home</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Dashboard</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>



            <section className="relative md:py-24 py-16 dashboard">
                    <Tabs
      aria-label="Vertical tabs"
      orientation="vertical"
  
    >
      <TabList>
        <Tab>Dashboard</Tab>
        <Tab>My Profile</Tab>
        <Tab>My Jobs</Tab>
      </TabList>
      <TabPanel value={0}>
        
      <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px] mb-5">
                        <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    {/* <BsTelephone className='text-slate-icon-400'/> */}
                                    <div className='text-slate-icon-400'>
                                    {dashboard?.data?.job_applied}
                                        </div>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Jobs Applied</h5>

                               
                            </div>
                        </div>


                        <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    {/* <LuMail className='text-slate-icon-400'/> */}
                                    <div className='text-slate-icon-400'> {dashboard?.data?.job_inprogress}</div> 
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Jobs Inprogress</h5>
                            </div>
                        </div>

                        <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                            
                                    <div className='text-slate-icon-400'>{dashboard?.data?.job_completed}</div>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Jobs Completed</h5>
                            </div>
                        </div>

                       
                    </div>
    
          <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px] border p-6">
            <div className="grid-cols-12 lg:col-span-6 md:col-span-12 xs:col-span-12">



            <div className="col-span-12 rounded-sm border border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-4 p-6">
        <div className="mb-4 justify-between gap-4 sm:flex">
          <div>
            <h4 className="text-xl font-semibold text-black dark:text-white">
            Jobs Detail
            </h4>
          </div>
          <div>
          </div>
        </div>

        <div>
          <div id="chartTwo" className="-ml-5 -mb-9">
            <ReactApexChart
              options={options}
              series={state.series}
              type="bar"
              height={350}
            />
          </div>
        </div>
      </div>
     
            </div>




            
            <div className="grid-cols-12 lg:col-span-6 md:col-span-12 xs:col-span-12" style={{ alignSelf:"center" }}>
            <PieChart
      series={[
        {
          data: barchart1?.map((item) => ({
            
            label: item?.label,
            value: item?.value,
          })),
          
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
        },
      ]}
      height={280}
    />

</div>
          </div>
       
      </TabPanel>
      <TabPanel value={1}>
   
      <div className="profile-banner relative text-transparent">
            <Stack>
              <Badge
                className="Coverbadge"
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <>
                    <SmallAvatar
                      alt="Remy Sharp"
                      src={pencil}
                      htmlFor="pro-img"
                      style={{ marginLeft: "270px", marginTop: "60px" }}
                    />
                    <input
                      id="pro-banner"
                      name="profile-banner"
                      type="file"
                      className="hidden"
                    />
                    <label
                      className="absolute inset-0 cursor-pointer"
                      htmlFor="pro-img"
                    ></label>
                  </>
                }
              >
         
                <img
                  src={`${process.env.REACT_APP_IMAGE_BASEURL}/${details?.geeks_details?.about?.company_profile}`}
                  crossOrigin="anonymous"
                  className="h-64 w-full object-cover lg:rounded-xl shadow dark:shadow-gray-700"
                  id="profile-banner"
                  alt=""
                />
                
              </Badge>
            </Stack>
          </div>
          <div className="md:flex mx-4 -mt-12">
            <div className="md:w-full">
              <div className="relative flex items-end">
                <Stack>
                  <input
                    id="pro-img"
                    name="profile-image"
                    type="file"
                    className="hidden"
                    onClick={ImageUpload}
                  />
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={
                      <>
                        <SmallAvatar
                          alt="Remy Sharp"
                          src={pencil}
                          htmlFor="pro-img"
                        />
                        <label
                          className="absolute inset-0 cursor-pointer"
                          htmlFor="pro-img"
                        ></label>
                      </>
                    }
                  >
                    <img
                      alt="Profile"
                      crossOrigin="anonymous"
                      src={
                        `${process.env.REACT_APP_IMAGE_BASEURL}/${details?.geeks_details?.about?.company_profile}` ||
                        team1
                      }
                      style={{
                        width: "140px",
                        height: "140px",
                        borderRadius: "50%",
                      }}
                    />
                  </Badge>
                </Stack>

                
                <div className="ms-4">
                  <h5 className="text-lg font-semibold">
                    {/* {details?.full_name} */}
                    {details?.first_name + " " + details?.last_name}
                  </h5>
                  <p className="text-slate-400">
                    {/* {data?.title ? data?.title : "UI Designer"} */}
                    {details?.geeks_details?.company_details?.city},
                    {details?.geeks_details?.company_details?.state}
                  </p>
                </div>
              </div>
            </div>
          </div>
       

        <div className="container mt-16">
          <div className="grid grid-cols-1 gap-[30px]">
            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
              <h5 className="text-lg font-semibold mb-4">
                Technical Expertise :
              </h5>

            

              <form
                onSubmit={(e) =>
                  UpdateProfileIndiviudal(e, "technical_expertise")
                }
              >
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                  <div>
                  <label className="form-label font-medium tooltip cursor-pointer">
                      Skills : <span className="text-red-600">*</span>
                      <span class="tooltiptext">
                        Antivirus Software <br/>
                        Linux <br/>
                        cisco routers 
                      </span>
                    </label>
                    <Select
                      name="skills"
                      value={
                        skills?.length > 0
                          ? skills?.map((item) => {
                              return {
                                value: item?.value ? item?.value : item,
                                label: item?.label ? item?.label : item,
                              };
                            })
                          : []
                      }
                      onChange={setSkill}
                      isMulti
                      options={Skillsoptions}
                    />
                  </div>

                  <div>
                  <label className="form-label font-medium tooltip cursor-pointer" data-tooltip-target="tooltip-default">
                      Level of Specialization :{" "}
                      <span className="text-red-600">*</span>
                      <span class="tooltiptext">
                        Beginner <br/>
                        Expert <br/>
                        Intermediate 
                      </span>
                    </label>
                    <Select
                      name="levelofspecialization"
                      options={optionsLevels}
                      defaultValue={{ label: special, value: special }}
                      onChange={(val) => setlevel_of_specialization(val.value)}
                      value={{
                        value: level_of_specialization,
                        label: level_of_specialization,
                      }}
                    />
                  </div>


                  <div>
                  <label className="form-label font-medium tooltip cursor-pointer">
                      Language : <span className="text-red-600">*</span>
                      <span class="tooltiptext">
                        Hindi <br/>
                        English <br/>
                        Telugu 
                      </span>
                    </label>
                    <Select
                      name="languages"
                      value={
                        language?.length > 0
                          ? language?.map((item) => {
                              return {
                                value: item?.value ? item?.value : item,
                                label: item?.label ? item?.label : item,
                              };
                            })
                          : []
                      }
                      onChange={setLanguagesIndividual}
                      isMulti
                      options={optionsLanguages}
                    />
                  </div>

                  <div>
                  <label className="form-label font-medium tooltip cursor-pointer">
                      Certificated Skills :{" "}
                      <span className="text-red-600">*</span>
                      <span class="tooltiptext">
                        Oracle <br/>
                        Juniper routers <br/>
                        Linux 
                      </span>
                    </label>
                    <Select
                      name="Certified skills"
                      value={
                        certifiedskills?.length > 0
                          ? certifiedskills?.map((item) => {
                              return {
                                value: item?.value ? item?.value : item,
                                label: item?.label ? item?.label : item,
                              };
                            })
                          : []
                      }
                      onChange={(selectedOptions) =>
                        setCertifiedSkill(selectedOptions)
                      }
                      isMulti
                      options={Skillsoptions}
                    />

                
                  <div>
                    <label className="form-label font-medium">
                      Certification Awards Recognition :
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.technical_expertise
                          ?.certification_awards_recognition
                      }
                      name="certification_awards_recognition"
                    />
                  </div>
                </div>
                <input
                  type="submit"
                  id="submit"
                  name="send"
                  className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5 cursor-pointer"
                  value="Save Changes"
                />
                </div>
              </form>
            </div>

            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
              <h5 className="text-lg font-semibold mb-4">About</h5>
              <form onSubmit={(e) => UpdateProfileCorporate(e, "about")}>
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                  <div>
                    <label className="form-label font-medium">
                      Name : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.about?.primary_contact_name
                      }
                      id="primary_contact_name"
                      name="primary_contact_name"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Email : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.about?.primary_contact_email_id
                      }
                      id="primary_contact_email_id"
                      name="primary_contact_email_id"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Description about company offering :{" "}
                      <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.about
                          ?.description_about_company_offering
                      }
                      id="description_about_company_offering"
                      name="description_about_company_offering"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Phone Number: <span className="text-red-600">*</span>
                    </label>

                    <PhoneInput
                      country={"in"}
                      value={phone}
                      onChange={(newPhone) => setPhone(newPhone)}
                      masks={{ in: "............", at: "(....) ...-...." }}
                      type="number"
                      defaultValue={details?.geeks_details?.about?.primary_contact_number?.replace(
                        /[+\-\s]/g,
                        ""
                      )}
                      name="primary_contact_number"
                      required=""
                      className="mt-2"
                      inputProps={{
                        id: "primary_contact_number",
                      }}
                    />

                   
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      {" "}
                      Upload Profile : <span className="text-red-600">*</span>
                    </label>
                    <input
                      className="relative form-input border border-slate-100 dark:border-slate-800 file:h-10 file:-mx-3 file:-my-2 file:cursor-pointer file:rounded-none file:border-0 file:px-3 file:text-neutral-700 bg-clip-padding px-3 py-1.5 file:me-3 mt-2"
                      onChange={(e) => ImageUpload(e, "compProfile")}
                      id="multiple_files"
                      type="file"
                      defaultValue={details?.geeks_details?.about?.compProfile}
                    />
                  </div>
                </div>
               
                <input
                  type="submit"
                  id="submit"
                  name="send"
                  className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5 cursor-pointer"
                  value="Save Changes"
                />
              </form>
            </div>

            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
              <h5 className="text-lg font-semibold mb-4">Company Details</h5>
              <form
                onSubmit={(e) => UpdateProfileCorporate(e, "company_details")}
              >
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                  <div>
                    <label className="form-label font-medium">
                      Company Name : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details?.company_name
                      }
                      id="firstname"
                      name="company_name"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium tooltip cursor-pointer">
                    Type of company :{" "}
                      <span className="text-red-600">*</span>
                      <span class="tooltiptext">
                        Proprietary <br/>
                        Partnership <br/>
                        LLP <br/>
                       Private Limited
                      </span>
                    </label>

                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details?.type_of_company
                      }
                      id="lastname"
                      name="type_of_company"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      CIN : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details
                          ?.company_incorporation_number
                      }
                      name="company_incorporation_number"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Date of CIN : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details
                          ?.date_of_incorporation
                      }
                      name="date_of_incorporation"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      PAN : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details?.pan_number
                      }
                      name="pan_number"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      GSTIN : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details?.gstin
                      }
                      name="gstin"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Website URL : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details
                          ?.company_website_url
                      }
                      name="company_website_url"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Address Line 1 : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details?.address_line1
                      }
                      name="address_line1"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Address Line 2 : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details?.address_line2
                      }
                      name="address_line2"
                      required=""
                    />
                  </div>

               

                  
                  <div>
                    <label className="form-label font-medium">
                      Country : <span className="text-red-600">*</span>
                    </label>

                    <Select
                      name="state"
                      value={country}
                      onChange={CountryChangeFunction}
                      options={CountryOption}
                      defaultValue={{
                        label:
                          profileData?.geeks_details?.company_details?.country,
                        value:
                          profileData?.geeks_details?.company_details?.country,
                      }}
                    />


                    
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      State : <span className="text-red-600">*</span>
                    </label>

                    <Select
                      name="state"
                      value={state}
                      onChange={StateChangeFunction}
                      options={StateOption}
                      defaultValue={{
                        label:
                          profileData?.geeks_details?.company_details?.state,
                        value:
                          profileData?.geeks_details?.company_details?.state,
                      }}
                    />

                 
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      City : <span className="text-red-600">*</span>
                    </label>

                    <Select
                      name="state"
                      value={city}
                      onChange={CityChangeFunction}
                      options={CityOption}
                      defaultValue={{
                        value:
                          profileData?.geeks_details?.company_details?.city,
                        label:
                          profileData?.geeks_details?.company_details?.city,
                      }}
                    />

                  
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      ZIP : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details?.pincode
                      }
                      name="pincode"
                      required=""
                    />
                  </div>
                </div>
                <input
                  type="submit"
                  id="submit"
                  name="send"
                  className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5 cursor-pointer"
                  value="Save Changes"
                />
              </form>
            </div>


            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
              <h5 className="text-lg font-semibold mb-5 text-red-600">
                Delete Account :
              </h5>
              <p className="text-slate-400 mb-4">
                Do you want to delete the account? Please press below "Delete"
                button
              </p>
              <Link
                to="#"
                className="btn bg-red-600 hover:bg-red-700 text-white rounded-md"
              >
                Delete
              </Link>
            </div>
          </div>
        </div>
     
      </TabPanel>
      <TabPanel value={2}>
     
          <div
            style={{
              border: "1px solid #68712e",
              padding: "20px",
              borderRadius: "10px",
              marginBottom:"20px"
            }}
          >
            <Tabs aria-label="Basic tabs" defaultValue={0}>
              <div className="grid lg:grid-cols-10 md:grid-cols-5 mt-12 gap-[30px]">
                <TabList>
                  <Tab>Pending</Tab>
                  <Tab>Hired</Tab>
                </TabList>
              </div>

              <TabPanel value={0}>
                <div>
                  <div className="grid lg:grid-cols-10 md:grid-cols-5 mt-12 gap-[30px]">
                    {pendingJobList?.length > 0 &&
                      pendingJobList?.map((item, index) => (
                        <div
                          className="group p-6 rounded-lg border border-emerald-600/20 dark:border-emerald-600/40 bg-white dark:bg-slate-900 hover:bg-emerald-600/[0.02] hover:dark:bg-emerald-600/5 hover:shadow-md hover:shadow-emerald-600/5 transition-all duration-500"
                          key={index}
                        >
                          <div className="flex justify-between items-start">
                            <div>
                              
                            </div>

                            <div className="flex items-center">
                              <input
                                type="button"
                                className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white searchbtn submit-btn w-100 cursor-pointer"
                                disabled={checkapplyornot(
                                  item?.applied_user_uuid
                                )}
                                value={
                                  checkapplyornot(item?.applied_user_uuid) ===
                                  true
                                    ? "Applied"
                                    : "Apply"
                                }
                                onClick={() => jobApply(item.uuid)}
                              />
                              
                            </div>
                          </div>

                          <div className="mt-1">
                          
                            <Link
                              to={`/JobListProfile/${item.uuid}`}
                              className="text-xl hover:text-emerald-600 font-semibold transition-all duration-500"
                            >
                              {item.title}
                            </Link>
                            <div className="mt-5">
                              <small class="text-muted display-inline-block text-muted">
                                <span>
                                  Posted &nbsp;
                                  <span data-test="posted-on">
                                    {calculateTimeAgo(item.createdAt)}
                                  </span>
                                </span>
                              </small>
                            </div>
                            <p className="text-slate-400 mt-2">
                              {truncate(
                                item.description?.replace(/(<([^>]+)>)/gi, ""),
                                400
                              )}
                            </p>
                            <div className="mt-3">
                              {item?.skills?.map((item) => {
                                return (
                                  <span className="bg-orange-500/5 hover:bg-orange-500/20 dark:bg-orange-500/10 hover:dark:bg-orange-500/30 inline-block text-orange-500 px-4 text-[14px] font-medium rounded-full mt-2 me-1 transition-all duration-500">
                                    {item?.value ? item?.value : item}
                                  </span>
                                );
                              })}
             
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div>
                    <a
                      id="back-to-top"
                      class="back-to-top fixed text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 text-center bg-emerald-600 text-white justify-center items-center flex"
                      href="#"
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 1024 1024"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={1}>
                <div className="grid lg:grid-cols-10 md:grid-cols-5 mt-12 gap-[30px]">
                  {hireddetails?.data?.length > 0 &&
                    hireddetails?.data?.map((item, index) => (
                      <div
                        className="group p-6 rounded-lg border border-emerald-600/20 dark:border-emerald-600/40 bg-white dark:bg-slate-900 hover:bg-emerald-600/[0.02] hover:dark:bg-emerald-600/5 hover:shadow-md hover:shadow-emerald-600/5 transition-all duration-500"
                        key={index}
                      >
                        <div className="flex justify-between items-start">
                          <div>
                           
                             <Link
                            to={`/ViewAppliedJobs/${item?.uuid}`}
                            className="text-xl hover:text-emerald-600 font-semibold transition-all duration-500"
                          >
                            {item.title}
                          </Link>
                          </div>
                          <div className="flex items-center">
                            <Link to={`/ViewAppliedJobs/${item?.uuid}`}>
                              <input
                                type="button"
                                className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white searchbtn submit-btn w-100 cursor-pointer"
                                value={"View"}
                              />
                            </Link>
                          </div>
                        </div>

                        <div className="mt-1">
                       
                         
                          <div className="mt-5">
                            <small class="text-muted display-inline-block text-muted">
                              <span>
                                Posted &nbsp;
                                <span data-test="posted-on">
                                  {calculateTimeAgo(item?.createdAt)}
                                </span>
                              </span>
                            </small>
                          </div>
                          <p className="text-slate-400 mt-2">
                            {truncate(
                              item?.description?.replace(/(<([^>]+)>)/gi, ""),
                              400
                            )}
                          </p>
                          <div className="mt-3">
                            {item?.skills?.map((items) => {
                              console.log(items, "services")
                              return (
                                <span className="bg-orange-500/5 hover:bg-orange-500/20 dark:bg-orange-500/10 hover:dark:bg-orange-500/30 inline-block text-orange-500 px-4 text-[14px] font-medium rounded-full mt-2 me-1 transition-all duration-500">
                                  {items?.label ? items?.label : items}
                                </span>
                              );
                            })}
                          </div>
                          <div className="mt-3">
                            {item?.services?.map((services) => {
                              console.log(services, "services")
                              return (
                                <span className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full mt-2 me-1">
                                  {services?.name ? services?.name : services}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

              
                <div>
                  <a
                    id="back-to-top"
                    class="back-to-top fixed text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 text-center bg-emerald-600 text-white justify-center items-center flex"
                    href="#"
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 1024 1024"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                    </svg>
                  </a>
                </div>
              </TabPanel>
            </Tabs>
         
      </div>
      </TabPanel>
    </Tabs>
              
           

             
            </section>
          



     



      




     
                

            

            <Footer style={{ bottom :"0px" }}/>
        </>
    )
}
