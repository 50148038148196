import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import JobDetailComp from "../components/job-detail-comp";
import Footer from "../components/Footer";
import { Link, useParams, useLocation } from "react-router-dom";
import closeButton from "../assets/images/closeButton.png";
import { Modal } from "@mui/material";
import {
  Book,
  Briefcase,
  Clock,
  DollarSign,
  MapPin,
  Monitor,
  UserCheck,
} from "react-feather";
import { MdOutlineArrowForward } from "react-icons/md";
import ExploreJob from "../components/Explore-job";
import { jobData } from "../data/data";
import axios from "axios";
import { useSelector } from "react-redux";
import { Button, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import team1 from "../assets/images/team/01.jpg";
import BigImages1 from '../assets/images/blog/04.jpg'
import BigImages2 from '../assets/images/hero/bg2.jpg'
import BigImages3 from '../assets/images/hero/bg3.jpg'
import BigImages4 from '../assets/images/hero/bg4.jpg'
import NewJobPost from "./NewJobPost";
import {initLightboxJS} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css';
import {SlideshowLightbox} from 'lightbox.js-react'
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { ImageExtended } from "react-grid-gallery";
import {images} from '../components/Images';
import './MultiStepForm.css'



export default function ViewAppliedJobs() {
  const params = useParams();
  const id = params.id;
  const data = jobData.find((jobs) => jobs.id === parseInt(id));
  const { currentUser } = useSelector((state) => state.auth);


  const [jobDetailes, setJobDetailes] = useState({});
  console.log(jobDetailes, "ghsdfjdgjfh");

  // const ProgressSteps = ['Geeks Type', 'Technical Expertise', 'Personal Details', 'About'];

  const [ProgressSteps, setProgressSteps] = useState([]);
  const [UpdateButton, setUpdateButton] = useState("");
  const [activeStep, setActiveStep] = React.useState(1);

  const JObDetailes = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/job/postview/${id}`, {
        headers: {
          "x-auth-token": currentUser?.token,
        },
      })
      .then((res) => {
        console.log(res.data, "response");
        setJobDetailes(res?.data);
        const data = [];
        res?.data?.data.status?.map((item) => {
          data.push(item?.status);
        });
        setProgressSteps(data);
        if (res?.data?.data?.job_status === "pending") {
          setUpdateButton("Accept");
          setActiveStep(1);
        } else if (res?.data?.data?.job_status === "accepted") {
          setUpdateButton("initiate");
          setActiveStep(2);
        } else if (res?.data?.data?.job_status === "initiate") {
          setUpdateButton("inprogress");
          setActiveStep(3);
        } else if (res?.data?.data?.job_status === "inprogress") {
          setUpdateButton("review");
          setActiveStep(4);
        } else if (res?.data?.data?.job_status === "review") {
          setActiveStep(5);
        } else if (res?.data?.data?.job_status === "completed") {
          setActiveStep(7);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    JObDetailes();
  }, []);

  const handleStatus = (data) => {
    let jobStatus = "";

    if (jobDetailes?.data?.job_status === "pending") {
      jobStatus = "accepted";
    } else if (jobDetailes?.data?.job_status === "accepted") {
      jobStatus = "initiate";
    } else if (jobDetailes?.data?.job_status === "initiate") {
      jobStatus = "inprogress";
    } else if (jobDetailes?.data?.job_status === "inprogress") {
      jobStatus = "review";
    }

    var payload = {
      status: jobStatus,
      job_uuid: id,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_BASEURL}/job/updateStatus`,
        payload,
        {
          headers: {
            "x-auth-token": currentUser?.token,
          },
        }
      )
      .then((res) => {
        toast.success("Status Updated", {
          position: "top-right",
        })
        JObDetailes();
      })
      .catch((err) => {
        toast.error("Error", {
          position: "top-right",
        }) 
      });
  };

  const jobApply = () => {
    const payload = {
      job_uuid: id,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/jobpost/apply`, payload, {
        headers: {
          "x-auth-token": currentUser?.token,
        },
      })
      .then((res) => {
        toast.success("Job applied", {
          position: "top-right",
        })
      })
      .catch((err) => {
        toast.error("Error" ,{
          position:'top-right'
        })
      });
  };

  const steps = ["Skills", "Service", "Image"];

  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const checkapplyornot = (array) => {
    const data = array?.includes(currentUser?.data?.uuid);
    console.log(data, "datadata");
    return data;
  };

  const stepArray = [
    "Pending",
    "Accecpted",
    "Intiated",
    "InProgress",
    "Review",
    "Completed",
  ];

  const StepCheck = (data, index) => {
    if (Number(activeStep) === Number(index)) {
      return (
        <li class="step is-active" data-step={String(index)}>
          {data}
        </li>
      );
    } else if (Number(activeStep) > Number(index)) {
      return (
        <li class="step is-complete" data-step={String(index)}>
          {data}
        </li>
      );
    } else {
      return (
        <li class="step" data-step={String(index)}>
          {data}
        </li>
      );
    }
  };


//   let images = [
//     {
//         url:"https://source.unsplash.com/user/c_v_r/1900x800 ",
//         title:"image title 1"
//     },
//     {
//         url:"https://source.unsplash.com/user/c_v_r/100x100",
//         title:"image title 2"
//     }
// ]

const [open, setOpen] = React.useState(false);
// const [profUuid, setProfUuid] = useState("");

const handleOpen = () => {
  // setProfUuid(uuid)
  setOpen(true);
};

// const handleClose = () => setOpen(false);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: 'none',
  boxShadow: 24,
  padding:0,
  width: "60%", // Adjust the width as needed
  height: "auto", // Adjust the height as needed
};



useEffect(() => {
  initLightboxJS("Insert your License Key here", "Insert plan type here");
}, []);

const [index, setIndex] = useState(-1);

const currentImage = images[index];
const nextIndex = (index + 1) % images.length;
const nextImage = images[nextIndex] || currentImage;
const prevIndex = (index + images.length - 1) % images.length;
const prevImage = images[prevIndex] || currentImage;

const handleClick = (index, item) => setIndex(index);
const handleClose = () => setIndex(-1);
const handleMovePrev = () => setIndex(prevIndex);
const handleMoveNext = () => setIndex(nextIndex);







const [isFullImageOpen, setIsFullImageOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openFullImage = (index) => {
    setSelectedImageIndex(index);
    setIsFullImageOpen(true);
  };

  const closeFullImage = () => {
    setIsFullImageOpen(false);
    setSelectedImageIndex(null);
  };

  const showPreviousImage = () => {
    if (selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };

  const showNextImage = () => {
    if (selectedImageIndex < jobDetailes.data.image?.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };

  const FullImageDisplay = ({ imageUrl, onClose }) => {
    return (
      <div className="full-image-display" >
        <div className="overlay"></div>
        <div  style={{ display:"flex" ,justifyContent:"center",alignItems:"flex-start" }}>
<div >
<img 
        // src={`https://api.god.scienstechnologies.com/user/images/${imageUrl}`}
         alt="Full Size" className="full-image" 
        
        src={`https://api.god.scienstechnologies.com/user/images/${imageUrl}`}
        // alt={`Image ${index + 1}`}
        // onClick={() => openFullImage(index)}
        // className="thumbnail"
        crossOrigin="anonymous"
        />
        </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div >
<img src={closeButton} alt="closeButton" style={{ width:"40px" ,height:"40px" ,cursor:"pointer"}} onClick={onClose}/>
</div>
        </div>
        
      </div>
    );
  };



  const handleKeyboardNavigation = (event) => {
    if (isFullImageOpen) {
      switch (event.key) {
        case 'ArrowLeft':
          showPreviousImage();
          break;
        case 'ArrowRight':
          showNextImage();
          break;
        case 'Escape':
          closeFullImage();
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardNavigation);
    return () => {
      document.removeEventListener('keydown', handleKeyboardNavigation);
    };
  }, [isFullImageOpen, selectedImageIndex]);




  function truncate(source, size) {
    return source?.length > size ? source.slice(0, size - 1) + "…" : source;
  }


  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };


  return (
    <>
      <Navbar navClass="justify-end nav-light" />
      <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-emerald-900/90"></div>
        <div className="container">
          <div className="grid grid-cols-1 text-center mt-10">
            {/* {data?.heading ? (
              <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">
                {data?.heading}
              </h3>
            ) : ( */}
              <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">
                Job Progress
              </h3>
            {/* // )} */}
          </div>
        </div>
        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
            <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/">Home</Link>
            </li>
            <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/AppliedJobs">Applied Jobs</Link>
            </li>
            <li
              className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Job View
            </li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="relative mt-12">
        <div className="container md:pb-24 pb-16 ">
          <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
            <div className="lg:col-span-12 md:col-span-12">
              {/* <h3 className=" text-3xl leading-normal font-semibold text-white flex items-center justify-center">
                Job Progress
              </h3> */}
              {/* <Stepper activeStep={activeStep} alternativeLabel style={{ paddingTop: "8%" }}>
                                {ProgressSteps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))
                                }
                            </Stepper> */}
              <ol class="steps">
                {stepArray?.map((item, index) => {
                  return StepCheck(item, index + 1);
                })}
              </ol>
              {jobDetailes?.data?.job_status !== "review" && (
                <Button
                  style={{ display: "flex", justifyContent: "end" }}
                  onClick={handleStatus}
                  className="justify-content-end btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md pl-5 pr-5"
                >
                  {UpdateButton}
                </Button>
              )}
                                  <h5 className="text-lg font-semibold mt-5">
                      {jobDetailes?.data?.title}
                    </h5>
                    <p className="text-slate-400 mt-4">
                    {/* {truncate(
                                jobDetailes?.data?.description?.replace(/(<([^>]+)>)/gi, ""),
                                400
                              )}
                                <span style={{ color: "#1daa82", cursor: "pointer" }}>
                  {showFullText ? "Show Less" : "Show More"}
                </span> */}
  <p className="text-slate-400 mt-4" >

                {showFullText
                  ? jobDetailes?.data?.description?.replace(/(<([^>]+)>)/gi, "")
                  : jobDetailes?.data?.description
                      ?.replace(/(<([^>]+)>)/gi, "")
                      .slice(0, 400)}{" "}
                <br />
                <span style={{ color: "#1daa82", cursor: "pointer" }} onClick={toggleText}>
                  {showFullText ? "Show Less" : "Show More"}
                </span>
                </p>
                      {/* {jobDetailes?.data?.description?.replace(/(<([^>]+)>)/gi, "")} */}
                    </p>
                    <h4 className="mt-6 text-xl font-semibold">Skills</h4>
                    <Grid item container className="mt-5 gap-2">
                      <Grid xs={12} md={12}>
                        {jobDetailes?.data?.skills?.map((skills) => {
                          return (
                            <>
                              <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full">
                                {skills?.label ? skills?.label : skills}
                              </div>
                              {/* &nbsp;&nbsp; */}
                            </>
                          );
                        })}
                      </Grid>
                    </Grid>
                    <h4 className="mt-6 text-xl font-semibold">Services</h4>
                    <Grid item container className="mt-5 gap-3">
                      <Grid xs={12} md={12} className="gap-3">
                        {jobDetailes?.data?.services?.map((service) => {
                          return (
                            <>
                              <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full mt-2">
                                {service?.label ? service?.label : service}{" "}
                                &nbsp;&nbsp;
                              </div>
                              &nbsp;&nbsp;
                            </>
                          );
                        })}
                      </Grid>
                    </Grid>

                    <h4 className="mt-6 text-xl font-semibold">Languages</h4>
                    <Grid item container className="mt-5 gap-2">
                      <Grid xs={12} md={12}>
                        {jobDetailes?.data?.languages?.map((languages) => {
                          return (
                            <>
                              <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full">
                                {languages?.value ? languages?.value : languages}
                              </div>
                              &nbsp;&nbsp;
                            </>
                          );
                        })}
                      </Grid>
                    </Grid>
              {/* {jobDetailes?.data?.map((item) => {
                console.log(item, "gfhdsfgshfjsgdjsh");
                return (
                  <>
                    <h5 className="text-lg font-semibold mt-5">
                      {jobDetailes?.data?.title}
                    </h5>
                    <p className="text-slate-400 mt-4">
                      {jobDetailes?.data?.description?.replace(/(<([^>]+)>)/gi, "")}
                    </p>
                    <h4 className="mt-6 text-xl font-semibold">Skills</h4>
                    <Grid item container className="mt-5 gap-2">
                      <Grid xs={12} md={12}>
                        {jobDetailes?.data?.skills?.map((skills) => {
                          return (
                            <>
                              <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full">
                                {skills?.label ? skills?.label : skills}
                              </div>
                              &nbsp;&nbsp;
                            </>
                          );
                        })}
                      </Grid>
                    </Grid>
                    <h4 className="mt-6 text-xl font-semibold">Services</h4>
                    <Grid item container className="mt-5 gap-3">
                      <Grid xs={12} md={12} className="gap-3">
                        {jobDetailes?.data?.services?.map((service) => {
                          return (
                            <>
                              <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full mt-2">
                                {service?.label ? service?.label : service}{" "}
                                &nbsp;&nbsp;
                              </div>
                              &nbsp;&nbsp;
                            </>
                          );
                        })}
                      </Grid>
                    </Grid>

                    <h4 className="mt-6 text-xl font-semibold">Languages</h4>
                    <Grid item container className="mt-5 gap-2">
                      <Grid xs={12} md={12}>
                        {jobDetailes?.data?.languages?.map((languages) => {
                          return (
                            <>
                              <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full">
                                {languages}
                              </div>
                              &nbsp;&nbsp;
                            </>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </>
                );
              })} */}
            

{/* <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
<Grid item container className="mt-5 gap-2">
                      <Grid xs={12} md={12}>
    <img className="w-full rounded" src="https://source.unsplash.com/pAKCx4y2H6Q/1400x1200" />
    </Grid>
    <Grid xs={12} md={12}>
    <img className="w-full rounded" src="https://source.unsplash.com/AYS2sSAMyhc/1400x1200" />  
    </Grid>
    <Grid xs={12} md={12}>
    <img className="w-full rounded" src="https://source.unsplash.com/Kk8mEQAoIpI/1600x1200" />
    </Grid>
    <Grid xs={12} md={12}>
    <img className="w-full rounded" src="https://source.unsplash.com/HF3X2TWv1-w/1600x1200" />
    </Grid>
  
</Grid>
</SlideshowLightbox>  */}
     <h4 className="mt-6 text-xl font-semibold mb-4">Images</h4>
     <div>
     <div className="image-container">
                        {jobDetailes?.data?.image?.slice(0, 4).map((imageUrl, index) => (
                          <img
                            key={index}
                            src={`https://api.god.scienstechnologies.com/user/images/${imageUrl}`}
                            alt={`Image ${index + 1}`}
                            onClick={() => openFullImage(index)}
                            className="thumbnail"
                            crossOrigin="anonymous"
                          />
                        ))}
                      </div>

                      {isFullImageOpen && (
                        <FullImageDisplay
                          imageUrl={jobDetailes.data.image[selectedImageIndex]}
                          onClose={closeFullImage}
                        />
                      )}

                      {/* {isFullImageOpen && selectedImageIndex > 0 && (
                        <button
                          className="buttonImagepre"
                          onClick={showPreviousImage}
                        >
                          Previous
                        </button>
                      )}

                      {isFullImageOpen &&
                        selectedImageIndex < jobDetailes?.image.length - 1 && (
                          <button
                            className="buttonImageNext"
                            onClick={showNextImage}
                          >
                            Next
                          </button>
                        )} */}
                    </div>
   {/* <div>
      <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
        className="GalleryImage"
      />
      {!!currentImage && (
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </div> */}


            </div>
          </div>
        </div>
      </section>

      <Footer />

      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        dialogClassName="full"
      >
        <Box sx={style} >
       
              <div>
 
                <img src={BigImages1} alt="BigImages" style={{ width:"100%",height:"100%" }}  onClick={() => handleClose()}/>
             
              </div>
       
       
        </Box>
      </Modal> */}



    </>
  );
}
