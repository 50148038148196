import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Navbar from "../../components/Navbar";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import bg5 from "../../assets/images/hero/bg5.jpg";
import team1 from "../../assets/images/team/01.jpg";
import { Link, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Twitter, Facebook, Instagram, Linkedin, Youtube } from "react-feather";
import Select from "react-select";
import { countries } from "countries-list";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { servicesURL } from "../../Redux/services/servicesSlice";

export default function CandidateProfileSetting({ etails }) {
  const [servicesSkil, setServicesSkill] = useState("");
  const [certifiedskills, setCertifiedSkill] = useState([]);
  const [special, setSpecial] = useState([]);
  const [optionsOne, setSkillsList] = useState([]);
  const [skills, setSkill] = useState("");
  const [language, setLanguagesIndividual] = useState("");

  const params = useParams();
  const id = params.id;
  const [details, setDetails] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [coverimage, setcoverImage] = useState("");
  const [cv, setcv] = useState("");
  const [GenderValue, setGenderValue] = useState(
    details?.geeks_details?.personal_details?.gender
  );
  const [data1, setData] = useState(details?.geeks_details?.about?.bio);
  const { currentUser } = useSelector((state) => state.auth);
  console.log(details?.geeks_details?.personal_details, "details");

  const GetProfileDetails = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/user/geeks/profile/${id}`, {
        headers: {
          "x-auth-token": currentUser?.token,
        },
      })
      .then((res) => {
        console.log(res.data?.data, "hdsfcgshgfjs");
        setDetails(res.data?.data);
        setcoverImage(
          res?.data?.data?.geeks_details?.personal_details?.cover_pic
        );
        setProfileImage(
          res?.data?.data?.geeks_details?.personal_details?.profile_pic
        );
        setGenderValue(
          res?.data?.data?.geeks_details?.personal_details?.gender
        );
        setServicesSkill(
          res?.data?.data?.geeks_details?.technical_expertise?.services
        );
        setSkill(res?.data?.data?.geeks_details?.technical_expertise?.skills);
        setCertifiedSkill(
          res?.data?.data?.geeks_details?.technical_expertise?.certified_skills
        );
        setLanguagesIndividual(
          res?.data?.data?.geeks_details?.technical_expertise?.language
        );
        setSpecial(
          res?.data?.data?.geeks_details?.technical_expertise
            ?.level_of_specialization
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetProfileDetails();
  }, []);

  const { servicesDropDown, services } = useSelector((state) => state.service);
  console.log(services, servicesDropDown, "services");

  const optionsLanguages = [
    { value: "ENGLISH", label: "ENGLISH" },
    { value: "TELUGU", label: " TELUGU" },
    { value: "HINDI", label: "HINDI" },
  ];
  const optionsLevels = [
    { value: "Beginer", label: "Beginer" },
    { value: "Intermediate", label: "Intermediate" },
    { value: "Expert", label: "Expert" },
  ];

  const genderOptions = [
    { value: "male", label: "male" },
    { value: "female", label: "female" },
  ];
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (selected) => {
    setSelectedOption(selected);
  };

  const certifiedSkillsData = certifiedskills.map((item) => ({
    value: item.value ? item.value : item,
    label: item.label ? item.label : item,
  }));

  const payload = {
    certified_skills: certifiedSkillsData,
  };

  useEffect(() => {
    // Your other useEffect code

    // Fetch skills list on component mount
    getSkillsList();
  }, []); // Empty dependency array to run the effect only once on mount

  const getSkillsList = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/addondata/skills`)
      .then((res) => {
        console.log(res, "hdsfgsfgshsss");
        setSkillsList(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const Skillsoptions = [];

  if (optionsOne?.length > 0) {
    optionsOne?.map((items) => {
      return Skillsoptions?.push({ value: items?.value, label: items?.label });
    });
  }

  const languageData =
    language?.length > 0
      ? language?.map((item) => {
          return item?.value ? item?.value : item;
        })
      : [];

  const ImageUpload = (event, type) => {
    const data1 = event.target.files[0];
    const formData = new FormData();
    formData.append("file", data1);
    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/upload/image`, formData)
      .then((res) => {
        // console.log(res.data.image.filename)
        if (type === "profile") {
          setProfileImage(res.data.image.filename);
        } else if (type === "cv") {
          setcv(res.data.image.filename);
        } else if (type === "cover") {
          setcoverImage(res.data.image.filename);
        }
      })
      .catch((err) => {
        console.log(err.response);
        setcoverImage("");
      });
  };

  const [selectedCountry, setSelectedCountry] = useState(null);

  const countryOptions = Object.keys(countries).map((countryCode) => ({
    value: countryCode,
    label: `${countries[countryCode].name} (+${countries[countryCode].phone})`,
  }));

  const [state, setState] = useState({
    label: details?.geeks_details?.personal_details?.state,
    value: details?.geeks_details?.personal_details?.state,
  });
  const [country, setCountry] = useState({
    label: details?.geeks_details?.personal_details?.country,
    value: details?.geeks_details?.personal_details?.country,
  });
  console.log(country, "dasasdasda");
  const [city, setCity] = useState({
    label: details?.geeks_details?.personal_details?.city,
    value: details?.geeks_details?.personal_details?.city,
  });

  const HandleGenderFunction = (value) => {
    setGenderValue(value.target.value);
  };

  const UpdateProfileIndiviudal = (e, Steptype) => {
    e.preventDefault();
    var payload = {};
    const dat = e.target.elements;
    console.log(dat, "dgjshfhgsjkhs");

    if (Steptype === "personal_details") {
      payload = {
        gender: GenderValue,
        dob: dat?.dob?.value,
        address: dat?.address?.value,
        // city: dat?.city?.value,
        // state: dat?.state?.value,
        // country: dat?.country?.label,
        country: country?.label,
        state: state?.label,
        city: city?.label,
        pincode: dat?.pincode?.value,
        adhar_number: dat?.aadhar?.value,
        pan_number: dat?.pan_number?.value,
        profile_pic: profileImage,
        cover_pic: coverimage,
      };
    } else if (Steptype === "technical_expertise") {
      const skilssData =
        details?.geeks_details?.technical_expertise?.skills?.length > 0
          ? details?.geeks_details?.technical_expertise?.skills?.map((item) => {
              return item?.value ? item?.value : item;
            })
          : [];

      const languageData =
        details?.geeks_details?.technical_expertise?.language?.length > 0
          ? details?.geeks_details?.technical_expertise?.language?.map(
              (item) => {
                return item?.value ? item?.value : item;
              }
            )
          : [];

      const certifiedskilssData =
        details?.geeks_details?.technical_expertise?.certified_skills?.length >
        0
          ? details?.geeks_details?.technical_expertise?.certified_skills?.map(
              (item) => {
                return item?.value ? item?.value : item;
              }
            )
          : [];

      const servicesSkillData =
        servicesSkil?.length > 0
          ? servicesSkil?.map((item) => {
              return item?.value ? item?.value : item;
            })
          : [];

      payload = {
        skills: skilssData,
        level_of_specialization: dat?.levelofspecialization?.value,
        years_of_experience: dat?.experience?.value,
        language: languageData,
        certified_skills: certifiedskilssData,
        services: servicesSkillData,
      };
    } else if (Steptype === "about") {
      payload = {
        nic_name: dat?.nicname?.value,
        highest_qualification: dat?.qualification?.value,
        academic_certification_award_testimonials:
          dat?.academic_qualification?.value,
        cv: cv,
        // bio: dat?.bio?.value
        bio: data1,
      };
    }

    // else if (activeStep >= 1) {
    const originalPayload = {
      geeks_type:
        details?.geeks_details?.geeks_type === "individual"
          ? "individual"
          : "corporate",
      type: Steptype,
      data: payload,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_BASEURL}/user/geeks/profile/update/${currentUser?.data?.uuid}`,
        originalPayload,
        {
          headers: {
            "x-auth-token": currentUser?.token,
          },
        }
      )
      .then((res) => {
        console.log(res, "dghsfdsjhkjshakj");
        toast.success(res?.data?.message, {
          position: "top-right",
        });
      })
      .catch((err) => {
        console.log(err.response, "ujjju");
        toast.error(err?.response?.data?.message, {
          position: "top-right",
        });
      });
    // }
  };

  const [phone, setPhone] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [profileData, setProfileData] = useState("");
  console.log(profileData, "profileData");
  //     const [state, setState] = useState('');
  //     const [country, setCountry] = useState('');
  //   console.log(country,'dasasdasda')
  //     const [city, setCity] = useState('');

  // country , state , city

  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [limit, setlimit] = useState(0);
  const [search, setSearch] = useState("");
  // const { servicesDropDown, services } = useSelector((state) => state.service);

  const changeScroll = (data, sarch) => {
    console.log(sarch, "data...");
    if (data === "search") {
      dispatch(servicesURL(page, 10, sarch));
    } else if (data === "inc" && page >= 0) {
      dispatch(servicesURL(page + 1, 10, search));
      setPage(page + 1);
    } else if (page >= 1) {
      dispatch(servicesURL(page - 1, 10, search));
      setPage(page - 1);
    }
  };

  console.log(services, servicesDropDown, "services");

  useEffect(() => {
    dispatch(servicesURL(page, 10, search));
    getcountryList();
  }, [activeStep]);

  const [type, setType] = useState(false);

  const changeGeek = (data) => {
    setType(data);
  };

  useEffect(() => {
    // setState(profileData?.personal_details?.state)
    // setCity(profileData?.personal_details?.city)
    // setCountry(profileData?.personal_details?.name)

    setCountry({
      label: details?.geeks_details?.personal_details?.country,
      value: details?.geeks_details?.personal_details?.country,
    });
    setState({
      label: details?.geeks_details?.personal_details?.state,
      value: details?.geeks_details?.personal_details?.state,
    });
    setCity({
      label: details?.geeks_details?.personal_details?.city,
      value: details?.geeks_details?.personal_details?.city,
    });
  }, [details]);

  useEffect(() => {
    setType(
      profileData?.geeks_details?.geeks_type === "corporate" ? true : false
    );
  }, []);

  const getProfileDetailes = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASEURL}/user/geeks/profile/${currentUser?.data?.uuid}`,
        {
          headers: {
            "x-auth-token": currentUser?.token,
          },
        }
      )
      .then((res) => {
        console.log(res.data?.data, "dsjvjssdjvg");
        setProfileData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  console.log(countryList, "sdasdadasda");

  const getstateList = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/state/list`)
      .then((res) => {
        console.log(res?.data?.data, "tertetrerte");
        setStateList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const getcityList = (page, limit, search) => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASEURL}/city/list?/city/list?page=&limit=&search=`
      )
      .then((res) => {
        console.log(res?.data?.data, "dfsdfdsfs");
        setCityList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const getcountryList = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/country/list`)
      .then((res) => {
        console.log(res?.data?.data, "erwerwre");
        setCountryList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const [statevalues, setStatevalues] = useState("");
  const [cityvalues, setCityvalues] = useState("");

  const GetStateDropdown = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASEURL}/state/listByCountry/CT-5BD8C295`
      )
      .then((res) => {
        console.log(res);
        setStatevalues(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    GetStateDropdown();
    GetCityDropdown(state?.value);
  }, []);

  const GetCityDropdown = (stateid) => {
    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/city/listByState/${stateid}`)
      .then((res) => {
        console.log(res, "asdasdsad");
        setCityvalues(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const CountryOption = [];
  countryList?.length > 0 &&
    countryList?.map((items) => {
      return CountryOption?.push({ label: items?.name, value: items?.uuid });
    });

  const CountryChangeFunction = (event) => {
    console.log(event, "event");
    setState("");
    setCountry(event);
    GetStateDropdown(event?.value);
  };

  const StateOption = [];
  statevalues?.length > 0 &&
    statevalues?.map((items) => {
      return StateOption?.push({ label: items?.name, value: items?.uuid });
    });

  const StateChangeFunction = (event) => {
    console.log(event, "event");
    setState(event);
    setCity("");
    GetCityDropdown(event?.value);
  };

  // state

  const CityOption = [];
  cityvalues?.length > 0 &&
    cityvalues?.map((items) => {
      return CityOption?.push({ label: items?.name, value: items?.uuid });
    });

  const CityChangeFunction = (event) => {
    console.log(event, "event");
    setCity(event);
  };

  useEffect(() => {
    setPhone(details?.mobile); // Assuming details is your prop or state containing initial values
  }, [details]);

  const [editorData, setEditorData] = useState("");

  // Set the initial content for CKEditor when the component mounts
  useEffect(() => {
    setEditorData(details?.geeks_details?.about?.bio || "");
  }, [details]);

  const defaultCityOption = {
    value: profileData?.company_details?.city,
    label: profileData?.company_details?.city,
  };

  // aadharNumber

  const [aadharNumber, setAadharNumber] = useState(
    details?.geeks_details?.personal_details?.adhar_number || ""
  );
  const [Valid, setValid] = useState(null);

  const validateAadhar = (inputAadhar) => {
    // Regular expression to check if Aadhar number is exactly 12 digits
    const aadharRegex = /^\d{12}$/;
    const isValidAadhar = aadharRegex.test(inputAadhar);
    setValid(isValidAadhar);
  };

  const handleAadharChange = (event) => {
    const inputAadhar = event.target.value.replace(/\D/g, ""); // Filter out non-numeric characters
    setAadharNumber(inputAadhar);
    validateAadhar(inputAadhar);

    const newValue = event.target.value;
    setAadharNumber(newValue);
  };

  const inputColor = Valid ? "green" : "red";

  //     let formattedAadhar = inputAadhar;
  //     if (inputAadhar.length > 4) {
  //       // Insert hyphen after the first 4 digits
  //       formattedAadhar =
  //         inputAadhar.slice(0, 4) + '-' + inputAadhar.slice(4, inputAadhar.length);
  //     }

  //     validateAadhar(formattedAadhar);
  //   };

  // pan card

  const [panNumber, setPanNumber] = useState("");
  const [isValid, setIsValid] = useState(null);

  const handlePanChange = (event) => {
    const inputPan = event.target.value.toUpperCase();
    setPanNumber(inputPan);

    const newValue = event.target.value;
    setPanNumber(newValue);

    // Regular expression to check if PAN card number is in the correct format
    const panRegex = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]$/;

    if (panRegex.test(inputPan)) {
      // Perform additional checks if needed
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const inputPanColor = panNumber.length === 10 ? "green" : "red";

  return (
    <>
      <Navbar />
      <section className="relative lg:mt-24 mt-[74px] pb-16">
        <div className="lg:container container-fluid">
          <div className="profile-banner relative text-transparent">
            <input
              id="pro-banner"
              name="profile-banner"
              type="file"
              className="hidden"
            />
            <div className="relative shrink-0">
              <img
                src={`${process.env.REACT_APP_IMAGE_BASEURL}/${details?.geeks_details?.personal_details?.cover_pic}`}
                crossOrigin="anonymous"
                className="h-64 w-full object-cover lg:rounded-xl shadow dark:shadow-gray-700"
                id="profile-banner"
                alt=""
              />
              <label
                className="absolute inset-0 cursor-pointer"
                htmlFor="pro-banner"
              ></label>
            </div>
          </div>
          <div className="md:flex mx-4 -mt-12">
            <div className="md:w-full">
              <div className="relative flex items-end">
                <div className="profile-pic text-center">
                  <input
                    id="pro-img"
                    name="profile-image"
                    type="file"
                    className="hidden"
                  />
                  <div>
                    <div className="relative h-28 w-28 max-w-[112px] max-h-[112px] mx-auto">
                      <img
                        src={`${process.env.REACT_APP_IMAGE_BASEURL}/${details?.geeks_details?.personal_details?.profile_pic}`}
                        crossOrigin="anonymous"
                        className="rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800"
                        id="profile-image"
                        alt=""
                      />
                      <label
                        className="absolute inset-0 cursor-pointer"
                        htmlFor="pro-img"
                      ></label>
                    </div>
                  </div>
                </div>
                <div className="ms-4">
                  <h5 className="text-lg font-semibold">
                    {/* {details?.full_name} */}
                    {details?.first_name + " " + details?.last_name}
                  </h5>
                  <p className="text-slate-400">
                    {/* {data?.title ? data?.title : "UI Designer"} */}
                    {details?.geeks_details?.personal_details?.city},
                    {details?.geeks_details?.personal_details?.state}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-16">
          <div className="grid grid-cols-1 gap-[30px]">
            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
              <h5 className="text-lg font-semibold mb-4">Personal Detail :</h5>
              <form
                onSubmit={(e) => UpdateProfileIndiviudal(e, "personal_details")}
              >
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                  <div>
                    <label className="form-label font-medium">
                      First Name : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={details?.first_name}
                      id="firstname"
                      name="firstname"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Last Name : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={details?.last_name}
                      id="lastname"
                      name="lastname"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Your Email : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={details?.email}
                      name="email"
                      required=""
                    />
                  </div>
                  {/* <div> */}
                  {/* <label className="form-label font-medium">Phone Number : <span className="text-red-600">*</span></label> */}
                  {/* {details?.mobile && (
                                            <PhoneInput
                                                country={'in'}
                                                value={phone}
                                                onChange={(newPhone) => setPhone(newPhone)}
                                                readOnly
                                                masks={{ in: '..........', at: '(....) ...-....' }}
                                               className="mt-2"
                                            />
                                             )}  */}
                  {/* <Select
                                            isDisabled={!edit}
                                            options={countryOptions}
                                            value={selectedCountry}
                                            onChange={(selectedOption) => setSelectedCountry(selectedOption)}
                                        /> */}
                  {/* <input type="text"
                                            
                                            readOnly
                                            defaultValue={details?.mobile}
                                            className="form-input border border-slate-100 dark:border-slate-800"
                                            placeholder="Enter"
                                            id="firstname"
                                            name="name"
                                            required="" /> */}
                  {/* </div> */}
                  <div>
                    <label className="form-label font-medium">Gender :</label>
                    <select
                      className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-2"
                      onChange={HandleGenderFunction}
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Date of birth : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.personal_details?.dob
                      }
                      placeholder="Enter"
                      name="dob"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Country : <span className="text-red-600">*</span>
                    </label>
                    <Select
                      name="state"
                      value={country}
                      onChange={CountryChangeFunction}
                      options={CountryOption}
                      defaultValue={{
                        label:
                          details?.geeks_details?.personal_details?.country,
                        value:
                          details?.geeks_details?.personal_details?.country,
                      }}
                    />
                  </div>

                  <div>
                    <label className="form-label font-medium">
                      State : <span className="text-red-600">*</span>
                    </label>

                    <Select
                      name="state"
                      value={state}
                      onChange={StateChangeFunction}
                      options={StateOption}
                      defaultValue={{
                        label: details?.geeks_details?.personal_details?.state,
                        value: details?.geeks_details?.personal_details?.state,
                      }}
                    />
                    {/* <label className="form-label font-medium">State : <span className="text-red-600">*</span></label>
                                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                                         defaultValue={details?.geeks_details?.personal_details?.state} name="state" required="" /> */}
                    {/* <Select name='state' value={state} onChange={StateChangeFunction} options={StateOption} defaultValue={{label:profileData?.company_details?.state, value:profileData?.company_details?.state}}/> */}
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      City : <span className="text-red-600">*</span>
                    </label>

                    <Select
                      name="city"
                      value={city}
                      onChange={CityChangeFunction}
                      options={CityOption}
                      defaultValue={{
                        value: details?.geeks_details?.personal_details?.city,
                        label: details?.geeks_details?.personal_details?.city,
                      }}
                    />
                    {/* <label className="form-label font-medium">City : <span className="text-red-600">*</span></label>
                                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                                         defaultValue={details?.geeks_details?.personal_details?.city} name="city" required="" /> */}

                    {/* 
                                        <Select name='state' value={city} onChange={CityChangeFunction} options={CityOption} defaultValue={{value:profileData?.company_details?.city,label:profileData?.company_details?.city}}/> */}
                    {/* <br/>
<Select
      name='state'
      value={defaultCityOption}
      onChange={CityChangeFunction}
      options={CityOption.map(city => ({ value: profileData?.company_details?.city, label: profileData?.company_details?.city }))}
    /> */}
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      ZIP Code : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.personal_details?.pincode
                      }
                      name="pincode"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Aadhar Number : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.personal_details?.adhar_number
                      }
                      name="aadhar"
                      required=""
                      maxLength="12"
                      // value={aadharNumber}
                      onChange={handleAadharChange}
                      // style={{ color: inputColor }}
                    />
                    {/* {Valid !== null && (
                                            <p style={{ color: inputColor }}>
                                            {Valid
                                                ? 'Aadhar card number is valid.'
                                                : 'Aadhar card number is not valid.'}
                                            </p>
                                        )} */}
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      PAN Number : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.personal_details?.pan_number ||
                        ""
                      }
                      name="pan_number"
                      required=""
                      maxLength="10"
                      // value={panNumber}
                      onChange={handlePanChange}
                    />
                    {/* {isValid !== null && (
                                            <p style={{ color: isValid ? 'green' : 'red' }}>
                                                {isValid
                                                ? 'PAN card number is valid.'
                                                : 'PAN card number is not valid.'}
                                            </p>
                                            )} */}
                  </div>
                  <div>
                    <label
                      className="form-label font-medium"
                      htmlFor="multiple_files"
                    >
                      Profile Picture Upload :
                    </label>
                    <input
                      className="relative form-input border border-slate-100 dark:border-slate-800 file:h-10 file:-mx-3 file:-my-2 file:cursor-pointer file:rounded-none file:border-0 file:px-3 file:text-neutral-700 bg-clip-padding px-3 py-1.5 file:me-3 mt-2"
                      id="multiple_files"
                      type="file"
                      onChange={(e) => ImageUpload(e, "profile")}
                    />
                  </div>
                  <div>
                    <label
                      className="form-label font-medium"
                      htmlFor="multiple_files"
                    >
                      Cover Picture Upload :
                    </label>
                    <input
                      className="relative form-input border border-slate-100 dark:border-slate-800 file:h-10 file:-mx-3 file:-my-2 file:cursor-pointer file:rounded-none file:border-0 file:px-3 file:text-neutral-700 bg-clip-padding px-3 py-1.5 file:me-3 mt-2"
                      id="multiple_files"
                      onChange={(e) => ImageUpload(e, "cover")}
                      type="file"
                    />
                  </div>
                </div>

                <div className="mt-5">
                  <label className="form-label font-medium">Address : </label>
                  <input
                    type="text"
                    className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                    defaultValue={
                      details?.geeks_details?.personal_details?.address
                    }
                    name="address"
                    required=""
                  />
                  {/* <CKEditor
                                        editor={ClassicEditor}
                                        data={data}
                                        onReady={editor => {
                                            console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => TextEditor(editor)}
                                        onBlur={(event, editor) => {
                                            console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            console.log('Focus.', editor);
                                        }}
                                    /> */}
                </div>
                <div className="mt-5">
                  <input
                    type="submit"
                    id="submit"
                    name="send"
                    className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md cursor-pointer"
                    value="Save Changes"
                  />
                </div>
              </form>
            </div>

            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
              <h5 className="text-lg font-semibold mb-4">Technical</h5>
              <form
                onSubmit={(e) =>
                  UpdateProfileIndiviudal(e, "technical_expertise")
                }
              >
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                  <div>
                    <label className="form-label font-medium tooltip cursor-pointer">
                      Skills : <span className="text-red-600">*</span>
                      <span class="tooltiptext">
                        Antivirus Software <br/>
                        Linux <br/>
                        cisco routers 
                      </span>
                    </label>
                    <Select
                      name="skills"
                      value={
                        skills?.length > 0
                          ? skills?.map((item) => {
                              return {
                                value: item?.value ? item?.value : item,
                                label: item?.label ? item?.label : item,
                              };
                            })
                          : []
                      }
                      onChange={setSkill}
                      isMulti
                      options={Skillsoptions}
                    />
                  </div>
                  <div>
            

                  {/* <div class="tooltip">Hover over me
  <span class="tooltiptext">Tooltip text</span>
</div> */}


                    <label className="form-label font-medium tooltip cursor-pointer" data-tooltip-target="tooltip-default">
                      Level of Specialization :{" "}
                      <span className="text-red-600">*</span>
                      <span class="tooltiptext">
                        Beginner <br/>
                        Expert <br/>
                        Intermediate 
                      </span>
                    </label>
                    <Select
                      name="levelofspecialization"
                      options={optionsLevels}
                      defaultValue={{ label: special, value: special }}
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Year of experience :{" "}
                      <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.technical_expertise
                          ?.years_of_experience
                      }
                      name="experience"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium tooltip cursor-pointer">
                      Language : <span className="text-red-600">*</span>
                      <span class="tooltiptext">
                        Hindi <br/>
                        English <br/>
                        Telugu 
                      </span>
                    </label>
                    <Select
                      name="languages"
                      value={
                        language?.length > 0
                          ? language?.map((item) => {
                              return {
                                value: item?.value ? item?.value : item,
                                label: item?.label ? item?.label : item,
                              };
                            })
                          : []
                      }
                      onChange={setLanguagesIndividual}
                      isMulti
                      options={optionsLanguages}
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium tooltip cursor-pointer">
                      Certificated Skills :{" "}
                      <span className="text-red-600">*</span>
                      <span class="tooltiptext">
                        Oracle <br/>
                        Juniper routers <br/>
                        Linux 
                      </span>
                    </label>
                    <Select
                      name="Certified skills"
                      value={
                        certifiedskills?.length > 0
                          ? certifiedskills?.map((item) => {
                              return {
                                value: item?.value ? item?.value : item,
                                label: item?.label ? item?.label : item,
                              };
                            })
                          : []
                      }
                      onChange={(selectedOptions) =>
                        setCertifiedSkill(selectedOptions)
                      }
                      isMulti
                      options={Skillsoptions}
                    />
                    {/* <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2" defaultValue={details?.geeks_details?.technical_expertise?.certified_skills} name="email" required="" /> */}
                  </div>
                  <div>
                  <label className="form-label font-medium tooltip cursor-pointer">
                  Services :{" "}
                      <span className="text-red-600">*</span>
                      <span class="tooltiptext">
                        Printer Issues <br/>
                        Wifi Connectivity <br/>
                        Data Recovery <br/>
                        Email Problems 
                      </span>
                    </label>
                   
                    <Select
                      name="services"
                      value={
                        servicesSkil?.length > 0
                          ? servicesSkil?.map((item) => {
                              return {
                                value: item?.value ? item?.value : item,
                                label: item?.label ? item?.label : item,
                              };
                            })
                          : []
                      }
                      onChange={setServicesSkill}
                      isMulti
                      options={servicesDropDown}
                    />
                    {/* {selectedOption && (
                                            <div>
                                                <p>Selected Option: {selectedOption.label}</p>
                                            </div>
                                        )} */}
                  </div>
                </div>
                <input
                  type="submit"
                  id="submit"
                  name="send"
                  className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5 cursor-pointer"
                  value="Save Changes"
                />
              </form>
            </div>

            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
              <h5 className="text-lg font-semibold mb-4">About</h5>
              <form onSubmit={(e) => UpdateProfileIndiviudal(e, "about")}>
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                  <div>
                    <label className="form-label font-medium">
                      Alias : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={details?.geeks_details?.about?.nic_name}
                      id="firstname"
                      name="nicname"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium tooltip cursor-pointer">
                      Highest Qualification :{" "}
                      <span className="text-red-600">*</span>
                      <span class="tooltiptext">
                        B.Tech <br/>
                        BA <br/>
                        MBA <br/>
                      PhD
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.about?.highest_qualification
                      }
                      id="lastname"
                      name="qualification"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium tooltip cursor-pointer">
                      Academic Certification :{" "}
                      <span className="text-red-600">*</span>
                      <span class="tooltiptext">
                        AWS Certificate <br/>
                        Cloud Certificate <br/>
                        Network Certificate
                      
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.about
                          ?.academic_certification_award_testimonials
                      }
                      name="academic_qualification"
                      required=""
                    />
                  </div>
                  {/* <div>
                                        <label className="form-label font-medium">Services : <span className="text-red-600">*</span></label>
                                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2" defaultValue={details?.geeks_details?.about?.nic_name} name="email" required="" />
                                    </div> */}
                  <div>
                    <label
                      className="form-label font-medium"
                      htmlFor="multiple_files"
                    >
                      CV Upload :
                    </label>
                    <input
                      className="relative form-input border border-slate-100 dark:border-slate-800 file:h-10 file:-mx-3 file:-my-2 file:cursor-pointer file:rounded-none file:border-0 file:px-3 file:text-neutral-700 bg-clip-padding px-3 py-1.5 file:me-3 mt-2"
                      id="multiple_files"
                      type="file"
                      onChange={(e) => ImageUpload(e, "cv")}
                      multiple
                      defaultValue={details?.geeks_details?.about?.cv}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <label className="form-label font-medium">Bio : </label>
                  {details?.geeks_details?.about?.bio && (
                    <CKEditor
                      editor={ClassicEditor}
                      data={editorData}
                      // data={data1}
                      onReady={(editor) => {
                        console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        const dataValue = editor.getData();
                        setData(dataValue);
                        console.log({ event, editor, dataValue });
                      }}
                      onBlur={(event, editor) => {
                        console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log("Focus.", editor);
                      }}
                    />
                  )}
                </div>
                <input
                  type="submit"
                  id="submit"
                  name="send"
                  className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5 cursor-pointer"
                  value="Save Changes"
                />
              </form>
            </div>

            {/* <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                            <div>
                                <h5 className="text-lg font-semibold mb-4">Contact Info :</h5>
                                <form>
                                    <div className="grid grid-cols-1 gap-4">
                                        <div>
                                            <label className="form-label font-medium">Phone No. :</label>
                                            <input name="number" id="number" type="number" className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="Phone :"/>
                                        </div>
                                        <div>
                                            <label className="form-label font-medium">Website :</label>
                                            <input name="url" id="url" type="url" className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="Url :"/>
                                        </div>
                                    </div>
                                    <button className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5">Add</button>
                                </form>
                            </div>
                            <div> 
                                <h5 className="text-lg font-semibold mb-4">Change password :</h5>
                                <form>
                                    <div className="grid grid-cols-1 gap-4">
                                        <div>
                                            <label className="form-label font-medium">Old password :</label>
                                            <input type="password" className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="Old password" required=""/>
                                        </div>
                                        <div>
                                            <label className="form-label font-medium">New password :</label>
                                            <input type="password" className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="New password" required=""/>
                                        </div>
                                        <div>
                                            <label className="form-label font-medium">Re-type New password :</label>
                                            <input type="password" className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="Re-type New password" required=""/>
                                        </div>
                                    </div>
                                    <button className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5">Save password</button>
                                </form>
                            </div>
                        </div>
                    </div> */}

            {/* <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                        <h5 className="text-lg font-semibold mb-4">Social Media :</h5>
                        <div className="md:flex">
                            <div className="md:w-1/3">
                                <span className="font-medium">Twitter</span>
                            </div>
                            <div className="md:w-2/3 mt-4 md:mt-0">
                                <form>
                                    <div className="form-icon relative">
                                        <Twitter className="w-4 h-4 absolute top-5 start-4"/>
                                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2 ps-12" placeholder="Twitter Profile Name" id="twitter_name" name="name" required=""/>
                                    </div>
                                </form>
                                <p className="text-slate-400 mt-1">Add your Twitter username (e.g. jennyhot).</p>
                            </div>
                        </div>
                        <div className="md:flex mt-8">
                            <div className="md:w-1/3">
                                <span className="font-medium">Facebook</span>
                            </div>
                            <div className="md:w-2/3 mt-4 md:mt-0">
                                <form>
                                    <div className="form-icon relative">
                                        <Facebook className="w-4 h-4 absolute top-5 start-4"/>
                                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2 ps-12" placeholder="Facebook Profile Name" id="facebook_name" name="name" required=""/>
                                    </div>
                                </form>
                                <p className="text-slate-400 mt-1">Add your Facebook username (e.g. jennyhot).</p>
                            </div>
                        </div>
                        <div className="md:flex mt-8">
                            <div className="md:w-1/3">
                                <span className="font-medium">Instagram</span>
                            </div>

                            <div className="md:w-2/3 mt-4 md:mt-0">
                                <form>
                                    <div className="form-icon relative">
                                        <Instagram className="w-4 h-4 absolute top-5 start-4"/>
                                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2 ps-12" placeholder="Instagram Profile Name" id="insta_name" name="name" required=""/>
                                    </div>
                                </form>
                                <p className="text-slate-400 mt-1">Add your Instagram username (e.g. jennyhot).</p>
                            </div>
                        </div>
                        
                        <div className="md:flex mt-8">
                            <div className="md:w-1/3">
                                <span className="font-medium">Linkedin</span>
                            </div>
                            <div className="md:w-2/3 mt-4 md:mt-0">
                                <form>
                                    <div className="form-icon relative">
                                        <Linkedin className="w-4 h-4 absolute top-5 start-4"/>
                                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2 ps-12" placeholder="Linkedin Profile Name" id="linkedin_name" name="name" required=""/>
                                    </div>
                                </form>
                                <p className="text-slate-400 mt-1">Add your Linkedin username.</p>
                            </div>
                        </div>
                        
                        <div className="md:flex mt-8">
                            <div className="md:w-1/3">
                                <span className="font-medium">Youtube</span>
                            </div>
                            <div className="md:w-2/3 mt-4 md:mt-0">
                                <form>
                                    <div className="form-icon relative">
                                        <Youtube className="w-4 h-4 absolute top-5 start-4"/>
                                        <input type="url" className="form-input border border-slate-100 dark:border-slate-800 mt-2 ps-12" placeholder="Youtube url" id="you_url" name="url" required=""/>
                                    </div>
                                </form>
                                <p className="text-slate-400 mt-1">Add your Youtube url.</p>
                            </div>
                        </div>
                        <div className="md:flex">
                            <div className="md:w-1/3">
                                <input type="submit" id="submit" name="send" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5" value="Save Changes"/>
                            </div>
                        </div>
                    </div> */}

            {/* <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                        <h5 className="text-lg font-semibold mb-5">Account Notifications :</h5>

                        <div className="flex justify-between pb-4">
                            <h6 className="mb-0 font-medium">When someone mentions me</h6>
                            <div className="">
                                <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" type="checkbox" value="" id="noti1"/>
                                <label className="form-check-label" htmlFor="noti1"></label>
                            </div>
                        </div>
                        <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                            <h6 className="mb-0 font-medium">When someone follows me</h6>
                            <div className="">
                                <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" type="checkbox" value="" defaultChecked id="noti2"/>
                                <label className="form-check-label" htmlFor="noti2"></label>
                            </div>
                        </div>
                        <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                            <h6 className="mb-0 font-medium">When shares my activity</h6>
                            <div className="">
                                <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" type="checkbox" value="" id="noti3"/>
                                <label className="form-check-label" htmlFor="noti3"></label>
                            </div>
                        </div>
                        <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                            <h6 className="mb-0 font-medium">When someone messages me</h6>
                            <div className="">
                                <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" type="checkbox" value="" id="noti4"/>
                                <label className="form-check-label" htmlFor="noti4"></label>
                            </div>
                        </div>
                    </div> */}

            {/* <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                        <h5 className="text-lg font-semibold mb-5">Marketing Notifications :</h5>

                        <div className="flex justify-between pb-4">
                            <h6 className="mb-0 font-medium">There is a sale or promotion</h6>
                            <div className="">
                                <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" type="checkbox" value="" id="noti5"/>
                                <label className="form-check-label" htmlFor="noti5"></label>
                            </div>
                        </div>
                        <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                            <h6 className="mb-0 font-medium">Company news</h6>
                            <div className="">
                                <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" type="checkbox" value="" id="noti6"/>
                                <label className="form-check-label" htmlFor="noti6"></label>
                            </div>
                        </div>
                        <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                            <h6 className="mb-0 font-medium">Weekly jobs</h6>
                            <div className="">
                                <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" type="checkbox" value="" defaultChecked id="noti7"/>
                                <label className="form-check-label" htmlFor="noti7"></label>
                            </div>
                        </div>
                        <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                            <h6 className="mb-0 font-medium">Unsubscribe News</h6>
                            <div className="">
                                <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" type="checkbox" value="" defaultChecked id="noti8"/>
                                <label className="form-check-label" htmlFor="noti8"></label>
                            </div>
                        </div>
                    </div> */}

            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
              <h5 className="text-lg font-semibold mb-5 text-red-600">
                Delete Account :
              </h5>
              <p className="text-slate-400 mb-4">
                Do you want to delete the account? Please press below "Delete"
                button
              </p>
              <Link
                to="#"
                className="btn bg-red-600 hover:bg-red-700 text-white rounded-md"
              >
                Delete
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
