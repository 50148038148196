import React, { useEffect, useState } from 'react'
import contact from '../../assets/images/svg/contact.svg';
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import ab03 from '../../assets/images/about/ab03.jpg';
import folder from '../../assets/images/folder.png';
import { Grid } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import {PiMapPin, BsTelephone, LuMail} from "../../assets/icons/vander"
import { MoreServiceDate } from '../../data/data';
import { useSelector, useDispatch } from 'react-redux'
import { servicesURL } from '../../Redux/services/servicesSlice'
import {MdOutlineArrowForward, MdOutlineCalendarMonth,AiOutlineClockCircle, MdKeyboardArrowLeft, MdKeyboardArrowRight} from "../../assets/icons/vander"
import './MoreService.css'
// import PasswordField from 'material-ui-password-field';

export default function MoreService() {

    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange =(evnt)=>{
        setPasswordInput(evnt.target.value);
    }
    const togglePassword =()=>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { services } = useSelector((state) => state.service);

        const [page, setPage] = useState(0); // Starting page
    const [search, setSearch] = useState('');
    const itemsPerPage = 10;

    // const fetchData = () => {
    //     dispatch(servicesURL(page, itemsPerPage, search));
    // };

    // useEffect(() => {
    //     fetchData(); // Fetch initial services
    // }, []);

    // const handleScroll = () => {
    //     if (
    //         window.innerHeight + document.documentElement.scrollTop ===
    //         document.documentElement.offsetHeight
    //     ) {
    //         // User has scrolled to the bottom
    //         setPage(page + 1); // Increment the page number
    //         fetchData(); // Fetch more services
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [page]);


    useEffect(() => {
        dispatch(servicesURL(page, 9, search)) 
    },[])

    const NavigateCandidate = (data) => {
        console.log(data, "data")
        navigate(`/service/${data?.name}`, {
            state : data
        })
    }


    function truncate(source, size) {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
      }

    return (
        <>
            <Navbar navClass='justify-end nav-light'/>
            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Services</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Home</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Services</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="relative md:py-15 py-16">
                <div className="container">
                    <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px]">
                        {services.map((item, index) => (
                            <div key={index} className="group relative overflow-hidden bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700">
                                <div className="relative overflow-hidden" style={{display:"flex",justifyContent:"center",alignItems:"center",height:"12vh"}}>
                                    <img src={`${process.env.REACT_APP_IMAGE_BASEURL}/${item.image}`} crossOrigin='anonymous' className="scale-110 group-hover:scale-100 transition-all duration-500" alt="" 
                                    style={{ width: "auto", maxHeight: "100%",}}  />
                                </div>

                                <div className="relative p-6">
                                    <div style={{ textAlign:"center" }}>
                                        <Link  className="title text-lg font-semibold hover:text-emerald-600 duration-500 ease-in-out ">
                                        {truncate(
                              item.name?.replace(/(<([^>]+)>)/gi, ""),
                              20
                            )}
                            
                            {/* {item.name} */}
                            </Link>
                                        <br/>
                                        <Link  className="title text-sm  hover:text-emerald-600 duration-500 ease-in-out">
                                           <p className='multiline-ellipsis'>{item.description}</p>
                                            </Link>
                                    </div>
                                    <div className="mt-4">
                                           {/* <Link to="/candidate-list"> */}
                                    <input type="button" onClick={() => NavigateCandidate(item)} className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md w-full cursor-pointer" value="View Profiles" />
                                    {/* </Link> */}
                                </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <div className="grid md:grid-cols-12 grid-cols-1 mt-5 mb-5">
                    <div className="md:col-span-12 text-center">
                   
{/* <form id="file-upload-form" action="<?php echo $_SERVER['PHP_SELF'] ?>">
	<input id="file-upload" type="file" name="fileUpload" />
    <div className='filearea'>
	<label for="file-upload" id="file-drag" >
        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <img src={folder} alt='folder' style={{width:"70px",height:"70px"}}/>
        </div>
        <br />
	<div style={{color:"#6a6a6a",fontSize:"20px",marginBottom:"10px",fontWeight:"600"}}>Choose a file</div>	
		
        <div style={{color:"grey",fontSize:"15px",fontWeight:"500"}}> Allowed file type: .xlsl <br/> Maximum upload file size:2MB</div>
	</label>
    <div style={{textAlign:"start"}}>
<h3 style={{color:"black",fontSize:"14px",marginTop:"10px",fontWeight:"600"}}>Importants</h3>
<h5 style={{color:"black",fontSize:"14px",marginBottom:"5px",marginTop:"5px",fontWeight:"600"}}>The data in the excel sheet should be in formatted as per the templete provide to ensure successfully import</h5>
<button className='buttonContainer'>Download Templete</button>
</div>
	</div>
	
</form> */}



                        <nav aria-label="Page navigation example">
                            <ul className="inline-flex items-center -space-x-px">
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">
                                        <MdKeyboardArrowLeft className="text-[20px] rtl:rotate-180 rtl:-mt-1"/>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">1</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">2</Link>
                                </li>
                                <li>
                                    <Link to="#" aria-current="page" className="z-10 w-[40px] h-[40px] inline-flex justify-center items-center text-white bg-emerald-600 border border-emerald-600">3</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">4</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">5</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">
                                        <MdKeyboardArrowRight className="text-[20px] rtl:rotate-180 rtl:-mt-1"/>
                                    </Link>
                                </li>
                            </ul>
                        </nav>


                        {/* <PasswordField
                    required
                    name='passcode'
                    placeholder="Enter your passcode"
                    disableUnderline={true}
                //   hintText="only  4 characters"
                //   inputProps={{maxLength: 4,
                //   minLength: 4}}
                
                 errorText="Your password is too short"
                 visible={false}
                 
                   className="input_box"
                   style={{border:"2px solid black"}}
              /> */}
             
                    </div>
                </div>


             


      




     
                

            {/* <section className="relative lg:py-24 py-16 MoreService" style={{paddingLeft:"300px",paddingRight:"300px"}}>
            <h4 className="lg:leading-normal leading-normal text-4xl lg:text-5xl mb-2 font-bold text-black text-center">Welcome to My IT Solution</h4>
                        <p className="text-black/50 text-lg max-w-xl mx-auto text-center">What are you looking for?</p>
            <Grid item container className='mt-5'>
                
                        <Grid xs={12} md={4} style={{display:"flex",justifyContent:"center",alignItems:"center",cursor:"pointer"}}>
<div >
<img src={ab03} className="lg:w-[10px] w-[10px] rounded-md shadow dark:shadow-gray-700" alt="" style={{width:"300px",height:"200px",padding:"30px 50px",backgroundColor:"#f5f5f5",borderRadius:"15px",marginLeft:"15%"}}/>
<p className='p-2 text-center'>All software Solution</p>
<p style={{padding:"10px 40px",textAlign:"justify"}} >We delivers high quality, reliable and cost-effective IT services to customers globally. We provide best technology services by constantly exploring</p>
</div>
                        </Grid>
                        <Grid xs={12} md={4} style={{display:"flex",justifyContent:"center",alignItems:"center",cursor:"pointer"}}>
<div >
<img src={ab03} className="lg:w-[10px] w-[10px] rounded-md shadow dark:shadow-gray-700" alt="" style={{width:"300px",height:"200px",padding:"30px 50px",backgroundColor:"#f5f5f5",borderRadius:"15px",marginLeft:"15%"}}/>
<p className='p-4 text-center'>Xerox Service</p>
<p style={{padding:"10px 40px",textAlign:"justify"}} >We delivers high quality, reliable and cost-effective IT services to customers globally. We provide best technology services by constantly exploring</p>
</div>
                        </Grid>
                        <Grid xs={12} md={4} style={{display:"flex",justifyContent:"center",alignItems:"center",cursor:"pointer"}}>
<div >
<img src={ab03} className="lg:w-[10px] w-[10px] rounded-md shadow dark:shadow-gray-700" alt="" style={{width:"300px",height:"200px",padding:"30px 50px",backgroundColor:"#f5f5f5",borderRadius:"15px",marginLeft:"15%"}}/>
<p className='p-4 text-center'>CPU Service</p>
<p style={{padding:"10px 40px",textAlign:"justify"}} >We delivers high quality, reliable and cost-effective IT services to customers globally. We provide best technology services by constantly exploring</p>
</div>
                        </Grid>
                        <Grid xs={12} md={4} style={{display:"flex",justifyContent:"center",alignItems:"center",cursor:"pointer"}}>
<div >
<img src={ab03} className="lg:w-[10px] w-[10px] rounded-md shadow dark:shadow-gray-700" alt="" style={{width:"300px",height:"200px",padding:"30px 50px",backgroundColor:"#f5f5f5",borderRadius:"15px",marginLeft:"15%"}}/>
<p className='p-4 text-center'>Mobile Service</p>
<p style={{padding:"10px 40px",textAlign:"justify"}} >We delivers high quality, reliable and cost-effective IT services to customers globally. We provide best technology services by constantly exploring</p>
</div>
                        </Grid>
                        <Grid xs={12} md={4} style={{display:"flex",justifyContent:"center",alignItems:"center",cursor:"pointer"}}>
<div >
<img src={ab03} className="lg:w-[10px] w-[10px] rounded-md shadow dark:shadow-gray-700" alt="" style={{width:"300px",height:"200px",padding:"30px 50px",backgroundColor:"#f5f5f5",borderRadius:"15px",marginLeft:"15%"}}/>
<p className='p-4 text-center'>Laptop Service</p>
<p style={{padding:"10px 40px",textAlign:"justify"}} >We delivers high quality, reliable and cost-effective IT services to customers globally. We provide best technology services by constantly exploring</p>
</div>
                        </Grid>
                        <Grid xs={12} md={4} style={{display:"flex",justifyContent:"center",alignItems:"center",cursor:"pointer"}}>
<div >
<img src={ab03} className="lg:w-[10px] w-[10px] rounded-md shadow dark:shadow-gray-700" alt="" style={{width:"300px",height:"200px",padding:"30px 50px",backgroundColor:"#f5f5f5",borderRadius:"15px",marginLeft:"15%"}}/>
<p className='p-4 text-center'>Tablet Service</p>
<p style={{padding:"10px 40px",textAlign:"justify"}} >We delivers high quality, reliable and cost-effective IT services to customers globally. We provide best technology services by constantly exploring</p>
</div>
                        </Grid>
                       </Grid>
            </section> */}

            <Footer />
        </>
    )
}
