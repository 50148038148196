import React, { useState, useEffect } from 'react';

const DynamicDropdowns = () => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');

  useEffect(() => {


    
    // Fetch countries on component mount
    fetch('https://countriesnow.space/api/v0.1/countries')
      .then(response => response.json())
      .then(data => setCountries(data.data))
      .catch(error => console.error('Error fetching countries:', error));
  }, []);



  const handleCountryChange = (event) => {
    const selectedCountryCode = event.target.value;
    setSelectedCountry(selectedCountryCode);

    // Fetch states based on the selected country
    fetch(`https://countriesnow.space/api/v0.1/countries/states/${selectedCountryCode}`)
      .then(response => response.json())
      .then(data => setStates(data.data))
      .catch(error => console.error('Error fetching states:', error));

    // Clear city dropdown when country changes
    setCities([]);
    setSelectedState('');
  };



  const handleStateChange = (event) => {
    const selectedStateCode = event.target.value;
    setSelectedState(selectedStateCode);

    // Fetch cities based on the selected state
    fetch(`https://countriesnow.space/api/v0.1/countries/states/cities/${selectedCountry}/${selectedStateCode}`)
      .then(response => response.json())
      .then(data => setCities(data.data))
      .catch(error => console.error('Error fetching cities:', error));
  };



  return (
    <div>
      <label htmlFor="country">Country:</label>
      <select id="country" value={selectedCountry} onChange={handleCountryChange}>
        <option value="">Select Country</option>
        {countries?.map(country => (
          <option key={country.code} value={country.code}>
            {country.name}
          </option>
        ))}
      </select>

      <label htmlFor="state">State:</label>
      <select id="state" value={selectedState} onChange={handleStateChange}>
        <option value="">Select State</option>
        {states?.map(state => (
          <option key={state.code} value={state.code}>
            {state.name}
          </option>
        ))}
      </select>

      <label htmlFor="city">City:</label>
      <select id="city">
        <option value="">Select City</option>
        {cities?.map(city => (
          <option key={city} value={city}>
            {city}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DynamicDropdowns;
