import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import JobDetailComp from "../../components/job-detail-comp";
import Footer from "../../components/Footer";
import { Link, useParams } from "react-router-dom";
import {
  Book,
  Briefcase,
  Clock,
  DollarSign,
  MapPin,
  Monitor,
  UserCheck,
} from "react-feather";
import { MdOutlineArrowForward } from "react-icons/md";
import ExploreJob from "../../components/Explore-job";
import { jobData } from "../../data/data";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  TbBrandHipchat,
  AiOutlineStar,
} from "../../assets/icons/vander";
import axios from "axios";
import { useSelector } from "react-redux";
import { Button, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import team1 from "../../assets/images/team/01.jpg";
import closeButton from "../../assets/images/closeButton.png";
import "./HiredJobView.scss";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function HiredJobView(page, onPageNumberClick) {
  const params = useParams();
  const id = params.id;
  const data = jobData.find((jobs) => jobs.id === parseInt(id));
  const { currentUser } = useSelector((state) => state.auth);

  const [jobDetailes, setJobDetailes] = useState({});
  console.log(jobDetailes, "asdasdasdsad");
  const [appliedUser, setAppliedUsers] = useState([]);
  const [ProgressSteps, setProgressSteps] = useState(["Pending"]);

  const JObDetailes = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/job/postviewSeeker/${id}`, {
        headers: {
          "x-auth-token": currentUser?.token,
        },
      })
      .then((res) => {
        console.log(res.data, "response");
        setJobDetailes(res?.data?.data);
        const data = [];
        res?.data?.data.status?.map((item) => {
          data.push(item?.status);
        });
        setProgressSteps(data);
        if (res?.data?.data[0]?.job_status === "pending") {
          setActiveStep(1);
        } else if (res?.data?.data[0]?.job_status === "accepted") {
          setActiveStep(2);
        } else if (res?.data?.data[0]?.job_status === "initiate") {
          setActiveStep(3);
        } else if (res?.data?.data[0]?.job_status === "inprogress") {
          setActiveStep(4);
        } else if (res?.data?.data[0]?.job_status === "review") {
          setActiveStep(5);
        } else if (res?.data?.data[0]?.job_status === "completed") {
          setActiveStep(7);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    JObDetailes();
    jobAppliedUsers();
  }, []);

  const jobAppliedUsers = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASEURL}/job/applyGeeksList?job_uuid=${id}&pagenum=0&limit=10&search=`,
        {
          headers: {
            "x-auth-token": currentUser?.token,
          },
        }
      )
      .then((res) => {
        console.log(res.data, "shgavj");
        setAppliedUsers(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const steps = ["Skills", "Service", "Image"];
  const [activeStep, setActiveStep] = React.useState(1);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleStatus = (data) => {
    var payload = {
      status: "completed",
      job_uuid: id,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_BASEURL}/job/updateStatus`,
        payload,
        {
          headers: {
            "x-auth-token": currentUser?.token,
          },
        }
      )
      .then((res) => {
        toast.success("Status updated", {
          position: "top-right"
        })
        JObDetailes();
      })
      .catch((err) => {
        console.log("Error");
      });
  };

  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  var stepPercentage = 0;
  if (page === "pageone") {
    stepPercentage = 16;
  } else if (page === "pagetwo") {
    stepPercentage = 49.5;
  } else if (page === "pagethree") {
    stepPercentage = 82.5;
  } else if (page === "pagefour") {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }

  const stepArray = [
    "Pending",
    "Accecpted",
    "Intiated",
    "InProgress",
    "Review",
    "Completed",
  ];

  const StepCheck = (data, index) => {
    if (Number(activeStep) === Number(index)) {
      return (
        <li class="step is-active" data-step={String(index)}>
          {data}
        </li>
      );
    } else if (Number(activeStep) > Number(index)) {
      return (
        <li class="step is-complete" data-step={String(index)}>
          {data}
        </li>
      );
    } else {
      return (
        <li class="step" data-step={String(index)}>
          {data}
        </li>
      );
    }
  };




 
  const [isFullImageOpen, setIsFullImageOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openFullImage = (index) => {
    setSelectedImageIndex(index);
    setIsFullImageOpen(true);
  };

  const closeFullImage = () => {
    setIsFullImageOpen(false);
    setSelectedImageIndex(null);
  };

  const showPreviousImage = () => {
    if (selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };

  const showNextImage = () => {
    if (selectedImageIndex < jobDetailes.image.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };

  const FullImageDisplay = ({ imageUrl, onClose }) => {
    return (
      <div className="full-image-display" >
        <div className="overlay"></div>
        <div  style={{ display:"flex" ,justifyContent:"center",alignItems:"flex-start" }}>
<div >
<img 
        // src={`https://api.god.scienstechnologies.com/user/images/${imageUrl}`}
         alt="Full Size" className="full-image" 
        
        src={`https://api.god.scienstechnologies.com/user/images/${imageUrl}`}
        // alt={`Image ${index + 1}`}
        // onClick={() => openFullImage(index)}
        // className="thumbnail"
        crossOrigin="anonymous"
        />
        </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div >
<img src={closeButton} alt="closeButton" style={{ width:"40px" ,height:"40px" ,cursor:"pointer"}} onClick={onClose}/>
</div>
        </div>
        
      </div>
    );
  };



  const handleKeyboardNavigation = (event) => {
      if (isFullImageOpen) {
        switch (event.key) {
          case 'ArrowLeft':
            showPreviousImage();
            break;
          case 'ArrowRight':
            showNextImage();
            break;
          case 'Escape':
            closeFullImage();
            break;
          default:
            break;
        }
      }
    };
  
    useEffect(() => {
      document.addEventListener('keydown', handleKeyboardNavigation);
      return () => {
        document.removeEventListener('keydown', handleKeyboardNavigation);
      };
    }, [isFullImageOpen, selectedImageIndex]);

const [ imagesLoadError ,setImagesLoadError ] = useState('');

    const handleImageError = (error, index) => {
      console.error(`Error loading image ${index + 1}:`, error);
    
      // Implement your error handling logic here
      // For example, you can replace the broken image with a placeholder or show an error message.
    
      // For illustration purposes, let's set a flag to indicate that the image at this index failed to load.
      // You might want to implement a more sophisticated error handling mechanism based on your requirements.
      setImagesLoadError((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = true;
        return newErrors;
      });
    };



  return (
    <>
      <Navbar navClass="justify-end nav-light" />
      <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-emerald-900/90"></div>
        <div className="container">
          <div className="grid grid-cols-1 text-center mt-10">
            {data?.heading ? (
              <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">
                {data?.heading}
              </h3>
            ) : (
              <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">
                Hired Job View
              </h3>
            )}
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
            <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/">Home</Link>
            </li>
            <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/MyJobs">My Jobs</Link>
            </li>
            <li
              className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              View
            </li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative mt-12">
        <div className="container md:pb-24 pb-16 ">
          <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
            {/* <div className="lg:col-span-6 md:col-span-7"></div> */}
            <div className="lg:col-span-12 md:col-span-12">
              <ol class="steps">
                {stepArray?.map((item, index) => {
                  return StepCheck(item, index + 1)
                })}
              </ol>

              {jobDetailes?.job_status === "review" && (
                <Button
                  onClick={handleStatus}
                  style={{ display: "flex", justifyContent: "end" }}
                  className="justify-content-end btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md pl-5 pr-5"
                >
                  Complete
                </Button>
              )}

              {/* <div class="max-w-xl mx-auto my-4 border-b-2 pb-4">	
	<div class="flex pb-3">
		<div class="flex-1">
		</div>

		<div class="flex-1">
			<div class="w-10 h-10 bg-green mx-auto rounded-full text-lg text-white flex items-center">
				<span class="text-white text-center w-full"><i class="fa fa-check w-full fill-current white"></i></span>
			</div>
		</div>


		<div class="w-1/6 align-center items-center align-middle content-center flex">
			<div class="w-full bg-grey-light rounded items-center align-middle align-center flex-1">
			 	<div class="bg-green-light text-xs leading-none py-1 text-center text-grey-darkest rounded " style="width: 100%"></div>
			</div>
		</div>
	
		
		<div class="flex-1">
			<div class="w-10 h-10 bg-green mx-auto rounded-full text-lg text-white flex items-center">
				<span class="text-white text-center w-full"><i class="fa fa-check w-full fill-current white"></i></span>
			</div>
		</div>
	
		<div class="w-1/6 align-center items-center align-middle content-center flex">
			<div class="w-full bg-grey-light rounded items-center align-middle align-center flex-1">
			 	<div class="bg-green-light text-xs leading-none py-1 text-center text-grey-darkest rounded " style="width: 20%"></div>
			</div>
		</div>
	
		<div class="flex-1">
			<div class="w-10 h-10 bg-white border-2 border-grey-light mx-auto rounded-full text-lg text-white flex items-center">
				<span class="text-grey-darker text-center w-full">3</span>
			</div>
		</div>
	
	
		<div class="w-1/6 align-center items-center align-middle content-center flex">
			<div class="w-full bg-grey-light rounded items-center align-middle align-center flex-1">
			 	<div class="bg-green-light text-xs leading-none py-1 text-center text-grey-darkest rounded " style="width: 0%"></div>
			</div>
		</div>


		<div class="flex-1">
			<div class="w-10 h-10 bg-white border-2 border-grey-light mx-auto rounded-full text-lg text-white flex items-center">
				<span class="text-grey-darker text-center w-full">4</span>
			</div>
		</div>
	
	
		<div class="flex-1">
		</div>		
	</div>
	
	<div class="flex text-xs content-center text-center">
		<div class="w-1/4">
			Invitation received
		</div>
		
		<div class="w-1/4">
			Personal details
		</div>
		
		<div class="w-1/4">
			Application details
		</div>
		
		<div class="w-1/4">
			Confirmation
		</div>			
	</div>
</div> */}

              <h5 className="text-lg font-semibold mt-5">
                {/* {details?.full_name} */}
                {jobDetailes?.title}
              </h5>
              <p className="text-slate-400 mt-4" onClick={toggleText}>
                {showFullText
                  ? jobDetailes?.description?.replace(/(<([^>]+)>)/gi, "")
                  : jobDetailes?.description
                    ?.replace(/(<([^>]+)>)/gi, "")
                    .slice(0, 400)}{" "}
                <br />
                <span style={{ color: "#1daa82", cursor: "pointer" }}>
                  {showFullText ? "Show Less" : "Show More"}
                </span>
              </p>
              <h4 className="mt-6 text-xl font-semibold">Skills</h4>
              <Grid item container className="mt-5 gap-2">
                <Grid xs={12} md={12}>
                  {jobDetailes?.skills?.map((item) => {
                    return (
                      <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full me-1">
                        {item?.label ? item?.label : item}&nbsp; &nbsp;
                      </div>
                    );
                  })}
                </Grid>
              </Grid>
              <h4 className="mt-6 text-xl font-semibold">Services</h4>
              <Grid item container className="mt-5 gap-3">
                <Grid xs={12} md={12} className="gap-3">
                  {jobDetailes?.services?.map((item) => {
                    return (
                      <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full me-1 mt-2">
                        {`${item?.name ? item?.name : item}`}
                      </div>
                    );
                  })}
                </Grid>
              </Grid>

              <h4 className="mt-6 text-xl font-semibold">Languages</h4>
              <Grid item container className="mt-5 gap-3">
                <Grid xs={12} md={12} className="gap-3">
                  {jobDetailes?.languages?.map((languages) => {
                    return (
                      <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full me-1 mt-2">
                        {`${languages?.value ? languages?.value : languages}`}
                      </div>
                    );
                  })}
                </Grid>
              </Grid>




              <Grid xs={12} md={12}>
                  <h4 className="mt-6 text-xl font-semibold mb-4">Images</h4>
                  <div>
                    <div>
                      <div className="image-container">
                        {jobDetailes?.image?.slice(0, 4).map((imageUrl, index) => (
                          <img
                            key={index}
                            src={`https://api.god.scienstechnologies.com/user/images/${imageUrl}`}
                            alt={`Image ${index + 1}`}
                            onClick={() => openFullImage(index)}
                            className="thumbnail"
                            crossOrigin="anonymous"
                            onError={(e) => handleImageError(e, index)}
                          />
                        ))}
                      </div>

                      {isFullImageOpen && (
                        <FullImageDisplay
                          imageUrl={jobDetailes?.image[selectedImageIndex]}
                          onClose={closeFullImage}
                        />
                      )}
                            {/* <img src={closeButton} crossOrigin="anonymous" /> */}
                      {/* {isFullImageOpen && selectedImageIndex > 0 && (
                        <button
                          className="buttonImagepre"
                          onClick={showPreviousImage}
                        >
                          Previous
                        </button>
                      )}

                      {isFullImageOpen &&
                        selectedImageIndex < jobDetailes?.image.length - 1 && (
                          <button
                            className="buttonImageNext"
                            onClick={showNextImage}
                          >
                            Next
                          </button>
                        )} */}
                    </div>
                  </div>
                </Grid>

              {/* {jobDetailes?.hire_geeks_uuid !== "" && <> */}
              <h4 className="mt-6 text-xl font-semibold">Candidate Hired</h4>
              <Grid item container className="mt-5">
                <Grid xs={12} md={4} >
                  <div className="group bg-white dark:bg-slate-900 relative overflow-hidden rounded-md shadow dark:shadow-gray-700 text-center p-6">
                    <img
                      // src={`${process.env.REACT_APP_IMAGE_BASEURL}/${item?.geeks_details?.personal_details?.profile_pic}`}
                      crossOrigin="anonymous"
                      className="h-20 w-20 rounded-full shadow dark:shadow-gray-700 mx-auto"
                      alt=""
                    />
                    <div className="mt-2">
                      <Link
                        // to={`/seeker/candidate-profile/${jobDetailes?.hire_geeks_uuid}`}
                        className="text-emerald-600 font-semibold text-lg"
                      >
                        {jobDetailes?.hire_geeks_firstname} {jobDetailes?.hire_geeks_lastname}
                      </Link>
                      <p className="text-sm text-slate-400 px-5">
                        {jobDetailes?.hire_geeks_email}
                      </p>
                    </div>
                    <Link to={`/seeker/candidate-profile/${jobDetailes?.hire_geeks_uuid}`}>
                    <div className='mt-5'>
                        <input type="submit" id="submit" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md cursor-pointer" value="View Profile" />
                    </div>
                    </Link>
                  </div>
                </Grid>
              </Grid>
              {/* <Stepper
                activeStep={activeStep}
                alternativeLabel
                style={{ paddingTop: "8%" }}
              >
                {ProgressSteps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper> */}

              <Grid item container className="mt-5">
                <Grid xs={12} md={3} >
                  {jobDetailes?.geeks?.length > 0 &&
                    jobDetailes?.geeks?.map((item) => {
                      return (
                        <div className="group bg-white dark:bg-slate-900 relative overflow-hidden rounded-md shadow dark:shadow-gray-700 text-center p-6">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_BASEURL}/${item?.geeks_details?.personal_details?.profile_pic}`}
                            crossOrigin="anonymous"
                            className="h-20 w-20 rounded-full shadow dark:shadow-gray-700 mx-auto"
                            alt=""
                          />
                          <div className="mt-2">
                            <Link
                              to={`/seeker/candidate-profile/${item.uuid}`}
                              className="hover:text-emerald-600 font-semibold text-lg"
                            >
                              {item?.first_name} {item?.last_name}
                            </Link>
                            <p className="text-sm text-slate-400">
                              {item?.geeks_details?.personal_details?.state}
                            </p>
                          </div>
                          <ul className="mt-2 list-none space-x-0.5">
                            {item?.geeks_details?.technical_expertise?.skills?.map(
                              (item) => {
                                return (
                                  <li className="inline">
                                    <span className="bg-emerald-600/10 inline-block text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full">
                                      {item}
                                    </span>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                          {/* <div className="flex justify-between mt-2"> */}

                          <div className="block" style={{ textAlign: "center" }}>
                            <span className="text-slate-400">Experience:</span>
                            <span className="block text-sm font-semibold">
                              {
                                item?.geeks_details?.technical_expertise
                                  ?.years_of_experience
                              }
                            </span>
                          </div>
                          {/* </div> */}
                          {/* <span className="w-24 text-white p-1 text-center absolute ltr:-rotate-45 rtl:rotate-45 -start-[30px] top-3 bg-yellow-400 flex justify-center"><AiOutlineStar /></span>
                                        <span className="absolute top-[10px] end-4">
                                            <Link to="#" className="text-slate-100 dark:text-slate-700 focus:text-red-600 dark:focus:text-red-600 hover:text-red-600 dark:hover:text-red-600 text-2xl"><i className="mdi mdi-heart"></i></Link>
                                        </span> */}
                        </div>
                      );
                    })}
                </Grid>
              </Grid>
              {/* </> */}
              {/* } */}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
