import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

let initialState = {
  jobsList: {},
  toastData: {},
  seekerJobList: {},
  pendingJobList: [],
  seekerHiredJobPost : [],
}

const jobSlice = createSlice({
  name: 'alljobs',
  initialState,
  reducers: {
    setJobsList(state, action) {
      state.jobsList = action.payload
    },
    setSeekerJobsList(state, action) {
      state.seekerJobList = action.payload
    },
    setSeekerHiredJobPost(state, action) {
      state.seekerHiredJobPost = action.payload
    },
    setApplyJobsPendingList(state, action) {
      state.pendingJobList = action.payload
    },
    settoastData(state, action) {
      state.toastData = action.payload;
    },
  }
})

export const { setJobsList, settoastData, setSeekerJobsList, setApplyJobsPendingList, setSeekerHiredJobPost } = jobSlice.actions;

export const allJobsList = (page, limit, search, token) => async (dispatch) => {
  dispatch(settoastData({ status: '', message: "" }));
  const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/job/postList?pagenum=${page}&limit=${limit}&search=`, {
    headers: {
      "x-auth-token": token
    }
  }).then((res) => {
    dispatch(settoastData({ status: true, message: "Data Recieved!" }));
    dispatch(setJobsList(res.data.data));
  })
    .catch((err) => {
      dispatch(settoastData({ status: false, message: err?.response?.data }));
    })
}

export const SeekerAllJobList = (page, limit, search, token) => async (dispatch) => {
  dispatch(settoastData({ status: '', message: "" }));
  // const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/jobpost/seeker/list?page=${page}&limit=${limit}&search=`, {
    const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/job/postListSeeker?pagenum=${page}&limit=${limit}&search=&post_type=post`, {  
  headers: {
      "x-auth-token": token
    }
  }).then((res) => {
    dispatch(settoastData({ status: true, message: "Data Recieved!" }));
    dispatch(setSeekerJobsList(res.data.data));
  })
    .catch((err) => {
      dispatch(setSeekerJobsList([]))
      dispatch(settoastData({ status: false, message: err?.response?.data }));
    })
}

export const GeeksApplyJobPendingList = (page, limit, search, token) => async (dispatch) => {
  dispatch(settoastData({ status: '', message: "" }));
  const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/job/applyPendingListGeeks?pagenum=${page}&limit=${limit}&search=`, {
    headers: {
      "x-auth-token": token
    }
  }).then((res) => {
    dispatch(settoastData({ status: true, message: "Data Recieved!" }));
    dispatch(setApplyJobsPendingList(res.data.data));
  })
    .catch((err) => {
      dispatch(setApplyJobsPendingList([]))
      dispatch(settoastData({ status: false, message: err?.response?.data }));
    })
}

export const SeekerHiredJobList = (page, limit, search, token) => async (dispatch) => {
  dispatch(settoastData({ status: '', message: "" }));
  const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/job/postListSeeker?pagenum=${page}&limit=${limit}&search=&post_type=hired`, {
    headers: {
      "x-auth-token": token
    }
  }).then((res) => {
    dispatch(settoastData({ status: true, message: "Data Recieved!" }));
    dispatch(setSeekerHiredJobPost(res.data.data));
  })
    .catch((err) => {
      dispatch(setSeekerHiredJobPost([]))
      dispatch(settoastData({ status: false, message: err?.response?.data }));
    })
}

const jobReducer = jobSlice.reducer
export default jobReducer;