import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Bookmark } from "react-feather";
import { Button, Modal } from "@mui/material";
import { jobData } from "../data/data";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import closeButton from "../assets/images/closeButton.png";
import {
  PiMapPin,
  MdOutlineArrowForward,
  FiArrowUpRight,
} from "../assets/icons/vander";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useSelector, useDispatch } from "react-redux";
import { SeekerHiredJobList, SeekerAllJobList } from "../Redux/geeks/jobSlice";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Form from "./Form";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import NewJobPost from "./NewJobPost";

export default function MyJobs() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);

  const { seekerJobList, seekerHiredJobPost } = useSelector(
    (state) => state.alljobs
  );

  const { currentUser } = useSelector((state) => state.auth);

  const [tab, setTab] = useState(0);

  const TabChange = (data) => {
    setTab(data)
  }

  useEffect(() => {
    dispatch(SeekerAllJobList(page, 10, "", currentUser?.token));
    dispatch(SeekerHiredJobList(page, 10, "", currentUser?.token));
  }, [tab]);

  function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }

  // Function to calculate how many hours or days ago a date came
  function calculateTimeAgo(targetDate) {
    // Get the current date and time
    const currentDate = new Date();

    // Convert the target date string to a Date object
    const targetDateObj = new Date(targetDate);

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - targetDateObj;

    if (timeDifference < 0) {
      // The target date is in the future
      const daysAgo = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      return `${daysAgo} days in the future`;
    } else if (currentDate.toDateString() === targetDateObj.toDateString()) {
      // The target date is today
      const hoursAgo = Math.floor(timeDifference / (1000 * 60 * 60));
      return `${hoursAgo} hours ago`;
    } else if (timeDifference < 24 * 60 * 60 * 1000) {
      // The target date is exactly one day ago
      return "1 day ago";
    } else {
      // The target date is in the past, more than one day ago
      const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      return `${daysAgo} days ago`;
    }
  }



  const jobApply = (jobId) => {
    const payload = {
      job_uuid: jobId,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/jobpost/apply`, payload, {
        headers: {
          "x-auth-token": currentUser?.token,
        },
      })
      .then((res) => {
        toast.success("Job applied" ,{
          position:"top-right"
        })
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const checkapplyornot = (array) => {
    const data = array.includes(currentUser?.data?.uuid);
    console.log(data, "datadata");
    return data;
  };

  const [open, setOpen] = React.useState(false);
  const [profUuid, setProfUuid] = useState("");

  const handleOpen = () => {
    // setProfUuid(uuid)
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 400,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    width: "50%", // Adjust the width as needed
    height: "auto", // Adjust the height as needed
  };

  return (
    <div>
      <Navbar navClass="justify-end nav-light" />
      <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-emerald-900/90"></div>
        <div className="container">
          <div className="grid grid-cols-1 text-center mt-10">
            <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">
              List of Jobs
            </h3>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
            <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/">Home</Link>
            </li>
            <li
              className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              {tab === 0 ? "Job Posts" : "Hired Job Posts"}
              {/* List of Jobs */}
            </li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <div className="container">
        <div className="grid lg:grid-cols-10 md:grid-cols-5 mt-12 gap-[30px]">
          <div
            style={{
              border: "1px solid #68712e",
              padding: "20px",
              borderRadius: "10px",
              marginBottom:"40px"
            }}
          >
            <Tabs>
              <TabList>
                <Tab onClick={() => TabChange(0)}>Job Posts</Tab>
                <Tab onClick={() => TabChange(1)}>Hired Job Posts</Tab>
              </TabList>

              <TabPanel>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      margin: "40px 0px",
                    }}
                  >
                    <Button
                      onClick={() => handleOpen()}
                      className="btn btn-sm bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-md"
                    >
                      New Job Post
                    </Button>
                  </div>
                  <div className="grid lg:grid-cols-10 md:grid-cols-5 mt-12 gap-[30px]">
                    {seekerJobList?.length > 0 &&
                      seekerJobList?.map((item, index) => (
                        <div
                          className="group p-6 rounded-lg border border-emerald-600/20 dark:border-emerald-600/40 bg-white dark:bg-slate-900 hover:bg-emerald-600/[0.02] hover:dark:bg-emerald-600/5 hover:shadow-md hover:shadow-emerald-600/5 transition-all duration-500"
                          key={index}
                        >
                          <div className="flex justify-between items-start">
                            <div></div>
                          </div>
                          <div className="mt-1">
                            <Link
                              to={`/jobView/${item.uuid}`}
                              className="text-xl hover:text-emerald-600 font-semibold transition-all duration-500"
                            >
                              {item.title}
                            </Link>
                            <div className="mt-5">
                              <small class="text-muted display-inline-block text-muted">
                                <span>
                                  Posted &nbsp;
                                  <span data-test="posted-on">
                                    {calculateTimeAgo(item.createdAt)}
                                  </span>
                                </span>
                              </small>
                            </div>
                            <p className="text-slate-400 mt-2">
                              {truncate(
                                item.description?.replace(/(<([^>]+)>)/gi, ""),
                                400
                              )}
                            </p>
                            <div className="mt-3">
                              {item?.skills?.map((items) => {
                                return (
                                  <span className="bg-orange-500/5 hover:bg-orange-500/20 dark:bg-orange-500/10 hover:dark:bg-orange-500/30 inline-block text-orange-500 px-4 text-[14px] font-medium rounded-full mt-2 me-1 transition-all duration-500">
                                    {items?.label ? items?.label : items}
                                  </span>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  {/* <div className="grid md:grid-cols-12 grid-cols-1 mt-8 mb-5">
                    <div className="md:col-span-12 text-center">
                      <Link
                        to="job-grid-four"
                        className="btn btn-link text-slate-400 hover:text-emerald-600 after:bg-emerald-600 duration-500 ease-in-out inline-flex items-center"
                      >
                        See More Jobs <MdOutlineArrowForward className="ms-1" />
                      </Link>
                    </div>
                  </div> */}
                  <div>
                    <a
                      id="back-to-top"
                      class="back-to-top fixed text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 text-center bg-emerald-600 text-white justify-center items-center flex"
                      href="#"
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 1024 1024"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="grid lg:grid-cols-10 md:grid-cols-5 mt-12 gap-[30px]">
                  {seekerHiredJobPost?.length > 0 &&
                    seekerHiredJobPost?.map((item, index) => (
                      <div
                        className="group p-6 rounded-lg border border-emerald-600/20 dark:border-emerald-600/40 bg-white dark:bg-slate-900 hover:bg-emerald-600/[0.02] hover:dark:bg-emerald-600/5 hover:shadow-md hover:shadow-emerald-600/5 transition-all duration-500"
                        key={index}
                      >
                        <div className="flex justify-between items-start">
                          <div></div>
                        </div>
                        <div className="mt-1">
                          <Link
                            to={`/HireJobView/${item.uuid}`}
                            className="text-xl hover:text-emerald-600 font-semibold transition-all duration-500"
                          >
                            {item.title}
                          </Link>
                          <div className="mt-5">
                            <small class="text-muted display-inline-block text-muted">
                              <span>
                                Posted &nbsp;
                                <span data-test="posted-on">
                                  {calculateTimeAgo(item.createdAt)}
                                </span>
                              </span>
                            </small>
                          </div>
                          <p className="text-slate-400 mt-2">
                            {truncate(
                              item.description?.replace(/(<([^>]+)>)/gi, ""),
                              400
                            )}
                          </p>
                          <div className="mt-3">
                            {/* {item?.skills?.map((items) => {
                                        return <span className="bg-orange-500/5 hover:bg-orange-500/20 dark:bg-orange-500/10 hover:dark:bg-orange-500/30 inline-block text-orange-500 px-4 text-[14px] font-medium rounded-full mt-2 me-1 transition-all duration-500">{items}</span>                                         
                                    })} */}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                {/* <div className="grid md:grid-cols-12 grid-cols-1 mt-8 mb-5">
                  <div className="md:col-span-12 text-center">
                    <Link
                      to="job-grid-four"
                      className="btn btn-link text-slate-400 hover:text-emerald-600 after:bg-emerald-600 duration-500 ease-in-out inline-flex items-center"
                    >
                      See More Jobs <MdOutlineArrowForward className="ms-1" />
                    </Link>
                  </div>
                </div> */}
                <div>
                  <a
                    id="back-to-top"
                    class="back-to-top fixed text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 text-center bg-emerald-600 text-white justify-center items-center flex"
                    href="#"
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 1024 1024"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                    </svg>
                  </a>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <Footer />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        dialogClassName="full"
      >
        <Box sx={style} className="ContainerForm">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div></div>
            <div>
              <p style={{ fontSize: "25px", fontWeight: "bold" }}>
                New Job Post
              </p>
            </div>
            <div>
              <img
                src={closeButton}
                alt="closeButton"
                style={{ width: "40px", height: "40px", cursor: "pointer" }}
                onClick={handleClose}
              />
            </div>
          </div>
          <NewJobPost />
        </Box>
      </Modal>
    </div>
  );
}
