import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./Form.css";
import { Link } from "react-router-dom";
import { Bookmark } from "react-feather";
import Select from "react-select";
import { servicesURL } from "../Redux/services/servicesSlice";
import { jobData } from "../data/data";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  PiMapPin,
  MdOutlineArrowForward,
  FiArrowUpRight,
} from "../assets/icons/vander";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useSelector, useDispatch } from "react-redux";

export default function Form({ uuid, jobUuid, handleClose, jobDetailes }) {

  console.log(uuid, "uuid")
  const [optionsOne, setSkillsList] = useState([]);
  const [skills, setSkill] = useState("");
  const [data, setData] = useState("");
  const [data1, setData1] = useState("");
  const [servicesSkil, setServicesSkill] = useState("");
  const [compProfile, setCompProfile] = useState("");
  const [language, setLanguagesIndividual] = useState("");
  const [Images, setImages] = useState([]);
  const [coverimage, setcoverImage] = useState("");
  const [cv, setcv] = useState("");
  const [profileData, setProfileData] = useState("");
  const dispatch = useDispatch();
  // const [servicesSkil, setServicesSkill] = useState('');
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");

  const { servicesDropDown, services } = useSelector((state) => state.service);
  const { currentUser } = useSelector((state) => state.auth);

  const TextEditor = (editor) => {
    const data = editor.getData();
    setData(data);
  };

  useEffect(() => {
    setServicesSkill(profileData?.geeks_details?.technical_expertise?.services);
    getSkillsList();
  }, []);


  const languageDropDown = [
    { label: "English", value: "English" },
    { label: "Hindi", value: "Hindi" },
    { label: "Telugu", value: "Telugu" },
  ]
  console.log(jobDetailes, "skills");

  const getSkillsList = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/addondata/skills`)
      .then((res) => {
        console.log(res?.data?.data);
        setSkillsList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const changeScroll = (data, sarch) => {
    console.log(sarch, "data...");
    if (data === "search") {
      dispatch(servicesURL(page, 10, sarch));
    } else if (data === "inc" && page >= 0) {
      dispatch(servicesURL(page + 1, 10, search));
      setPage(page + 1);
    } else if (page >= 1) {
      dispatch(servicesURL(page - 1, 10, search));
      setPage(page - 1);
    }
  };

  const ImageUpload = (event, type) => {
    const data1 = event.target.files;
    const formData = new FormData();

    for (let i = 0; i < data1.length; i++) {
      formData.append("files", data1[i]);
    }

    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/upload/multiImage`, formData)
      .then((res) => {
        console.log(res.data);
        setImages(res.data.image);
      })
      .catch((err) => {
        console.log(err.response);
        setcoverImage("");
      });
  };

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    const service = [];
    const skillsData = [];
    if (jobDetailes?.skills?.length > 0) {
      jobDetailes?.skills?.map((item) => {
        skillsData.push(item?.value ? item?.value : item);
      });
      setSkill(skillsData);
    }
    if (jobDetailes?.services?.length > 0) {
      jobDetailes?.services?.map((item) => {
        service.push(item?.name ? item?.name : item);
      });
      setServicesSkill(service);
    }
    if (jobDetailes?.title) {
      setTitle(jobDetailes?.title);
    }
    if (jobDetailes?.title) {
      setData(jobDetailes?.description);
    }
  }, []);

  const handleNext = (e) => {
    e.preventDefault();

    if (!currentUser.token) {
      toast.warn("Please Login To Continue", {
        position: "top-right",
      })
    } else {
      var payload = {};
      var url = "";

      const service = [];
      const skillsData = [];
      if (jobDetailes?.skills?.length > 0) {
        jobDetailes?.skills?.map((item) => {
          skillsData.push(item?.value ? item?.value : item);
        });
      }
      if (jobDetailes?.services?.length > 0) {
        jobDetailes?.services?.map((item) => {
          service.push(item?.name ? item?.name : item);
        });
      }

      if (skills?.length > 0) {
        skills?.map((item) => {
          skillsData.push(item?.value ? item?.value : item);
        });
      }
      if (servicesSkil?.length > 0) {
        servicesSkil?.map((item) => {
          service.push(item?.value ? item?.value : item);
        });
      }

      if (jobUuid) {
        payload = {
          // title: title,
          job_uuid: jobDetailes?.uuid,
          geeks_uuid: uuid,
          // description: data,
          // skills: skillsData,
          // services: service,
          // image: Images,
        };
        url = `${process.env.REACT_APP_API_BASEURL}/job/assignToGeeks`;
      } else {
        payload = {
          title: title,
          hire_geeks_uuid: uuid,
          description: data,
          skills: skillsData,
          services: service,
          languages : language
          // image: Images,
        };
        url = `${process.env.REACT_APP_API_BASEURL}/job/hire`;
      }
      axios
        .post(url, payload, {
          headers: {
            "x-auth-token": currentUser?.token,
          },
        })
        .then((res) => {
          console.log(res ,"hhhh")
          toast.success(res?.data?.message, {
            position: "top-right",
          })
          setServicesSkill([])
          setSkill([])
          setData('')
          setTitle('')
          setImages([])
          handleClose();
        })
        .catch((err) => {

          toast.error(err.response.data?.message, {
            position: "top-right",
          })
          console.log("Error", err.response.data);
        });
    }
  };

  return (
    <div>
      <div className="grid grid-cols-12 gap-2">
        <div className="md:col-span-6 col-span-12">
          <label htmlFor="name" className="font-semibold">
            Title
          </label>
          <input
            name="title"
            id="name"
            type="text"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            className="form-input mt-2"
            placeholder="Enter"
          />
        </div>
        <div className="md:col-span-6 col-span-12">
          <label htmlFor="email" className="font-semibold">
            Skills
          </label>
          <Select
            className="mt-2"
            name="skills"
            value={
              skills?.length > 0
                ? skills?.map((item) => {
                  return {
                    value: item?.value ? item?.value : item,
                    label: item?.label ? item?.label : item,
                  };
                })
                : []
            }
            onChange={setSkill}
            isMulti
            options={optionsOne}
          />
        </div>
        <div className="md:col-span-6 col-span-12">
          <label htmlFor="email" className="font-semibold">
            Service
          </label>
          <Select
            className="mt-2"
            name="services"
            value={
              servicesSkil?.length > 0
                ? servicesSkil?.map((item) => {
                  return {
                    value: item?.value ? item?.value : item,
                    label: item?.label ? item?.label : item,
                  };
                })
                : []
            }
            onChange={setServicesSkill}
            isMulti
            options={servicesDropDown}
            onMenuScrollToTop={(event) => changeScroll("dec")}
            onMenuScrollToBottom={(event) => changeScroll("inc")}
            onInputChange={(event) => changeScroll("search", event)}
          />
        </div>

        <div className="md:col-span-6 col-span-12">
          <label htmlFor="text" className="font-semibold">
            Languages
          </label>

          <Select className='mt-2' name="services" onChange={setLanguagesIndividual} isMulti options={languageDropDown} />

        </div>
        <div className="md:col-span-6 col-span-12">
          <label htmlFor="email" className="font-semibold">
            Image
          </label>
          <input
            className="relative form-input border border-slate-100 dark:border-slate-800 file:h-10 file:-mx-3 file:-my-2 file:cursor-pointer file:rounded-none file:border-0 file:px-3 file:text-neutral-700 bg-clip-padding px-3 py-1.5 file:me-3 mt-2"
            id="multiple_files"
            type="file"
            multiple
            onChange={ImageUpload}
          />
        </div>
        <div className="col-span-12">
          <label htmlFor="subject" className="font-semibold">
            Work Description
          </label>
          <CKEditor
            // style={{height:"120px"}}
            name="description"
            editor={ClassicEditor}
            data={data}
            onReady={(editor) => {
              console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => TextEditor(editor)}
            onBlur={(event, editor) => {
              console.log("Blur.", editor);
            }}
            onFocus={(event, editor) => {
              console.log("Focus.", editor);
            }}
          />
        </div>

        <div className="col-span-12 mb-8">
          <input
            className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
            type="checkbox"
            value=""
            defaultChecked
            id="noti7"
          /> &nbsp; &nbsp;


          <label className="form-check-label text-slate-400" htmlFor="AcceptTC">Yes, I understand and agree to the Geek on Demand <a href='/Terms' target='_blank' className="text-emerald-600">Terms of Service</a>, including the <a href='/Agreement' target="_blank" className="text-emerald-600">User Agreement</a> and <a href='/Privacy' target='_blank' className="text-emerald-600">Privacy Policy</a>.</label>

        </div>
      </div>
      <button
        type="button"
        onClick={handleNext}
        className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md"
      >
        Hire Me
      </button>
    </div>
  );
}
