import logo_light from '../assets/images/logo-light.png';
import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";

import logo_icon_40 from '../assets/images/logo-icon-40.png';
import logo_icon_40_white from '../assets/images/logo-icon-40-white.png';
import logo_dark from '../assets/images/logo-dark.png';
import logo_white from '../assets/images/logo-white.png';
import image from '../assets/images/team/05.jpg';
import webLogo from '../assets/images/web-logo.png';
import companylogo1 from '../assets/images/company-logo1.png';
import companylogo2 from '../assets/images/company-logo2.png';
import { LuSearch } from "../assets/icons/vander";
import { User, Settings, Lock, LogOut } from 'react-feather';
import { LogoutUrl } from '../Redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function Navbar(props) {
    const [isDropdown, openDropdown] = useState(true);
    const { navClass, topnavClass, isContainerFluid } = props;
    const [isOpen, setMenu] = useState(true);

    window.addEventListener("scroll", windowScroll);
    useEffect(() => {
        activateMenu();
    }, []);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { currentUser } = useSelector((state) => state.auth);

    console.log(currentUser?.data?.seekers_details?.profile_type, "currentUser...f")

    const Logout = (data) => {
        dispatch(LogoutUrl())
        setTimeout(() => {
            if(data === "login"){
                navigate('/login')
            }
            else{
                navigate('/')
            }
            // navigate('/admin-specific-route')
        })
    }

    function windowScroll() {
        const navbar = document.getElementById("topnav");
        if (
            document.body.scrollTop >= 50 ||
            document.documentElement.scrollTop >= 50
        ) {
            if (navbar !== null) {
                navbar?.classList.add("nav-sticky");
            }
        } else {
            if (navbar !== null) {
                navbar?.classList.remove("nav-sticky");
            }
        }

        const mybutton = document.getElementById("back-to-top");
        if (mybutton != null) {
            if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                mybutton.classList.add("flex");
                mybutton.classList.remove("hidden");
            } else {
                mybutton.classList.add("hidden");
                mybutton.classList.remove("flex");
            }
        }
    }

    const toggleMenu = () => {
        setMenu(!isOpen)
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    }


    const getClosest = (elem, selector) => {

        // Element.matches() polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) { }
                    return i > -1;
                };
        }

        // Get the closest matching element
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.matches(selector)) return elem;
        }
        return null;

    };

    const activateMenu = () => {
        var menuItems = document.getElementsByClassName("sub-menu-item");
        if (menuItems) {

            var matchingMenuItem = null;
            for (var idx = 0; idx < menuItems.length; idx++) {
                if (menuItems[idx].href === window.location.href) {
                    matchingMenuItem = menuItems[idx];
                }
            }

            if (matchingMenuItem) {
                matchingMenuItem.classList.add('active');


                var immediateParent = getClosest(matchingMenuItem, 'li');

                if (immediateParent) {
                    immediateParent.classList.add('active');
                }

                var parent = getClosest(immediateParent, '.child-menu-item');
                if (parent) {
                    parent.classList.add('active');
                }

                var parent = getClosest(parent || immediateParent, '.parent-menu-item');

                if (parent) {
                    parent.classList.add('active');

                    var parentMenuitem = parent.querySelector('.menu-item');
                    if (parentMenuitem) {
                        parentMenuitem.classList.add('active');
                    }

                    var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                } else {
                    var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                }
            }
        }
    }

    const [showLoginForm, setShowLoginForm] = useState(false);

    const handleLoginClick = () => {
        setShowLoginForm(true);
    };

    const handleLoginFormClose = () => {
        setShowLoginForm(false);
    };

  


    return (
        <nav id="topnav" className={`defaultscroll is-sticky ${topnavClass}`}>
            <div className={`${isContainerFluid === true ? 'container-fluid md:px-8 px-3' : 'container'}`}>
                <Link className="logo mt-2" to="/">
                    <div className="block sm:hidden">
                        <img src={companylogo2} className=" inline-block dark:hidden" alt="" width="50px" height="20px" />
                        <img src={companylogo2} className=" hidden dark:inline-block" alt="" width="50px" height="20px" />
                    </div>
                    {navClass && navClass.includes('nav-light') ?
                        <div className="sm:block hidden">
                            <span className="inline-block dark:hidden">
                                <img src={companylogo2} className=" l-dark" alt="" width="50px" height="20px" />
                                <img src={companylogo2} className=" l-light" alt="" width="50px" height="20px" />
                            </span>
                            <img src={companylogo2} className=" hidden dark:inline-block" alt="" width="50px" height="20px" />
                        </div> :
                        <div className="sm:block hidden">
                            <img src={companylogo2} className=" inline-block dark:hidden" alt="" width="50px" height="20px" />
                            <img src={companylogo2} className=" hidden dark:inline-block" alt="" width="50px" height="20px" />
                        </div>
                    }
                </Link>

                <div className="menu-extras">
                    <div className="menu-item">
                        <Link to="#" className="navbar-toggle" id="isToggle" onClick={toggleMenu}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </div>
                </div>
                {/* <!-- End Mobile Toggle --> */}

                {/* <!--Login button Start--> */}
                <ul className="buy-button list-none mb-0">
                    {/* <li className="inline-block mb-0">
                        <div className="relative">
                            <LuSearch className="text-lg absolute top-[10px] end-3" />
                            <input type="text" className="form-input h-9 pe-10 rounded-3xl sm:w-44 w-36 border-gray-100 dark:border-slate-800 bg-white dark:bg-slate-900" name="s" id="searchItem" placeholder="Search..." />
                        </div>
                    </li> */}

                    {currentUser?.data !== undefined && JSON.stringify(currentUser?.data) !== "{}" ?
                        <li className="dropdown inline-block relative ps-1">
                            <button onClick={() => openDropdown(!isDropdown)} data-dropdown-toggle="dropdown" className="dropdown-toggle btn btn-icon rounded-full bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white inline-flex" type="button">
                                <img src={image} className="rounded-full" alt="" />
                            </button>
                            <div className={`dropdown-menu absolute end-0 m-0 mt-4 z-10 w-44 rounded-md overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 ${isDropdown ? 'hidden' : 'block'}`} >
                                <ul className="py-2 text-start">

                                    {(currentUser?.data?.type === "geeks") && (currentUser?.data?.geeks_details?.geeks_type === "individual") &&
                                        <li>
                                            <Link to={`/geeeks/profile/individual/${currentUser?.data?.uuid}`} className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-emerald-600 dark:hover:text-white"><User className="h-4 w-4 me-2" />Profile</Link>
                                        </li>
                                       
                                    }
                                        {(currentUser?.data?.type === "geeks") && (currentUser?.data?.geeks_details?.geeks_type === "individual") &&
                                          <li>
                                          <Link to='/Dashboard' className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-emerald-600 dark:hover:text-white"><User className="h-4 w-4 me-2" />Dashboard</Link>
                                      </li>
                                       
                                    }
                                    {(currentUser?.data?.type === "geeks") && (currentUser?.data?.geeks_details?.geeks_type === "corporate") &&
                                        <li>
                                            <Link to={`/geeks/profile/corporate`} className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-emerald-600 dark:hover:text-white"><User className="h-4 w-4 me-2" />Profile</Link>
                                        </li>
                                    }
                                      {(currentUser?.data?.type === "geeks") && (currentUser?.data?.geeks_details?.geeks_type === "corporate") &&
                                         <li>
                                         <Link to='/Dashboard' className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-emerald-600 dark:hover:text-white"><User className="h-4 w-4 me-2" />Dashboard</Link>
                                     </li>
                                    }
                                    {(currentUser?.data?.type === "geeks") && (currentUser?.data?.geeks_details?.geeks_type === undefined) &&
                                        <li>
                                            <Link to={`/MultiStepForm`} className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-emerald-600 dark:hover:text-white"><User className="h-4 w-4 me-2" />Profile</Link>
                                        </li>
                                    }
                                    {(currentUser?.data?.type === 'seeker') && (currentUser?.data?.seekers_details?.profile_type !== undefined) &&
                                        <li>
                                            <Link to={`/seeker/profile`} className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-emerald-600 dark:hover:text-white"><User className="h-4 w-4 me-2" />Profile</Link>                                          
                                            {/* <Link to={`/SeekersProfile`} className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-emerald-600 dark:hover:text-white"><User className="h-4 w-4 me-2" />Profile</Link> */}
                                        </li>
                                    }
                                    {(currentUser?.data?.type === "seeker") && (currentUser?.data?.seekers_details?.profile_type === undefined) &&
                                        <li>
                                            <Link to={`/seeker/profile`} className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-emerald-600 dark:hover:text-white"><User className="h-4 w-4 me-2" />Profile</Link>
                                        </li>
                                    }
                                    <li>
                                        <Link onClick={() => Logout('logout')} className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-emerald-600 dark:hover:text-white"><LogOut className="h-4 w-4 me-2" />Logout</Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        :
                        <button onClick={() => Logout('login')} className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full mx-5" type="button">
                            Login/Signup
                        </button>
                    }
                </ul>

                <div id="navigation" className={`${isOpen === true ? 'hidden' : 'block'}`}>
                    <ul className={`navigation-menu ${navClass}`}>
                        <li className="has-submenu parent-menu-item">
                            <Link to="/">Home</Link>
                        </li>

                        <li className="has-submenu parent-parent-menu-item">
                            <Link to="/aboutus">About Us</Link>
                        </li>

                        <li className="has-submenu parent-parent-menu-item">
                            <Link to="#">Benefits</Link><span className="menu-arrow"></span>
                            <ul className="submenu">
                                <li><Link to="/SeekerGet" className="sub-menu-item">What Does The Seeker Get?</Link></li>
                                <li><Link to="/GeekGet" className="sub-menu-item">What Does The Geek  Get?</Link></li>
                              
                            </ul>
                        </li>


                        <li><Link to="/contact" className="sub-menu-item">Contact</Link></li>
                        {currentUser?.data?.type === "seeker" ?
                            <li className="has-submenu parent-parent-menu-item">
                                <Link to="/MyJobs">My Jobs</Link>
                            </li>
                            :
                            <>
                                <li className="has-submenu parent-parent-menu-item">
                                    <Link to="/JobList">Find Jobs</Link>
                                </li>
                                {currentUser?.data?.type === "geeks" &&
                                    <li className="has-submenu parent-parent-menu-item">
                                        <Link to="/AppliedJobs">My Jobs</Link>
                                    </li>
                                }
                            </>
                        }
                    </ul>
                </div>
            </div>
        </nav>

    )
}
