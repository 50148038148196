import React, { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Navbar from '../components/Navbar';
import bg5 from "../assets/images/hero/bg5.jpg"
import team1 from "../assets/images/team/01.jpg"
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import axios from 'axios'
import { Twitter, Facebook, Instagram, Linkedin, Youtube } from 'react-feather';
import Footer from './Footer';
import geeks from '../assets/images/work/programmer.png';
import seeks from '../assets/images/work/job.png'
import { Stepper, Step, StepLabel, Button, Typography, Container, Paper, Grid, TextField } from '@mui/material';
import SeekerIndividualProfile from './SeekerIndividualProfile';
import SeekerCorporateProfile from './SeekerCorporateProfile';

export default function GeekerSeekerProfile() {

    const { currentUser } = useSelector((state) => state.auth);

    const [data, setData] = useState('');
    const TextEditor = (editor) => {
        const data = editor.getData();
        setData(data)
    }

    const [selectedTab, setSelectedTab] = useState(0);
    const [editDetailes, setEditDetailes] = useState(true)
    const [type, setType] = useState(false)
    const [details, setDetails] = useState("");

    const ChangeEditStatus = () => {
        setEditDetailes(!editDetailes)
    }

    const EditStatusFalse = () => {
        setEditDetailes(false)
    }

    const GetProfileDetails = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_BASEURL}/user/seeker/profile/${currentUser?.data?.uuid}`,
                {
                    headers: {
                        "x-auth-token": currentUser?.token,
                    },
                }
            )
            .then((res) => {
                console.log(res.data?.data, "hdsfcgshgfjs");
                setDetails(res.data?.data);
                if (res.data?.data?.seekers_details?.profile_type === "corporate") {
                    setSelectedTab(1)
                    setEditDetailes(false)
                }
                else if (res.data?.data?.seekers_details?.profile_type === "individual") {
                    setSelectedTab(0)
                    setEditDetailes(false)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        GetProfileDetails();
    }, []);

    const changeGeek = (data) => {
        setType(data)
    }

    return (

        <>
            <Navbar navClass='justify-end nav-light' />
            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Seeker Profile</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Home</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Profile</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="relative lg:mt-10 mt-[74px] pb-16">
                <div className="lg:container container-fluid">
                </div>
                {details?.seekers_details?.profile_type !== undefined && !editDetailes &&
                    <div className='mt-5' style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10%' }}>
                        <input type="button" onClick={ChangeEditStatus} className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md cursor-pointer" value={editDetailes ? "View" : "Edit"} />
                    </div>
                }
                <div>
                    {details?.seekers_details?.profile_type === undefined &&
                        <Grid item container style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Grid xs={12} md={3} lg={3} className="text-center rounded border cursor-pointer "
                                style={{ margin: "20px", padding: "20px 50px", border: selectedTab == 0 ? '2px solid #059669' : '#555', backgroundColor: "rgb(5 150 105 / 0.05)" }} onClick={() => { setSelectedTab(0); changeGeek(type ? false : true) }} >
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <img src={geeks} alt="geeks" style={{ width: "70px", height: "70px" }} />
                                </div>
                                <div className="mt-4">
                                    <p className="text-sm font-semibold hover:text-emerald-600 transition-all duration-500" >Individual</p>
                                </div>
                            </Grid>

                            <Grid xs={12} md={3} lg={3} className="text-center rounded border cursor-pointer" style={{ margin: "20px", padding: "20px 50px", border: selectedTab == 1 ? '2px solid #059669' : '#555', backgroundColor: "rgb(5 150 105 / 0.05)" }} onClick={() => { setSelectedTab(1); changeGeek(type ? false : true) }}>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <img src={seeks} alt="seeks" style={{ width: "70px", height: "70px" }} />
                                </div>
                                <div className="mt-4">
                                    <p to="#" className="text-sm font-semibold hover:text-emerald-600 transition-all duration-500" >Corporate</p>
                                </div>
                            </Grid>
                        </Grid>
                    }
                </div>
                <div >
                    <div className="grid grid-cols-1 gap-[30px]">
                        {selectedTab == 0 ? (<SeekerIndividualProfile edit={editDetailes} EditStatusFalse={EditStatusFalse}/>) : selectedTab == 1 ? (<SeekerCorporateProfile edit={editDetailes} EditStatusFalse={EditStatusFalse} />) : null}
                    </div>

                </div>
            </section>
            <Footer />
        </>
    )
}