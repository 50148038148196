import React, { useState } from 'react'
import { Link } from "react-router-dom";
import logo_dark from '../assets/images/logo-dark.png';
import logo_light from '../assets/images/logo-light.png';
import { MdOutlineArrowForward, BsTelephone, FaAtom, FiUserPlus, AiOutlineHourglass } from "../assets/icons/vander"
import { useNavigate } from 'react-router-dom';
import geeks from '../assets/images/work/programmer.png';
import seeks from '../assets/images/work/job.png'
import weblogo from '../assets/images/web-logo.png'

export default function Joinning() {
    const [selectedTab ,setSelectedTab] = useState(0);
    const navigate = useNavigate();

    const featuredata = [
        {
            icon: AiOutlineHourglass,
            title: 'GeekOnDemand Pay Safe',
            data: 'If you are on the hunt for a tech whizz to solve your digital dilemmas, "GeekOnDemand Pay Safe" is your knight in shining armor. This extraordinary feature ensures a secure, straightforward and stress-free process of hiring geeks.'
        },
        {
            icon: FaAtom,
            title: 'Dedicated Support',
            data: 'At GeekOnDemand, we pride ourselves on our dedicated support - because we know the importance of swift and reliable help when you are dealing with IT challenges. Our team of geeks are always ready to provide assistance, whether you are facing a minor glitch or a major hurdle.'
        },
    ];


    const payloadForm = {
        "type": "",
        "first_name": "",
        "last_name": "",
        "email": "",
        "mobile": "",
        // "country_uuid": "",
        // "password": ""
    }

    console.log(payloadForm, "payloadForm")

    const [seekerOrGeeker, setGeekSeek] = useState(false)

    const changeGeek = (data) => {
        console.log(data, "data")
        setGeekSeek(data)
    }

    const NavigateSignup = () => {
        if(selectedTab === 1){
            payloadForm.type = "seeker";
        }
        else{
            payloadForm.type = "geeks";
        };

        if(payloadForm.type){
            navigate('/signup', {state : payloadForm})
            // console.log(payloadForm, "dddddd")
        }
    }

    const [selectedRole, setSelectedRole] = useState('Geek');

    const handleRoleChange = (role) => {
      setSelectedRole(role);
    };



    return (
        <section className="h-screen flex items-center justify-center relative overflow-hidden bg-[url('../../assets/images/hero/bg3.jpg')] bg-no-repeat bg-center bg-cover">
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
            {/* <div className="container"> */}
            <div className="grid lg:grid-cols-6 md:grid-cols-4 grid-cols-6">
                <div className="relative overflow-hidden bg-white  rounded-md">
                    <div style={{ padding: "30px" }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems:"center"}} >
                        <img src={weblogo} alt='weblogo' className="h-10 inline-block dark:hidden" width="100px" height="100px"/>
                        </div>


                        <p className="py-2 font-semibold" style={{ display: "flex", justifyContent: "center", fontSize: "20px" }}>Join as a Geek or Seeker</p>


                        <form className="text-left">
                            <div className="grid grid-cols-1">
                                {/* <div className="container" > */}
                                <div >
                                    {/* <div className="grid lg:grid-cols-2 md:grid-cols-6 sm:grid-cols-12 grid-cols-1" style={{ display: "flex", justifyContent: "center", marginBottom: "5%" }}>
                                        <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500" style={{ margin: "15px" }}>
                                            <div className="inline-flex items-center mb-0">
                                                <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50 me-2" type="checkbox" value="" checked={!seekerOrGeeker ? true : false} onChange={() => changeGeek(seekerOrGeeker  ? false : true)} id="RememberMe" />
                                            </div>
                                            <div className="mt-4">
                                                <Link to="#" className="text-lg font-semibold hover:text-emerald-600 transition-all duration-500" >I’m a Geeks, <br /> looking for work</Link>
                                            </div>
                                        </div>
                                        
                                        <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500" style={{ margin: "15px" }}>
                                            <div className="inline-flex items-center mb-0">
                                                <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50 me-2" type="checkbox" value="" checked={seekerOrGeeker ? true : false} onChange={() => changeGeek(seekerOrGeeker  ? false : true)} id="RememberMe" />
                                            </div>
                                            <div className="mt-4">
                                                <Link to="#" className="text-lg font-semibold hover:text-emerald-600 transition-all duration-500" >I’m a Seekers, <br /> hiring  for a project</Link>
                                            </div>
                                        </div>
                                        <form >
                                <div >
                                    
                                
                                </div>
                              
                              
                            </form>
                              
                                    </div> */}


                                    <div style={{ display: "flex", justifyContent: "center"}}>
                                        <div className=" p-6  text-center rounded border cursor-pointer" style={{ margin: "10px" , backgroundColor : selectedTab == 0 ? 'rgb(5 150 105 / 0.05)' : '#fff'}} onClick={() =>{setSelectedTab(0);changeGeek(seekerOrGeeker  ? false : true);handleRoleChange('Geek')}} >
                                        
                                        <div className="mt-4" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                <img src={geeks}  alt="geeks" style={{width:"70px",height:"70px"}} />
                                            </div>

                                            <div className="mt-4">
                                                <p className="text-lg font-semibold hover:text-emerald-600 transition-all duration-500" >I'm a Geek, <br/>looking for work</p>
                                            </div>
                                        </div>
                                    
                                        <div 
                                        className=" p-6 text-center rounded border cursor-pointer" 
            style={{ margin: "10px" , backgroundColor : selectedTab == 1 ? 'rgb(5 150 105 / 0.05)' : '#fff'}} 
            onClick={() =>{setSelectedTab(1);changeGeek(seekerOrGeeker  ? false : true);handleRoleChange('Seeker')}}>

                                        <div className="mt-4" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        <img src={seeks}  alt="seeks" style={{width:"70px",height:"70px"}}/>
                                        </div>
                                        
                                            <div className="mt-2">
                                           
                                                <Link to="#" className="text-lg font-semibold hover:text-emerald-600 transition-all duration-500" >I'm a Seeker, <br/>hiring for a project</Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* </div> */}
                                </div>
                                <div className="my-2" style={{ display:"flex",justifyContent:"center",alignItems:"center" }}>
                                    {/* <Link to="/signup"> */}
                                    <input type="button" onClick={NavigateSignup} className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md cursor-pointer" value="Sign Up" />
                                    {/* </Link> */}
                                </div>
                                {/* </div> */}
                                <div className="text-center">
                                    <span className="text-slate-400 me-2">Don't have an account ?</span> <Link to="/signup" className="text-black dark:text-white font-bold">Sign In</Link>
                                </div>
                                <div className="text-center" style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                        <div></div>
                                        <div>
                                        <Link to="/"> <span className="text-slate-400 me-2"style={{fontSize:"14px",textDecoration:"underline"}}>Back to Home</span></Link>
                                        </div>
                                        <div></div>
                                   
                                    </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </section>
    )
}
