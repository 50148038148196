import React, { useState, useEffect } from "react";
import team2 from "../assets/images/team/07.jpg";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Select from "react-select";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import pencil from "../assets/images/pencil.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { countries } from "countries-list";
// import { Select } from '@mui/material';
import { MuiTelInput } from "mui-tel-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { servicesURL } from "../Redux/services/servicesSlice";

export default function SeekerCorporateProfile({ edit, EditStatusFalse }) {
  const [data, setData] = useState("");
  const TextEditor = (editor) => {
    const data = editor.getData();
    setData(data);
  };

  const [selectedTab, setSelectedTab] = useState(0);

  const [type, setType] = useState(false);

  const changeGeek = (data) => {
    setType(data);
  };

  const { currentUser } = useSelector((state) => state.auth);

  const [details, setDetails] = useState("");
  const [image, setImage] = useState("");
  // const [pincode, setPincode] = useState('')

  const GetProfileDetails = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASEURL}/user/seeker/profile/${currentUser?.data?.uuid}`,
        {
          headers: {
            "x-auth-token": currentUser?.token,
          },
        }
      )
      .then((res) => {
        console.log(res.data?.data, "hdsfcgshgfjs");
        setDetails(res.data?.data);
        setImage(res.data?.data?.seekers_details?.logo || "");
        // setPincode(res.data?.data?.seekers_details?.pincode || "")
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetProfileDetails();
  }, []);

  const [selectedCountry, setSelectedCountry] = useState(null);

  const countryOptions = Object.keys(countries).map((countryCode) => ({
    value: countryCode,
    label: `${countries[countryCode].name} (+${countries[countryCode].phone})`,
  }));

  const ImageUpload = (event) => {
    console.log(event.target.files, "event");
    const data1 = event.target.files[0];
    const formData = new FormData();
    formData.append("file", data1);
    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/upload/image`, formData)
      .then((res) => {
        // console.log(res.data.image.filename)
        setImage(res.data.image.filename);
      })
      .catch((err) => {
        console.log(err.response);
        setImage("");
      });
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 50,
    height: 50,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  const [value, setValue] = React.useState("");

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const [phone, setPhone] = useState(details?.mobile?.replace(/[+\-\s]/g,''));
  // const [activeStep, setActiveStep] = useState(0);

  const [profileData, setProfileData] = useState("");
  // individual
  // const [skills, setSkill] = useState('');
  // const [bio123, setBio] = useState('');
  // const [certifiedskills, setCertifiedSkill] = useState('');
  // const [servicesSkil, setServicesSkill] = useState('');
  // const [language, setLanguagesIndividual] = useState('');
  // const [profileImage, setProfileImage] = useState('')
  // const [coverimage, setcoverImage] = useState('')
  // const [cv, setcv] = useState('');
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  console.log(country, "dasasdasda");
  const [city, setCity] = useState("");

  // corporate
  // const [compProfile, setCompProfile] = useState('');

  const optionsLanguages = [
    { value: "ENGLISH", label: "ENGLISH" },
    { value: "TELUGU", label: " TELUGU" },
    { value: "HINDI", label: "HINDI" },
  ];

  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [limit, setlimit] = useState(0);
  // const [search, setsearch] = useState(0);
  const [search, setSearch] = useState("");
  const [companySelected, setCompanySelected] = useState("");
  const [companySelected1, setCompanySelected1] = useState("");
  const [panNumber, setPanNumber] = useState(
    details?.seekers_details?.pan_number
  );
  const { servicesDropDown, services } = useSelector((state) => state.service);

  const changeScroll = (data, sarch) => {
    console.log(sarch, "data...");
    if (data === "search") {
      dispatch(servicesURL(page, 10, sarch));
    } else if (data === "inc" && page >= 0) {
      dispatch(servicesURL(page + 1, 10, search));
      setPage(page + 1);
    } else if (page >= 1) {
      dispatch(servicesURL(page - 1, 10, search));
      setPage(page - 1);
    }
  };

  console.log(services, servicesDropDown, "services");

  // useEffect(() => {
  //     dispatch(servicesURL(page, 10, search))
  //     // getProfileDetailes();
  //     getCompanyList();
  //     getSkillsList();
  //     // getstateList();
  //     // getcityList();
  //     getcountryList();
  // }, [activeStep])

  // step 0
  // const [type, setType] = useState(false)

  // const changeGeek = (data) => {
  //     setType(data)
  // }

  // const [data, setData] = useState('');
  const [data1, setData1] = useState("");

  // const TextEditor = (editor) => {
  //     const data = editor?.getData();
  //     setData(data)
  // }
  const TextEditor1 = (editor) => {
    const data = editor?.getData();
    setData1(data);
  };

  const TypeofCompanyFunc = (item) => {
    console.log(item, "item");
    setCompanySelected(item);
    setCompanySelected1(item.key);
  };

  useEffect(() => {
    // setServicesSkill(profileData?.geeks_details?.technical_expertise?.services)
    // setCertifiedSkill(profileData?.geeks_details?.technical_expertise?.skills)
    // setLanguagesIndividual(profileData?.geeks_details?.technical_expertise?.language)
    // setSkill(profileData?.geeks_details?.technical_expertise?.skills)
    // setData(profileData?.geeks_details?.about?.bio)
    // setData1(profileData?.geeks_details?.about?.description_about_company_offering)
    setPhone(details?.mobile?.replace(/[+\-\s]/g,''))
    setState({
      label: details?.seekers_details?.state,
      value: details?.seekers_details?.state,
    });
    setCity({
      label: details?.seekers_details?.city,
      value: details?.seekers_details?.city,
    });
    setCountry({
      label: details?.seekers_details?.country,
      value: details?.seekers_details?.country,
    });

    setPanNumber(details?.seekers_details?.pan_number);
    setCompanySelected({
      key: details?.seekers_details?.type_of_company,
      label: details?.seekers_details?.type_of_company,
    });
    // setProfileImage()
    // setcoverImage()
    // setcv()
    // setCompProfile()
  }, [details]);

  useEffect(() => {
    setType(
      profileData?.geeks_details?.geeks_type === "corporate" ? true : false
    );
  }, []);

  // const getProfileDetailes = () => {
  //     axios.get(`${process.env.REACT_APP_API_BASEURL}/user/geeks/profile/${currentUser?.data?.uuid}`, {
  //         headers: {
  //             "x-auth-token": currentUser?.token
  //         }
  //     })
  //         .then((res) => {
  //             console.log(res.data?.data, "dsjvjssdjvg")
  //             setProfileData(res?.data?.data)
  //         })
  //         .catch((err) => {
  //             console.log(err, "err")
  //         })
  // }

  const [companyList, setCompanyList] = useState([]);
  const [optionsOne, setSkillsList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  console.log(countryList, "sdasdadasda");

  console.log(companySelected, "companySelected");
  const getCompanyList = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/addondata/companyType`)
      .then((res) => {
        // console.log(res?.data?.data)
        setCompanyList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const getSkillsList = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/addondata/skills`)
      .then((res) => {
        console.log(res?.data?.data);
        setSkillsList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const getstateList = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/state/list`)
      .then((res) => {
        console.log(res?.data?.data, "tertetrerte");
        setStateList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const getcityList = (page, limit, search) => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASEURL}/city/list?/city/list?page=&limit=&search=`
      )
      .then((res) => {
        console.log(res?.data?.data, "dfsdfdsfs");
        setCityList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const getcountryList = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/country/list`)
      .then((res) => {
        console.log(res?.data?.data, "erwerwre");
        setCountryList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const [statevalues, setStatevalues] = useState("");
  const [cityvalues, setCityvalues] = useState("");

  const GetStateDropdown = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASEURL}/state/listByCountry/CT-5BD8C295`
      )
      .then((res) => {
        console.log(res);
        setStatevalues(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    GetStateDropdown();
    GetCityDropdown(state);
    getCompanyList();
    getcountryList();
  }, []);

  const GetCityDropdown = (stateid) => {
    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/city/listByState/${stateid}`)
      .then((res) => {
        console.log(res, "rafi123");
        setCityvalues(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const CountryOption = [];
  countryList?.length > 0 &&
    countryList?.map((items) => {
      return CountryOption?.push({ label: items?.name, value: items?.uuid });
    });

  const CountryChangeFunction = (event) => {
    console.log(event, "event");
    setState("");
    setCountry(event);
    GetStateDropdown(event?.value);
  };

  const StateOption = [];
  statevalues?.length > 0 &&
    statevalues?.map((items) => {
      return StateOption?.push({ label: items?.name, value: items?.uuid });
    });

  const StateChangeFunction = (event) => {
    console.log(event, "event");
    setState(event);
    setCity("");
    GetCityDropdown(event?.value);
  };

  // state

  const CityOption = [];
  cityvalues?.length > 0 &&
    cityvalues?.map((items) => {
      return CityOption?.push({ label: items?.name, value: items?.uuid });
    });

  const CityChangeFunction = (event) => {
    console.log(event, "event");
    setCity(event);
  };

  // pan card

  const [isValid, setIsValid] = useState(null);

  const handlePanChange = (event) => {
    const inputPan = event.target.value.toUpperCase();
    setPanNumber(inputPan);

    // Regular expression to check if PAN card number is in the correct format
    const panRegex = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]$/;

    if (panRegex.test(inputPan)) {
      // Perform additional checks if needed
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const UpdateSeekerProfile = (e) => {
    e.preventDefault();
    console.log(e.target.elements, "saduygs");
    const data = e.target.elements;
    var payload = {
      profile_type: "corporate",
      first_name: data?.first_name?.value,
      last_name: data?.last_name?.value,
      seekers_details: {
        company_name: data?.company_name?.value,
        // type_of_company: data?.companySelected?.value,
        type_of_company: companySelected1,
        cn: data?.cn?.value,
        cn_date: data?.cn_date?.value,
        pan_number: data?.pan_number?.value,
        gstin: data?.gstin?.value,
        address: data?.address?.value,
        // city: data?.city?.value,
        // state: data?.state?.value,
        // country: data?.country?.value,
        country: country.label,
        city: city?.label,
        state: state?.label,
        pincode: data?.pincode?.value,
        logo: image,
      },
    };
    axios
      .put(
        `${process.env.REACT_APP_API_BASEURL}/user/seeker/profile/update/${currentUser?.data?.uuid}`,
        payload,
        {
          headers: {
            "x-auth-token": currentUser?.token,
          },
        }
      )
      .then((res) => {
        console.log(res.data?.data, "hdsfcgshgfjs");
        toast.success("Profile Updated", {
          postion: "top-right",
        });
        GetProfileDetails();
        EditStatusFalse();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error");
      });
  };

  const inputPanColor = panNumber?.length === 10 ? "green" : "red";

  return (
    <>
      <section className="relative lg:mt-24 mt-[74px] pb-16">
        <div className="lg:container container-fluid"></div>
        <div className="container">
          <form onSubmit={UpdateSeekerProfile}>
            <div className="grid grid-cols-1 gap-[30px]">
              <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                <div>
                  <div className="md:flex mx-4 -mt-20">
                    <div className="md:w-full">
                      <div className="relative flex items-end">
                        <input
                          id="pro-img"
                          name="profile-image"
                          type="file"
                          className="hidden"
                          onClick={ImageUpload}
                        />
                        <Stack>
                          <input
                            id="pro-img"
                            disabled={!edit}
                            name="profile-image"
                            type="file"
                            className="hidden"
                            onClick={ImageUpload}
                          />
                          <Badge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            badgeContent={
                              <>
                                <SmallAvatar
                                  alt="Remy Sharp"
                                  src={pencil}
                                  htmlFor="pro-img"
                                  disabled={!edit}
                                />
                                <label
                                  className="absolute inset-0 cursor-pointer"
                                  htmlFor="pro-img"
                                ></label>
                              </>
                            }
                          >
                            <img
                              alt="Profile"
                              crossOrigin="anonymous"
                              src={
                                `${process.env.REACT_APP_IMAGE_BASEURL}/${details?.seekers_details?.logo}` ||
                                team2
                              }
                              style={{
                                width: "140px",
                                height: "140px",
                                borderRadius: "50%",
                              }}
                            />
                          </Badge>
                        </Stack>
                        <div className="ms-4">
                          <h5 className="text-lg font-semibold">
                            {/* {details?.full_name} */}
                            Profile Seeker (
                            {details?.seekers_details
                              ? details?.seekers_details?.profile_type
                              : "corporate"}
                            )
                          </h5>
                          <p className="text-slate-400">
                            {/* {data?.title ? data?.title : "UI Designer"} */}
                            {/* Hyderabad, Telangana, India */}
                            {details?.seekers_details?.city},
                            {details?.seekers_details?.state},
                            {details?.seekers_details?.country}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-lg font-semibold mt-5">
                    Company Details :
                  </div>
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 mt-5">
                    <div>
                      <label className="form-label font-medium">
                        Company Name :<span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        disabled={!edit}
                        defaultValue={
                          details?.seekers_details?.company_name || ""
                        }
                        className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                        placeholder="Enter"
                        id="company_name"
                        name="company_name"
                        required=""
                      />
                    </div>
                    <div>
                      <label className="form-label font-medium">
                        Type of company :<span className="text-red-600">*</span>
                      </label>
                      <Select
                        id="company"
                        name="typeofcompany"
                        value={companySelected}
                        onChange={TypeofCompanyFunc}
                        options={companyList}
                        defaultValue={{
                          key: details?.seekers_details?.type_of_company,
                          label: details?.seekers_details?.type_of_company,
                        }}
                      />
                      {/* <input type="text" disabled={!edit} defaultValue={details?.seekers_details?.type_of_company || ""} className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="Enter" id="type_of_company" name="type_of_company" required="" /> */}
                    </div>
                    <div>
                      <label className="form-label font-medium">
                        CIN :<span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        disabled={!edit}
                        defaultValue={details?.seekers_details?.cn || ""}
                        className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                        placeholder="Enter"
                        id="cn"
                        name="cin"
                        required=""
                      />
                    </div>
                    <div>
                      <label className="form-label font-medium">
                        Date of CIN :<span className="text-red-600">*</span>
                      </label>
                      <input
                        type="date"
                        disabled={!edit}
                        defaultValue={details?.seekers_details?.cn_date || ""}
                        className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                        placeholder="Enter"
                        id="cn_date"
                        name="cin_date"
                        required=""
                      />
                    </div>
                    <div>
                      <label className="form-label font-medium">
                        PAN :<span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        disabled={!edit}
                        defaultValue={
                          details?.seekers_details?.pan_number || ""
                        }
                        className="form-input border border-slate-100 dark:border-slate-800 mt-2 mb-2"
                        placeholder="Enter"
                        id="pan_number"
                        name="pan_number"
                        required=""
                        maxLength="10"
                        value={panNumber}
                        onChange={handlePanChange}
                      />
                      {isValid !== null && (
                        <p style={{ color: inputPanColor }}>
                          {isValid
                            ? "PAN card number is valid."
                            : "PAN card number is not valid."}
                        </p>
                      )}
                    </div>
                    <div>
                      <label className="form-label font-medium">
                        GSTIN :<span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        disabled={!edit}
                        defaultValue={details?.seekers_details?.gstin || ""}
                        className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                        placeholder="Enter"
                        id="gstin"
                        name="gstin"
                        required=""
                      />
                    </div>
                    <div>
                      <label className="form-label font-medium">
                        Address :<span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        disabled={!edit}
                        defaultValue={details?.seekers_details?.address || ""}
                        className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                        placeholder="Enter"
                        id="address"
                        name="address"
                        required=""
                      />
                    </div>

                    <div>
                      <label className="form-label font-medium">
                        Country :<span className="text-red-600">*</span>
                      </label>

                      <Select
                        name="state"
                        value={country}
                        onChange={CountryChangeFunction}
                        options={CountryOption}
                        defaultValue={{
                          label: details?.seekers_details?.country,
                          value: details?.seekers_details?.country,
                        }}
                      />

                      {/* <input type="text" disabled={!edit} defaultValue={details?.seekers_details?.country || ""} className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="Enter" id="country" name="country" required="" /> */}
                    </div>
                    <div>
                      <label className="form-label font-medium">
                        State :<span className="text-red-600">*</span>
                      </label>

                      <Select
                        name="state"
                        value={state}
                        onChange={StateChangeFunction}
                        options={StateOption}
                        defaultValue={{
                          label: profileData?.company_details?.state,
                          value: profileData?.company_details?.state,
                        }}
                      />

                      {/* <input type="text" disabled={!edit} defaultValue={details?.seekers_details?.state || ""} className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="Enter" id="state" name="state" required="" /> */}
                    </div>
                    <div>
                      <label className="form-label font-medium">
                        City :<span className="text-red-600">*</span>
                      </label>

                      <Select
                        name="state"
                        value={city}
                        onChange={CityChangeFunction}
                        options={CityOption}
                        defaultValue={{
                          value: profileData?.company_details?.city,
                          label: profileData?.company_details?.city,
                        }}
                      />

                      {/* <input type="text" disabled={!edit} defaultValue={details?.seekers_details?.city || ""} className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="Enter" id="city" name="city" required="" /> */}
                    </div>
                    <div>
                      <label className="form-label font-medium">
                        ZIP :<span className="text-red-600">*</span>
                      </label>
                      <input
                        type="number"
                        disabled={!edit}
                        defaultValue={details?.seekers_details?.pincode || ""}
                        className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                        placeholder="Enter"
                        id="pincode"
                        name="pincode"
                        required=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-10">
                <div className="text-lg font-semibold ">Contact Details :</div>
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  <div>
                    <label className="form-label font-medium">
                      First Name :<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      disabled={!edit}
                      defaultValue={details?.first_name}
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="Enter"
                      id="first_name"
                      name="first_name"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Last Name :<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      disabled={!edit}
                      defaultValue={details?.last_name}
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="Enter"
                      id="last_name"
                      name="last_name"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Email Id :<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      disabled={!edit}
                      readOnly
                      defaultValue={details?.email}
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="Enter"
                      id="firstname"
                      name="name"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Phone Number :<span className="text-red-600">*</span>
                    </label>
                    {/* <input
                      type="number"
                      disabled={!edit}
                      readOnly
                      defaultValue={details?.mobile}
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="Enter"
                      id="firstname"
                      name="name"
                      required=""
                    />
                    <br /> */}
                    <PhoneInput
                      country={"in"}
                      value={phone}
                      masks={{in: '............', at: '(....) ...-....'}}
                      onChange={(newPhone) => setPhone(newPhone)}
                      flagSize="medium"
                      disabled={!edit}
                      readOnly
                      defaultValue={details?.mobile}
                      className="mt-2"
                      type="number"
                    />
                  </div>
                </div>
                {edit && (
                  <div className="mt-5">
                    <input
                      type="submit"
                      id="submit"
                      className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md cursor-pointer"
                      value="Save Changes"
                    />
                  </div>
                )}
              </div>
              {/* </div> */}
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
