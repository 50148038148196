import React, { useState } from 'react';
import './BigImages.css'

const FullImageDisplay = ({ imageUrl, onClose }) => {
  return (
    <div className="full-image-display" onClick={onClose}>
      <div className="overlay"></div>
      <img src={imageUrl} alt="Full Size" className="full-image" />
    </div>
  );
};

const BigImages = () => {
  const [isFullImageOpen, setIsFullImageOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [imageList, setImageList] = useState([
    'https://via.placeholder.com/650',
    'https://via.placeholder.com/750',
    'https://via.placeholder.com/1050',
    // Add more image URLs as needed
  ]);

  const openFullImage = (index) => {
    setSelectedImageIndex(index);
    setIsFullImageOpen(true);
  };

  const closeFullImage = () => {
    setIsFullImageOpen(false);
    setSelectedImageIndex(null);
  };

  const showPreviousImage = () => {
    if (selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };

  const showNextImage = () => {
    if (selectedImageIndex < imageList.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };




  return (
    <div>
      {/* <h1>Image Gallery</h1> */}
      <div className="image-container">
        {imageList.map((imageUrl, index) => (
          <img
            key={index}
            src={imageUrl}
            alt={`Image ${index + 1}`}
            onClick={() => openFullImage(index)}
            className="thumbnail"
            crossOrigin="anonymous"
          />
        ))}
      </div>

      {isFullImageOpen && (
        <FullImageDisplay imageUrl={imageList[selectedImageIndex]} onClose={closeFullImage} />
      )}

      {isFullImageOpen && selectedImageIndex > 0 && (
        <button className='buttonImage' onClick={showPreviousImage}>Previous</button>
      )}

      {isFullImageOpen && selectedImageIndex < imageList.length - 1 && (
        <button className='buttonImage' onClick={showNextImage}>Next</button>
      )}
    </div>
  );
};

export default BigImages;
