import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import { Bookmark } from "react-feather";
import { CandidateList, jobData } from "../data/data";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { PieChart } from '@mui/x-charts/PieChart';
import ReactApexChart from "react-apexcharts";
import {
    Settings,
    FileText,
    Gift,
    Globe,
    Home,
    Mail,
    MapPin,
    Phone,
    Server
   
  } from "react-feather";

import {
    LuMail,
    BiLogoDribbble,
    AiOutlineBehance,
    BiLogoLinkedin,
    FaFacebookF,
    IoLogoTwitter,
    FaInstagram,
    FiFileText, BsTelephone,
    PiMapPin
  } from "../assets/icons/vander";
import Form from "../components/Form";
import { Grid } from "@mui/material";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from "react-redux";
import { allJobsList, GeeksApplyJobPendingList } from "../Redux/geeks/jobSlice";
import axios from "axios";
import "./dashboard.css";
import { Routes, useParams } from 'react-router-dom/dist';
import bg5 from "../assets/images/hero/bg5.jpg";


export default function Dashboard() {
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
  
    const [hireddetails, setHiredDetails] = useState("");
    console.log(hireddetails, "hireddetails");
  
    const { pendingJobList } = useSelector((state) => state.alljobs);
  
    const { currentUser } = useSelector((state) => state.auth);
  
    console.log(currentUser, "currentUser");
  
    console.log(pendingJobList, "pendingJobList");
  
    useEffect(() => {
      dispatch(GeeksApplyJobPendingList(page, 10, "", currentUser?.token));
    }, []);
  
    // Function to calculate how many hours or days ago a date came
    function calculateTimeAgo(targetDate) {
      // Get the current date and time
      const currentDate = new Date();
  
      // Convert the target date string to a Date object
      const targetDateObj = new Date(targetDate);
  
      // Calculate the time difference in milliseconds
      const timeDifference = currentDate - targetDateObj;
  
      if (timeDifference < 0) {
        // The target date is in the future
        const daysAgo = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        return `${daysAgo} days in the future`;
      } else if (currentDate.toDateString() === targetDateObj.toDateString()) {
        // The target date is today
        const hoursAgo = Math.floor(timeDifference / (1000 * 60 * 60));
        return `${hoursAgo} hours ago`;
      } else if (timeDifference < 24 * 60 * 60 * 1000) {
        // The target date is exactly one day ago
        return "1 day ago";
      } else {
        // The target date is in the past, more than one day ago
        const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        return `${daysAgo} days ago`;
      }
    }
  
    const jobApply = (jobId) => {
      const payload = {
        job_uuid: jobId,
      };
      axios
        .post(`${process.env.REACT_APP_API_BASEURL}/job/apply`, payload, {
          headers: {
            "x-auth-token": currentUser?.token,
          },
        })
        .then((res) => {
          console.log(res,"hhhjk")
          toast.success("Job Applied !", {
            position: "top-right",
          })
          dispatch(GeeksApplyJobPendingList(page, 10, "", currentUser?.token));
          HiredListFunction();
        })
        .catch((err) => {
          console.log("err", err);
        });
    };
  
    const checkapplyornot = (array) => {
      const data = array?.includes(currentUser?.data?.uuid);
      console.log(data, "datadata");
      return data;
    };
  
    const HiredListFunction = () => {
      axios
        .get(
          `${process.env.REACT_APP_API_BASEURL}/job/hiredListGeeks?pagenum=0&limit=10&search=`,
          {
            headers: { "x-auth-token": currentUser?.token },
          }
        )
        .then((res) => {
          console.log(res, "ghdfgjzshdhshj");
          setHiredDetails(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
    useEffect(() => {
      HiredListFunction();
    }, []);
  
    const staticPart = "${process.env.REACT_APP_IMAGE_BASEURL}/";
    // const profilepart = hireddetails?.image[0];
  
    const Image = staticPart;
  
    function truncate(source, size) {
      return source.length > size ? source.slice(0, size - 1) + "…" : source;
    }
  
  

          //   dashboard    integration 
          const [barchart1, setBarchart1] = useState([]);
          const [chartKey,setChartKey] = useState([]);
          const [chartValue, setChartValue] = useState([]);
          console.log(barchart1, "barchart1")
          const [dashboard, setDashboard] = useState("");
   
  
          const GetProfileDashboard = () => {
            axios
              .get(
                `${process.env.REACT_APP_API_BASEURL}/job/geeksDashboard`,
                {
                  headers: {
                    "x-auth-token": currentUser?.token,
                  },
                }
              )
              .then((res) => {
                console.log(res?.data, "qweqwewqe");
                if(res?.data?.data){
                  setBarchart1([])
                  let data = [];
                  let key = Object.keys(res?.data?.data);
                  setChartKey(key);
                  let value = Object.values(res?.data?.data);
                  setChartValue(value);
                  console.log("Key:",key)
                  key.forEach((key,index)=>{
                    data?.push({
                       label : key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),
                       value : value[index]
                    })
                  })
                  setBarchart1(data)
                  
                }
                setDashboard(res?.data);
              })
              .catch((err) => {
                console.log(err?.data , "asdasasd");
              });
          };
        
          useEffect(() => {
            GetProfileDashboard();
          }, []);



          const state ={
            series: [
              {
                name: "Jobs Detail",
                data: chartValue
              },
            
            ],
          };
        
          const options = {
            colors: ["#3C50E0", "#80CAEE"],
            chart: {
              fontFamily: "Satoshi, sans-serif",
              type: "bar",
              height: 335,
              stacked: true,
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
              },
            },
            responsive: [
              {
                breakpoint: 1536,
                options: {
                  plotOptions: {
                    bar: {
                      borderRadius: 0,
                      columnWidth: "25%",
                    },
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 0,
                columnWidth: "25%",
                borderRadiusApplication: "end",
                borderRadiusWhenStacked: "last",
              },
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
                categories: chartKey
              },
            legend: {
              position: "top",
              horizontalAlign: "left",
              fontFamily: "Satoshi",
              fontWeight: 500,
              fontSize: "14px",
              markers: {
                radius: 99,
              },
            },
            fill: {
              opacity: 1,
            },
          };
        
          const [chartData, setChartData] = useState({
            series: [1, 3, 5, 7, 2],
            options: {
              chart: {
                type: "donut",
              },
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 250,
                    },
                    legend: {
                      position: "bottom",
                    },
                  },
                },
              ],
            },
          });


        //  Geeks Corporate Profile 
        const params = useParams();
        const id = params.id;
        const data = CandidateList.find((jobs) => jobs.id === parseInt(id));
      
        // const { currentUser } = useSelector((state) => state.auth);
        // const [barchart1, setBarchart1] = useState([]);
        // const [chartKey,setChartKey] = useState([]);
        // const [chartValue, setChartValue] = useState([]);
        console.log(barchart1, "barchart1")
        const [details, setDetails] = useState("");
       
      
        const GetProfileDetails = () => {
          axios
            .get(
              `${process.env.REACT_APP_API_BASEURL}/user/geeks/profile/${currentUser?.data?.uuid}`,
              {
                headers: {
                  "x-auth-token": currentUser?.token,
                },
              }
            )
            .then((res) => {
              console.log(res.data?.data, "hdsfcgshgfjs");
              setDetails(res.data?.data);
            })
            .catch((err) => {
              console.log(err);
            });
        };
      
        useEffect(() => {
          GetProfileDetails();
        }, []);
      
      
      
      
        const staticPart1 = "${process.env.REACT_APP_IMAGE_BASEURL}/";
        const profilepart = details?.geeks_details?.personal_details?.profile_pic;
        const coverpart = details?.geeks_details?.personal_details?.cover_pic;
      
        const profile = staticPart1 + profilepart;
        const cover = staticPart + coverpart;
      
        function truncate(source, size) {
          return source?.length > size ? source.slice(0, size - 1) + "…" : source;
        }


        const [selectedTab, setSelectedTab] = useState('dashboard');
        // const [selectedTab1, setSelectedTab1] = useState('myprofile');

        useEffect(() => {
          // Update the hash in the URL based on the selected tab
          window.location.hash = selectedTab;
        }, [selectedTab]);

        const handleTabClick = (tabName) => {
            setSelectedTab(tabName);
          };



        // useEffect(() => {
            
        //     window.location.hash = selectedTab1;
        //   }, [selectedTab1]);
      
        // const handleTabClick1 = (tabName1) => {
        //   setSelectedTab1(tabName1);
        // };
       

     
    return (
        <>
            <Navbar navClass='justify-end nav-light'/>
            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Dashboard</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Home</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Dashboard</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>



            <section className="relative md:py-24 py-16 dashboard">
            {/* <div id="TabPanel">
        <button onClick={() => handleTabClick('dashboard')}>Dashboard</button>
        <button onClick={() => handleTabClick('myprofile')}>My Profile</button>
      
      </div> */}
                    <Tabs
      aria-label="Vertical tabs"
      orientation="vertical"
  
    >
      <TabList>
      <div id="TabPanel">
        <Tab>
        <button className='tabButton' onClick={() => handleTabClick('dashboard')}>Dashboard</button>
        </Tab>
        <Tab>
        <button className='tabButton' onClick={() => handleTabClick('myprofile')}>My Profile</button>
        </Tab>
        <Tab>
        <button className='tabButton' onClick={() => handleTabClick('myjobs')}>My Jobs</button>
          </Tab>
        </div>
       
      </TabList>
      <TabPanel value={0} id="TabPanel" onClick={() => handleTabClick('dashboard')}>
        
      <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px] mb-5">
                        <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    {/* <BsTelephone className='text-slate-icon-400'/> */}
                                    <div className='text-slate-icon-400'>
                                    {dashboard?.data?.job_applied}
                                        </div>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Jobs Applied</h5>

                               
                            </div>
                        </div>


                        <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    {/* <LuMail className='text-slate-icon-400'/> */}
                                    <div className='text-slate-icon-400'> {dashboard?.data?.job_inprogress}</div> 
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Jobs Inprogress</h5>
                            </div>
                        </div>

                        <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                            
                                    <div className='text-slate-icon-400'>{dashboard?.data?.job_completed}</div>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Jobs Completed</h5>
                            </div>
                        </div>

                       
                    </div>
    
          <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px] border p-6">
            <div className="grid-cols-12 lg:col-span-6 md:col-span-12 xs:col-span-12">



            <div className="col-span-12 rounded-sm border border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-4 p-6">
        <div className="mb-4 justify-between gap-4 sm:flex">
          <div>
            <h4 className="text-xl font-semibold text-black dark:text-white">
            Jobs Detail
            </h4>
          </div>
          <div>
          </div>
        </div>

        <div>
          <div id="chartTwo" className="-ml-5 -mb-9">
            <ReactApexChart
              options={options}
              series={state.series}
              type="bar"
              height={350}
            />
          </div>
        </div>
      </div>
     
            </div>




            
            <div className="grid-cols-12 lg:col-span-6 md:col-span-12 xs:col-span-12" style={{ alignSelf:"center" }}>
            <PieChart
      series={[
        {
          data: barchart1?.map((item) => ({
            
            label: item?.label,
            value: item?.value,
          })),
          
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
        },
      ]}
      height={280}
    />

</div>
          </div>
       
      </TabPanel>
      <TabPanel value={1} id="TabPanel" onClick={() => handleTabClick('myprofile')}>
   
          <div className="relative shrink-0">
            <img
              src={bg5}
              className="h-64 w-full object-cover lg:rounded-xl shadow dark:shadow-gray-700"
              id="profile-banner"
              alt=""
            />
            <label
              className="absolute inset-0 cursor-pointer"
              htmlFor="pro-banner"
            ></label>
          </div>
      

          <div className="md:flex ms-4 -mt-12">
            <div className="md:w-full">
              <div className="relative flex items-end justify-between">
                <div className="relative flex items-end">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_BASEURL}/${details?.geeks_details?.about?.company_profile}`}
                    alt="profile"
                    crossOrigin="anonymous"
                    className="h-28 w-28 rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800"
                  />
                  <div className="ms-4">
                    <h5 className="text-lg font-semibold">
                      {details?.first_name + " " + details?.last_name}
                    </h5>
                    <p className="text-slate-400">
                      {details?.geeks_details?.company_details?.city},
                      {details?.geeks_details?.company_details?.country}
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom:"15px",
                    marginTop:"10px"
                  }}
                >
                  {/* <div>
                    <input
                      type="submit"
                      id="submit"
                      name="send"
                      className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md cursor-pointer"
                      value="Hire Now"
                    />
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                  <div>
                    <Link
                      to="/geeks/profile/corporate/edit"
                      className="btn btn-icon rounded-full bg-emerald-600/5  border-emerald-600/10 text-white flex justify-center items-center"
                    >
                      <Settings className="h-4 w-4" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
    

    
          <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
            <div className="lg:col-span-8 md:col-span-7">
              <h5 className="text-lg font-semibold">
                {truncate(
                  details?.geeks_details?.about?.description_about_company_offering?.replace(
                    /(<([^>]+)>)/gi,
                    ""
                  ),
                  400
                )}

             
              </h5>
              <p className="text-slate-400 mt-4">
                {
                  details?.geeks_details?.technical_expertise
                    ?.certification_awards_recognition
                }
              </p>

              <h4 className="mt-6 text-xl font-semibold">Skills</h4>
              <Grid item container className="mt-5 gap-2">
                <Grid xs={12} md={12}>
                  {details?.geeks_details?.technical_expertise?.skills?.map(
                    (skills) => {
                      return (
                        <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full me-2">
                          {skills} &nbsp;&nbsp;
                        </div>
                      );
                    }
                  )}
                
                </Grid>
                
              </Grid>

              <h4 className="mt-6 text-xl font-semibold">
                Certified Skills
              </h4>
              <Grid item container className="mt-5">
                <Grid xs={12} md={12}>
                  {details?.geeks_details?.technical_expertise?.certified_skills?.map(
                    (certified_skills) => {
                      return (
                        <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full mt-2 me-2">
                          {certified_skills}&nbsp;&nbsp;
                        </div>
                      );
                    }
                  )}

                </Grid>
              </Grid>

              <h4 className="mt-6 text-xl font-semibold">Languages</h4>
              <Grid item container className="mt-5">
                <Grid xs={12} md={12}>
                  {details?.geeks_details?.technical_expertise?.language?.map(
                    (language) => {
                      return (
                        <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full mt-2 me-2">
                          {language}&nbsp;&nbsp;
                        </div>
                      );
                    }
                  )}
                </Grid>
              </Grid>

              <div className="rounded-md shadow dark:shadow-gray-700 p-6 mt-6">
                <h5 className="text-xl font-semibold text-center mb-3">
                  Hire Me
                </h5>

                <form className="ContainerForm">
                  <Form />
                </form>
              </div>
            </div>

            <div className="lg:col-span-4 md:col-span-5">
              <div className="bg-slate-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-700 p-6 sticky top-20">
                <h5 className="text-lg font-semibold">Company Detail:</h5>
                <ul className="list-none mt-4">
                  <li className="flex justify-between mt-3 items-center font-medium">
                    <span>
                      <Mail className="h-4 w-4 text-slate-400 me-3 inline"></Mail>
                      <span className="text-slate-400 me-3">Company Name:</span>
                    </span>

                    <span>
                      {details?.geeks_details?.company_details?.company_name}
                    </span>
                  </li>
                  <li className="flex justify-between mt-3 items-center font-medium">
                    <span>
                      <Mail className="h-4 w-4 text-slate-400 me-3 inline"></Mail>
                      <span className="text-slate-400 me-3">
                        Type of Company:
                      </span>
                    </span>

                    <span>
                      {details?.geeks_details?.company_details?.type_of_company}
                    </span>
                  </li>
                  <li className="flex justify-between mt-3 items-center font-medium">
                    <span>
                      <Phone className="h-4 w-4 text-slate-400 me-3 inline"></Phone>
                      <span className="text-slate-400 me-3">CIN :</span>
                    </span>

                    <span>3411231231</span>
                  </li>
                  <li className="flex justify-between mt-3 items-center font-medium">
                    <span>
                      <Phone className="h-4 w-4 text-slate-400 me-3 inline"></Phone>
                      <span className="text-slate-400 me-3">
                        Date of Incorporation :
                      </span>
                    </span>

                    <span>
                      {
                        details?.geeks_details?.company_details
                          ?.date_of_incorporation
                      }
                    </span>
                  </li>
                  <li className="flex justify-between mt-3 items-center font-medium">
                    <span>
                      <Gift className="h-4 w-4 text-slate-400 me-3 inline"></Gift>
                      <span className="text-slate-400 me-3">PAN:</span>
                    </span>

                    <span>DFV2312313</span>
                  </li>
                  <li className="flex justify-between mt-3 items-center font-medium">
                    <span>
                      <Home className="h-4 w-4 text-slate-400 me-3 inline"></Home>
                      <span className="text-slate-400 me-3">GSTIN :</span>
                    </span>

                    <span>
                      {details?.geeks_details?.company_details?.gstin}
                    </span>
                  </li>
                  <li className="flex justify-between mt-3 items-center font-medium">
                    <span>
                      <MapPin className="h-4 w-4 text-slate-400 me-3 inline"></MapPin>
                      <span className="text-slate-400 me-3">Website:</span>
                    </span>

                    <span>
                      {
                        details?.geeks_details?.company_details
                          ?.company_website_url
                      }
                    </span>
                  </li>
                  <li className="flex justify-between mt-3 items-center font-medium">
                    <span>
                      <Globe className="h-4 w-4 text-slate-400 me-3 inline"></Globe>
                      <span className="text-slate-400 me-3">
                        Contract Number:
                      </span>
                    </span>

                    <span>{details?.mobile}</span>
                  </li>
                  <li className="flex justify-between mt-3 items-center font-medium">
                    <span>
                      <Server className="h-4 w-4 text-slate-400 me-3 inline"></Server>
                      <span className="text-slate-400 me-3">
                        Contact Email:
                      </span>
                    </span>

                    <span>{details?.email}</span>
                  </li>
                  <li className="flex justify-between mt-3 items-center font-medium">
                    <span>
                      <Server className="h-4 w-4 text-slate-400 me-3 inline"></Server>
                      <span className="text-slate-400 me-3">Address:</span>
                    </span>

                    <span>
                      {details?.geeks_details?.company_details?.address_line1},
                      {details?.geeks_details?.company_details?.city}
                    </span>
                  </li>

                  <li className="mt-3 w-full bg-white dark:bg-slate-900 p-3 rounded-md shadow dark:shadow-gray-700">
                    <Link
                      to="assets/images/calvin-carlo-resume.pdf"
                      className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-md w-full flex items-center justify-center"
                      download
                    >
                      <FiFileText className="me-2" /> Download CV
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
     
      </TabPanel>
      <TabPanel value={2}>
     
          <div
            style={{
              border: "1px solid #68712e",
              padding: "20px",
              borderRadius: "10px",
              marginBottom:"20px"
            }}
          >
            <Tabs aria-label="Basic tabs" defaultValue={0}>
              <div className="grid lg:grid-cols-10 md:grid-cols-5 mt-12 gap-[30px]">
                <TabList>
                  <Tab>Pending</Tab>
                  <Tab>Hired</Tab>
                </TabList>
              </div>

              <TabPanel value={0}>
                <div>
                  <div className="grid lg:grid-cols-10 md:grid-cols-5 mt-12 gap-[30px]">
                    {pendingJobList?.length > 0 &&
                      pendingJobList?.map((item, index) => (
                        <div
                          className="group p-6 rounded-lg border border-emerald-600/20 dark:border-emerald-600/40 bg-white dark:bg-slate-900 hover:bg-emerald-600/[0.02] hover:dark:bg-emerald-600/5 hover:shadow-md hover:shadow-emerald-600/5 transition-all duration-500"
                          key={index}
                        >
                          <div className="flex justify-between items-start">
                            <div>
                              
                            </div>

                            <div className="flex items-center">
                              <input
                                type="button"
                                className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white searchbtn submit-btn w-100 cursor-pointer"
                                disabled={checkapplyornot(
                                  item?.applied_user_uuid
                                )}
                                value={
                                  checkapplyornot(item?.applied_user_uuid) ===
                                  true
                                    ? "Applied"
                                    : "Apply"
                                }
                                onClick={() => jobApply(item.uuid)}
                              />
                              
                            </div>
                          </div>

                          <div className="mt-1">
                          
                            <Link
                              to={`/JobListProfile/${item.uuid}`}
                              className="text-xl hover:text-emerald-600 font-semibold transition-all duration-500"
                            >
                              {item.title}
                            </Link>
                            <div className="mt-5">
                              <small class="text-muted display-inline-block text-muted">
                                <span>
                                  Posted &nbsp;
                                  <span data-test="posted-on">
                                    {calculateTimeAgo(item.createdAt)}
                                  </span>
                                </span>
                              </small>
                            </div>
                            <p className="text-slate-400 mt-2">
                              {truncate(
                                item.description?.replace(/(<([^>]+)>)/gi, ""),
                                400
                              )}
                            </p>
                            <div className="mt-3">
                              {item?.skills?.map((item) => {
                                return (
                                  <span className="bg-orange-500/5 hover:bg-orange-500/20 dark:bg-orange-500/10 hover:dark:bg-orange-500/30 inline-block text-orange-500 px-4 text-[14px] font-medium rounded-full mt-2 me-1 transition-all duration-500">
                                    {item?.value ? item?.value : item}
                                  </span>
                                );
                              })}
             
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div>
                    <a
                      id="back-to-top"
                      class="back-to-top fixed text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 text-center bg-emerald-600 text-white justify-center items-center flex"
                      href="#"
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 1024 1024"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={1}>
                <div className="grid lg:grid-cols-10 md:grid-cols-5 mt-12 gap-[30px]">
                  {hireddetails?.data?.length > 0 &&
                    hireddetails?.data?.map((item, index) => (
                      <div
                        className="group p-6 rounded-lg border border-emerald-600/20 dark:border-emerald-600/40 bg-white dark:bg-slate-900 hover:bg-emerald-600/[0.02] hover:dark:bg-emerald-600/5 hover:shadow-md hover:shadow-emerald-600/5 transition-all duration-500"
                        key={index}
                      >
                        <div className="flex justify-between items-start">
                          <div>
                           
                             <Link
                            to={`/ViewAppliedJobs/${item?.uuid}`}
                            className="text-xl hover:text-emerald-600 font-semibold transition-all duration-500"
                          >
                            {item.title}
                          </Link>
                          </div>
                          <div className="flex items-center">
                            <Link to={`/ViewAppliedJobs/${item?.uuid}`}>
                              <input
                                type="button"
                                className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white searchbtn submit-btn w-100 cursor-pointer"
                                value={"View"}
                              />
                            </Link>
                          </div>
                        </div>

                        <div className="mt-1">
                       
                         
                          <div className="mt-5">
                            <small class="text-muted display-inline-block text-muted">
                              <span>
                                Posted &nbsp;
                                <span data-test="posted-on">
                                  {calculateTimeAgo(item?.createdAt)}
                                </span>
                              </span>
                            </small>
                          </div>
                          <p className="text-slate-400 mt-2">
                            {truncate(
                              item?.description?.replace(/(<([^>]+)>)/gi, ""),
                              400
                            )}
                          </p>
                          <div className="mt-3">
                            {item?.skills?.map((items) => {
                              console.log(items, "services")
                              return (
                                <span className="bg-orange-500/5 hover:bg-orange-500/20 dark:bg-orange-500/10 hover:dark:bg-orange-500/30 inline-block text-orange-500 px-4 text-[14px] font-medium rounded-full mt-2 me-1 transition-all duration-500">
                                  {items?.label ? items?.label : items}
                                </span>
                              );
                            })}
                          </div>
                          <div className="mt-3">
                            {item?.services?.map((services) => {
                              console.log(services, "services")
                              return (
                                <span className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full mt-2 me-1">
                                  {services?.name ? services?.name : services}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

              
                <div>
                  <a
                    id="back-to-top"
                    class="back-to-top fixed text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 text-center bg-emerald-600 text-white justify-center items-center flex"
                    href="#"
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 1024 1024"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
                    </svg>
                  </a>
                </div>
              </TabPanel>
            </Tabs>
         
      </div>
      </TabPanel>
    </Tabs>
              
           

             
            </section>
          


          
     



      




     
                

            

            <Footer style={{ bottom :"0px" }}/>
        </>
    )
}
