import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import Navbar from "../../components/Navbar";
import bg5 from "../../assets/images/hero/bg5.jpg";
import team1 from "../../assets/images/team/01.jpg";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import pencil from "../../assets/images/pencil.png";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Twitter, Facebook, Instagram, Linkedin, Youtube } from "react-feather";
import "./CandidateProfile.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { servicesURL } from "../../Redux/services/servicesSlice";
import { Grid } from "@mui/material";

export default function CorporateProfileSetting() {
  const params = useParams();
  const id = params.id;
  const [data, setData] = useState("");

  // const TextEditor = (editor) => {
  //     const data = editor.getData();
  //     setData(data)
  // }

  const { currentUser } = useSelector((state) => state.auth);

  const [compProfile, setCompProfile] = useState("");


  const ImageUpload = (event, type) => {
    const data1 = event.target.files[0];
    const formData = new FormData();
    formData.append("file", data1);
    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/upload/image`, formData)
      .then((res) => {
        if (type === "compProfile") {
          setCompProfile(res.data.image.filename);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const [details, setDetails] = useState("");
  console.log(details, "dsgjcjhdschjs");

  const GetProfileDetails = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASEURL}/user/geeks/profile/${currentUser?.data?.uuid}`,
        {
          headers: {
            "x-auth-token": currentUser?.token,
          },
        }
      )
      .then((res) => {
        console.log(res.data?.data, "hdsfcgshgfjs");
        setDetails(res.data?.data);
        setCompProfile(res?.data?.data?.geeks_details?.about?.company_profile);
        setData(
          res?.data?.data?.geeks_details?.about
            ?.description_about_company_offering
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetProfileDetails();
  }, []);

  // const [country, setCountry] = useState([{ country: '', reading: false, writing: false, listening: false }]);

  // const addCountry = () => {
  //     setCountry([...country, { language: '', reading: false, writing: false, listening: false }]);
  // };

  // const removeCountry = (index) => {
  //     const updatedLanguages = [...country];
  //     updatedLanguages.splice(index, 1);
  //     setCountry(updatedLanguages);
  // };

  // const handleCountryChange = (index, selectedLanguage) => {
  //     const updatedLanguages = [...country];
  //     updatedLanguages[index].language = selectedLanguage;
  //     setCountry(updatedLanguages);
  // };

  // const handleCountryChange = (index, type) => {
  //     const updatedLanguages = [...country];
  //     updatedLanguages[index][type] = !updatedLanguages[index][type];
  //     setCountry(updatedLanguages);
  // };

  

  const UpdateProfileCorporate = (e, Steptype) => {
    e.preventDefault();
    var payload = {};
    const dat = e.target.elements;
    console.log(dat, "dgjshfhgsjkhs");

    if (Steptype === "company_details") {
      payload = {
        company_name: dat?.company_name?.value,
        type_of_company: dat?.type_of_company?.value,
        company_incorporation_number: dat?.company_incorporation_number?.value,
        date_of_incorporation: dat?.date_of_incorporation?.value,
        pan_number: dat?.pan_number?.value,
        gstin: dat?.gstin?.value,
        company_website_url: dat?.company_website_url?.value,
        address_line1: dat?.address_line1?.value,
        address_line2: dat?.address_line2?.value,
        // country: dat?.country?.value,
        // city: dat?.city?.value,
        // state: dat?.state?.value,
        pincode: dat?.pincode?.value,
        city: city?.label,
        state: state?.label,
        country: country?.label,
      };
    } else if (Steptype === "technical_expertise") {
      const skilssData =
        details?.geeks_details?.technical_expertise?.skills?.length > 0
          ? details?.geeks_details?.technical_expertise?.skills?.map((item) => {
              return item?.value ? item?.value : item;
            })
          : [];

      const languageData =
        details?.geeks_details?.technical_expertise?.language?.length > 0
          ? details?.geeks_details?.technical_expertise?.language?.map(
              (item) => {
                return item?.value ? item?.value : item;
              }
            )
          : [];

      const certifiedskilssData =
        details?.geeks_details?.technical_expertise?.certified_skills?.length >
        0
          ? details?.geeks_details?.technical_expertise?.certified_skills?.map(
              (item) => {
                return item?.value ? item?.value : item;
              }
            )
          : [];

      //   const servicesSkillData = servicesSkil?.length > 0 ? servicesSkil?.map((item) => {
      //       return item?.value ? item?.value : item
      //   }) : []

      payload = {
        skills: skilssData,
        level_of_specialization: dat?.level_of_specialization?.value,
        // years_of_experience: dat?.experience?.value,
        certification_awards_recognition:
          dat?.certification_awards_recognition?.value,
        language: languageData,
        certified_skills: certifiedskilssData,
        // servicesSkillData: servicesSkil
      };
    } else if (Steptype === "about") {
      console.log(details?.geeks_details?.about?.primary_contact_number , "sasdasdas");
      payload = {
        primary_contact_name: dat?.primary_contact_name?.value,
        primary_contact_number: dat?.primary_contact_number?.value.replace(
          /[+\-\s]/g,
          ""
        ),
        
        primary_contact_email_id: dat?.primary_contact_email_id?.value,
        company_profile: compProfile,
        // description_about_company_offering: dat?.offerings?.value
        description_about_company_offering:
          dat?.description_about_company_offering?.value,
      };
    }

    // else if (activeStep >= 1) {
    const originalPayload = {
      geeks_type:
        details?.geeks_details?.geeks_type === "individual"
          ? "individual"
          : "corporate",
      type: Steptype,
      data: payload,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_BASEURL}/user/geeks/profile/update/${currentUser?.data?.uuid}`,
        originalPayload,
        {
          headers: {
            "x-auth-token": currentUser?.token,
          },
        }
      )
      .then((res) => {
        console.log(res, "dghsfdsjhkjshakj");
        toast.success(res?.data?.message, {
          position: "top-right",
        });
      })
      .catch((err) => {
        toast.error(err?.response?.message, {
          position: "top-right",
        });
        console.log(err.response, "yhikhh");
      });
    // }
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 50,
    height: 50,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  const [phone, setPhone] = useState("");
  const [special, setSpecial] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  const [step1Data, setStep1Data] = useState({});
  const [step2Data, setStep2Data] = useState({});
  const [step3Data, setStep3Data] = useState({});
  const [step4Data, setStep4Data] = useState({});
  const [step5Data, setStep5Data] = useState({});

  const [languagestep3, setLangStep3] = useState("NY");
  const [servicestep4, setServicestep4] = useState("1");
  const [check, setCheck] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const [profileData, setProfileData] = useState("");
  console.log(profileData, "dsjvjssdjvg");
  // individual
  const [skills, setSkill] = useState("");
  const [level_of_specialization, setlevel_of_specialization] = useState("");
  const [bio123, setBio] = useState("");
  const [certifiedskills, setCertifiedSkill] = useState("");
  const [servicesSkil, setServicesSkill] = useState("");
  const [language, setLanguagesIndividual] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [coverimage, setcoverImage] = useState("");
  const [cv, setcv] = useState("");
  const [state, setState] = useState({
    label: profileData?.geeks_details?.company_details?.state,
    value: profileData?.geeks_details?.company_details?.state,
  });
  const [country, setCountry] = useState({
    label: profileData?.geeks_details?.company_details?.country,
    value: profileData?.geeks_details?.company_details?.country,
  });
  console.log(country, "dasasdasda");
  const [city, setCity] = useState({
    label: profileData?.geeks_details?.company_details?.city,
    value: profileData?.geeks_details?.company_details?.city,
  });

  // corporate
  // const [compProfile, setCompProfile] = useState('');

  const optionsLanguages = [
    { value: "ENGLISH", label: "ENGLISH" },
    { value: "TELUGU", label: " TELUGU" },
    { value: "HINDI", label: "HINDI" },
  ];

  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [limit, setlimit] = useState(0);
  // const [search, setsearch] = useState(0);
  const [search, setSearch] = useState("");
  const { servicesDropDown, services } = useSelector((state) => state.service);

  const changeScroll = (data, sarch) => {
    console.log(sarch, "data...");
    if (data === "search") {
      dispatch(servicesURL(page, 10, sarch));
    } else if (data === "inc" && page >= 0) {
      dispatch(servicesURL(page + 1, 10, search));
      setPage(page + 1);
    } else if (page >= 1) {
      dispatch(servicesURL(page - 1, 10, search));
      setPage(page - 1);
    }
  };

  console.log(services, servicesDropDown, "services");

  useEffect(() => {
    dispatch(servicesURL(page, 10, search));
    getProfileDetailes();
    getCompanyList();
    getSkillsList();
    // getstateList();
    // getcityList();
    getcountryList();
  }, [activeStep]);

  // step 0
  const [type, setType] = useState(false);

  const changeGeek = (data) => {
    setType(data);
  };

  const [data1, setData1] = useState("");
  const [ckeditor, setCkeditor] = useState("");

  const TextEditor = (editor) => {
    const ckeditor = editor?.getData();
    setData(ckeditor);
  };
  // const TextEditor1 = (editor) => {
  //     const data = editor?.getData();
  //     setData1(data)
  // }

  // if (editor) {
  //     // Access properties or methods on the editor
  // } else {
  //     console.error('CKEditor instance is null or undefined.');
  // }

  useEffect(() => {
    setServicesSkill(profileData?.geeks_details?.technical_expertise?.services);
    setCertifiedSkill(profileData?.geeks_details?.technical_expertise?.skills);
    setlevel_of_specialization(
      profileData?.geeks_details?.technical_expertise?.level_of_specialization
    );
    setLanguagesIndividual(
      profileData?.geeks_details?.technical_expertise?.language
    );
    setSkill(profileData?.geeks_details?.technical_expertise?.skills);
    setData(profileData?.geeks_details?.about?.bio);
    setData1(
      profileData?.geeks_details?.about?.description_about_company_offering
    );
    setState({
      label: profileData?.geeks_details?.company_details?.state,
      value: profileData?.geeks_details?.company_details?.state,
    });
    setCity({
      label: profileData?.geeks_details?.company_details?.city,
      value: profileData?.geeks_details?.company_details?.city,
    });
    console.log(profileData?.geeks_details?.company_details?.city,"fgdsgadagdsafga")
    setCountry({
      label: profileData?.geeks_details?.company_details?.country,
      value: profileData?.geeks_details?.company_details?.country,
    });

    // setProfileImage()
    // setcoverImage()
    // setcv()
    // setCompProfile()
  }, [profileData]);

  useEffect(() => {
    setType(
      profileData?.geeks_details?.geeks_type === "corporate" ? true : false
    );
  }, []);

  const getProfileDetailes = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASEURL}/user/geeks/profile/${currentUser?.data?.uuid}`,
        {
          headers: {
            "x-auth-token": currentUser?.token,
          },
        }
      )
      .then((res) => {
        console.log(res.data?.data, "dsjvjssdjvg");
        setProfileData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const [companyList, setCompanyList] = useState([]);
  const [companySelected, setCompanySelected] = useState("");
  const [optionsOne, setSkillsList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  console.log(countryList, "sdasdadasda");

  console.log(companySelected, "companySelected");
  const getCompanyList = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/addondata/companyType`)
      .then((res) => {
        // console.log(res?.data?.data)
        setCompanyList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const getSkillsList = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/addondata/skills`)
      .then((res) => {
        console.log(res?.data?.data);
        setSkillsList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const getstateList = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/state/list`)
      .then((res) => {
        console.log(res?.data?.data, "tertetrerte");
        setStateList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const getcityList = (page, limit, search) => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASEURL}/city/list?/city/list?page=&limit=&search=`
      )
      .then((res) => {
        console.log(res?.data?.data, "dfsdfdsfs");
        setCityList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const getcountryList = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/country/list`)
      .then((res) => {
        console.log(res?.data?.data, "erwerwre");
        setCountryList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const [statevalues, setStatevalues] = useState("");
  const [cityvalues, setCityvalues] = useState("");

  const GetStateDropdown = (state) => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASEURL}/state/listByCountry/${state}`
      )
      .then((res) => {
        console.log(res);
        setStatevalues(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    GetStateDropdown(country?.value);
    GetCityDropdown(state?.value);
  }, []);

  const GetCityDropdown = (stateid) => {
    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/city/listByState/${stateid}`)
      .then((res) => {
        console.log(res, "rafi123");
        setCityvalues(res?.data?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const CountryOption = [];
  countryList?.length > 0 &&
    countryList?.map((items) => {
      return CountryOption?.push({ label: items?.name, value: items?.uuid });
    });

  const CountryChangeFunction = (event) => {
    console.log(event, "event");
    setState("");
    setCountry(event);
    GetStateDropdown(event?.value);
  };

  const StateOption = [];
  statevalues?.length > 0 &&
    statevalues?.map((items) => {
      return StateOption?.push({ label: items?.name, value: items?.uuid });
    });

  const StateChangeFunction = (event) => {
    console.log(event, "event");
    setState(event);
    setCity("");
    GetCityDropdown(event?.value);
  };

  // state

  const CityOption = [];
  cityvalues?.length > 0 &&
    cityvalues?.map((items) => {
      return CityOption?.push({ label: items?.name, value: items?.uuid });
    });

  const CityChangeFunction = (event) => {
    console.log(event, "event");
    setCity(event);
  };

  // useEffect(() => {
  //   GetStateDropdown();
  //   GetCityDropdown(state?.value);
  // }, []);

  // const [servicesSkil, setServicesSkill] = useState('');

  const [GenderValue, setGenderValue] = useState(
    details?.geeks_details?.personal_details?.gender
  );

  const Skillsoptions = [];

  if (optionsOne?.length > 0) {
    optionsOne?.map((items) => {
      return Skillsoptions?.push({ value: items?.value, label: items?.label });
    });
  }

  const optionsLevels = [
    { value: "Beginer", label: "Beginer" },
    { value: "Intermediate", label: "Intermediate" },
    { value: "Expert", label: "Expert" },
  ];

  const UpdateProfileIndiviudal = (e, Steptype) => {
    e.preventDefault();
    var payload = {};
    const dat = e.target.elements;
    console.log(dat, "dgjshfhgsjkhs");

    if (Steptype === "personal_details") {
      payload = {
        gender: GenderValue,
        dob: dat?.dob?.value,
        address: dat?.address?.value,
        city: dat?.city?.value,
        state: dat?.state?.value,
        pincode: dat?.pincode?.value,
        adhar_number: dat?.aadhar?.value,
        pan_number: dat?.pan_number?.value,
        profile_pic: profileImage,
        cover_pic: coverimage,
      };
    } else if (Steptype === "technical_expertise") {
      const skilssData =
        details?.geeks_details?.technical_expertise?.skills?.length > 0
          ? details?.geeks_details?.technical_expertise?.skills?.map((item) => {
              return item?.value ? item?.value : item;
            })
          : [];

      const languageData =
        details?.geeks_details?.technical_expertise?.language?.length > 0
          ? details?.geeks_details?.technical_expertise?.language?.map(
              (item) => {
                return item?.value ? item?.value : item;
              }
            )
          : [];

      const certifiedskilssData =
        details?.geeks_details?.technical_expertise?.certified_skills?.length >
        0
          ? details?.geeks_details?.technical_expertise?.certified_skills?.map(
              (item) => {
                return item?.value ? item?.value : item;
              }
            )
          : [];

      // const servicesSkillData = details?.geeks_details?.technical_expertise?.servicesSkil?.length > 0 ? details?.geeks_details?.technical_expertise?.servicesSkil?.map((item) => {
      //     return item?.value ? item?.value : item
      // }) : []

      payload = {
        skills: skilssData,
        level_of_specialization: dat?.levelofspecialization?.value,
        // years_of_experience: dat?.experience?.value,
        language: languageData,
        certified_skills: certifiedskilssData,
        // services: servicesSkillData,
        certification_awards_recognition:
          dat?.certification_awards_recognition?.value,
      };
    } else if (Steptype === "about") {
      payload = {
        nic_name: dat?.nicname?.value,
        highest_qualification: dat?.qualification?.value,
        academic_certification_award_testimonials:
          dat?.academic_qualification?.value,
        cv: cv,
        // bio: dat?.bio?.value
        bio: data1,
      };
    }

    // else if (activeStep >= 1) {
    const originalPayload = {
      geeks_type:
        details?.geeks_details?.geeks_type === "individual"
          ? "individual"
          : "corporate",
      type: Steptype,
      data: payload,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_BASEURL}/user/geeks/profile/update/${currentUser?.data?.uuid}`,
        originalPayload,
        {
          headers: {
            "x-auth-token": currentUser?.token,
          },
        }
      )
      .then((res) => {
        console.log(res, "dghsfdsjhkjshakj");
        toast.success(res?.data?.message, {
          position: "top-right",
        });
      })
      .catch((err) => {
        console.log(err.response, "ujjju");
        toast.error(err?.response?.data?.message, {
          position: "top-right",
        });
      });
    // }
  };

  return (
    <>
      <Navbar />
      <section className="relative lg:mt-24 mt-[74px] pb-16">
        <div className="lg:container container-fluid">
          <div className="profile-banner relative text-transparent">
            <Stack>
              <Badge
                className="Coverbadge"
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <>
                    <SmallAvatar
                      alt="Remy Sharp"
                      src={pencil}
                      htmlFor="pro-img"
                      style={{ marginLeft: "270px", marginTop: "60px" }}
                    />
                    <input
                      id="pro-banner"
                      name="profile-banner"
                      type="file"
                      className="hidden"
                    />
                    <label
                      className="absolute inset-0 cursor-pointer"
                      htmlFor="pro-img"
                    ></label>
                  </>
                }
              >
                {/* <div className="relative shrink-0"> */}
                <img
                  src={`${process.env.REACT_APP_IMAGE_BASEURL}/${details?.geeks_details?.about?.company_profile}`}
                  crossOrigin="anonymous"
                  className="h-64 w-full object-cover lg:rounded-xl shadow dark:shadow-gray-700"
                  id="profile-banner"
                  alt=""
                />
                {/* <label className="absolute inset-0 cursor-pointer" htmlFor="pro-banner"></label> */}
                {/* </div> */}
              </Badge>
            </Stack>
          </div>
          <div className="md:flex mx-4 -mt-12">
            <div className="md:w-full">
              <div className="relative flex items-end">
                <Stack>
                  <input
                    id="pro-img"
                    name="profile-image"
                    type="file"
                    className="hidden"
                    onClick={ImageUpload}
                  />
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={
                      <>
                        <SmallAvatar
                          alt="Remy Sharp"
                          src={pencil}
                          htmlFor="pro-img"
                        />
                        <label
                          className="absolute inset-0 cursor-pointer"
                          htmlFor="pro-img"
                        ></label>
                      </>
                    }
                  >
                    <img
                      alt="Profile"
                      crossOrigin="anonymous"
                      src={
                        `${process.env.REACT_APP_IMAGE_BASEURL}/${details?.geeks_details?.about?.company_profile}` ||
                        team1
                      }
                      style={{
                        width: "140px",
                        height: "140px",
                        borderRadius: "50%",
                      }}
                    />
                  </Badge>
                </Stack>

                {/* <div className="profile-pic text-center">
                                    <input id="pro-img" name="profile-image" type="file" className="hidden" />
                                    <div>
                                        <div className="relative h-28 w-28 max-w-[112px] max-h-[112px] mx-auto">
                                            <img src={`${process.env.REACT_APP_IMAGE_BASEURL}/${details?.geeks_details?.about?.company_profile}`} crossOrigin='anonymous' className="rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800" id="profile-image" alt="" />
                                            <label className="absolute inset-0 cursor-pointer" htmlFor="pro-img"></label>
                                        </div>
                                    </div>
                                </div> */}
                <div className="ms-4">
                  <h5 className="text-lg font-semibold">
                    {/* {details?.full_name} */}
                    {details?.first_name + " " + details?.last_name}
                  </h5>
                  <p className="text-slate-400">
                    {/* {data?.title ? data?.title : "UI Designer"} */}
                    {details?.geeks_details?.company_details?.city},
                    {details?.geeks_details?.company_details?.state}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-16">
          <div className="grid grid-cols-1 gap-[30px]">
            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
              <h5 className="text-lg font-semibold mb-4">
                Technical Expertise :
              </h5>

              {/* <form onSubmit={(e) => UpdateProfileCorporate(e, "technical_expertise")}>
                                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                                    <div>
                                        <label className="form-label font-medium">Skills : <span className="text-red-600">*</span></label>
                                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2" defaultValue={details?.geeks_details?.technical_expertise?.skills} id="firstname" name="skills" required="" />
                                    </div>
                                    <div>
                                        <label className="form-label font-medium">Level of Specialization : <span className="text-red-600">*</span></label>
                                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2" defaultValue={details?.geeks_details?.technical_expertise?.level_of_specialization} id="lastname" name="level_of_specialization" required="" />
                                    </div>
                                    <div>
                                        <label className="form-label font-medium">Language : <span className="text-red-600">*</span></label>
                                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2" defaultValue={details?.geeks_details?.technical_expertise?.language} name="language" required="" />
                                    </div>
                                    <div>
                                        <label className="form-label font-medium">Certified Skills : <span className="text-red-600">*</span></label>
                                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2" defaultValue={details?.geeks_details?.technical_expertise?.certified_skills} name="certified_skills" required="" />
                                    </div>
                                    <div>
                                        <label className="form-label font-medium">Certifications/Awards : <span className="text-red-600">*</span></label>
                                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2" defaultValue={details?.geeks_details?.technical_expertise?.certification_awards_recognition} name="certification_awards_recognition" required="" />
                                    </div>
                                    <div>
                                        <label className="form-label font-medium">Services : <span className="text-red-600">*</span></label>
                                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2" defaultValue={details?.geeks_details?.technical_expertise?.services} name="certification_awards_recognition" required="" />
                                    </div>
                                </div>

                                <div className='mt-5'>
                                    <input type="submit" id="submit" name="send" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md" value="Save Changes" />
                                </div>


                            </form> */}

              <form
                onSubmit={(e) =>
                  UpdateProfileIndiviudal(e, "technical_expertise")
                }
              >
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                  <div>
                  <label className="form-label font-medium tooltip cursor-pointer">
                      Skills : <span className="text-red-600">*</span>
                      <span class="tooltiptext">
                        Antivirus Software <br/>
                        Linux <br/>
                        cisco routers 
                      </span>
                    </label>
                    <Select
                      name="skills"
                      value={
                        skills?.length > 0
                          ? skills?.map((item) => {
                              return {
                                value: item?.value ? item?.value : item,
                                label: item?.label ? item?.label : item,
                              };
                            })
                          : []
                      }
                      onChange={setSkill}
                      isMulti
                      options={Skillsoptions}
                    />
                  </div>

                  <div>
                  <label className="form-label font-medium tooltip cursor-pointer" data-tooltip-target="tooltip-default">
                      Level of Specialization :{" "}
                      <span className="text-red-600">*</span>
                      <span class="tooltiptext">
                        Beginner <br/>
                        Expert <br/>
                        Intermediate 
                      </span>
                    </label>
                    <Select
                      name="levelofspecialization"
                      options={optionsLevels}
                      defaultValue={{ label: special, value: special }}
                      onChange={(val) => setlevel_of_specialization(val.value)}
                      value={{
                        value: level_of_specialization,
                        label: level_of_specialization,
                      }}
                    />
                  </div>

                  {/* <div>
                                        <label className="form-label font-medium">Year of experience : <span className="text-red-600">*</span></label>
                                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2" defaultValue={details?.geeks_details?.technical_expertise?.years_of_experience} name="experience" required="" />
                                    </div> */}

                  <div>
                  <label className="form-label font-medium tooltip cursor-pointer">
                      Language : <span className="text-red-600">*</span>
                      <span class="tooltiptext">
                        Hindi <br/>
                        English <br/>
                        Telugu 
                      </span>
                    </label>
                    <Select
                      name="languages"
                      value={
                        language?.length > 0
                          ? language?.map((item) => {
                              return {
                                value: item?.value ? item?.value : item,
                                label: item?.label ? item?.label : item,
                              };
                            })
                          : []
                      }
                      onChange={setLanguagesIndividual}
                      isMulti
                      options={optionsLanguages}
                    />
                  </div>

                  <div>
                  <label className="form-label font-medium tooltip cursor-pointer">
                      Certificated Skills :{" "}
                      <span className="text-red-600">*</span>
                      <span class="tooltiptext">
                        Oracle <br/>
                        Juniper routers <br/>
                        Linux 
                      </span>
                    </label>
                    <Select
                      name="Certified skills"
                      value={
                        certifiedskills?.length > 0
                          ? certifiedskills?.map((item) => {
                              return {
                                value: item?.value ? item?.value : item,
                                label: item?.label ? item?.label : item,
                              };
                            })
                          : []
                      }
                      onChange={(selectedOptions) =>
                        setCertifiedSkill(selectedOptions)
                      }
                      isMulti
                      options={Skillsoptions}
                    />

                    {/* <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2" defaultValue={details?.geeks_details?.technical_expertise?.certified_skills} name="email" required="" /> */}
                  </div>
                  {/* <div> */}
                  {/* <label>Services :</label> */}
                  {/* <Select
                                            name="services" value={servicesSkil?.length > 0 ? servicesSkil?.map((item) => {
                                                return { value: item?.value ? item?.value : item, label: item?.label ? item?.label : item }
                                            }) : []} onChange={setServicesSkill} isMulti options={servicesDropDown}
                                        /> */}
                  {/* {selectedOption && (
                                            <div>
                                                <p>Selected Option: {selectedOption.label}</p>
                                            </div>
                                        )} */}
                  {/* </div> */}
                  <div>
                    <label className="form-label font-medium">
                      Certification Awards Recognition :
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.technical_expertise
                          ?.certification_awards_recognition
                      }
                      name="certification_awards_recognition"
                    />
                  </div>
                </div>
                <input
                  type="submit"
                  id="submit"
                  name="send"
                  className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5 cursor-pointer"
                  value="Save Changes"
                />
              </form>
            </div>

            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
              <h5 className="text-lg font-semibold mb-4">About</h5>
              <form onSubmit={(e) => UpdateProfileCorporate(e, "about")}>
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                  <div>
                    <label className="form-label font-medium">
                      Name : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.about?.primary_contact_name
                      }
                      id="primary_contact_name"
                      name="primary_contact_name"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Email : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.about?.primary_contact_email_id
                      }
                      id="primary_contact_email_id"
                      name="primary_contact_email_id"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Description about company offering :{" "}
                      <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.about
                          ?.description_about_company_offering
                      }
                      id="description_about_company_offering"
                      name="description_about_company_offering"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Phone Number: <span className="text-red-600">*</span>
                    </label>

                    <PhoneInput
                      country={"in"}
                      value={phone}
                      onChange={(newPhone) => setPhone(newPhone)}
                      masks={{ in: "............", at: "(....) ...-...." }}
                      type="number"
                      defaultValue={details?.geeks_details?.about?.primary_contact_number?.replace(
                        /[+\-\s]/g,
                        ""
                      )}
                      name="primary_contact_number"
                      required=""
                      className="mt-2"
                      inputProps={{
                        id: "primary_contact_number",
                      }}
                    />

                    {/* <input
                      type="number"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.about?.primary_contact_number
                      }
                      name="primary_contact_number"
                      required=""
                    /> */}
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      {" "}
                      Upload Profile : <span className="text-red-600">*</span>
                    </label>
                    <input
                      className="relative form-input border border-slate-100 dark:border-slate-800 file:h-10 file:-mx-3 file:-my-2 file:cursor-pointer file:rounded-none file:border-0 file:px-3 file:text-neutral-700 bg-clip-padding px-3 py-1.5 file:me-3 mt-2"
                      onChange={(e) => ImageUpload(e, "compProfile")}
                      id="multiple_files"
                      type="file"
                      defaultValue={details?.geeks_details?.about?.compProfile}
                    />
                  </div>
                </div>
                {/* <div className="mt-4">
                  <label className="form-label font-medium">Bio : </label>
                  <CKEditor
                    editor={ClassicEditor}
                    defaultValue={details?.geeks_details?.about?.bio}
                   data={ckeditor}
                    // data="<p>Hello from CKEditor&nbsp;5!</p>"
                    onReady={(editor) => {
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => TextEditor(editor)}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                </div> */}
                <input
                  type="submit"
                  id="submit"
                  name="send"
                  className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5 cursor-pointer"
                  value="Save Changes"
                />
              </form>
            </div>

            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
              <h5 className="text-lg font-semibold mb-4">Company Details</h5>
              <form
                onSubmit={(e) => UpdateProfileCorporate(e, "company_details")}
              >
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                  <div>
                    <label className="form-label font-medium">
                      Company Name : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details?.company_name
                      }
                      id="firstname"
                      name="company_name"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium tooltip cursor-pointer">
                    Type of company :{" "}
                      <span className="text-red-600">*</span>
                      <span class="tooltiptext">
                        Proprietary <br/>
                        Partnership <br/>
                        LLP <br/>
                       Private Limited
                      </span>
                    </label>
                    {/* <Select id='company' name='company' onChange={setCompanySelected} options={companyList} defaultValue={{ value: profileData?.company_details?.type_of_company, label: profileData?.company_details?.type_of_company }} /> */}

                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details?.type_of_company
                      }
                      id="lastname"
                      name="type_of_company"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      CIN : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details
                          ?.company_incorporation_number
                      }
                      name="company_incorporation_number"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Date of CIN : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details
                          ?.date_of_incorporation
                      }
                      name="date_of_incorporation"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      PAN : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details?.pan_number
                      }
                      name="pan_number"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      GSTIN : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details?.gstin
                      }
                      name="gstin"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Website URL : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details
                          ?.company_website_url
                      }
                      name="company_website_url"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Address Line 1 : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details?.address_line1
                      }
                      name="address_line1"
                      required=""
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Address Line 2 : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details?.address_line2
                      }
                      name="address_line2"
                      required=""
                    />
                  </div>

                  {/* 
                                    <Grid item container>
                                    <Grid xs={12} md={5} style={{ marginLeft: "10%", marginTop: "2%" }} className='multistepInput'>
                        <label htmlFor="comments" className="font-semibold">Country</label>

                        <Select name='state' value={country} onChange={CountryChangeFunction} options={CountryOption} defaultValue={{label:profileData?.company_details?.country, value:profileData?.company_details?.country}}/>

                       
                    </Grid>
                    <Grid xs={12} md={5} style={{ marginTop: "2%" }} >
                        <label htmlFor="comments" className="font-semibold">State</label>
                        <Select name='state' value={state} onChange={StateChangeFunction} options={StateOption} defaultValue={{label:profileData?.company_details?.state, value:profileData?.company_details?.state}}/>
                      
                    </Grid>
                    <Grid xs={12} md={5} style={{ marginLeft: "10%", marginTop: "2%" }} className='multistepInput'>
                        <label htmlFor="comments" className="font-semibold">City</label>
                        <Select name='state' value={city} onChange={CityChangeFunction} options={CityOption} defaultValue={{value:profileData?.company_details?.city,label:profileData?.company_details?.city}}/>
                       
                    </Grid>
                    </Grid> */}

                  
                  <div>
                    <label className="form-label font-medium">
                      Country : <span className="text-red-600">*</span>
                    </label>

                    <Select
                      name="state"
                      value={country}
                      onChange={CountryChangeFunction}
                      options={CountryOption}
                      defaultValue={{
                        label:
                          profileData?.geeks_details?.company_details?.country,
                        value:
                          profileData?.geeks_details?.company_details?.country,
                      }}
                    />

                    {/* <Select name='state' value={country} onChange={CountryChangeFunction} options={CountryOption} defaultValue={{label:profileData?.company_details?.country, value:profileData?.company_details?.country}}/> */}

                    {/* 
                                        <Select name='languages' value={language?.length > 0 ? language?.map((item) => {
                                    return { value: item?.value ? item?.value : item, label: item?.label ? item?.label : item }
                                }) : []} onChange={setLanguagesIndividual} isMulti options={optionsLanguages} /> */}

                    {/* <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details?.country
                      }
                      name="country"
                      required=""
                    /> */}
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      State : <span className="text-red-600">*</span>
                    </label>

                    <Select
                      name="state"
                      value={state}
                      onChange={StateChangeFunction}
                      options={StateOption}
                      defaultValue={{
                        label:
                          profileData?.geeks_details?.company_details?.state,
                        value:
                          profileData?.geeks_details?.company_details?.state,
                      }}
                    />

                    {/* <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details?.state
                      }
                      name="state"
                      required=""
                    /> */}
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      City : <span className="text-red-600">*</span>
                    </label>

                    <Select
                      name="state"
                      value={city}
                      onChange={CityChangeFunction}
                      options={CityOption}
                      defaultValue={{
                        value:
                          profileData?.geeks_details?.company_details?.city,
                        label:
                          profileData?.geeks_details?.company_details?.city,
                      }}
                    />

                    {/* <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details?.city
                      }
                      name="city"
                      required=""
                    /> */}
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      ZIP : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      defaultValue={
                        details?.geeks_details?.company_details?.pincode
                      }
                      name="pincode"
                      required=""
                    />
                  </div>
                </div>
                <input
                  type="submit"
                  id="submit"
                  name="send"
                  className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5 cursor-pointer"
                  value="Save Changes"
                />
              </form>
            </div>

            {/* <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                            <div>
                                <h5 className="text-lg font-semibold mb-4">Contact Info :</h5>
                                <form>
                                    <div className="grid grid-cols-1 gap-4">
                                        <div>
                                            <label className="form-label font-medium">Phone No. :</label>
                                            <input name="number" id="number" type="number" className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="Phone :"/>
                                        </div>
                                        <div>
                                            <label className="form-label font-medium">Website :</label>
                                            <input name="url" id="url" type="url" className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="Url :"/>
                                        </div>
                                    </div>
                                    <button className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5">Add</button>
                                </form>
                            </div>
                            <div> 
                                <h5 className="text-lg font-semibold mb-4">Change password :</h5>
                                <form>
                                    <div className="grid grid-cols-1 gap-4">
                                        <div>
                                            <label className="form-label font-medium">Old password :</label>
                                            <input type="password" className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="Old password" required=""/>
                                        </div>
                                        <div>
                                            <label className="form-label font-medium">New password :</label>
                                            <input type="password" className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="New password" required=""/>
                                        </div>
                                        <div>
                                            <label className="form-label font-medium">Re-type New password :</label>
                                            <input type="password" className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="Re-type New password" required=""/>
                                        </div>
                                    </div>
                                    <button className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5">Save password</button>
                                </form>
                            </div>
                        </div>
                    </div> */}

            {/* <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                        <h5 className="text-lg font-semibold mb-4">Social Media :</h5>
                        <div className="md:flex">
                            <div className="md:w-1/3">
                                <span className="font-medium">Twitter</span>
                            </div>
                            <div className="md:w-2/3 mt-4 md:mt-0">
                                <form>
                                    <div className="form-icon relative">
                                        <Twitter className="w-4 h-4 absolute top-5 start-4"/>
                                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2 ps-12" placeholder="Twitter Profile Name" id="twitter_name" name="name" required=""/>
                                    </div>
                                </form>
                                <p className="text-slate-400 mt-1">Add your Twitter username (e.g. jennyhot).</p>
                            </div>
                        </div>
                        <div className="md:flex mt-8">
                            <div className="md:w-1/3">
                                <span className="font-medium">Facebook</span>
                            </div>
                            <div className="md:w-2/3 mt-4 md:mt-0">
                                <form>
                                    <div className="form-icon relative">
                                        <Facebook className="w-4 h-4 absolute top-5 start-4"/>
                                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2 ps-12" placeholder="Facebook Profile Name" id="facebook_name" name="name" required=""/>
                                    </div>
                                </form>
                                <p className="text-slate-400 mt-1">Add your Facebook username (e.g. jennyhot).</p>
                            </div>
                        </div>
                        <div className="md:flex mt-8">
                            <div className="md:w-1/3">
                                <span className="font-medium">Instagram</span>
                            </div>

                            <div className="md:w-2/3 mt-4 md:mt-0">
                                <form>
                                    <div className="form-icon relative">
                                        <Instagram className="w-4 h-4 absolute top-5 start-4"/>
                                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2 ps-12" placeholder="Instagram Profile Name" id="insta_name" name="name" required=""/>
                                    </div>
                                </form>
                                <p className="text-slate-400 mt-1">Add your Instagram username (e.g. jennyhot).</p>
                            </div>
                        </div>
                        
                        <div className="md:flex mt-8">
                            <div className="md:w-1/3">
                                <span className="font-medium">Linkedin</span>
                            </div>
                            <div className="md:w-2/3 mt-4 md:mt-0">
                                <form>
                                    <div className="form-icon relative">
                                        <Linkedin className="w-4 h-4 absolute top-5 start-4"/>
                                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2 ps-12" placeholder="Linkedin Profile Name" id="linkedin_name" name="name" required=""/>
                                    </div>
                                </form>
                                <p className="text-slate-400 mt-1">Add your Linkedin username.</p>
                            </div>
                        </div>
                        
                        <div className="md:flex mt-8">
                            <div className="md:w-1/3">
                                <span className="font-medium">Youtube</span>
                            </div>
                            <div className="md:w-2/3 mt-4 md:mt-0">
                                <form>
                                    <div className="form-icon relative">
                                        <Youtube className="w-4 h-4 absolute top-5 start-4"/>
                                        <input type="url" className="form-input border border-slate-100 dark:border-slate-800 mt-2 ps-12" placeholder="Youtube url" id="you_url" name="url" required=""/>
                                    </div>
                                </form>
                                <p className="text-slate-400 mt-1">Add your Youtube url.</p>
                            </div>
                        </div>
                        <div className="md:flex">
                            <div className="md:w-1/3">
                                <input type="submit" id="submit" name="send" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5" value="Save Changes"/>
                            </div>
                        </div>
                    </div> */}

            {/* <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                        <h5 className="text-lg font-semibold mb-5">Account Notifications :</h5>

                        <div className="flex justify-between pb-4">
                            <h6 className="mb-0 font-medium">When someone mentions me</h6>
                            <div className="">
                                <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" type="checkbox" value="" id="noti1"/>
                                <label className="form-check-label" htmlFor="noti1"></label>
                            </div>
                        </div>
                        <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                            <h6 className="mb-0 font-medium">When someone follows me</h6>
                            <div className="">
                                <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" type="checkbox" value="" defaultChecked id="noti2"/>
                                <label className="form-check-label" htmlFor="noti2"></label>
                            </div>
                        </div>
                        <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                            <h6 className="mb-0 font-medium">When shares my activity</h6>
                            <div className="">
                                <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" type="checkbox" value="" id="noti3"/>
                                <label className="form-check-label" htmlFor="noti3"></label>
                            </div>
                        </div>
                        <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                            <h6 className="mb-0 font-medium">When someone messages me</h6>
                            <div className="">
                                <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" type="checkbox" value="" id="noti4"/>
                                <label className="form-check-label" htmlFor="noti4"></label>
                            </div>
                        </div>
                    </div> */}

            {/* <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                        <h5 className="text-lg font-semibold mb-5">Marketing Notifications :</h5>

                        <div className="flex justify-between pb-4">
                            <h6 className="mb-0 font-medium">There is a sale or promotion</h6>
                            <div className="">
                                <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" type="checkbox" value="" id="noti5"/>
                                <label className="form-check-label" htmlFor="noti5"></label>
                            </div>
                        </div>
                        <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                            <h6 className="mb-0 font-medium">Company news</h6>
                            <div className="">
                                <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" type="checkbox" value="" id="noti6"/>
                                <label className="form-check-label" htmlFor="noti6"></label>
                            </div>
                        </div>
                        <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                            <h6 className="mb-0 font-medium">Weekly jobs</h6>
                            <div className="">
                                <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" type="checkbox" value="" defaultChecked id="noti7"/>
                                <label className="form-check-label" htmlFor="noti7"></label>
                            </div>
                        </div>
                        <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                            <h6 className="mb-0 font-medium">Unsubscribe News</h6>
                            <div className="">
                                <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" type="checkbox" value="" defaultChecked id="noti8"/>
                                <label className="form-check-label" htmlFor="noti8"></label>
                            </div>
                        </div>
                    </div> */}

            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
              <h5 className="text-lg font-semibold mb-5 text-red-600">
                Delete Account :
              </h5>
              <p className="text-slate-400 mb-4">
                Do you want to delete the account? Please press below "Delete"
                button
              </p>
              <Link
                to="#"
                className="btn bg-red-600 hover:bg-red-700 text-white rounded-md"
              >
                Delete
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
