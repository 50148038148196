import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link } from "react-router-dom";
import {MdOutlineArrowForward} from "../assets/icons/vander"

export default function Privacy() {
    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Privacy Policy</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Home</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Privacy Policy</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="relative lg:py-24 py-16">
                <div className="container">
                    <div className="md:flex justify-center">
                        <div className="md:w-3/4">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                                <h5 className="text-xl font-medium mb-4 mt-4">Privacy Policy</h5>
                                <p className="text-slate-400">Welcome to GeekOnDemand! This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our website and services.</p>


                                <h5 className="text-xl font-medium mb-4 mt-4">Information we collect</h5>
                                <p className="text-slate-400">We collect information when you use our website or services. The information we collect includes:</p>

                                <p className="text-slate-400">Personal Information: We may collect personal information, such as your name, email address, phone number, and billing information, when you register to use our services or make a payment.</p>

                                <p className="text-slate-400">Non-Personal Information: We may collect non-personal information, such as your IP address, device type, and browser type, when you use our website.</p>

                                <h5 className="text-xl font-medium mb-4 mt-4">How we use your information</h5>
                                <p className="text-slate-400">We use your information to provide and improve our services. Specifically, we use your information to: Connect you with service providers: We use your information to connect you with service providers who can provide IT services to you.</p>

                                <p className="text-slate-400">Process payments: We use your billing information to process payments for the services you receive.</p>

                                <p className="text-slate-400">Improve our services: We use non-personal information to analyze how our website is used and to improve our services.</p>

                                <p className="text-slate-400">Disclosure of your information</p>

                                <p className="text-slate-400">We may disclose your information in the following circumstances:</p>

                                <p className="text-slate-400">Service providers: We may share your information with service providers who provide IT services to you.</p>

                                <p className="text-slate-400">Legal requirements: We may disclose your information in response to a subpoena, court order, or other legal process</p>
                                <p className="text-slate-400">Business transfer: We may disclose your information as part of a business transfer, such as a merger or acquisition.</p>





                                <h5 className="text-xl font-medium mb-4 mt-4">Protection of your information</h5>
                                <p className="text-slate-400">We take reasonable steps to protect your information from unauthorized access, use, or disclosure. We use a variety of security measures to protect your information, including encryption, firewalls, and secure servers.</p>


                                <h5 className="text-xl font-medium mb-4 mt-4">Your rights</h5>
                                <p className="text-slate-400">You have the right to access, correct, or delete your personal information. You may also request that we stop using your information for certain purposes. To exercise these rights, please contact us using the contact information provided below.</p>


                                <h5 className="text-xl font-medium mb-4 mt-4">Changes to this Privacy Policy</h5>
                                <p className="text-slate-400">We may update this Privacy Policy from time to time. If we make any material changes, we will notify you by email or by posting a notice on our website prior to the change becoming effective.</p>

                                <h5 className="text-xl font-medium mb-4 mt-4">Contact us</h5>
                                <p className="text-slate-400">If you have any questions or concerns about this Privacy Policy or our use of your information, please contact us at hello@geekondemand.in.</p>


                                <p className="text-slate-400 mt-4">Last updated: March 24, 2023.</p>


                                {/* <h5 className="text-xl font-medium mb-4 mt-8">We use your information to :</h5>
                                <ul className="list-unstyled text-slate-400 mt-4">
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-emerald-600 align-middle me-2"/>Digital Marketing Solutions for Tomorrow</li>
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-emerald-600 align-middle me-2"/>Our Talented & Experienced Marketing Agency</li>
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-emerald-600 align-middle me-2"/>Create your own skin to match your brand</li>
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-emerald-600 align-middle me-2"/>Digital Marketing Solutions for Tomorrow</li>
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-emerald-600 align-middle me-2"/>Our Talented & Experienced Marketing Agency</li>
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-emerald-600 align-middle me-2"/>Create your own skin to match your brand</li>
                                </ul>

                                <h5 className="text-xl font-medium mb-4 mt-8">Information Provided Voluntarily :</h5>
                                <p className="text-slate-400">In the 1960s, the text suddenly became known beyond the professional circle of typesetters and layout designers when it was used for Letraset sheets (adhesive letters on transparent film, popular until the 1980s) Versions of the text were subsequently included in DTP programmes such as PageMaker etc.</p>

                                <div className="mt-8">
                                    <Link to="#" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md">Print</Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
