import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import logo_dark from "../../assets/images/logo-dark.png";
import logo_light from "../../assets/images/logo-light.png";
import webLogo from "../../assets/images/web-logo.png";
import { LoginURL } from "../../Redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import previousIcon from "../../assets/images/previous.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function Login() {
  const { currentUser, toastData } = useSelector((state) => state.auth);
  console.log(currentUser, "currentUser");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const [stat, setStat] = useState(false);

  // useEffect(() => {
  //     if (stat === true && toastData.status === true ) {
  //         navigate('/');
  //     }
  // }, [stat, toastData])

  const submitLogin = (e) => {
    // e.preventDefault();
    // const ele = e?.target?.elements

    // const payLoad = {
    //     "username": ele?.LoginEmail?.value,
    //     "password": ele?.LoginPassword?.value
    // }
    // console.log(ele, "ele")
    // dispatch(LoginURL(payLoad))
    // setStat(true)
    e.preventDefault();
    const ele = e?.target?.elements;

    const payLoad = {
      mobile: ele?.LoginEmail?.value.replace(/[+\-\s]/g,'')
    };
    // axios.post(`${process.env.REACT_APP_API_BASEURL}/user/otpVerify`, payLoad)
    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/user/signin`, payLoad)
      .then((res) => {
        const payLoadData = {
          mobile: ele?.LoginEmail?.value,
          login: true,
        };
        setTimeout(() => {
          setTimeout(() => {
            navigate("/otp", {
              state: {
                data: payLoadData,
                id: id,
              },
            });
          }, 500);
          toast.success(res?.data?.message, {
            position: "top-right",
          });
        });
      })
      .catch((err) => {
        console.log("error", err);
        toast.error(err.response?.data?.message, {
          position: "top-right",
        });
      });
  };

  const [phone, setPhone] = useState("");
  const [rawPhone, setRawPhone] = useState('');
  console.log(rawPhone?.length,"dshdfdsgfdsghf")
  const handleOnChange = (value, data, event) => {
  
    setRawPhone(value)
  };

  return (
    <section className="h-screen flex items-center justify-center relative overflow-hidden bg-[url('../../assets/images/hero/bg3.jpg')] bg-no-repeat bg-center bg-cover">
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
      <div className="container">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
          <div className="relative overflow-hidden bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
            <div className="p-6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  {/* <Link to="/"><img src={previousIcon} alt='previousIcon' style={{width:"35px",height:"35x"}}/></Link> */}
                </div>
                <div>
                  <Link to="/">
                    <img
                      src={webLogo}
                      className="mx-auto h-[34px] block dark:hidden"
                      alt=""
                    />
                    <img
                      src={webLogo}
                      className="mx-auto h-[34px] dark:block hidden"
                      alt=""
                    />
                  </Link>
                </div>
                <div></div>
              </div>

              <h5 className="my-6 text-xl font-semibold">Login</h5>
              <form className="text-left" onSubmit={submitLogin}>
                <div className="grid grid-cols-1">
                  <div className="mb-4 ltr:text-left rtl:text-right">
                    <label className="font-semibold" htmlFor="LoginEmail">
                      Mobile Number:
                    </label>
                    {/* <input id="LoginEmail" type="number"  onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10) }} className="form-input mt-3 rounded-md"  placeholder="Ex : 1234567890" /> */}

                    <PhoneInput
                     onlyCountries={['in']}
                    //  enableAreaCodes={true}
                    //  defaultCountry={'in'}
                      masks={{in: '............', at: '(....) ...-....'}}
                      // disableCountryCode={true}
                      value={rawPhone}
                      onChange={handleOnChange}
                      className="mt-2"
                      flagSize="lg"
                      country={'in'}
                      // enableAreaCodes={false}
                  
                      // autoFormat={false}
                      // enableAreaCodes={false}
                      // onlyCountries={["in"]}
                      // areaCodes={{ in: ["91"] }}
                      inputProps={{
                        id: "LoginEmail"
                      }}
                      // copyNumbersOnly={false}
                      // defaultMask={{in:"............"}}
                      
                      // prefix={true}
                      // disableCountryCode={true}
                      // enableAreaCodes={true}
                      // onInput={(e) => {
                      //   e.target.value = Math.max(
                      //     0,
                      //     parseInt(e.target.value || 0)
                      //   )
                      //     .toString()
                      //     .slice(0, 14);
                      // }}
                      // placeholder="Ex : 1234567890"
                      // inputProps={{
                      //   id: "LoginEmail"
                      // }}
                    />

{/* <PhoneInput
      className="mt-2"
      isValid={(inputNumber, onlyCountries) => {
        return onlyCountries?.some((country) => {
          return (
            inputNumber?.indexOf(country.dialCode) === 0 ||
            country?.dialCode?.indexOf(inputNumber) === 0
          );
        });
      }}
    /> */}
                  </div>

                  {/* <div className="mb-4 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="LoginPassword">Password:</label>
                                        <input id="LoginPassword" type="password" className="form-input mt-3 rounded-md" placeholder="Password:" />
                                    </div> */}

                  {/* <div className="flex justify-between mb-4">
                                        <div className="inline-flex items-center mb-0">
                                            <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50 me-2" type="checkbox" value="" id="RememberMe" />
                                            <label className="form-checkbox-label text-slate-400" htmlFor="RememberMe">Remember me</label>
                                        </div>
                                        <p className="text-slate-400 mb-0"><Link to="/reset-password" className="text-slate-400">Forgot password ?</Link></p>
                                    </div> */}

                  <div className="mb-4">
                    {/* <Link className="text-slate-400"> */}
                    <input
                      type="submit"
                      className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md w-full"
                      value="Login / Sign in"
                    />
                    {/* </Link> */}
                  </div>

                  <div className="text-center">
                    <span className="text-slate-400 me-2">
                      Don't have an account ?
                    </span>{" "}
                    <Link
                      to="/Joinning"
                      className="text-black dark:text-white font-bold"
                    >
                      Sign Up
                    </Link>
                  </div>
                  <div
                    className="text-center mt-4"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div></div>
                    <div>
                      <Link to="/">
                        {" "}
                        <span
                          className="text-slate-400 me-2"
                          style={{
                            fontSize: "14px",
                            textDecoration: "underline",
                          }}
                        >
                          Back to Home
                        </span>
                      </Link>
                    </div>
                    <div></div>
                  </div>
                </div>
              </form>
            </div>

            {/* <div className="px-6 py-2 bg-slate-50 dark:bg-slate-800 text-center">
                            <p className="mb-0 text-gray-400 font-medium">© {(new Date().getFullYear())}{" "} Jobstack. Designed by <Link to="https://shreethemes.in/" target="_blank" className="text-reset">Shreethemes</Link>.</p>
                        </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
