import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import OtpInput from 'react-otp-input';
import logo_dark from '../../assets/images/logo-dark.png';
import logo_light from '../../assets/images/logo-light.png';
import { MdOutlineArrowForward, BsTelephone, FaAtom, FiUserPlus, AiOutlineHourglass } from "../../assets/icons/vander"
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { LoginURL, UserDetailesSave } from '../../Redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Otp() {

    const [otp, setOtp] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [otpTrue, setOtpTrue] = useState(false)
    const { currentUser, toastData } = useSelector((state) => state.auth);

    console.log(currentUser, "currentUsercurrentUser")
    useEffect(() => {
        if (otpTrue === true && toastData.status === true) {
            navigate('/MultiStepForm');
        }
    }, [otpTrue, toastData])

    const otpVerify = (e) => {
        e.preventDefault()
        const payLoad = {
            "otp": otp,
            "mobile": location?.state?.data?.mobile ? location?.state?.data?.mobile.replace(/[+\-\s]/g,'') : location?.state?.mobile.replace(/[+\-\s]/g,'')
        }
        // axios.post(`${process.env.REACT_APP_API_BASEURL}/user/otpVerify`, payLoad)
        axios.post(`${process.env.REACT_APP_API_BASEURL}/user/verifyOTP`, payLoad)
            .then((res) => {
                toast.success(res?.data?.message,{
                    position:"top-right"
                })
                console.log(res, "res")
                setTimeout(() => {
                    // navigate('/login')
                    dispatch(UserDetailesSave(res.data));
                    setTimeout(() => {
                        // if(location?.state?.id === "candidate"){
                        //     navigate(`/service/${data?.name}`);
                        // }
                        if ((location?.state?.data?.login ? location?.state?.data?.login : location?.state?.login) === true || location?.state?.id === "candidate") {
                            navigate('/');
                        }
                        else {
                            if ((location?.state?.data?.type ? location?.state?.data?.type : location?.state?.type) === "seeker") {
                                navigate('/seeker/profile');
                            }
                            else {
                                navigate('/MultiStepForm');
                            }
                        }
                    }, 500)
                    // const payLoad = {
                    //     "username": location?.state?.data?.mobile,
                    //     "password": location?.state?.data?.password
                    // }
                    // dispatch(LoginURL(payLoad))
                    // setOtpTrue(true)
                })
            })
            .catch((err) => {
                console.log("error",err)
                toast.error(err.response?.data?.message, {
                    position: "top-right",
                  });
            })
    }

    const otpResend = () => {
        // fdf
        const payLoad = {
            "mobile": location?.state?.data?.mobile ? location?.state?.data?.mobile : location?.state?.mobile,
            // "email": location?.state?.data?.email
        }
        // axios.post(`${process.env.REACT_APP_API_BASEURL}/user/otpResend`, payLoad)
        axios.post(`${process.env.REACT_APP_API_BASEURL}/user/resendOTP`, payLoad)
            .then((res) => {
                toast.success(res?.data?.message,{
                    position:"top-right"
                })
            })
            .catch((err) => {
                console.log("error",err?.response)
                toast.error(err.response?.data?.message, {
                    position: "top-right",
                  });
            })
    }

    const featuredata = [
        {
            icon: AiOutlineHourglass,
            title: 'GeekOnDemand Pay Safe',
            data: 'If you are on the hunt for a tech whizz to solve your digital dilemmas, "GeekOnDemand Pay Safe" is your knight in shining armor. This extraordinary feature ensures a secure, straightforward and stress-free process of hiring geeks.'
        },
        {
            icon: FaAtom,
            title: 'Dedicated Support',
            data: 'At GeekOnDemand, we pride ourselves on our dedicated support - because we know the importance of swift and reliable help when you are dealing with IT challenges. Our team of geeks are always ready to provide assistance, whether you are facing a minor glitch or a major hurdle.'
        },
    ];


    return (
        <section className="h-screen flex items-center justify-center relative overflow-hidden bg-[url('../../assets/images/hero/bg3.jpg')] bg-no-repeat bg-center bg-cover">
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
            {/* <div className="container"> */}
            <div className="grid lg:grid-cols-6 md:grid-cols-4 grid-cols-6">
                <div className="relative overflow-hidden bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
                    <div style={{ padding: "50px 120px" }}>

                        <h1 className="my-6 font-semibold" style={{ display: "flex", justifyContent: "center", fontSize: "25px" }}>Enter Otp here</h1>


                        <form onSubmit={otpVerify}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    renderSeparator={<span> &nbsp;&nbsp;</span>}
                                    renderInput={(props) => <input {...props} style={{ border: "1px solid black", width: "40px", marginBottom: "5%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", fontSize: "18px", borderRadius: "7px", padding: "10px" }} />}
                                    
                                />
                            </div>
                            <div className="mb-4">
                                <input type="submit" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md w-full" value="submit" />
                            </div>
                            <div className="text-center">
                                <span className="text-slate-400 me-2">OTP Not Recieved ?</span> <span onClick={otpResend} className="text-black dark:text-white font-bold cursor-pointer">Resend</span>
                            </div>
                        </form>
                    </div>


                </div>
            </div>
            {/* </div> */}
        </section>
    )
}
