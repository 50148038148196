// import { Image } from "react-grid-gallery";

export interface CustomImage extends Image {
  original: string;
}

export const images: CustomImage[] = [
  {
    src: "https://source.unsplash.com/AYS2sSAMyhc/1400x1200",
    original: "https://source.unsplash.com/AYS2sSAMyhc/1400x1200",
    width: 120,
    height: 74,
 
  },
  {
    src: "https://source.unsplash.com/Kk8mEQAoIpI/1600x1200",
    original: "https://source.unsplash.com/Kk8mEQAoIpI/1600x1200",
    width: 120,
    height: 74,

  },
  {
    src: "https://source.unsplash.com/AYS2sSAMyhc/1400x1200",
    original: "https://source.unsplash.com/AYS2sSAMyhc/1400x1200",
    width: 120,
    height: 74,
   
  },
  {
    src: "https://source.unsplash.com/Kk8mEQAoIpI/1600x1200",
    original: "https://source.unsplash.com/Kk8mEQAoIpI/1600x1200",
    width: 120,
    height: 74,
   
  },

  // ... (the rest of your images)
];

// export { images };