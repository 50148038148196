import React ,{useState,useEffect}from 'react'
import { Link } from "react-router-dom";
import logo_dark from '../../assets/images/logo-dark.png';
import logo_light from '../../assets/images/logo-light.png';
import webLogo from '../../assets/images/web-logo.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'



export default function Signup() {

    const [phone, setPhone] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const payloadForm = location?.state
    console.log(payloadForm, "dddddd")

    const navigateOtp = (payloadForm1) => {
        navigate('/otp', { state : payloadForm1})
    }

    const submitAPi = (e) => {
        e.preventDefault();
        const ele = e.target.elements
        console.log(ele?.AcceptTC?.checked,"hjgfdshfkdjshkjs")
        if(ele?.AcceptTC?.checked){
            payloadForm.first_name = ele?.RegisterFirstName?.value
            payloadForm.last_name = ele?.RegisterLastName?.value
            payloadForm.email = ele?.LoginEmail?.value
            payloadForm.mobile = ele?.LoginMobile?.value?.replace(/[+\-\s]/g,'')
            // payloadForm.password = ele?.LoginPassword?.value
            // payloadForm.country_uuid = "CT-5BD8C295"
            console.log(payloadForm, "dddddd")

            // axios.post(`${process.env.REACT_APP_API_BASEURL}/user/register`, payloadForm)
            axios.post(`${process.env.REACT_APP_API_BASEURL}/user/signup`, payloadForm)
            .then((res) => {
                toast.success(res?.data?.message,{
                    position:"top-right"
                })
                const payLoadData = {
                    "mobile": ele?.LoginMobile?.value?.replace(/[+\-\s]/g,''),
                    "login" : false,
                    "type" : payloadForm.type
                }
                setTimeout(() => {
                    navigateOtp(payLoadData)
                }, 1000)
            })
            .catch((err) => {
                console.log("error", err.response.data)
                toast.error(err?.response?.data?.message,{
                    position:"top-right"
                })
            })
        }
        else{
            toast.error("Please accept  terms and conditions",{
                position:"top-right"
            })
        }
    }

    const { currentUser } = useSelector((state) => state.auth);

    // console.log(currentUser?.data, "adsadasdasda")

    const [userType, setUserType] = useState('geek');

    const handleUserTypeChange = (event) => {
      setUserType(event.target.value);
    };

    // const [seekerOrGeeker, setGeekSeek] = useState(false)

    // const changeGeek = (data) => {
    //     console.log(data, "data")
    //     setGeekSeek(data)
    // }


    const [selectedRole, setSelectedRole] = useState('seeker');

useEffect(() => {
    setSelectedRole(payloadForm?.type)
},[payloadForm])


    const [isCardFixed, setIsCardFixed] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        setIsCardFixed(scrollPosition > 900); // Adjust the scroll position where you want the card to become fixed
      };
  
      window.addEventListener('scroll', handleScroll);
  
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);



    const [rawPhone, setRawPhone] = useState('');
    console.log(rawPhone?.length,"dshdfdsgfdsghf")
    const handleOnChange = (value, data, event) => {
    
      setRawPhone(value)
    };



    return (
        <section className="h-screen flex items-center justify-center relative overflow-hidden bg-[url('../../assets/images/hero/bg3.jpg')] bg-no-repeat bg-center bg-cover">
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
            <div className="container" >
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1" >
                    <div className="relative overflow-hidden bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
                        <div className="p-6" 
                     style={{overflow:"auto",height:"auto"}}>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <Link to="/">
                                <img src={webLogo}  alt="" className="h-10 inline-block dark:hidden" width="100px" height="100px"/>
                                {/* <img src={webLogo} alt="" className="h-10 inline-block dark:hidden" width="100px" height="100px" /> */}
                            </Link>
                            </div>
                            <h5 className="py-2 font-semibold underline" style={{fontSize:"15px"}}> Signup as a {selectedRole}</h5>

                            {/* <h5 className="my-6 text-xl font-semibold underline">
                            Signup as a {selectedRole}
                                 </h5>


                            <div className="flex flex-col items-center justify-center">
      <h1 className="text-3xl font-bold mb-4">
        Signup as a {selectedRole}
      </h1>
      <div className="flex mb-4">
        <button
          className="mr-2 px-4 py-2 rounded "
          onClick={() => handleRoleChange('Seeker')}
        >
          Seeker
        </button>
        <button
         className="mr-2 px-4 py-2 rounded "
          onClick={() => handleRoleChange('Geek')}
        >
          Geek
        </button>
      </div>
    </div> */}








            <form className="text-left" onSubmit={submitAPi}>
                <div className="grid grid-cols-1">
                    <div className="mb-2 ltr:text-left rtl:text-right">
                        {/* <label className="font-semibold" htmlFor="RegisterName">First Name:</label> */}
                        <input id="RegisterFirstName" type="text" className="form-input mt-2 rounded-md" placeholder="First Name" required />
                    </div>

                    <div className="mb-2 ltr:text-left rtl:text-right">
                        {/* <label className="font-semibold" htmlFor="RegisterName">Last Name:</label> */}
                        <input id="RegisterLastName" type="text" className="form-input mt-2 rounded-md" placeholder="Last Name" required />
                    </div>

                    <div className="mb-2 ltr:text-left rtl:text-right">
                        {/* <label className="font-semibold" htmlFor="LoginEmail">Email Address:</label> */}
                        <input id="LoginEmail" type="email" className="form-input mt-2 rounded-md" placeholder="Email Id" />
                    </div>

                                    <div className="mb-4 ltr:text-left rtl:text-right">
                                        {/* <label className="font-semibold" htmlFor="LoginEmail">Mobile Number:</label> */}
                                        {/* <input id="LoginMobile" type="number" onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10) }} className="form-input mt-3 rounded-md" placeholder="1234567894" /> */}

                                        <PhoneInput
                                         onlyCountries={['in']}
                                         masks={{in: '............', at: '(....) ...-....'}}
                                            country={'in'}
                                            value={rawPhone}
                                             onChange={handleOnChange}
                                           
                                         className="mt-2"
                                            // id="LoginMobile" onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10) }}  placeholder="1234567894"

                                            inputProps={{
                                                id: "LoginMobile"
                                              }}

                                            />

                                    </div>
                                    
                                    {/* <div className="mb-4 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="LoginEmail">Country:</label>
                                        <input id="Logincountry" type="text" className="form-input mt-3 rounded-md" placeholder="India" />
                                    </div>
                                    <div className="mb-4 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="LoginPassword">Password:</label>
                                        <input id="LoginPassword" type="password" className="form-input mt-3 rounded-md" placeholder="Password:" />
                                    </div> */}

                    <div className="mb-4">
                        <div className="flex items-center w-full mb-0">
                            <input className="form-checkbox text-emerald-600 rounded w-4 h-4 me-2 border border-inherit" type="checkbox" value="" id="AcceptTC" />
                            <label className="form-check-label text-slate-400" htmlFor="AcceptTC" style={{fontSize:"12px",textAlign:"justify"}}>Yes, I understand and agree to the Geek on Demand <a  href='/Terms' target='_blank' className="text-emerald-600">Terms of Service</a>, including the <a href='/Agreement' target="_blank" className="text-emerald-600">User Agreement</a> and <a href='/Privacy' target='_blank' className="text-emerald-600">Privacy Policy</a>.</label>
                        </div>
                    </div>

                    <div>
                        {/* <Link to="/otp" className="text-slate-400"> */}
                        <input type="submit" style={{fontSize:"12px"}} className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md w-full" value="Register" />
                        {/* </Link> */}
                    </div>

                    <div className="text-center" style={{fontSize:"12px"}}>
                        <span className="text-slate-400 me-2">Already have an account ? </span> <Link to="/login" className="text-black dark:text-white font-bold">Sign in</Link>
                    </div>


                    <div className="text-center" style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                        <div></div>
                        <div>
                        <Link to="/"> <span className="text-slate-400"style={{fontSize:"12px",textDecoration:"underline"}}>Back to Home</span></Link>
                        </div>
                        <div></div>
                    
                    </div>
                </div>
            </form>
        </div>

                        {/* <div className="px-6 py-2 bg-slate-50 dark:bg-slate-800 text-center">
                            <p className="mb-0 text-gray-400 font-medium">© {(new Date().getFullYear())}{" "} Jobstack. Designed by <Link to="https://shreethemes.in/" target="_blank" className="text-reset">Shreethemes</Link>.</p>
                        </div> */}
                    </div>
                </div>
            </div>


        </section>
    )
}
