// import { configureStore } from '@reduxjs/toolkit'

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import persistStore from 'reduxjs-toolkit-persist/es/persistStore';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'reduxjs-toolkit-persist/es/constants';

import authReducer from './Redux/authSlice'
import serviceReducer from './Redux/services/servicesSlice';
import jobReducer from './Redux/geeks/jobSlice';


const persistConfig = {
    key: "JobSeekerGeeker",
    storage,
    // whitelist: ['menu', 'settings', 'lang'],
  };
  
  const persistedReducer = persistReducer(
    persistConfig,
    combineReducers({
        auth: authReducer,
        service : serviceReducer,
        alljobs : jobReducer,
    })
  );
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });
  const persistedStore = persistStore(store);
  export { store, persistedStore };