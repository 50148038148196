import React, { useState } from 'react';
import Navbar from '../components/Navbar'
import { Stepper, Step, StepLabel, Button, Typography, Container, Paper, Grid, TextField } from '@mui/material';
import { Link } from "react-router-dom";
import { DollarSign } from 'react-feather';
import './MultiStepForm.css'
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import geeks from '../assets/images/work/programmer.png';
import seeks from '../assets/images/work/job.png'
import { servicesURL } from '../Redux/services/servicesSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MultiStepForm = () => {

    const [activeStep, setActiveStep] = useState(0);

    const [step1Data, setStep1Data] = useState({})
    const [step2Data, setStep2Data] = useState({})
    const [step3Data, setStep3Data] = useState({})
    const [step4Data, setStep4Data] = useState({})
    const [step5Data, setStep5Data] = useState({})

    const [languagestep3, setLangStep3] = useState('NY')
    const [servicestep4, setServicestep4] = useState("1")
    const [check, setCheck] = useState(false)
    const [selectedTab, setSelectedTab] = useState(0);

    const [profileData, setProfileData] = useState('')
    // individual
    const [skills, setSkill] = useState('');
    const [bio123, setBio] = useState('');
    const [certifiedskills, setCertifiedSkill] = useState('');
    const [servicesSkil, setServicesSkill] = useState('');
    const [language, setLanguagesIndividual] = useState('');
    const [profileImage, setProfileImage] = useState('')
    const [coverimage, setcoverImage] = useState('')
    const [cv, setcv] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
  console.log(country,'dasasdasda')
    const [city, setCity] = useState('');
    console.log(city,'rtyryrytyrt')
    // corporate
    const [compProfile, setCompProfile] = useState('');


    const dispatch = useDispatch();

    const [page, setPage] = useState(0);
    const [limit, setlimit] = useState(0);
    // const [search, setsearch] = useState(0);
    const [search, setSearch] = useState('')
    const { servicesDropDown, services } = useSelector((state) => state.service);

    const changeScroll = (data, sarch) => {
        console.log(sarch, "data...")
        if (data === 'search') {
            dispatch(servicesURL(page, 10, sarch))
        }
        else if (data === 'inc' && page >= 0) {
            dispatch(servicesURL(page + 1, 10, search))
            setPage(page + 1)
        }
        else if (page >= 1) {
            dispatch(servicesURL(page - 1, 10, search))
            setPage(page - 1)
        }
    }

    console.log(services, servicesDropDown, "services")

    useEffect(() => {
        dispatch(servicesURL(page, 10, search))
        getProfileDetailes();
        getCompanyList();
        getSkillsList();
        getstateList();
        getcityList();
        getcountryList();
    }, [activeStep])

    // step 0 
    const [type, setType] = useState(false)

    const changeGeek = (data) => {
        setType(data)
    }

    const [data, setData] = useState('');
    const [data1, setData1] = useState('');


    const TextEditor = (editor) => {
        const data = editor.getData();
        setData(data)
    }
    const TextEditor1 = (editor) => {
        const data = editor.getData();
        setData1(data)
    }
    
    useEffect(() => {
        setServicesSkill(profileData?.geeks_details?.technical_expertise?.services)
        setCertifiedSkill(profileData?.geeks_details?.technical_expertise?.skills)
        setLanguagesIndividual(profileData?.geeks_details?.technical_expertise?.language)
        setSkill(profileData?.geeks_details?.technical_expertise?.skills)
        setData(profileData?.geeks_details?.about?.bio)
        setData1(profileData?.geeks_details?.about?.description_about_company_offering)
        setState({label : profileData?.geeks_details?.personal_details?.state, value : profileData?.geeks_details?.personal_details?.state})
        setCity({label : profileData?.geeks_details?.personal_details?.city , value : profileData?.geeks_details?.personal_details?.city})
        setCountry({label : profileData?.geeks_details?.personal_details?.name , value : profileData?.geeks_details?.personal_details?.name})
        
        // setProfileImage()
        // setcoverImage()
        // setcv()
        // setCompProfile()
    }, [profileData])

    useEffect(() => {
        setType(profileData?.geeks_details?.geeks_type === "corporate" ? true : false)
    }, [])

    const getProfileDetailes = () => {
        axios.get(`${process.env.REACT_APP_API_BASEURL}/user/geeks/profile/${currentUser?.data?.uuid}`, {
            headers: {
                "x-auth-token": currentUser?.token
            }
        })
            .then((res) => {
                console.log(res.data?.data, "dsjvjssdjvg")
                setProfileData(res?.data?.data)
            })
            .catch((err) => {
                console.log(err, "err")
            })
    }

    const [companyList, setCompanyList] = useState([])
    const [companySelected, setCompanySelected] = useState('')
    const [optionsOne, setSkillsList] = useState([])
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [countryList, setCountryList] = useState([])
    console.log(countryList,"sdasdadasda")
    

    console.log(companySelected, "companySelected")
    const getCompanyList = () => {
        axios.get(`${process.env.REACT_APP_API_BASEURL}/addondata/companyType`)
            .then((res) => {
                // console.log(res?.data?.data)
                setCompanyList(res?.data?.data)
            })
            .catch((err) => {
                console.log(err, "err")
            })
    }

    const getSkillsList = () => {
        axios.get(`${process.env.REACT_APP_API_BASEURL}/addondata/skills`)
            .then((res) => {
                console.log(res?.data?.data)
                setSkillsList(res?.data?.data)
            })
            .catch((err) => {
                console.log(err, "err")
            })
    }


    const getstateList = () => {
        axios.get(`${process.env.REACT_APP_API_BASEURL}/state/list`)
            .then((res) => {
                console.log(res?.data?.data,"tertetrerte")
                setStateList(res?.data?.data)
            })
            .catch((err) => {
                console.log(err, "err")
            })
    }


    const getcityList = (page, limit, search) => {
        axios.get(`${process.env.REACT_APP_API_BASEURL}/city/list?/city/list?page=&limit=&search=`)
            .then((res) => {
                console.log(res?.data?.data,"dfsdfdsfs")
                setCityList(res?.data?.data)
            })
            .catch((err) => {
                console.log(err, "err")
            })
    }

    
    const getcountryList = () => {
        axios.get(`${process.env.REACT_APP_API_BASEURL}/country/list`)
            .then((res) => {
                console.log(res?.data?.data,"erwerwre")
                setCountryList(res?.data?.data)
            })
            .catch((err) => {
                console.log(err, "err")
            })
    }

    const [statevalues, setStatevalues] = useState('');
    const [cityvalues, setCityvalues] = useState('');


    const GetStateDropdown = () => {
        axios.get(`${process.env.REACT_APP_API_BASEURL}/state/listByCountry/CT-5BD8C295`)
            .then((res) => {
                console.log(res)
                setStatevalues(res?.data?.data)
            })
            .catch((err) => {
                console.log(err, "err")
            })
    }

    useEffect(()=>{
        GetStateDropdown()
        GetCityDropdown(state)
    },[])

    const GetCityDropdown = (stateid) => {
        axios.get(`${process.env.REACT_APP_API_BASEURL}/city/listByState/${stateid}`)
            .then((res) => {
                console.log(res,"bharath123")
                setCityvalues(res?.data?.data)
            })
            .catch((err) => {
                console.log(err, "err")
            })
    }


    const CountryOption = [];
    countryList?.length > 0 && countryList?.map((items)=>{
        return CountryOption?.push({ label: items?.name , value: items?.uuid})
    })

    const CountryChangeFunction = (event) => {
        console.log(event,"event")
        setState('')
        setCountry(event)
        GetStateDropdown(event?.value)
    }

    const StateOption = [];
    statevalues?.length > 0 && statevalues?.map((items)=>{
        return StateOption?.push({ label: items?.name , value: items?.uuid})
    })

    const StateChangeFunction = (event) => {
        console.log(event,"event")
        setState(event)
        setCity('')
        GetCityDropdown(event?.value)
    }

    // state

    const CityOption = [];
    cityvalues?.length > 0 && cityvalues?.map((items)=>{
        return CityOption?.push({ label: items?.name , value: items?.uuid})
    })

    const CityChangeFunction = (event) => {
        console.log(event,"event")
        setCity(event)

    }


    
    const ImageUpload = (event, type) => {
        const data1 = event.target.files[0]
        const formData = new FormData();
        formData.append('file', data1);
        axios.post(`${process.env.REACT_APP_API_BASEURL}/upload/image`, formData)
            .then((res) => {
                // console.log(res.data.image.filename)
                if (type === 'profile') {
                    setProfileImage(res.data.image.filename);
                }
                else if (type === "cv") {
                    setcv(res.data.image.filename);
                }
                else if (type === "cover") {
                    setcoverImage(res.data.image.filename);
                }
                else if (type === "compProfile") {
                    setCompProfile(res.data.image.filename);
                }
            })
            .catch((err) => {
                console.log(err.response)
                setcoverImage('');
            })
    }




    // const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6'];
    // const steps1 = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6', 'step 7'];

    const steps = ['Geeks Type', 'Technical Expertise', 'Personal Details', 'About'];
    const steps1 = ['Step 1', 'Step 2', 'Step 3', 'Step 4'];

    const { currentUser } = useSelector((state) => state.auth);


    const handleNext = (e, step, Steptype) => {
        e.preventDefault();
        var payload = {};
        const dat = e.target.elements;
        console.log(dat, "dgjshfhgsjkhs")
        if (step === "0") {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        if (step === "1") {
            setStep1Data(e.target.elements)
            if (type === false) {
                const skilssData = skills?.length > 0 ? skills?.map((item) => {
                    return item?.value ? item?.value : item
                }) : []

                const languageData = language?.length > 0 ? language?.map((item) => {
                    return item?.value ? item?.value : item
                }) : []

                const certifiedskilssData = certifiedskills?.length > 0 ? certifiedskills?.map((item) => {
                    return item?.value ? item?.value : item
                }) : []

                const servicesSkillData = servicesSkil?.length > 0 ? servicesSkil?.map((item) => {
                    return item?.value ? item?.value : item
                }) : []

                console.log(skilssData, "e.target.elements")

                payload = {
                    skills: skilssData,
                    level_of_specialization: dat?.levelofspecialization?.value,
                    years_of_experience: dat?.experience?.value,
                    language: languageData,
                    certified_skills: certifiedskilssData,
                    services: servicesSkillData,
                }
            }
            else if (type === true) {

                const skilssData = skills?.length > 0 ? skills?.map((item) => {
                    return item?.value ? item?.value : item
                }) : []

                const languageData = language?.length > 0 ? language?.map((item) => {
                    return item?.value ? item?.value : item
                }) : []

                const certifiedskilssData = certifiedskills?.length > 0 ? certifiedskills?.map((item) => {
                    return item?.value ? item?.value : item
                }) : []

                payload = {
                    skills: skilssData,
                    level_of_specialization: dat?.levelofspecialization?.value,
                    certification_awards_recognition: dat?.Firm_certifications?.value,
                    language: languageData,
                    certified_skills: certifiedskilssData
                }
            }
            // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        else if (step === "2") {
            setStep2Data(e.target.elements)
            if (type === false) {
                payload = {
                    gender: dat?.gender?.value,
                    dob: dat?.dob?.value,
                    address: dat?.address?.value,
                    // city: dat?.city?.value,
                    // state: dat?.State?.value,
                    // city: city?.label,
                    // state: state?.label,
                    country: country?.label,
                    city: city?.label,
                    state: state?.label,
                    pincode: dat?.pincode?.value,
                    adhar_number: dat?.aadhar?.value,
                    pan_number: dat?.pan?.value,
                    profile_pic: profileImage,
                    cover_pic: coverimage,
                }
            }
            else if (type === true) {
                payload = {
                    company_name: dat?.companyName?.value,
                    type_of_company: companySelected?.key ? companySelected?.key : '',
                    company_incorporation_number: dat?.incorporationNumber?.value,
                    date_of_incorporation: dat?.dateofincorporation?.value,
                    pan_number: dat?.pan?.value,
                    gstin: dat?.gstin?.value,
                    company_website_url: dat?.url?.value,
                    address_line1: dat?.add1?.value,
                    address_line2: dat?.add2?.value,
                    // country: dat?.country?.value,
                    // city: dat?.city?.value,
                    // state: dat?.state?.value,
                    country: country?.label,
                    city: city?.label,
                    state: state?.label,
                    pincode: dat?.pincode?.value
                }
            }
            // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        else if (step === "3") {
            // console.log(e, languagestep3, "eeeee")
            if (languagestep3 === '') {
                toast.warn("Please Enter All Fields" ,{
                    position:'top-right'
                })
            }
            else {
                setStep3Data(e.target.elements)
                if (type === false) {
                    console.log(e.target.elements, "e.target.elements")
                    payload = {
                        nic_name: dat?.nicname?.value,
                        highest_qualification: dat?.qualification?.value,
                        academic_certification_award_testimonials: dat?.academic_qualification?.value,
                        cv: cv,
                        // bio: dat?.bio?.value
                        bio: data
                    }
                }
                else if (type === true) {
                    payload = {
                        primary_contact_name: dat?.contactName?.value,
                        primary_contact_number: dat?.contactNo?.value,
                        primary_contact_email_id: dat?.contactEmailId?.value,
                        company_profile: compProfile,
                        // description_about_company_offering: dat?.offerings?.value
                        description_about_company_offering: data1
                    }
                }
                // setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }

        // else if (step === "4") {
        //     console.log(e, servicestep4, "eeeee")
        //     if (servicestep4 === '') {
        //     }
        //     else {
        //         setStep4Data(e.target.elements)
        //         setActiveStep((prevActiveStep) => prevActiveStep + 1);
        //     }
        // }
        // else if (step === "5") {
        //     setStep5Data(e.target.elements)
        //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // }

        if (activeStep >= 1) {
            const originalPayload = {
                "geeks_type": type === true ? 'corporate' : "individual",
                "type": Steptype,
                "data": payload
            }
            axios.put(`${process.env.REACT_APP_API_BASEURL}/user/geeks/profile/update/${currentUser?.data?.uuid}`, originalPayload, {
                headers: {
                    "x-auth-token": currentUser?.token
                }
            })
                .then((res) => {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    toast.success(res?.data?.message,{
                        position:"top-right"
                    })
                })
                .catch((err) => {
                    console.log(err.response,"Yhyhi")
                    toast.error(err?.response?.message,{
                        position:"top-right"
                    })
                })
        }

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [languages, setLanguages] = useState([{ language: '', reading: false, writing: false, listening: false }]);

    const addLanguage = () => {
        setLanguages([...languages, { language: '', reading: false, writing: false, listening: false }]);
    };

    const removeLanguage = (index) => {
        const updatedLanguages = [...languages];
        updatedLanguages.splice(index, 1);
        setLanguages(updatedLanguages);
    };

    const handleLanguageChange = (index, selectedLanguage) => {
        const updatedLanguages = [...languages];
        updatedLanguages[index].language = selectedLanguage;
        setLanguages(updatedLanguages);
    };

    const handleCheckboxChange = (index, type) => {
        const updatedLanguages = [...languages];
        updatedLanguages[index][type] = !updatedLanguages[index][type];
        setLanguages(updatedLanguages);
    };


    const [inputValue, setInputValue] = useState('');
    const [values, setValues] = useState([]);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputKeyPress = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault(); // Prevent Enter key from adding a line break in the input
            const newValues = inputValue
                .split(',')
                .map((value) => value.trim())
                .filter((value) => value !== ''); // Remove empty values

            setValues([...values, ...newValues]);
            setInputValue('');
        }
    };


    const finalStep = (event) => {
        event.preventDefault();
        const finalStepData = event?.target?.elements
        const payload = {
            "professional_role": step1Data?.role?.value,
            "geeks_type": type === true ? 'corporate' : 'individual',
            "work_experience": [{
                "title": step2Data?.title?.value,
                "company": step2Data?.company?.value,
                "location": step2Data?.location?.value,
                "country": step2Data?.Country?.value,
                "is_currently_working": check,
                "start_date": step2Data?.startDate?.value,
                "end_date": check ? '' : step2Data?.startDate?.value,
                "description": step2Data?.workdescription?.value
            }],
            "education": [{
                "school_university": step2Data?.eduSchool?.value,
                "degree": step2Data?.eduDegree?.value,
                "study_field": step2Data?.feildofstudy?.value,
                "from_date": step2Data?.edustartDate?.value,
                "to_date": step2Data?.eduendDate?.value,
                "description": step2Data?.eduDescription?.value
            }],
            "language": [{
                "name": languagestep3,
                "label": "conversational"
            }],
            // "skils": [step3Data?.skills?.value],
            "skils": values,
            "bio": step1Data?.description?.value,
            "service": servicestep4,
            "rate_detail": {
                "hourly_rate": step4Data?.hourRate?.value,
                "service_fee": step4Data?.ServiceFee?.value,
                "will_paid_amount": step4Data?.youwillget?.value
            },
            "profile": {
                // "profile_pic": image,
                "dob": finalStepData?.dob?.value,
                "state": finalStepData?.state?.value,
                "city": finalStepData?.city?.value,
                "address": finalStepData?.address?.value,
                "pincode": finalStepData?.pincode?.value
            }
        }
        axios.put(`${process.env.REACT_APP_API_BASEURL}/user/geeks/profile/update/${currentUser?.data?.uuid}`, payload)
            .then((res) => {
                toast.success(res?.data?.message ,{
                    position:'top-right'
                })
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            })
            .catch((err) => {
                toast.error(err?.message,{
                    position:"top-right"
                })
                console.log(err,"ooihkhh")
            })
    }


    // const optionsOne = [
    //     { value: 'React', label: 'React' },
    //     { value: 'Html', label: ' Html' },
    //     { value: 'CSS', label: 'CSS' },
    //     { value: 'Java', label: 'Java' },
    // ]

    const optionsLanguages = [
        { value: 'ENGLISH', label: 'ENGLISH' },
        { value: 'TELUGU', label: ' TELUGU' },
        { value: 'HINDI', label: 'HINDI' },
    ]
    const optionsLevels = [
        { value: 'Beginer', label: 'Beginer' },
        { value: 'Intermediate', label: 'Intermediate' },
        { value: 'Expert', label: 'Expert' },
    ]

    const genderOptions = [
        { value: 'male', label: 'male' },
        { value: 'female', label: 'female' }
    ]

    // const cityOptions = [
    //     { value: 'Hyderabad', label: 'Hyderabad' }
    // ]

    // const stateOptions = [
    //     { value: 'Telangana', label: 'Telangana' }
    // ]

    

    function truncate(source, size) {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
      }






          // aadharNumber

    const [aadharNumber, setAadharNumber] = useState('');
    const [Valid, setValid] = useState(null);
  
    const validateAadhar = (inputAadhar) => {
      // Regular expression to check if Aadhar number is exactly 12 digits
      const aadharRegex = /^\d{12}$/;
      const isValidAadhar = aadharRegex.test(inputAadhar);
      setValid(isValidAadhar);
    };
  
    const handleAadharChange = (event) => {
      const inputAadhar = event.target.value;
      setAadharNumber(inputAadhar);
      validateAadhar(inputAadhar);
    };
  
    const inputColor = Valid ? 'green' : 'red';





    return (
        <section className="h-screen flex items-center justify-center relative overflow-hidden bg-[url('../../assets/images/hero/bg3.jpg')] bg-no-repeat bg-center bg-cover">
            {/* <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div> */}
            <Navbar navClass='justify-end nav-dark' />
            <Container className='MainContainer'>

                <Paper style={{ padding: '40px', marginLeft: "50px", marginRight: "50px", marginTop: "80px" }} className='MultiStepForm'>
                    <h3 className=" text-3xl leading-normal font-semibold text-black flex items-center justify-center">Profile Creation Process</h3>
                    <Stepper activeStep={activeStep} alternativeLabel style={{ paddingTop: "2%" }}>
                        {/* {type === true && steps1.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))} */}
                        {/* {type === false &&  */}
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))
                        }
                        {/* } */}
                    </Stepper>
                    <div>
                        {activeStep === steps.length ? (
                            <div style={{ textAlign: "center", padding: 20 }}>
                                <Typography variant="h6" >
                                    Profile Creation Success!
                                    <br /> <br />
                                    Congratulations on creating your profile. Our dedicated team will now review and verify your information. Once approved, you'll have full access to all the features and benefits of our platform.
                                    <br /> <br />
                                    Please keep an eye on your email for updates on your profile status. If you have any questions or need assistance, don't hesitate to contact our support team.
                                    <br /> <br />
                                    Thank you for choosing our platform. We look forward to having you as a valued member of our community!</Typography>
                                <div className="mt-4">
                                    <Link to={`/geeeks/profile/individual/${currentUser?.data?.uuid}`} className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full" >Go to Profile</Link>
                                </div>
                                
                            </div>
                        ) : (
                            <div>
                                {/* <Typography variant="h5">{steps[activeStep]}</Typography> */}
                                {/* Render the appropriate form based on the step */}
                                {activeStep === 0 && <Step0Form steps={steps} setSelectedTab={setSelectedTab} selectedTab={selectedTab} handleNext={handleNext} activeStep={activeStep} step1Data={step1Data} changeGeek={changeGeek} type={type} />}
                                {activeStep === 1 && <Step1Form changeScroll={changeScroll} servicesDropDown={servicesDropDown} language={language} handleBack={handleBack} skills={skills} certifiedskills={certifiedskills} profileData={profileData?.geeks_details} setLanguagesIndividual={setLanguagesIndividual} setCertifiedSkill={setCertifiedSkill} setServicesSkill={setServicesSkill} serviceSkill={servicesSkil} setSkill={setSkill} type={type} optionsLanguages={optionsLanguages} optionLevels={optionsLevels} optionsOne={optionsOne} steps={steps} handleNext={handleNext} activeStep={activeStep} step1Data={step1Data} />}
                                {activeStep === 2 && <Step2Form inputColor={inputColor} Valid={Valid} handleAadharChange={handleAadharChange} aadharNumber={aadharNumber} handleBack={handleBack} setCompanySelected={setCompanySelected} companyList={companyList} genderOptions={genderOptions}  profileData={profileData?.geeks_details} ImageUpload={ImageUpload} steps={steps} handleNext={handleNext} activeStep={activeStep} step2Data={step2Data} setCheck={setCheck} check={check} type={type} StateOption={StateOption} StateChangeFunction={StateChangeFunction} setState={setState} state={state} setCity={setCity} city={city} CityChangeFunction={CityChangeFunction} CityOption={CityOption} CountryChangeFunction={CountryChangeFunction} CountryOption={CountryOption} setCountry={setCountry} country={country}/>}
                                {activeStep === 3 && <Step3Form handleBack={handleBack} profileData={profileData?.geeks_details} ImageUpload={ImageUpload} steps={steps} handleNext={handleNext} activeStep={activeStep} setLangStep3={setLangStep3} languagestep3={languagestep3} step3Data={step3Data}
                                    handleCheckboxChange={handleCheckboxChange} addLanguage={addLanguage} languages={languages} removeLanguage={removeLanguage} handleLanguageChange={handleLanguageChange}
                                    handleInputKeyPress={handleInputKeyPress} handleInputChange={handleInputChange} inputValue={inputValue} values={values} type={type} data={data} data1={data1} TextEditor={TextEditor} TextEditor1={TextEditor1} bio123={bio123}
                                />}
                                {/* {activeStep === 4 && <Step4Form steps={type === true ? steps1 : steps} handleNext={handleNext} activeStep={activeStep} setServicestep4={setServicestep4} servicestep4={servicestep4} step4Data={step4Data} />}
                                {activeStep === 5 && <Step5Form steps={type === true ? steps1 : steps} handleNext={handleNext} activeStep={activeStep} step4Data={step5Data} ImageUpload={ImageUpload} />}
                                {activeStep === 6 && <Step6Form steps={type === true ? steps1 : steps} handleNext={finalStep} activeStep={activeStep} step4Data={step5Data} ImageUpload={ImageUpload} />} */}
                                {/* {activeStep === 5 && <Step5Form steps={type === true ? steps1 : steps} handleNext={finalStep} activeStep={activeStep} step4Data={step5Data} ImageUpload={ImageUpload} />} */}

                                {/* <Grid container spacing={2}> */}
                                {/* <Grid item>
                                        {activeStep !== 0 && (
                                            <div className="mt-4">
                                                <Link className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full" onClick={handleBack}>Back</Link>
                                            </div>
                                            // <Button variant="contained" color="primary" onClick={handleBack}>
                                            //   Back
                                            // </Button>
                                        )}
                                    </Grid> */}
                                {/* <Grid item> */}
                                {/* <div className="mt-4">
                                                <Link  className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full" onClick={handleNext}>
                                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                </Link>
                                            </div> 
                                        */}
                                {/* <Button variant="contained" color="primary" onClick={handleNext}>
                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                            </Button> 
                                        */}
                                {/* </Grid> */}
                                {/* </Grid> */}
                            </div>
                        )}
                    </div>
                </Paper>
            </Container>
        </section>

    );
};

const Step0Form = ({ handleNext, activeStep, step1Data, changeGeek, type, steps, setSelectedTab, selectedTab }) => (


    <div >
        {/* <Grid item container>
            <Grid xs={12} md={12} lg={12}> */}
        <div >
            <div className="lg:ms-5">
                <div className="p-6">
                    {/* <h3 className="mb-6 text-2xl leading-normal font-semibold">Geeks Type</h3> */}

                    <form onSubmit={(e) => handleNext(e, "0")}>
                        <div >
                            {/* <div className="grid lg:grid-cols-2 md:grid-cols-6 sm:grid-cols-12 grid-cols-1" style={{ display: "flex", justifyContent: "center", marginBottom: "5%" }}>
                                        <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500" style={{ margin: "15px" }}>
                                            <div className="inline-flex items-center mb-0">
                                                <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50 me-2" type="checkbox" value=""
                                                    checked={!type ? true : false} onChange={() => changeGeek(type ? false : true)}
                                                    id="RememberMe" />
                                            </div>
                                            <div className="mt-4">
                                                <Link to="#" className="text-lg font-semibold hover:text-emerald-600 transition-all duration-500" >Individual</Link>
                                            </div>
                                        </div>
                                        <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500" style={{ margin: "15px" }}>
                                            <div className="inline-flex items-center mb-0">
                                                <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50 me-2" type="checkbox" value=""
                                                    checked={type ? true : false} onChange={() => changeGeek(type ? false : true)}
                                                    id="RememberMe" />
                                            </div>
                                            <div className="mt-4">
                                                <Link to="#" className="text-lg font-semibold hover:text-emerald-600 transition-all duration-500" >Corporate</Link>
                                            </div>
                                        </div>
                                    </div> */}

                            <Grid item container style={{ display: "flex", justifyContent: "flex-start" }}>
                                <Grid xs={12} md={3} lg={3} className="text-center rounded border cursor-pointer "
                                    style={{ margin: "20px", padding: "20px 50px", backgroundColor: selectedTab == 0 ? 'rgb(5 150 105 / 0.05)' : '#fff' }} onClick={() => { setSelectedTab(0); changeGeek(type ? false : true) }} >

                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <img src={geeks} alt="geeks" style={{ width: "70px", height: "70px" }} />
                                    </div>

                                    <div className="mt-4">
                                        <p className="text-sm font-semibold hover:text-emerald-600 transition-all duration-500" >Individual</p>
                                    </div>
                                </Grid>

                                <Grid xs={12} md={3} lg={3} className="text-center rounded border cursor-pointer" style={{ margin: "20px", padding: "20px 50px", backgroundColor: selectedTab == 1 ? 'rgb(5 150 105 / 0.05)' : '#fff' }} onClick={() => { setSelectedTab(1); changeGeek(type ? false : true) }}>

                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <img src={seeks} alt="seeks" style={{ width: "70px", height: "70px" }} />
                                    </div>

                                    <div className="mt-4">

                                        <p to="#" className="text-sm font-semibold hover:text-emerald-600 transition-all duration-500" >Corporate</p>
                                    </div>
                                </Grid>
                            </Grid>
                            {/* </div> */}
                        </div>
                        <div style={{ float: "inline-end" }}>
                            <button type='submit' className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full" >
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </button>
                        </div>
                        {/* <button type="submit" id="submit" name="send" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md">Send Message</button> */}
                    </form>
                </div>
            </div>
        </div>
        {/* </Grid> */}
        {/* <Grid xs={12} md={12} lg={6}></Grid> */}
        {/* </Grid> */}

    </div>
);

const Step1Form = ({ handleNext, activeStep, profileData, certifiedskills, language, setServicesSkill, serviceSkill, skills, servicesDropDown, changeScroll, step1Data, type, steps, optionsOne, optionsLanguages, optionLevels, setLanguagesIndividual, setSkill, setCertifiedSkill, handleBack }) => (
    <div >
        {/* <Grid item container>
            <Grid xs={12} md={12} lg={6}> */}
        <div className="lg:col-span-5 md:col-span-3">
            <div className="lg:ms-5">
                <div className="p-6">
                    {/* <h3 className="mb-6 text-2xl leading-normal font-semibold">Technical Expertise</h3> */}
                    <form onSubmit={(e) => handleNext(e, "1", "technical_expertise")}>
                        {/* <div className="grid lg:grid-cols-6 lg:gap-12"> */}
                        <Grid item container >


                            <Grid xs={12} md={5}>
                                <label htmlFor="comments" className="font-semibold">Skills</label>
                                {/* <input name="email" id="role" type="text" className="form-input mt-2" /> */}
                                <Select name='skills' value={skills?.length > 0 ? skills?.map((item) => {
                                    return { value: item?.value ? item?.value : item, label: item?.label ? item?.label : item }
                                }) : []} onChange={setSkill} isMulti options={optionsOne} />
                            </Grid>

                            <Grid xs={12} md={5} style={{ marginLeft: "10%" }} className='multistepInput'>
                                <label htmlFor="comments" className="font-semibold">Level of Specialization</label>
                                <Select name='levelofspecialization' options={optionLevels} defaultValue={{ value: profileData?.technical_expertise?.level_of_specialization, label: profileData?.technical_expertise?.level_of_specialization }} />
                            </Grid>
                            {
                                type === false ?
                                    <Grid xs={12} md={5} style={{ marginTop: "2%" }}>
                                        <label htmlFor="comments" className="font-semibold" >Years of Experience</label>
                                        <input name="experience" id="role" type="text" className="form-input" defaultValue={profileData?.technical_expertise?.years_of_experience} />
                                    </Grid>
                                    :
                                    <Grid xs={12} md={5} style={{ marginTop: "2%" }}>
                                        <label htmlFor="comments" className="font-semibold">Enter your Firm Certifications
                                            /Awards/Recognitions</label>
                                        <input name="Firm_certifications" id="role" type="text" className="form-input" defaultValue={profileData?.technical_expertise?.certification_awards_recognition} />
                                    </Grid>
                            }
                            <Grid xs={12} md={5} style={{ marginLeft: "10%", marginTop: "2%" }} className='multistepInput'>
                                <label htmlFor="comments" className="font-semibold">Languages</label>
                                <Select name='languages' value={language?.length > 0 ? language?.map((item) => {
                                    return { value: item?.value ? item?.value : item, label: item?.label ? item?.label : item }
                                }) : []} onChange={setLanguagesIndividual} isMulti options={optionsLanguages} />
                            </Grid>
                            <Grid xs={12} md={5} style={{ marginTop: "2%" }}>
                                <label htmlFor="comments" className="font-semibold">Certified Skills</label>
                                <Select name='Certified skills' value={certifiedskills?.length > 0 ? certifiedskills?.map((item) => {
                                    return { value: item?.value ? item?.value : item, label: item?.label ? item?.label : item }
                                }) : []} onChange={setCertifiedSkill} isMulti options={optionsOne} />
                            </Grid>
                            {
                                type === false ?
                                    <Grid xs={12} md={5} style={{ marginLeft: "10%", marginTop: "2%" }} className='multistepInput'>
                                        <label htmlFor="comments" className="font-semibold">Services</label>
                                        {/* <input name="email" id="role" type="text" className="form-input mt-2" /> */}
                                        <Select name="services" value={serviceSkill?.length > 0 ? serviceSkill?.map((item) => {
                                            return { value: item?.value ? item?.value : item, label: item?.label ? item?.label : item }
                                        }) : []} onChange={setServicesSkill} isMulti options={servicesDropDown} onMenuScrollToTop={(event) => changeScroll("dec")} onMenuScrollToBottom={(event) => changeScroll("inc")} onInputChange={(event) => changeScroll("search", event)} />
                                    </Grid>
                                    :
                                    null
                            }
                            {/* </div> */}
                        </Grid>

                        <div className="mt-5" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            {activeStep !== 0 && (
                                <div >
                                    <Link className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full" onClick={handleBack}>Back</Link>
                                </div>
                            )}
                            <button type='submit' className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full" >
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </button>
                        </div>
                        {/* <button type="submit" id="submit" name="send" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md">Send Message</button> */}
                    </form>
                </div>
                {/* <div className="p-6">
                            <h3 className="mb-6 text-2xl leading-normal font-semibold">Professional Role</h3>
                            <form onSubmit={(e) => handleNext(e, "1")}>
                                <div className="grid lg:grid-cols-6 lg:gap-12">
                                    <div className="lg:col-span-3 mb-5">
                                        <input name="email" id="role" type="text" className="form-input mt-2" defaultValue={step1Data?.role?.value} required />
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="comments" className="font-semibold">Bio</label>
                                        <textarea name="comments" id="description" className="form-input mt-2 textarea" defaultValue={step1Data?.description?.value} placeholder="Enter Here...." required></textarea>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <button type='submit' className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full" >
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </button>
                                </div>
                                </form>
                        </div> */}
            </div>
        </div>
        {/* </Grid> */}
        {/* <Grid xs={12} md={12} lg={6}></Grid> */}
        {/* // </Grid> */}

    </div>
);

const Step2Form = ({ handleNext, setCompanySelected, activeStep, handleBack, ImageUpload, companyList, profileData, genderOptions, cityOptions,stateOptions,step2Data, setCheck, check, steps, type ,StateOption,StateChangeFunction,setState,state , setCity,city,CityOption,CityChangeFunction,CountryChangeFunction,country,setCountry,CountryOption,aadharNumber , handleAadharChange,Valid,inputColor}) => (
    <form onSubmit={(e) => handleNext(e, "2", type === true ? "company_details" : "personal_details")}>
        {type === false ?
            <div>
                {/* <Grid item container>
                    <Grid xs={12} md={12} lg={6}> */}
                <div className="lg:col-span-5 md:col-span-6">
                    <div className="lg:ms-5">
                        <div className="p-6" 
                        // style={{ overflowY:"scroll",height:"230px" }}
                        >
                            {/* <h3 className="mb-6 text-2xl leading-normal font-semibold">Personal Details</h3> */}
                            <Grid item container>
                                {/* <div className="grid lg:grid-cols-6 lg:gap-12"> */}

                                <Grid xs={12} md={5}>
                                    <label htmlFor="email" className="font-semibold">Gender</label>
                                    {/* <input name="gender" id="title" type="text" className="form-input mt-2" defaultValue={profileData?.personal_details?.gender} /> */}
                                    <Select name='gender' options={genderOptions} defaultValue={{ value: profileData?.personal_details?.gender, label: profileData?.personal_details?.gender }} className="mt-2" />
                                </Grid>
                                <Grid xs={12} md={5} style={{ marginLeft: "10%" }} className='multistepInput'>
                                    <label htmlFor="email" className="font-semibold">Dob</label>
                                    <input name="dob" id="company" type="date" className="form-input mt-2" defaultValue={profileData?.personal_details?.dob} />
                                </Grid>
                                {/* </div> */}
                                {/* <div className="grid lg:grid-cols-6 lg:gap-12"> */}
                                <Grid xs={12} md={5} style={{ marginTop: "2%" }}>
                                    <label htmlFor="Workingstatus" className="font-semibold">Address</label>
                                    <input type="text" id="vehicle1" name="address" className="form-input mt-2" defaultValue={profileData?.personal_details?.address} />
                                </Grid>
                                <Grid xs={12} md={5} style={{ marginLeft: "10%", marginTop: "2%" }}>
                                    <label htmlFor="email" className="font-semibold">Country</label>
                                    <Select name='state' value={country} onChange={CountryChangeFunction} options={CountryOption} defaultValue={{label:profileData?.company_details?.country, value:profileData?.company_details?.country}}/>
                                    {/* <Select name='country' value={country} onChange={CountryChangeFunction} options={CountryOption} defaultValue={{label:profileData?.personal_details?.country, value:profileData?.personal_details?.country}}/> */}

                                           {/* <input name="country" id="country" type='text' className="form-input mt-2" defaultValue={profileData?.company_details?.country}></input> */}
                                </Grid>
                                <Grid xs={12} md={5} style={{ marginTop: "2%" }}>
                                    <label htmlFor="email" className="font-semibold">State</label>

                                    <Select name='state' value={state} onChange={StateChangeFunction} options={StateOption} defaultValue={{label:profileData?.company_details?.state, value:profileData?.company_details?.state}}/>

                                    
                                    {/* <Select name='state' value={state} onChange={StateChangeFunction} options={StateOption} defaultValue={{label:profileData?.personal_details?.state, value:profileData?.personal_details?.state}}/> */}


                                    {/* <Select name="State" id="State" options={stateOptions}  defaultValue={{ value: profileData?.personal_details?.state, label: profileData?.personal_details?.state }} className="mt-2" /> */}

                                    {/* <input name="State" id="State" type="text" className="form-input mt-2" defaultValue={profileData?.personal_details?.state} /> */}
                                </Grid>
                                <Grid xs={12} md={5} style={{ marginLeft: "10%",marginTop: "2%" }} className='multistepInput'>
                                    <label htmlFor="email" className="font-semibold">City</label>
                                    {/* <input name="city" id="city" type="text" className="form-input mt-2" 
                                    
                                    defaultValue={profileData?.personal_details?.city} /> */}
                                     <Select name='state' value={city} onChange={CityChangeFunction} options={CityOption} defaultValue={{value:profileData?.company_details?.city,label:profileData?.company_details?.city}}/>

                                    {/* <Select name='state' value={city} onChange={CityChangeFunction} options={CityOption} defaultValue={{value:profileData?.personal_details?.city,label:profileData?.personal_details?.city}}/> */}

                                    {/* <Select name="city" id="startDate" options={cityOptions}  defaultValue={{ value: profileData?.personal_details?.city, label: profileData?.personal_details?.city }} className="mt-2" /> */}

                                 
                                </Grid>
                                
                                {/* </div> */}
                                {/* <div className="grid grid-cols-1"> */}
                                <Grid xs={12} md={5} style={{  marginTop: "2%" }} className='multistepInput'>
                                    <label htmlFor="subject" className="font-semibold">PIN Code</label>
                                    <input name="pincode" id="Country" className="form-input mt-2" defaultValue={profileData?.personal_details?.pincode} />
                                </Grid>
                                <Grid xs={12} md={5} style={{ marginLeft: "10%" ,marginTop: "2%" }}>
                                    <label htmlFor="subject" className="font-semibold">Aadhar</label>
                                    <input name="aadhar" id="location" className="form-input mt-2mb-2" defaultValue={profileData?.personal_details?.adhar_number} 
                                       maxLength="12"
                                    //    value={aadharNumber}
                                    //    onChange={handleAadharChange}
                                    />
                                        {Valid !== null && (
                                            <p style={{ color: inputColor }}>
                                            {Valid
                                                ? 'Aadhar card number is valid.'
                                                : 'Aadhar card number is not valid.'}
                                            </p>
                                        )}
                                </Grid>
                                <Grid xs={12} md={5} style={{  marginTop: "2%" }} className='multistepInput'>
                                    <label htmlFor="comments" className="font-semibold">PAN Number</label>
                                    <input name="pan" id="workdescription" className="form-input mt-2" defaultValue={profileData?.personal_details?.pan_number} maxLength="10"/>
                                </Grid>
                                <Grid xs={12} md={5} style={{ marginLeft: "10%",marginTop: "2%" }}>
                                    <label htmlFor="comments" className="font-semibold">Profile Picture</label>
                                    <input name="profile" id="workdescription" type='file' onChange={(e) => ImageUpload(e, 'profile')} style={{ border: "2px solid #f1f2f4", paddingRight: "15px" }} className="mt-2" />
                                </Grid>
                                <Grid xs={12} md={5} style={{ marginTop: "2%" }} className='multistepInput'>
                                    <label htmlFor="comments" className="font-semibold">Cover Image</label>
                                    <input name="coverimage" id="workdescription" type='file' onChange={(e) => ImageUpload(e, 'cover')} style={{ border: "2px solid #f1f2f4", paddingRight: "15px" }} className="mt-2" />
                                </Grid>
                                {/* </div> */}
                            </Grid>
                        </div>
                    </div>
                </div>
                {/* </Grid> */}
                {/* //     <Grid xs={12} md={12} lg={6}>

                //     </Grid>
                // </Grid> */}
            </div>
            :

            <div>

                {/* <Grid item container>
                    <Grid xs={12} md={12} lg={6}> */}

                {/* <div className="lg:col-span-5 md:col-span-6">
                            <div className="lg:ms-5">
                                <div className="p-6"> */}
                <h3 className="text-2xl leading-normal font-semibold p-4">Company Details</h3>
                <Grid item container style={{ overflowY: "scroll", height: "350px" ,padding:"15px"}}>
                    {/* <div className="grid lg:grid-cols-6 lg:gap-12"> */}
                    <Grid xs={12} md={5} >
                        <label htmlFor="email" className="font-semibold">Company Name</label>
                        <input name="companyName" id="companyName" type="text" className="form-input mt-2" defaultValue={profileData?.company_details?.company_name} />
                    </Grid>
                    <Grid xs={12} md={5} style={{ marginLeft: "10%" }} className='multistepInput'>
                        <label htmlFor="email" className="font-semibold">Type of company</label>
                        {/* <input name="company" id="company" type="text" className="form-input mt-2" defaultValue={profileData?.company_details?.type_of_company} /> */}
                        <Select id='company' name='company' onChange={setCompanySelected} options={companyList} defaultValue={{ value: profileData?.company_details?.type_of_company, label: profileData?.company_details?.type_of_company }} />
                    </Grid>
                    {/* </div> */}
                    {/* <div className="grid lg:grid-cols-6 lg:gap-12"> */}
                    <Grid xs={12} md={5} style={{ marginTop: "2%" }} >
                        <label htmlFor="Workingstatus" className="font-semibold">Company Incorporation Number</label>
                        <input type="text" id="incorporationNumber" name="incorporationNumber" className="form-input mt-2" defaultValue={profileData?.company_details?.company_incorporation_number} />
                    </Grid>
                    <Grid xs={12} md={5} style={{ marginLeft: "10%", marginTop: "2%" }} className='multistepInput'>
                        <label htmlFor="email" className="font-semibold">Date of Incorporation</label>
                        <input name="dateofincorporation" id="dateofincorporation" type="date" className="form-input mt-2" defaultValue={profileData?.company_details?.date_of_incorporation} />
                    </Grid>
                    <Grid xs={12} md={5} style={{ marginTop: "2%" }} >
                        <label htmlFor="email" className="font-semibold">PAN</label>
                        <input name="pan" id="pan" type="text" className="form-input mt-2" defaultValue={profileData?.company_details?.pan_number} />
                    </Grid>
                    {/* </div> */}
                    <Grid xs={12} md={5} style={{ marginLeft: "10%", marginTop: "2%" }} className='multistepInput'>
                        <div className="mb-5">
                            <label htmlFor="subject" className="font-semibold">GSTIN</label>
                            <input name="gstin" id="gstin" className="form-input mt-2" defaultValue={profileData?.company_details?.gstin} />
                        </div>

                    </Grid>


                    <Grid xs={12} md={12} >
                        <h3 className=" text-2xl leading-normal font-semibold">.</h3>
                    </Grid>

                    <Grid xs={12} md={5} >
                        <label htmlFor="subject" className="font-semibold">Company Website URL</label>
                        <input name="url" id="url" className="form-input mt-2" defaultValue={profileData?.company_details?.company_website_url} />
                    </Grid>
                    <Grid xs={12} md={5} style={{ marginLeft: "10%" }} className='multistepInput'>
                        <label htmlFor="comments" className="font-semibold">Address Line1</label>
                        <input name="add1" id="add1" className="form-input mt-2" defaultValue={profileData?.company_details?.address_line1}></input>
                    </Grid>
                    <Grid xs={12} md={5} style={{ marginTop: "2%" }} >
                        <label htmlFor="comments" className="font-semibold">Address Line2</label>
                        <input name="add2" id="add2" type='text' className="form-input mt-2" defaultValue={profileData?.company_details?.address_line2}></input>
                    </Grid>
                    <Grid xs={12} md={5} style={{ marginLeft: "10%", marginTop: "2%" }} className='multistepInput'>
                        <label htmlFor="comments" className="font-semibold">Country</label>

                        <Select name='state' value={country} onChange={CountryChangeFunction} options={CountryOption} defaultValue={{label:profileData?.company_details?.country, value:profileData?.company_details?.country}}/>

                        {/* <input name="country" id="country" type='text' className="form-input mt-2" defaultValue={profileData?.company_details?.country}></input> */}
                    </Grid>
                    <Grid xs={12} md={5} style={{ marginTop: "2%" }} >
                        <label htmlFor="comments" className="font-semibold">State</label>
                        <Select name='state' value={state} onChange={StateChangeFunction} options={StateOption} defaultValue={{label:profileData?.company_details?.state, value:profileData?.company_details?.state}}/>
                        {/* <Select name="state" id="state" options={stateOptions}  defaultValue={{ value: profileData?.company_details?.state, label: profileData?.company_details?.state }} className="mt-2" /> */}
                        {/* <input name="state" id="state" className="form-input mt-2" defaultValue={profileData?.company_details?.state}></input> */}
                    </Grid>
                    <Grid xs={12} md={5} style={{ marginLeft: "10%", marginTop: "2%" }} className='multistepInput'>
                        <label htmlFor="comments" className="font-semibold">City</label>
                        <Select name='state' value={city} onChange={CityChangeFunction} options={CityOption} defaultValue={{value:profileData?.company_details?.city,label:profileData?.company_details?.city}}/>
                        {/* <Select name="city" id="city" options={cityOptions}  defaultValue={{ value: profileData?.company_details?.city, label: profileData?.company_details?.city }} className="mt-2" /> */}
                        {/* <input name="city" id="city" type='text' className="form-input mt-2" defaultValue={profileData?.company_details?.city}></input> */}
                    </Grid>
                    <Grid xs={12} md={5} style={{ marginTop: "2%" }} >
                        <label htmlFor="comments" className="font-semibold">PIN Code</label>
                        <input name="pincode" id="pincode" type='text' className="form-input mt-2" defaultValue={profileData?.company_details?.pincode}></input>
                    </Grid>
                </Grid>
                {/* </div>
                            </div>
                        </div> */}
                {/* </Grid>
                    <Grid xs={12} md={12} lg={6}> */}
                {/* <div className="lg:col-span-5 md:col-span-6">
                            <div className="lg:ms-5"> */}
                {/* <div className="p-6"> */}

                {/* <div className="grid lg:grid-cols-6 lg:gap-12"> */}
                {/* <Grid item container>

                                        </Grid> */}
                {/* </div> */}
                {/* </div> */}
                {/* </div>
                        </div> */}

                {/* </Grid>
                </Grid> */}

            </div>
        }
        <div className="mt-5" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            {activeStep !== 0 && (
                <div >
                    <Link className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full" onClick={handleBack}>Back</Link>
                </div>
            )}
            <button type='submit' className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full" >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </button>
        </div>
    </form>
    // <form onSubmit={(e) => handleNext(e, "2")}>
    //     <div>
    //         <Grid item container>
    //             <Grid xs={12} md={12} lg={6}>
    //                 <div className="lg:col-span-5 md:col-span-6">
    //                     <div className="lg:ms-5">
    //                         <div className="p-6">
    //                             <h3 className="mb-6 text-2xl leading-normal font-semibold">Work Experience</h3>
    //                             <div className="grid lg:grid-cols-6 lg:gap-12">
    //                                 <div className="lg:col-span-6 mb-5">
    //                                     <label htmlFor="email" className="font-semibold">Title</label>
    //                                     <input name="email" id="title" type="text" className="form-input mt-2" defaultValue={step2Data?.title?.value} />
    //                                 </div>
    //                                 <div className="lg:col-span-6 mb-5">
    //                                     <label htmlFor="email" className="font-semibold">Company</label>
    //                                     <input name="email" id="company" type="text" className="form-input mt-2" defaultValue={step2Data?.company?.value} />
    //                                 </div>
    //                             </div>
    //                             <div className="grid lg:grid-cols-6 lg:gap-12">
    //                                 <div className="lg:col-span-6 mb-5">
    //                                     <label htmlFor="Workingstatus" className="font-semibold">Currently Working ? &nbsp;</label>
    //                                     <input type="checkbox" id="vehicle1" name="vehicle1" checked={check} onChange={() => setCheck(!check)} />
    //                                 </div>
    //                                 <div className="lg:col-span-6 mb-5">
    //                                     <label htmlFor="email" className="font-semibold">Start Date</label>
    //                                     <input name="email" id="startDate" type="date" className="form-input mt-2" defaultValue={step2Data?.startDate?.value} />
    //                                 </div>
    //                                 <div className="lg:col-span-6 mb-5">
    //                                     <label htmlFor="email" className="font-semibold">End Date</label>
    //                                     <input name="email" id="endDate" type="date" className="form-input mt-2" disabled={check} defaultValue={check ? '' : step2Data?.endDate?.value} value={check ? '' : step2Data?.endDate?.value} />
    //                                 </div>
    //                             </div>
    //                             <div className="grid grid-cols-1">
    //                                 <div className="mb-5">
    //                                     <label htmlFor="subject" className="font-semibold">Country</label>
    //                                     <input name="Country" id="Country" className="form-input mt-2" defaultValue={step2Data?.Country?.value} />
    //                                 </div>
    //                                 <div className="mb-5">
    //                                     <label htmlFor="subject" className="font-semibold">Location</label>
    //                                     <input name="subject" id="location" className="form-input mt-2" defaultValue={step2Data?.location?.value} />
    //                                 </div>
    //                                 <div className="mb-5">
    //                                     <label htmlFor="comments" className="font-semibold">Description</label>
    //                                     <textarea name="comments" id="workdescription" className="form-input mt-2 textarea" defaultValue={step2Data?.workdescription?.value} placeholder="Enter Here...."></textarea>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </Grid>
    //             <Grid xs={12} md={12} lg={6}>
    //                 <div className="lg:col-span-5 md:col-span-6">
    //                     <div className="lg:ms-5">
    //                         <div className="p-6">
    //                             <h3 className="mb-6 text-2xl leading-normal font-semibold">Education</h3>
    //                             <div className="grid lg:grid-cols-6 lg:gap-12">
    //                                 <div className="lg:col-span-6 mb-5">
    //                                     <label htmlFor="email" className="font-semibold">School/University</label>
    //                                     <input name="email" id="eduSchool" type="text" className="form-input mt-2" defaultValue={step2Data?.eduSchool?.value} />
    //                                 </div>
    //                                 <div className="lg:col-span-6 mb-5">
    //                                     <label htmlFor="email" className="font-semibold">Degree</label>
    //                                     <input name="email" id="eduDegree" type="text" className="form-input mt-2" defaultValue={step2Data?.eduDegree?.value} />
    //                                 </div>
    //                             </div>
    //                             <div className="grid lg:grid-cols-6 lg:gap-12">
    //                                 <div className="lg:col-span-6 mb-5">
    //                                     <label htmlFor="email" className="font-semibold">Start Date</label>
    //                                     <input name="email" id="edustartDate" type="date" className="form-input mt-2" defaultValue={step2Data?.edustartDate?.value} />
    //                                 </div>
    //                                 <div className="lg:col-span-6 mb-5">
    //                                     <label htmlFor="email" className="font-semibold">To Date</label>
    //                                     <input name="email" id="eduendDate" type="date" className="form-input mt-2" defaultValue={step2Data?.eduendDate?.value} />
    //                                 </div>
    //                             </div>
    //                             <div className="grid grid-cols-1">
    //                                 <div className="mb-5">
    //                                     <label htmlFor="subject" className="font-semibold">Fields of Study</label>
    //                                     <input name="subject" id="feildofstudy" className="form-input mt-2" defaultValue={step2Data?.feildofstudy?.value} />
    //                                 </div>
    //                                 <div className="mb-5">
    //                                     <label htmlFor="comments" className="font-semibold">Description</label>
    //                                     <textarea name="comments" id="eduDescription" className="form-input mt-2 textarea" placeholder="Enter Here...." defaultValue={step2Data?.eduDescription?.value}></textarea>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </Grid>
    //         </Grid>
    //     </div>
    //     <div className="mt-4">
    //         <button type='submit' className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full" >
    //             {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
    //         </button>
    //     </div>
    // </form>
);


const Step3Form = ({ handleNext, activeStep, ImageUpload, profileData, steps, inputValue, type, data, data1, TextEditor, TextEditor1, bio123, values, handleInputChange, handleInputKeyPress, addLanguage, handleCheckboxChange, languages, removeLanguage, handleBack, handleLanguageChange }) => (
    <form onSubmit={(e) => handleNext(e, "3", 'about')} >
        {type === false ?
            <div>
                {/* <Grid item container>
                    <Grid xs={12} md={12} lg={6}> */}
                <div className="lg:col-span-5 md:col-span-6">
                    <div className="lg:ms-5">
                        <div className="p-6">
                            {/* <h3 className="mb-6 text-2xl leading-normal font-semibold">About</h3> */}
                            <div>

                                {/* <div className='mb-4 ltr:text-left rtl:text-right'>
                                    <select id="Language" defaultValue={languagestep3} onChange={(e) => setLangStep3(e.target.value)} className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1">
                                        <option value="NY">English</option>
                                        <option value="MC">Hindi</option>
                                        <option value="SC">Tamil</option>
                                    </select>
                                </div> */}
                                <Grid item container>
                                    {/* <div className="grid grid-cols-1"> */}
                                    <Grid xs={12} md={5} >
                                        <label htmlFor="subject" className="font-semibold">Alias/Nickname</label>
                                        <input name="nicname" id="nicname" className="form-input mt-2" defaultValue={profileData?.about?.nic_name} />
                                    </Grid>
                                    <Grid xs={12} md={5} style={{ marginLeft: "10%" }} className='multistepInput'>
                                        <label htmlFor="subject" className="font-semibold">Highest Qualification</label>
                                        <input name="qualification" id="qualification" className="form-input mt-2" defaultValue={profileData?.about?.highest_qualification} />
                                    </Grid>
                                    <Grid xs={12} md={5} style={{ marginTop: "2%" }} >
                                        <label htmlFor="comments" className="font-semibold">Enter your Academic
                                            Certifications/
                                            Awards/Testimonials</label>
                                        <input name="academic_qualification" id="academic_qualification" className="form-input mt-2" defaultValue={profileData?.about?.academic_certification_award_testimonials} />

                                        {/* <input
                                                    type="text"
                                                    placeholder="Enter values separated by commas"
                                                    value={inputValue}
                                                    onChange={handleInputChange}
                                                    onKeyPress={handleInputKeyPress}
                                                    className="form-input mt-2"
                                                /> */}
                                    </Grid>
                                    <Grid xs={12} md={5} style={{ marginLeft: "10%", marginTop: "2%" }} className='multistepInput'>
                                        <label htmlFor="comments" className="font-semibold">Upload CV</label>
                                        <input name="cv" id="cv" type='file' style={{ border: "2px solid #f1f2f4", paddingRight: "15px" }} onChange={(e) => ImageUpload(e, 'cv')}></input>
                                    </Grid>
                                    {console.log(bio123, "bio123")}
                                    <Grid xs={12} md={12} style={{ marginTop: "2%" }} >
                                    {/* {truncate(
                              item.name?.replace(/(<([^>]+)>)/gi, ""),
                              20
                            )} */}
                                        <label htmlFor="comments" className="font-semibold">Bio</label>
                                        {/* <textarea name="bio" id="bio" type='file' className="form-input mt-2 textarea" defaultValue={profileData?.about?.bio}></textarea> */}
                                        <CKEditor
                                            // style={{ height: "120px" }}
                                            editor={ClassicEditor}
                                            data={data}
                                            config={{
                                                autoParagraph: false, // Disable automatic paragraph creation
                                            }}
                                            onReady={editor => {
                                                console.log('Editor is ready to use!', editor);
                                            }}
                                            onChange={(event, editor) => TextEditor(editor)}
                                            onBlur={(event, editor) => {
                                                console.log('Blur.', editor);
                                            }}
                                            onFocus={(event, editor) => {
                                                console.log('Focus.', editor);
                                            }}
                                        />
                                          <div className="mt-4">
                                        <div className="flex items-center w-full">
                                            <input className="form-checkbox text-emerald-600 rounded w-4 h-4 me-2 border border-inherit" type="checkbox" value="" id="AcceptTC" /> &nbsp;
                                            <label className="form-check-label text-slate-400" htmlFor="AcceptTC">Yes, I understand and agree to the Geek on Demand <a  href='/Terms' target='_blank' className="text-emerald-600">Terms of Service</a>, including the <a href='/Agreement' target="_blank" className="text-emerald-600">User Agreement</a> and <a href='/Privacy' target='_blank' className="text-emerald-600">Privacy Policy</a>.</label>
                                        </div>
                                    </div>
                                    </Grid>
                                    {/* </div> */}
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </Grid>
                    <Grid xs={12} md={12} lg={6}>

                    </Grid>
                </Grid> */}
            </div>
            :
            <div>

                {/* <Grid item container>
                    <Grid xs={12} md={12} lg={6}> */}
                <div className="lg:col-span-5 md:col-span-6">
                    <div className="lg:ms-5">
                        <div className="p-6">
                            <h3 className="mb-6 text-2xl leading-normal font-semibold">About</h3>
                            {/* <div> */}
                            {/* <div className='mb-4 ltr:text-left rtl:text-right'>
                                    <select id="Language" defaultValue={languagestep3} onChange={(e) => setLangStep3(e.target.value)} className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1">
                                        <option value="NY">English</option>
                                        <option value="MC">Hindi</option>
                                        <option value="SC">Tamil</option>
                                    </select>
                                </div> */}
                            <Grid item container>
                                {/* <div className="grid grid-cols-1"> */}
                                <Grid xs={12} md={5} >
                                    <label htmlFor="subject" className="font-semibold">Primary Contact Name</label>
                                    <input name="contactName" id="contactName" className="form-input mt-2" defaultValue={profileData?.about?.primary_contact_name} />
                                </Grid>
                                <Grid xs={12} md={5} style={{ marginLeft: "10%" }} className='multistepInput'>
                                    <label htmlFor="subject" className="font-semibold">Primary Contact No</label>
                                    <input name="contactNo" id="contactNo" className="form-input mt-2" defaultValue={profileData?.about?.primary_contact_number} />
                                </Grid>
                                <Grid xs={12} md={5} style={{ marginTop: "2%" }} >
                                    <label htmlFor="comments" className="font-semibold">Primary Contact Email Id</label>
                                    <input name="contactEmailId" id="contactEmailId" className="form-input mt-2" defaultValue={profileData?.about?.primary_contact_email_id} />
                                </Grid>
                                <Grid xs={12} md={5} style={{ marginLeft: "10%", marginTop: "2%" }} className='multistepInput'>
                                    <label htmlFor="comments" className="font-semibold">Upload Company Profile</label>
                                    <input name="companyProfile" id="companyProfile" type='file' onChange={(e) => ImageUpload(e, 'compProfile')} style={{ border: "2px solid #f1f2f4", paddingRight: "15px" }} />
                                </Grid>
                                <Grid xs={12} md={12} style={{ marginTop: "2%" }} >
                                    <label htmlFor="comments" className="font-semibold">Brief description about
                                        company and its
                                        offerings</label>
                                    {/* <textarea name="offerings" id="offerings" type='file' className="form-input mt-2 textarea" defaultValue={profileData?.about?.description_about_company_offering}></textarea> */}
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={data1}
                                        onReady={editor => {
                                            console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => TextEditor1(editor)}
                                        onBlur={(event, editor) => {
                                            console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            console.log('Focus.', editor);
                                        }}
                                    />
                                    <div className="mt-4">
                                        <div className="flex items-center w-full">
                                            <input className="form-checkbox text-emerald-600 rounded w-4 h-4 me-2 border border-inherit" type="checkbox" value="" id="AcceptTC" /> &nbsp;
                                            <label className="form-check-label text-slate-400" htmlFor="AcceptTC">Yes, I understand and agree to the Geek on Demand <a  href='/Terms' target='_blank' className="text-emerald-600">Terms of Service</a>, including the <a href='/Agreement' target="_blank" className="text-emerald-600">User Agreement</a> and <a href='/Privacy' target='_blank' className="text-emerald-600">Privacy Policy</a>.</label>
                                        </div>
                                    </div>
                                </Grid>

                                {/* </div> */}
                            </Grid>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
                {/* </Grid>
                    <Grid xs={12} md={12} lg={6}>

                    </Grid>
                </Grid> */}
            </div>
        }
        <div className="mt-5" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            {activeStep !== 0 && (
                <div >
                    <Link className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full" onClick={handleBack}>Back</Link>
                </div>
            )}
            <button type='submit' className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full" >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </button>
        </div>
    </form>
    // <form onSubmit={(e) => handleNext(e, "3")} >
    //     <div>
    //         <Grid item container>
    //             <Grid xs={12} md={12} lg={6}>
    //                 <div className="lg:col-span-5 md:col-span-6">
    //                     <div className="lg:ms-5">
    //                         <div className="p-6">
    //                             <h3 className="mb-6 text-2xl leading-normal font-semibold">Language</h3>
    //                             <div>
    //                                 {languages.map((language, index) => (
    //                                     <div key={index}>
    //                                         <label>Language:</label>
    //                                         <select
    //                                             value={language.language}
    //                                             onChange={(e) => handleLanguageChange(index, e.target.value)}
    //                                             className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1"
    //                                         >
    //                                             <option value="">Select a language</option>
    //                                             <option value="English">English</option>
    //                                             <option value="Spanish">Spanish</option>
    //                                         </select>
    //                                         <input
    //                                             type="checkbox"
    //                                             checked={language.reading}
    //                                             onChange={() => handleCheckboxChange(index, 'reading')}
    //                                         />
    //                                         <label>Reading</label>
    //                                         <input
    //                                             type="checkbox"
    //                                             checked={language.writing}
    //                                             onChange={() => handleCheckboxChange(index, 'writing')}
    //                                         />
    //                                         <label>Writing</label>
    //                                         <input
    //                                             type="checkbox"
    //                                             checked={language.listening}
    //                                             onChange={() => handleCheckboxChange(index, 'listening')}
    //                                         />
    //                                         <label>Listening</label>
    //                                         <button type='button' onClick={() => removeLanguage(index)}>Remove</button>
    //                                     </div>
    //                                 ))}
    //                                 <button type='button' onClick={() => addLanguage()}>Add Language</button>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </Grid>
    //             <Grid xs={12} md={12} lg={6}>
    //                 <div className="lg:col-span-5 md:col-span-6">
    //                     <div className="lg:ms-5">
    //                         <div className="p-6">
    //                             <h3 className="mb-6 text-2xl leading-normal font-semibold">Skills</h3>
    //                             <input
    //                                 type="text"
    //                                 placeholder="Enter values separated by commas"
    //                                 value={inputValue}
    //                                 onChange={handleInputChange}
    //                                 onKeyPress={handleInputKeyPress}
    //                                 className="form-input mt-2"
    //                             />
    //                         </div>
    //                         <div>
    //                             <h3>Values:</h3>
    //                             <ul>
    //                                 {values.map((value, index) => (
    //                                     <li key={index}>{value}</li>
    //                                 ))}
    //                             </ul>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </Grid>
    //         </Grid>
    //     </div>
    //     <div className="mt-4">
    //         <button type='submit' className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full" >
    //             {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
    //         </button>
    //     </div>
    // </form>
);
const Step4Form = ({ handleNext, activeStep, step4Data, steps, setServicestep4, servicestep4, handleBack }) => (
    <form onSubmit={(e) => handleNext(e, "4")}>
        <div>
            <Grid item container>
                <Grid xs={12} md={12} lg={6}>
                    <div className="lg:col-span-5 md:col-span-6">
                        <div className="lg:ms-5">
                            <div className="p-6">
                                <h3 className="mb-6 text-2xl leading-normal font-semibold">Service</h3>
                                <div className='mb-4 ltr:text-left rtl:text-right'>
                                    <select id="service" defaultValue={servicestep4} onChange={(e) => setServicestep4(e.target.value)} className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1">
                                        <option value="1">Full Time</option>
                                        <option value="2">Part Time</option>
                                        <option value="3">Freelancer</option>
                                        <option value="4">Remote Work</option>
                                        <option value="5">Office Work</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12} md={12} lg={6}>
                    <div className="lg:col-span-5 md:col-span-6">
                        <div className="lg:ms-5">
                            <div className="p-6">
                                <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                    <h3 className="mb-6 text-2xl leading-normal font-semibold">Salary</h3>
                                </div>
                                <div className="md:col-span-3 col-span-12 ltr:text-left rtl:text-right">
                                    <label className="font-semibold">Hourly rate</label>
                                    <div className="relative">
                                        <span className="w-10 h-10 bg-slate-50 dark:bg-slate-800 border border-slate-100 dark:border-slate-800 absolute top-0 start-0 overflow-hidden rounded">
                                            <DollarSign className="w-4 h-4 absolute top-3 start-3"></DollarSign>
                                        </span>
                                        <input type="number" id="hourRate" defaultValue={step4Data?.hourRate?.value} className="form-input border border-slate-100 dark:border-slate-800 ps-12" name="minsalary" />
                                    </div>
                                </div>

                                <div className="md:col-span-3 col-span-12 ltr:text-left rtl:text-right">
                                    <label className="font-semibold ">Service fees</label>
                                    <div className="relative mt-1">
                                        <span className="w-10 h-10 bg-slate-50 dark:bg-slate-800 border border-slate-100 dark:border-slate-800 absolute top-0 start-0 overflow-hidden rounded">
                                            <DollarSign className="w-4 h-4 absolute top-3 start-3"></DollarSign>
                                        </span>
                                        <input type="number" id="ServiceFee" defaultValue={step4Data?.ServiceFee?.value} className="form-input border border-slate-100 dark:border-slate-800 ps-12" name="maxsalary" />
                                    </div>
                                </div>
                                <div className="md:col-span-3 col-span-12 ltr:text-left rtl:text-right">
                                    <label className="font-semibold ">You will get</label>
                                    <div className="relative mt-1">
                                        <span className="w-10 h-10 bg-slate-50 dark:bg-slate-800 border border-slate-100 dark:border-slate-800 absolute top-0 start-0 overflow-hidden rounded">
                                            <DollarSign className="w-4 h-4 absolute top-3 start-3"></DollarSign>
                                        </span>
                                        <input type="number" id="youwillget" defaultValue={step4Data?.youwillget?.value} className="form-input border border-slate-100 dark:border-slate-800 ps-12" name="maxsalary" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
        <div className="mt-5" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            {activeStep !== 0 && (
                <div className="mt-4">
                    <Link className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full" onClick={handleBack}>Back</Link>
                </div>
            )}
            <button type='submit' className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full" >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </button>
        </div>
    </form>

);
const Step5Form = ({ handleNext, activeStep, steps, step4Data, setServicestep4, servicestep4, handleBack, ImageUpload }) => (
    <div>
        <div className="p-6">
            <h5 className="text-lg font-semibold mb-4">Personal Detail :</h5>
            <form onSubmit={(e) => handleNext(e, '5')}>
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                    <div>
                        <label className="form-label font-medium">First Name : <span className="text-red-600">*</span></label>
                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="First Name:" id="firstname" name="name" required="" />
                    </div>
                    <div>
                        <label className="form-label font-medium">Last Name : <span className="text-red-600">*</span></label>
                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="Last Name:" id="lastname" name="name" required="" />
                    </div>
                    <div>
                        <label className="form-label font-medium">Your Email : <span className="text-red-600">*</span></label>
                        <input type="email" className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="Email" name="email" required="" />
                    </div>
                    <div>
                        <label className="form-label font-medium">State : <span className="text-red-600">*</span></label>
                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2" name="state" id="state" required="" />
                    </div>
                    <div>
                        <label className="form-label font-medium">City : <span className="text-red-600">*</span></label>
                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2" name="city" id="city" required="" />
                    </div>
                    <div>
                        <label className="form-label font-medium">Date of birth : <span className="text-red-600">*</span></label>
                        <input type="date" className="form-input border border-slate-100 dark:border-slate-800 mt-2" name="dob" id="dob" required="" />
                    </div>
                    <div>
                        <label className="form-label font-medium">Pin code : <span className="text-red-600">*</span></label>
                        <input type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-2" name="pincode" id="pincode" required="" />
                    </div>
                    <div>
                        <label className="form-label font-medium" htmlFor="multiple_files">Upload Picture:</label>
                        <input className="relative form-input border border-slate-100 dark:border-slate-800 file:h-10 file:-mx-3 file:-my-2 file:cursor-pointer file:rounded-none file:border-0 file:px-3 file:text-neutral-700 bg-clip-padding px-3 py-1.5 file:me-3 mt-2" id="multiple_files" type="file" onChange={ImageUpload} />
                    </div>
                    <div>
                        <label className="form-label font-medium">Address : </label>
                        <textarea name="comments" id="address" className="form-input border border-slate-100 dark:border-slate-800 mt-2 textarea" placeholder="Enter here.. :"></textarea>
                    </div>
                </div>
                <div className="mt-5" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {activeStep !== 0 && (
                        <div className="mt-4">
                            <Link className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full" onClick={handleBack}>Back</Link>
                        </div>
                    )}
                    <button type='submit' className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full" >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </button>
                </div>
                {/* <input type="submit" id="submit" name="send" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5" value="Save Changes"/> */}
            </form>
        </div>
    </div>
);

const Step6Form = ({ handleNext, activeStep, step1Data, changeGeek, type, steps }) => (
    <div >
        <Grid item container>
            <Grid xs={12} md={12} lg={6}>
                <div className="lg:col-span-5 md:col-span-3">
                    <div className="lg:ms-5">
                        <div className="p-6">
                            <h3 className="mb-6 text-2xl leading-normal font-semibold">Last Step</h3>
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid xs={12} md={12} lg={6}></Grid>
        </Grid>
    </div>
);
export default MultiStepForm;
