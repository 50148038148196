import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import JobDetailComp from "../components/job-detail-comp";
import Footer from "../components/Footer";
import { Link, useParams, useLocation } from "react-router-dom";
import {
  Book,
  Briefcase,
  Clock,
  DollarSign,
  MapPin,
  Monitor,
  UserCheck,
} from "react-feather";
import { MdOutlineArrowForward } from "react-icons/md";
import ExploreJob from "../components/Explore-job";
import { jobData } from "../data/data";
import axios from "axios";
import { useSelector } from "react-redux";
import { Button, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import team1 from "../assets/images/team/01.jpg";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import closeButton from "../assets/images/closeButton.png";
import "react-image-lightbox/style.css";
import { ImageExtended } from "react-grid-gallery";
import { images } from "../components/Images";
import { initLightboxJS } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";


export default function JobListProfile() {
  const params = useParams();
  const id = params.id;
  const data = jobData.find((jobs) => jobs.id === parseInt(id));
  const { currentUser } = useSelector((state) => state.auth);

  const [jobDetailes, setJobDetailes] = useState({});
  console.log(jobDetailes, "ghsdfjdgjfh");
  const [productImage, setProductImage] = useState("");
  console.log(productImage, "asdasdasd");

  const JObDetailes = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/job/postview/${id}`)
      .then((res) => {
        console.log(res.data, "response");
        setJobDetailes(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    JObDetailes();
  }, []);

  const jobApply = () => {
    if (!currentUser?.token) {
      toast.warn("Please Login to Apply", {
        position: "top-right",
      });
    } else if (currentUser?.data?.type === "seeker") {
      toast.error("To apply please login as Geeks", {
        position: "top-right",
      });
    } else {
      const payload = {
        job_uuid: id,
        image: [productImage],
      };
      axios
        .post(`${process.env.REACT_APP_API_BASEURL}/job/apply`, payload, {
          headers: {
            "x-auth-token": currentUser?.token,
          },
        })
        .then((res) => {
          toast.success("Job applied", {
            position: "top-right",
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const steps = ["Skills", "Service", "Image"];
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const checkapplyornot = (array) => {
    const data = array?.includes(currentUser?.data?.uuid);
    console.log(data, "datadata");
    return data;
  };

  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  // big image

  useEffect(() => {
    initLightboxJS("Insert your License Key here", "Insert plan type here");
  }, []);

  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);


  

  const [isFullImageOpen, setIsFullImageOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openFullImage = (index) => {
    setSelectedImageIndex(index);
    setIsFullImageOpen(true);
  };

  const closeFullImage = () => {
    setIsFullImageOpen(false);
    setSelectedImageIndex(null);
  };

  const showPreviousImage = () => {
    if (selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };

  const showNextImage = () => {
    if (selectedImageIndex < jobDetailes.image.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };

  const FullImageDisplay = ({ imageUrl, onClose }) => {
    return (
      <div className="full-image-display" >
        <div className="overlay"></div>
        <div  style={{ display:"flex" ,justifyContent:"center",alignItems:"flex-start" }}>
<div >
<img 
        // src={`https://api.god.scienstechnologies.com/user/images/${imageUrl}`}
         alt="Full Size" className="full-image" 
        
        src={`https://api.god.scienstechnologies.com/user/images/${imageUrl}`}
        // alt={`Image ${index + 1}`}
        // onClick={() => openFullImage(index)}
        // className="thumbnail"
        crossOrigin="anonymous"
        />
        </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div >
<img src={closeButton} alt="closeButton" style={{ width:"40px" ,height:"40px" ,cursor:"pointer"}} onClick={onClose}/>
</div>
        </div>
        
      </div>
    );
  };



  const handleKeyboardNavigation = (event) => {
    if (isFullImageOpen) {
      switch (event.key) {
        case 'ArrowLeft':
          showPreviousImage();
          break;
        case 'ArrowRight':
          showNextImage();
          break;
        case 'Escape':
          closeFullImage();
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardNavigation);
    return () => {
      document.removeEventListener('keydown', handleKeyboardNavigation);
    };
  }, [isFullImageOpen, selectedImageIndex]);

  return (
    <>
      <Navbar navClass="justify-end nav-light" />
      <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-emerald-900/90"></div>
        <div className="container">
          <div className="grid grid-cols-1 text-center mt-10">
            {/* {data?.heading ? <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">{data?.heading}</h3> : <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Full Stack Developer</h3>} */}
            <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">
              {jobDetailes?.title}
            </h3>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
            <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/">Home</Link>
            </li>
            <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/AppliedJobs">My Jobs</Link>
            </li>
            <li
              className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Job View
            </li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="relative mt-12">
        <div className="container md:pb-24 pb-16 ">
          <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
            {/* <div className="lg:col-span-6 md:col-span-7"></div> */}
            <div className="lg:col-span-12 md:col-span-12">
              {/* <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption"></Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}

            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box> */}

              <h5 className="text-lg font-semibold mt-5">
                {/* {details?.full_name} */}
                {jobDetailes?.title}
              </h5>
              <p className="text-slate-400 mt-4" onClick={toggleText}>
                {showFullText
                  ? jobDetailes?.description?.replace(/(<([^>]+)>)/gi, "")
                  : jobDetailes?.description
                      ?.replace(/(<([^>]+)>)/gi, "")
                      .slice(0, 400)}{" "}
                <br />
                <span style={{ color: "#1daa82", cursor: "pointer" }}>
                  {showFullText ? "Show Less" : "Show More"}
                </span>
              </p>

              <h4 className="mt-6 text-xl font-semibold">Skills</h4>
              <Grid item container className="mt-5 gap-2">
                <Grid xs={12} md={12}>
                  {jobDetailes?.skills?.map((skills) => {
                    return (
                      <>
                        <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full">
                          {skills?.label ? skills?.label : skills}
                        </div>
                        &nbsp;&nbsp;
                      </>
                    );
                  })}
                  {/* <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full" >
                    CSS
                  </div>&nbsp;&nbsp;
                  <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full"  >
                    Bootstrap
                  </div>&nbsp;&nbsp;
                  <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full"  >
                    Javascript
                  </div>&nbsp;&nbsp;
                  <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full" >
                    React JS
                  </div> */}
                </Grid>
              </Grid>
              <h4 className="mt-6 text-xl font-semibold">Services</h4>
              <Grid item container className="mt-5 gap-3">
                <Grid xs={12} md={12} className="gap-3">
                  {jobDetailes?.services?.map((service) => {
                    return (
                      <>
                        <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full mt-2">
                          {service?.name ? service?.name : service} &nbsp;&nbsp;
                        </div>
                        &nbsp;&nbsp;
                      </>
                    );
                  })}

                  {/* <div  className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full"  >
                                Laptop service
                                </div>&nbsp;&nbsp;
                                <div  className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full" >
                                Tablet service
                                </div>&nbsp;&nbsp;
                                <div  className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full"  >
                                Mobile service
                                </div>&nbsp;&nbsp;
                                <div  className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full mt-2" >
                                TV service
                                </div> */}
                </Grid>
              </Grid>

              <h4 className="mt-6 text-xl font-semibold">Languages</h4>
              <Grid item container className="mt-5 gap-2">
                <Grid xs={12} md={12}>
                  {jobDetailes?.languages?.map((languages) => {
                    return (
                      <>
                        <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full">
                          {languages?.label ? languages?.label : languages}
                        </div>
                        &nbsp;&nbsp;
                      </>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item container className="mt-5">
                <Grid
                  xs={12}
                  md={12}
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <div>
                    <input
                      type="button"
                      className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white searchbtn submit-btn w-100 cursor-pointer"
                      disabled={checkapplyornot(jobDetailes?.applied_user_uuid)}
                      value={
                        checkapplyornot(jobDetailes?.applied_user_uuid) === true
                          ? "Applied"
                          : "Apply"
                      }
                      onClick={() => jobApply(jobDetailes.uuid)}
                    />
                  </div>
                </Grid>
              </Grid>
              {/* <Grid item container className='mt-5'>
                <Grid xs={12} md={4}>
                  <img src={team1} alt='team1' />
                </Grid>
              </Grid> */}
              <Grid item container className="mt-5">
                <Grid xs={12} md={12}>
                  <h4 className="mt-6 text-xl font-semibold mb-4">Images</h4>
                  <div>
                    <div>
                      <div className="image-container">
                        {jobDetailes?.image?.slice(0, 4).map((imageUrl, index) => (
                          <img
                            key={index}
                            src={`https://api.god.scienstechnologies.com/user/images/${imageUrl}`}
                            alt={`Image ${index + 1}`}
                            onClick={() => openFullImage(index)}
                            className="thumbnail"
                            crossOrigin="anonymous"
                          />
                        ))}
                      </div>

                      {isFullImageOpen && (
                        <FullImageDisplay
                          imageUrl={jobDetailes.image[selectedImageIndex]}
                          onClose={closeFullImage}
                        />
                      )}

                      {/* {isFullImageOpen && selectedImageIndex > 0 && (
                        <button
                          className="buttonImagepre"
                          onClick={showPreviousImage}
                        >
                          Previous
                        </button>
                      )}

                      {isFullImageOpen &&
                        selectedImageIndex < jobDetailes?.image.length - 1 && (
                          <button
                            className="buttonImageNext"
                            onClick={showNextImage}
                          >
                            Next
                          </button>
                        )} */}
                    </div>
                    {/* <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
        className="GalleryImage"
      />
      {!!currentImage && (
      
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )} */}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
