import React from 'react'
import { HelpCircle } from 'react-feather'


export default function QuesAnswer() {
    const QuesAnswerData = [
        {
            question: 'Engage Directly With Experts',
            answer: 'Looking to hire the best tech talent for your projects? At GeekOnDemand, we are more than just a platform. We are a community of passionate tech experts ready to bring your vision to life. Connect directly with professionals who understand your needs, delivering quality, on-time, and within budget. Whether it is a one-off task or long-term project, your perfect match is waiting. ',
        },
        {
            question: 'Cost Effective Solutions ',
            answer: 'Unlock the door to budget-friendly tech solutions with GeekOnDemand. Our handpicked freelancers deliver high-quality work without breaking the bank.',
        },
        {
            question: 'Ready to transform your ideas into reality ?',
            answer: 'Let is start your tech journey today. Quality, affordability, and expertise are just a click away!',
        },
        {
            question: 'Streamlined Project Management',
            answer: "At GeekOnDemand, we've simplified project management to its finest. Our platform seamlessly connects you with top-tier tech freelancers and streamlines the whole project execution process. It's smooth, efficient, and designed to make your tech projects a dream to manage!",
        },
        {
            question: 'Improved Budget Allocation ',
            answer: "With GeekOnDemand, you'll enjoy an improved budget allocation like never before. No more paying for tech services you don't need. Instead, our platform allows you to handpick the specific tech talent you require, on a per-project basis. This way, you only pay for the expertise you need, when you need it.",
        },
        {
            question: 'Support ',
            answer: "At GeekOnDemand, we're not just about pairing you with top-tier tech talent. We're also committed to providing unrivalled support every step of the way. Have a query or hit a roadblock? Our team is ready to lend a hand",
        }
    ]
    return (
        <div className="container md:mt-24 mt-16">
            <div className="grid grid-cols-1 pb-8 text-center">
                <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">Why Choose GeekOnDemand?</h3>

                <p className="text-slate-400 max-w-xl mx-auto">At GeekOnDemand, we are the tech superheroes you've been searching for. With our extensive network of skilled freelancers, your tech issues don't stand a chance.</p>
            </div>


            <div className="grid md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                {QuesAnswerData.map((item, index) => (
                    <div className="flex" key={index}>
                        <HelpCircle className="h-8 text-emerald-600 me-3" ></HelpCircle>
                        <div className="flex-1">
                            <h5 className="mb-2 text-lg font-semibold">{item.question}<span className="text-emerald-600"></span> </h5>
                            <p className="text-slate-400">{item.answer}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

