import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Geek from '../assets/images/blog/Geek.jpg';
import Footer from '../components/Footer';
import { Link } from "react-router-dom";
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import {MdKeyboardArrowLeft, MdKeyboardArrowRight, TbBrandHipchat, AiOutlineStar, MdOutlineArrowForward} from "../assets/icons/vander"


export default function GeekGet() {
    const [isOpen, setOpen] = useState(false)

    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Geek Get</h3>

                    </div>
                </div>

                {/* <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Home</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">About Us</li>
                    </ul>
                </div> */}
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            {/* <AboutusCard/> */}
            <section className="relative md:py-24 py-16">
            <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7 md:col-span-6">
                            <img src={Geek} alt="" />
                        </div>

                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="lg:ms-5">
                              
                                    <h3 className="mb-6 text-2xl leading-normal font-semibold">What does the Geek get?</h3>

                                    <p className="text-slate-400 max-w-xl mx-auto">As a Geek (IT professional or freelancer), don’t you want better jobs, more tasks? At Geek on Demand, you can sign up today and get ready to receive high-quality IT jobs.</p>
                                    <br/>
                                    <p className="text-slate-400 max-w-xl mx-auto">Geek on Demand has been customized as per the needs of a Geek as well. Simply offer us some details about your experience and get ready to receive many opportunities.</p>
                                    <br/>

                                    <Link to="#" className="hover:text-emerald-600 font-medium transition-all duration-500 items-center inline-flex text-slate-400"><MdOutlineArrowForward className="mr-3"/> Better pay </Link><br/>
                                    <Link to="#" className="hover:text-emerald-600 font-medium transition-all duration-500 items-center inline-flex text-slate-400"><MdOutlineArrowForward className="mr-3"/> More opportunities </Link><br/>
                                    <Link to="#" className="hover:text-emerald-600 font-medium transition-all duration-500 items-center inline-flex text-slate-400"><MdOutlineArrowForward className="mr-3"/> Hassle-free working </Link><br/>
                                    <Link to="#" className="hover:text-emerald-600 font-medium transition-all duration-500 items-center inline-flex text-slate-400"><MdOutlineArrowForward className="mr-3"/> CQuick conversions </Link><br/>
                                    <Link to="#" className="hover:text-emerald-600 font-medium transition-all duration-500 items-center inline-flex text-slate-400"><MdOutlineArrowForward className="mr-3"/> Reliability </Link>
                                    
                            
                            </div>
                        </div>
                    </div>
                </div>

             
            </section>
            <Footer />
            {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="S_CGed6E610" onClose={() => setOpen(false)} /> */}

        </>
    )
}