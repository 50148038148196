import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { allJobsList } from '../Redux/geeks/jobSlice'
import axios from "axios";
import { Bookmark } from 'react-feather';
import { LuMapPin, MdKeyboardArrowLeft, MdKeyboardArrowRight, FiArrowUpRight, MdOutlineArrowForward } from "../assets/icons/vander"
import { jobData } from '../data/data';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import JobList from './JobList';

// import {PiMapPin, MdOutlineArrowForward, FiArrowUpRight} from "../assets/icons/vander"


export default function JobGridsFourComp() {

  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const { jobsList } = useSelector((state) => state.alljobs);

  const { currentUser } = useSelector((state) => state.auth);

  console.log(currentUser, "currentUser");

  console.log(jobsList, "jobsList");

  useEffect(() => {
    dispatch(allJobsList(page, 4, "", currentUser?.token));
  }, []);

  const skilledArray = (data) => {
    const emptyArray = [];
    data?.map((item) => {
      // emptyArray.push({value : item, label : item})
      return (
        <span className="bg-orange-500/5 hover:bg-orange-500/20 dark:bg-orange-500/10 hover:dark:bg-orange-500/30 inline-block text-orange-500 px-4 text-[14px] font-medium rounded-full mt-2 me-1 transition-all duration-500">
          {item}
        </span>
      );
    });
  };
  // Function to calculate how many hours or days ago a date came
  function calculateTimeAgo(targetDate) {
    // Get the current date and time
    const currentDate = new Date();

    // Convert the target date string to a Date object
    const targetDateObj = new Date(targetDate);

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - targetDateObj;

    if (timeDifference < 0) {
      // The target date is in the future
      const daysAgo = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      return `${daysAgo} days in the future`;
    } else if (currentDate?.toDateString() === targetDateObj?.toDateString()) {
      // The target date is today
      const hoursAgo = Math.floor(timeDifference / (1000 * 60 * 60));
      return `${hoursAgo} hours ago`;
    } else if (timeDifference < 24 * 60 * 60 * 1000) {
      // The target date is exactly one day ago
      return "1 day ago";
    } else {
      // The target date is in the past, more than one day ago
      const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      return `${daysAgo} days ago`;
    }
  }

  const jobApply = (jobId) => {
    if (!currentUser?.token) {
      toast.warn("Please Login to Apply", {
        position: "top-right",
      })
    } else if (currentUser?.data?.type === "seeker") {
      toast.error("To Apply Please Login as Geeks", {
        position:"top-right"
      })
    } else {
      const payload = {
        job_uuid: jobId,
      };
      axios
        .post(`${process.env.REACT_APP_API_BASEURL}/job/apply`, payload, {
          headers: {
            "x-auth-token": currentUser?.token,
          },
        })
        .then((res) => {
          toast.success("Job Applied !", {
            position: "top-right",
          })
          dispatch(allJobsList(page, 4, "", currentUser?.token));
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }

  const checkapplyornot = (array) => {
    const data = array.includes(currentUser?.data?.uuid);
    console.log(data, "datadata");
    return data;
  };

  const checkLogin = (url, uuid) => {
    if (currentUser?.token) {
      navigate(`/${url}/${uuid}`)
    }
    else{
      toast.warn("Please Login to Apply", {
        position: "top-right",
      })
    }
  }


  return (
    <>
      <div className="container mt-10">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">Popular Jobs</h3>

          <p className="text-slate-400 max-w-xl mx-auto">Search all the open positions on the web. Get your own personalized salary estimate. Read reviews on over 30000+ companies worldwide.</p>
        </div>
        <div className="grid lg:grid-cols-1 md:grid-cols-1 gap-[30px]">
          {jobsList?.length > 0 &&
            jobsList?.map((item, index) => (
              <div
                className="group p-6 rounded-lg border"
                key={index}
              >
                <div className="flex justify-between items-start">
                  <div>
                    {/* <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-full mb-2">
                                        <img src={item.image} className="h-8 w-8" alt="" />
                                    </div> */}
                    {/* <Link to={`/employer-detail/${item.id}`} className="text-lg hover:text-emerald-600 font-semibold transition-all duration-500">{item.title}</Link> */}
                  </div>

                  <div className="flex items-center">
                    <input
                      type="button"
                      className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white searchbtn submit-btn w-100 cursor-pointer"
                      disabled={checkapplyornot(item?.applied_user_uuid)}
                      value={
                        checkapplyornot(item?.applied_user_uuid) === true
                          ? "Applied"
                          : "Apply"
                      }
                      onClick={() => jobApply(item.uuid)}
                    />
                    {/* <Link to="#" className="text-slate-100 dark:text-slate-700 focus:text-red-600 dark:focus:text-red-600 hover:text-red-600 dark:hover:text-red-600 text-2xl"><i className="mdi mdi-heart"></i></Link>
                                    <Link to="#" className="btn btn-icon rounded-full bg-emerald-600/5 hover:bg-emerald-600 border-emerald-600/10 hover:border-emerald-600 text-emerald-600 hover:text-white ms-1"><Bookmark className="h-4 w-4"></Bookmark></Link>
                                    <Link to="/job-apply" className="btn btn-icon rounded-full bg-emerald-600/5 group-hover:bg-emerald-600 border-emerald-600/10 text-emerald-600 group-hover:text-white ms-1"><FiArrowUpRight/></Link> */}
                  </div>
                </div>

                <div className="mt-1">
                  {/* <Link to={`/JobListProfile`} className="text-xl hover:text-emerald-600 font-semibold transition-all duration-500">asdsda</Link><br/> */}
                  <Link
                    // to={`/JobListProfile/${item.uuid}`}
                    className="text-xl hover:text-emerald-600 font-semibold transition-all duration-500"
                    disabled={checkapplyornot(item?.applied_user_uuid)}
                    value={
                      checkapplyornot(item?.applied_user_uuid) === true
                        ? "Applied"
                        : "Apply"
                    }
                    onClick={() => jobApply(item.uuid)}
                  >
                    {item.title}
                  </Link>
                  <div className="mt-5">
                    <small class="text-muted display-inline-block text-muted">
                      <span>
                        Posted &nbsp;
                        <span data-test="posted-on">
                          {calculateTimeAgo(item.createdAt)}
                        </span>
                      </span>
                    </small>
                  </div>
                  <p className="text-slate-400 mt-2">
                    {" "}
                    {truncate(
                      item.description?.replace(/(<([^>]+)>)/gi, ""),
                      400
                    )}
                  </p>
                  <div className="mt-3">
                    {item?.skills?.map((items) => {
                      return (
                        <span className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full mt-2 me-1">
                          {items?.label ? items?.label : items}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            ))}
          {/* {jobData.map((item, index) => (
                        <div className="group p-6 rounded-lg border border-emerald-600/20 dark:border-emerald-600/40 bg-white dark:bg-slate-900 hover:bg-emerald-600/[0.02] hover:dark:bg-emerald-600/5 hover:shadow-md hover:shadow-emerald-600/5 transition-all duration-500" key={index} >
                            <div className="flex justify-between items-start">
                                <div>
                                    <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-full mb-2">
                                        <img src={item.image} className="h-8 w-8" alt="" />
                                    </div>
                                    <Link to={`/employer-detail/${item.id}`} className="text-lg hover:text-emerald-600 font-semibold transition-all duration-500">{item.title}</Link>
                                       <Link 
                                       to={`/job-detail-one/${item.id}`} 
                                       className="text-xl hover:text-emerald-600 font-semibold transition-all duration-500">{item.heading}</Link>
                                <p className="text-slate-400 mt-2">{item.headline}</p>
                                </div>

                                <div className="flex items-center">
                                   
                                    <Link to="/job-apply" className="btn btn-icon rounded-full bg-emerald-600/5 group-hover:bg-emerald-600 border-emerald-600/10 text-emerald-600 group-hover:text-white ms-1"><FiArrowUpRight/></Link>
                                </div>
                            </div>

                         
                             

                                <div className="mt-3">
                                
                                    <Link to="#">
                                        <span className="bg-purple-600/5 hover:bg-purple-600/20 dark:bg-purple-600/10 hover:dark:bg-purple-600/30 inline-block text-purple-600 px-4 text-[14px] font-medium rounded-full mt-2 me-1 transition-all duration-500">{item.price}</span>
                                    </Link>
                                    <Link to="#">
                                        <span className="bg-emerald-600/5 hover:bg-emerald-600/20 dark:bg-emerald-600/10 hover:dark:bg-emerald-600/30 inline-flex items-center text-emerald-600 px-4 text-[14px] font-medium rounded-full mt-2 transition-all duration-500"> {item.Location}</span>
                                    </Link>
                                </div>
                         
                        </div>
                    ))} */}

        </div>
        {/* <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                    <div className="md:col-span-12 text-center">
                        <Link to="/JobList" className="btn btn-link text-slate-400 hover:text-emerald-600 after:bg-emerald-600 duration-500 ease-in-out inline-flex items-center">See More Jobs <MdOutlineArrowForward className="ms-1"/></Link>
                    </div>
                </div> */}

        {/* <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                    <div className="md:col-span-12 text-center">
                        <nav aria-label="Page navigation example">
                            <ul className="inline-flex items-center -space-x-px">
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">
                                        <MdKeyboardArrowLeft className="text-[20px] rtl:rotate-180 rtl:-mt-1"/>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">1</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">2</Link>
                                </li>
                                <li>
                                    <Link to="#" aria-current="page" className="z-10 w-[40px] h-[40px] inline-flex justify-center items-center text-white bg-emerald-600 border border-emerald-600">3</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">4</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">5</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">
                                        <MdKeyboardArrowRight className="text-[20px] rtl:rotate-180 rtl:-mt-1"/>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div> */}
      </div>
    </>
  )
}
