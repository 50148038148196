import React, { useState } from 'react';
import Navbar from '../components/Navbar'
import { Stepper, Step, StepLabel, Button, Typography, Container, Paper, Grid, TextField } from '@mui/material';
import { Link } from "react-router-dom";
import { DollarSign } from 'react-feather';
import './MultiStepForm.css'
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const SeekerProfileForm = () => {

    const optionsOne = [
        { value: 'male', label: 'male' },
        { value: 'female', label: 'female' }
    ]

    const navigate = useNavigate()

    const { currentUser, toastData } = useSelector((state) => state.auth);
    const [image, setImage] = useState('')
    const [data, setData] = useState({})

    useEffect(() => {
        getProfile();
    }, [])

    const getProfile = () => {
        axios.get(`${process.env.REACT_APP_API_BASEURL}/user/seeker/profile/${currentUser?.data?.uuid}`, {
            headers: {
                'x-auth-token': currentUser?.token
            }
        })
            .then((res) => {
                console.log(res?.data?.data, "res")
                setData(res?.data?.data)
            })
            .catch((err) => {

            })
    }

    const ImageUpload = (event) => {
        const data1 = event.target.files[0]
        const formData = new FormData();
        formData.append('file', data1);
        axios.post(`${process.env.REACT_APP_API_BASEURL}/upload/image`, formData)
            .then((res) => {
                // console.log(res.data.image.filename)
                setImage(res.data.image.filename);
            })
            .catch((err) => {
                console.log(err.response)
                setImage('');
            })
    }

    const handleSubmitProfile = (e) => {
        e.preventDefault();
        const data = e.target.elements
        console.log(data, e, "data")
        const payLoad = {
            "gender": data.gender.value,
            "dob": data.dob.value,
            "address": data.address.value,
            "city": data.city.value,
            "state": data.state.value,
            "pincode": data.pincode.value,
            "adhar_number": data.adhar_number.value,
            "pan_number": data.pan_number.value,
            "profile_pic": image
        }
        axios.put(`${process.env.REACT_APP_API_BASEURL}/user/seeker/profile/update/${currentUser?.data?.uuid}`, payLoad, {
            headers: {
                "x-auth-token": currentUser?.token
            }
        })
            .then((res) => {
                toast.success("Profile Updated" , {
                    position:"top-right"
                })
                navigate('/')
            })
            .catch((err) => {
                toast.error(err?.response?.data)
            })
    }
    return (
        < div>
            <Navbar navClass='justify-end nav-dark' />
            <Container>
                <Paper style={{ padding: '20px', marginLeft: "50px", marginRight: "50px", marginTop: "130px" }} className='MultiStepForm'>
                    <div >
                        <Grid item container>
                            <Grid xs={12} md={12} lg={6}>
                                <div className="lg:col-span-5 md:col-span-3">
                                    <div className="lg:ms-5">
                                        <div className="p-6">
                                            <h3 className="mb-6 text-2xl leading-normal font-semibold">Seeker Profile</h3>
                                            <form onSubmit={handleSubmitProfile}>
                                                <div className="grid lg:grid-cols-6 lg:gap-12">
                                                    <div className="lg:col-span-3 mb-5">
                                                        <label htmlFor="comments" className="font-semibold">Gender</label>
                                                        {/* <input name="email" id="role" type="text" className="form-input mt-2" /> */}
                                                        <Select className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-2-red" name="gender" options={optionsOne} value={{ value: data?.seekers_details?.gender, label: data?.seekers_details?.gender }} defaultValue={{ value: data?.seekers_details?.gender, label: data?.seekers_details?.gender }} />
                                                    </div>
                                                    <div className="lg:col-span-3 mb-5">
                                                        <label htmlFor="comments" className="font-semibold">Date of Birth</label>
                                                        <input name="dob" id="role" type="date" className="form-input mt-2" defaultValue={data?.seekers_details?.dob ? data?.seekers_details?.dob : ''} />
                                                    </div>
                                                    <div className="lg:col-span-3 mb-5">
                                                        <label htmlFor="comments" className="font-semibold">Address</label>
                                                        <input name="address" id="role" type="text" className="form-input mt-2" defaultValue={data?.seekers_details?.address ? data?.seekers_details?.address : ''} />
                                                    </div>
                                                    <div className="lg:col-span-3 mb-5">
                                                        <label htmlFor="comments" className="font-semibold">City</label>
                                                        <input name="city" id="role" type="text" className="form-input mt-2" defaultValue={data?.seekers_details?.city ? data?.seekers_details?.city : ''} />
                                                    </div>
                                                    <div className="lg:col-span-3 mb-5">
                                                        <label htmlFor="comments" className="font-semibold">State</label>
                                                        <input name="state" id="role" type="text" className="form-input mt-2" defaultValue={data?.seekers_details?.state ? data?.seekers_details?.state : ''} />
                                                    </div>
                                                    <div className="lg:col-span-3 mb-5">
                                                        <label htmlFor="comments" className="font-semibold">Pincode</label>
                                                        <input name="pincode" id="role" type="text" className="form-input mt-2" defaultValue={data?.seekers_details?.pincode ? data?.seekers_details?.pincode : ''} />
                                                    </div>
                                                    <div className="lg:col-span-3 mb-5">
                                                        <label htmlFor="comments" className="font-semibold">Adhar Number</label>
                                                        <input name="adhar_number" id="role" type="number" className="form-input mt-2" defaultValue={data?.seekers_details?.adhar_number ? data?.seekers_details?.adhar_number : ''} />
                                                    </div>
                                                    <div className="lg:col-span-3 mb-5">
                                                        <label htmlFor="comments" className="font-semibold">Pan Number</label>
                                                        <input name="pan_number" id="role" type="text" className="form-input mt-2" defaultValue={data?.seekers_details?.pan_number ? data?.seekers_details?.pan_number : ''} />
                                                    </div>
                                                    <div className="lg:col-span-3 mb-5">
                                                        <label htmlFor="comments" className="font-semibold">Profile Pic</label>
                                                        <input name="profile_pic" id="role" type="file" className="form-input mt-2" onChange={ImageUpload} />
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <button type='submit' className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full" >
                                                        SUBMIT
                                                    </button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </Grid>
                            <Grid xs={12} md={12} lg={6}></Grid>
                        </Grid>

                    </div>
                </Paper>
            </Container>
        </div>

    );
};
export default SeekerProfileForm;