import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import "./Form.css";
import Select from 'react-select'
import { servicesURL } from '../Redux/services/servicesSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function NewJobPost() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isOpen, setOpen] = useState(false);


  const [optionsOne, setSkillsList] = useState([])
  const [skill, setSkill] = useState('');
//   const [data, setData] = useState('');
//   const [data1, setData1] = useState('');
  const [servicesSkil, setServicesSkill] = useState('');
  const [compProfile, setCompProfile] = useState('');
  const [language, setLanguagesIndividual] = useState('');
  const [Images, setImages] = useState([])
  const [coverimage, setcoverImage] = useState('')
  const [cv, setcv] = useState('');
  const [profileData, setProfileData] = useState('')
  const dispatch = useDispatch();
  // const [servicesSkil, setServicesSkill] = useState('');
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('')
  const { servicesDropDown, services } = useSelector((state) => state.service);

  const languageDropDown = [
    {label : "English", value : "English"},
    {label : "Hindi", value : "Hindi"},
    {label : "Telugu", value : "Telugu"},

  ]


  const accordionData = [
    {
      title: "How does it work ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
    {
      title: "Do I need a designer to use Jobstack ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
    {
      title: "What do I need to do to start selling ?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    },
  ];

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const [data, setData] = useState("");
  const [data1, setData1] = useState("");

  const TextEditor = (editor) => {
    const data = editor.getData();
    setData(data);
  };

  // const jobpost = () => {

  //     const payload = {

  //             "seeker_uuid" : seeker_uuid,
  //             "title" : title,
  //             "description" : description,
  //             "languages" : languages,
  //             "skills" : skills,
  //             "services" : services

  //      }
  //     axios.post(`${process.env.REACT_APP_API_BASEURL}/jobpost/create`, payload, {
  //         headers : {
  //             "x-auth-token" : currentUser?.token
  //         }
  //     })
  //   }

  const [title, setTitle] = useState("");
  
  const [languages, setLanguages] = useState([]);
  console.log(languages, "sdfsfsdf");
  const [skills, setSkills] = useState([]);
//   const [services, setServices] = useState([]);

  const { currentUser } = useSelector((state) => state.auth);

  console.log(currentUser, "currentUser");

  const handleSubmitjobpost = (e) => {
    e.preventDefault();

    const serviceData = []
    servicesSkil?.length > 0 && servicesSkil?.map((item) => {
      serviceData.push(item?.value)
      return serviceData
    })

    const payload = {
      // "seeker_uuid": currentUser?.data.uuid,
      "title": title,
      "description": data,
      "languages": language,
      "skills": skills,
      "services": serviceData,
      "image" : Images
    };
    // jobpost/create
    axios.post(`${process.env.REACT_APP_API_BASEURL}/job/post`, payload, {
      headers: {
        "x-auth-token": currentUser?.token,
      },
    }).then((res) => {
      toast.success("Job posted" ,{
        position:"top-right"
      })
    })
    .catch((err) => {
      toast.error('Error')
    })
  };

  useEffect(() => {
    setServicesSkill(profileData?.geeks_details?.technical_expertise?.services)
    getSkillsList();
},[])


const getSkillsList = () => {
    axios.get(`${process.env.REACT_APP_API_BASEURL}/addondata/skills`)
        .then((res) => {
            console.log(res?.data?.data)
            setSkillsList(res?.data?.data)
        })
        .catch((err) => {
            console.log(err, "err")
        })
}

const changeScroll = (data, sarch) => {
    console.log(sarch,"data...")
    if(data === 'search'){
        dispatch(servicesURL(page, 10 , sarch))
    }
    else if(data === 'inc' && page >= 0){
        dispatch(servicesURL(page+1, 10 , search))
        setPage(page + 1)
    }
    else if (page >= 1){
        dispatch(servicesURL(page-1, 10, search))
        setPage(page - 1)
    }
}



const ImageUpload = (event, type) => {
  const data1 = event.target.files;
  const formData = new FormData();

  for (let i = 0; i < data1.length; i++) {
    formData.append("files", data1[i]);
  }

  axios
    .post(`${process.env.REACT_APP_API_BASEURL}/upload/multiImage`, formData)
    .then((res) => {
      console.log(res.data);
      setImages(res.data.image);
    })
    .catch((err) => {
      console.log(err.response);
      setImages('')
    });
};

// const [title, setTitle] = useState('');
const [description, setDescription] = useState('')



const handleNext = (e) => {
    e.preventDefault();
    var payload = {};
    payload = {
        // "title" : title,
        // "geeks_uuid" : uuid,
        // "description" : data,
        "skills" : skills,
        "services" : servicesSkil,
        "image" : Images
    }

    axios.post(`${process.env.REACT_APP_API_BASEURL}/jobhire`)

    console.log(payload, "payloadpayload")

}

  return (
    <>
      {/* <Navbar navClass="justify-end nav-light" />
      <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-emerald-900/90"></div>
        <div className="container">
          <div className="grid grid-cols-1 text-center mt-10">
            <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">
              Post New Jobs
            </h3>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
            <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/">Home</Link>
            </li>
            <li
              className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Post New Jobs
            </li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div> */}

      {/* <div className="container"> */}
        <div
          className="grid lg:grid-cols-10 md:grid-cols-5 mt-10 gap-[30px]"
          style={{ marginBottom: "20px" }}
        >
            {/* <div  style={{ textAlign:"center" }}>
            <p style={{fontSize:"25px",fontWeight:"bold"}}>Apply New Job Post</p>
            </div> */}
          <form className="ContainerForm" onSubmit={handleSubmitjobpost}>
            <div className="grid grid-cols-12 gap-2">
              <div className="md:col-span-6 col-span-12">
                <label htmlFor="name" className="font-semibold">
                  Title
                </label>
                <input
                  name="name"
                  id="name"
                  type="text"
                  className="form-input mt-2"
                  placeholder="Enter"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className="md:col-span-6 col-span-12">
                                            <label htmlFor="email" className="font-semibold">Skills</label>
                                            <Select className='mt-2' name='skills' value={skills?.length > 0 ? skills?.map((item) => {
                                            return { value: item?.value ? item?.value : item, label: item?.label ? item?.label : item }
                                        }) : []} onChange={setSkills} isMulti options={optionsOne} />
                                        </div>
            <div className="md:col-span-6 col-span-12">
                <label htmlFor="email" className="font-semibold">Service</label>
                <Select className='mt-2' name="services"  value={servicesSkil?.length > 0 ? servicesSkil?.map((item) => {
                return { value: item?.value ? item?.value : item, label: item?.label ? item?.label : item }
            }) : []} onChange={setServicesSkill} isMulti options={servicesDropDown} onMenuScrollToTop={(event) => changeScroll("dec")} onMenuScrollToBottom={(event) => changeScroll("inc")} onInputChange={(event) => changeScroll("search", event)} />
            </div>
              <div className="md:col-span-6 col-span-12">
                <label htmlFor="text" className="font-semibold">
                  Languages
                </label>
                
                <Select className='mt-2' name="services" onChange={setLanguagesIndividual} isMulti options={languageDropDown} onInputChange={(event) => changeScroll("search", event)} />
                
              </div>
              <div className="md:col-span-6 col-span-12">
                <label htmlFor="text" className="font-semibold">
                  Image
                </label>
                <input
                  onChange={ImageUpload}
                  className="relative form-input border border-slate-100 dark:border-slate-800 file:h-10 file:-mx-3 file:-my-2 file:cursor-pointer file:rounded-none file:border-0 file:px-3 file:text-neutral-700 bg-clip-padding px-3 py-1.5 file:me-3 mt-2"
                  id="multiple_files"
                  type="file"
                  multiple
                />
              </div>

              <div className="col-span-12">
                <label htmlFor="subject" className="font-semibold">
                  Work Description
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={data}
                  onReady={(editor) => {
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => TextEditor(editor)}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>

              <div className="col-span-12 mb-8">
                <input
                  className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
                  type="checkbox"
                  value=""
                  defaultChecked
                  id="noti7"
                />
                &nbsp;&nbsp;
                <label className="form-check-label text-slate-400" htmlFor="AcceptTC">Yes, I understand and agree to the Geek on Demand <a href='/Terms' target='_blank' className="text-emerald-600">Terms of Service</a>, including the <a  href='/Agreement' target='_blank' className="text-emerald-600">User Agreement</a> and <a href='/Privacy' target='_blank' className="text-emerald-600">Privacy Policy</a>.</label>
              </div>
            </div>

            <button
              type="submit"
              id="submit"
              name="send"
              className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md"
            >
              Post Job
            </button>
          </form>
        {/* </div> */}
      </div>
      {/* <Footer /> */}
    </>
  );
}
