import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Index from './pages/index';
import React, { useEffect, useState } from 'react';
import IndexTwo from './pages/index/index-two';
import IndexThree from './pages/index/index-three';
import IndexFour from './pages/index/index-four';
import IndexFive from './pages/index/index-five';
import IndexSix from './pages/index/index-six';
import IndexSeven from './pages/index/index-seven';
import Comingsoon from './pages/comingsoon';
import Maintenance from './pages/maintenance';
import Error from './pages/error';
import Thankyou from './pages/thankyou';
import Privacy from './pages/privacy';
import Terms from './pages/terms';
import Login from './pages/auth/login';
import Signup from './pages/auth/signup';
import ResetPassword from './pages/auth/reset-password';
import Support from './pages/helpcenter/support';
import Guides from './pages/helpcenter/guides';

import JobGridsOne from './pages/job-grids/job-grids-one';
import JobGridsTwo from './pages/job-grids/job-grids-two';
import JobGridsThree from './pages/job-grids/job-grids-three';
import JobGridsFour from './pages/job-grids/job-grids-four';
import JobListOne from './pages/job-list/job-list-one';
import JobListTwo from './pages/job-list/job-list-two';
import JobListThree from './pages/job-list/job-list-three';
import JobListFour from './pages/job-list/job-list-four';
import JobListFive from './pages/job-list/job-list-five';
import JobListSix from './pages/job-list/job-list-six';
import JobDetailOne from './pages/job-detail/job-detail-one';
import JobDetailTwo from './pages/job-detail/job-detail-two';
import JobDetailThree from './pages/job-detail/job-detail-three';
import Overview from './pages/helpcenter/overview';
import Faqs from './pages/helpcenter/faqs';
import Aboutus from './pages/aboutus';
import Services from './pages/services';
import Pricing from './pages/pricing';
import Employerlist from './pages/employer/list';
import EmployerDetail from './pages/employer/detail';
import CandidateList from './pages/candidate/list';
import CandidateSearch from './pages/candidate/candidate-searchby';
import CandidateDetail from './pages/candidate/detail';
import JobApply from './pages/job-apply';
import JobPost from './pages/job-post';
import Career from './pages/career';
import Contact from './pages/contact';
import Switcher from './components/Switcher';
import Blogs from './pages/blog/blogs';
import BlogDetail from './pages/blog/blog-detail';
import LockScreen from './pages/auth/lock-screen';
import JobCategories from './pages/job-categories'
import ScrollToTop from './components/Scroll-top';
import IndexEight from './pages/index/index-eight';
import CandidateProfileSetting from './pages/candidate/candidate-profile-setting';
import Joinning from './components/Joinning';
import Otp from './pages/auth/Otp';
import MultiStepForm from './components/StepperWithContent';
import CandidateProfile from './pages/candidate/CandidateProfile';
import CandidateProfileforSeeker from './pages/candidate/CandidateProfileforSeeker';
import SeekerProfileForm from './components/SeekerProfileUpdate';
// import AboutusCard from './pages/AboutusCard';

import { useSelector } from 'react-redux';
import MoreService from './pages/MoreService/MoreService';
import JobList from './components/JobList';
import AppliedJobs from './components/AppliedJobs';
import ViewAppliedJobs from './components/ViewAppliedJobs';
import Form from './components/Form';
import NewJobPost from './components/NewJobPost';
import CorporateProfile from './pages/candidate/CorporateProfile';
import CorporateProfileSetting from './pages/candidate/CorporateProfileSetting';
import SeekersProfile from './pages/candidate/SeekersProfile';
import JobListProfile from './components/JobListProfile';
import GeekerSeekerProfile from './components/GeekerSeekerProfile';
import MyJobs from './components/MyJobs';
import Agreement from './Agreement';
import JobView from './pages/MyJobs/jobview';
import HiredJobView from './pages/MyJobs/HiredJobView';
import SeekersProfileSetting from './pages/candidate/SeekersProfileSetting';
import SeekerIndividualProfile from './components/SeekerIndividualProfile';
import SeekerCorporateProfile from './components/SeekerCorporateProfile';
import DynamicDropdowns from './pages/City/DynamicDropdowns';
import aboutCandidateList from './pages/aboutCandidateList';
import GeekGet from './components/GeekGet';
import SeekerGet from './components/SeekerGet';
import BigImage from './BigImages/BigImages';
import Dashboard from './Dashboard/dashboard';
import GeeksCorpProfileSet from './Dashboard/geeksCorpProfileSet';

function PrivateRoute({ element, roles, userRole, redirectTo }) {
  return roles.includes(userRole) ? element : <Navigate to={redirectTo} replace />;
}

function App() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add('light');
  }, []);

  const { currentUser } = useSelector((state) => state.auth);

  // console.log(currentUser, "currentUser")

  // const [userRole, setUserRole] = useState('guests')

  // useEffect(() => {
  //   setUserRole(currentUser?.data?.type ? currentUser?.data?.type : "guests")
  // }, [currentUser])

  // Note : if user not loged in role will be guests
  // Roles : seeker, geeks, guests

  const userRole = currentUser?.data?.type ? currentUser?.data?.type : "guests"

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {/* <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Signup />} /> */}
        <Route path="/login" element={
          <PrivateRoute
            element={<Login />}
            roles={['guests']} // Define roles that can access this route
            userRole={userRole}
            redirectTo="/"
          />
        } />

        <Route path="/login/:id" element={
          <PrivateRoute
            element={<Login />}
            roles={['guests']} // Define roles that can access this route
            userRole={userRole}
            redirectTo="/"
          />
        } />

        {/* This Routes works when user not loged in */}
        <Route path="/signup" element={
          <PrivateRoute
            element={<Signup />}
            roles={['guests']}
            userRole={userRole}
            redirectTo="/"
          />
        } />

        {/* This Routes works when user not loged in or user loged in with geeks role */}

        <Route path="/" element={
          <PrivateRoute
            element={<IndexSeven />}
            roles={['guests', 'geeks', 'seeker']}
            userRole={userRole}
            redirectTo="/not-found"
          />
        } />

        {/* This Routes works when user not loged in or user loged in with geeks role or  user loged in with seekers role */}

        <Route path="/index" element={
          <PrivateRoute
            element={<Index />}
            roles={['guests', 'geeks', 'seeker']}
            userRole={userRole}
            redirectTo="/not-found"
          />
        } />

        <Route path="/seekerRoutes" element={
          <PrivateRoute
            element={<IndexTwo />}
            roles={['seeker']}
            userRole={userRole}
            redirectTo="/not-found"
          />
        }
        />

        <Route path="/geeksRoutes" element={
          <PrivateRoute
            element={<IndexTwo />}
            roles={['geeks']}
            userRole={userRole}
            redirectTo="/not-found"
          />
        }
        />

        <Route path="/MyJobs" element={
          <PrivateRoute
            element={<MyJobs />}
            roles={['seeker']}
            userRole={userRole}
            redirectTo="/not-found"
          />
        }
        />

        <Route path="/jobView/:id" element={
          <PrivateRoute
            element={<JobView />}
            roles={['seeker']}
            userRole={userRole}
            redirectTo="/not-found"
          />
        }
        />

<Route path="/HireJobView/:id" element={
          <PrivateRoute
            element={<HiredJobView />}
            roles={['seeker']}
            userRole={userRole}
            redirectTo="/not-found"
          />
        }
        />
        <Route path="/seeker/candidate-profile/:id/:id1" element={
          <PrivateRoute
            element={<CandidateProfileforSeeker />}
            roles={['seeker']}
            userRole={userRole}
            redirectTo="/not-found"
          />
        }
        />
        <Route path="/seeker/candidate-profile/:id" element={
          <PrivateRoute
            element={<CandidateProfileforSeeker />}
            roles={['seeker']}
            userRole={userRole}
            redirectTo="/not-found"
          />
        }
        />

        <Route path="/not-found" element={<Error />} />

        <Route path="/SeekerProfileForm" element={<SeekerProfileForm />} />

        {/* <Route exact path="/" element={<Index />} /> */}
        <Route exact path="/" element={<IndexSeven />} />
        {/* <Route path="/index" element={<Index />} />
        <Route path="/index-two" element={<IndexTwo />} />
        <Route path="/index-three" element={<IndexThree />} />
        <Route path="/index-four" element={<IndexFour />} />
        <Route path="/index-five" element={<IndexFive />} />
        <Route path="/index-six" element={<IndexSix />} />  */}
        <Route exact path="/index-seven" element={<IndexSeven />} />
        {/* <Route path="/index-eight" element={<IndexEight />} /> */}

        <Route path="/job-categories" element={<JobCategories />} />
        <Route path="/job-apply" element={<JobApply />} />
        <Route path="/job-post" element={<JobPost />} />
        <Route path="/job-grid-one" element={<JobGridsOne />} />
        <Route path="/job-grid-two" element={<JobGridsTwo />} />
        <Route path="/job-grid-three" element={<JobGridsThree />} />
        <Route path="/job-grid-four" element={<JobGridsFour />} />
        <Route path="/job-list-one" element={<JobListOne />} />
        <Route path="/job-list-two" element={<JobListTwo />} />
        <Route path="/job-list-three" element={<JobListThree />} />
        <Route path="/job-list-four" element={<JobListFour />} />
        <Route path="/job-list-five" element={<JobListFive />} />
        <Route path="/job-list-six" element={<JobListSix />} />
        <Route path="/job-detail-one/:id" element={<JobDetailOne />} />
        <Route path="/job-detail-one" element={<JobDetailOne />} />
        <Route path="/job-detail-two/:id" element={<JobDetailTwo />} />
        <Route path="/job-detail-two" element={<JobDetailTwo />} />
        <Route path="/job-detail-three/:id" element={<JobDetailThree />} />
        <Route path="/job-detail-three" element={<JobDetailThree />} />

        <Route path="/helpcenter-faqs" element={<Faqs />} />
        <Route path="/helpcenter-overview" element={<Overview />} />
        <Route path="/helpcenter-support" element={<Support />} />
        <Route path="/helpcenter-guides" element={<Guides />} />

        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/services1" element={<Services />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/employer-list" element={<Employerlist />} />
        <Route path="/employer-detail/:id" element={<EmployerDetail />} />
        <Route path="/employer-detail" element={<EmployerDetail />} />
        <Route path="/service/:id" element={<CandidateList />} />
        <Route path="/CandidateSearch/:id" element={<CandidateSearch />} />
        <Route path="/candidate-profile/:id" element={<CandidateDetail />} />
        <Route path="/candidate-profile" element={<CandidateDetail />} />
        <Route path="/geeeks/profile/individual/edit/:id" element={<CandidateProfileSetting />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/comingsoon" element={<Comingsoon />} />
        <Route path="/maintenance" element={<Maintenance />} />
        <Route path='/error' element={<Error />} />
        <Route path='/thankyou' element={<Thankyou />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/blogs' element={<Blogs />} />
        <Route path='/blog-detail' element={<BlogDetail />} />
        <Route path='/blog-detail/:id' element={<BlogDetail />} />
        <Route path='/lock-screen' element={<LockScreen />} />
        <Route path='/Joinning' element={<Joinning />} />
        <Route path='/Otp' element={<Otp />} />
        <Route path='/MultiStepForm' element={<MultiStepForm />} />
        <Route path='/geeeks/profile/individual/:id' element={<CandidateProfile />} />
        <Route path='/services' element={<MoreService />} />
        {/* <Route path='/AboutusCard' element={<AboutusCard />} /> */}
        <Route path='/JobList' element={<JobList />} />
        <Route path='/AppliedJobs' element={<AppliedJobs />} />
        <Route path='/ViewAppliedJobs/:id' element={<ViewAppliedJobs />} />
        <Route path='/Form' element={<Form />} />
        <Route path='/NewJobPost' element={<NewJobPost />} />
        <Route path='/geeks/profile/corporate' element={<CorporateProfile />} />
        <Route path='/geeks/profile/corporate/edit' element={<CorporateProfileSetting />} />
        <Route path='/SeekersProfile' element={<SeekersProfile />} />
        <Route path='/JobListProfile' element={<JobListProfile />} />
        <Route path='/JobListProfile/:id' element={<JobListProfile />} />
        <Route path='/seeker/profile' element={<GeekerSeekerProfile />} />
        <Route path='/Agreement' element={<Agreement />} />
        <Route path='/SeekersProfileSetting' element={<SeekersProfileSetting />} />
        <Route path='/SeekerIndividualProfile' element={<SeekerIndividualProfile />} />
        <Route path='/SeekerCorporateProfile' element={<SeekerCorporateProfile />} />
        <Route path='/DynamicDropdowns' element={<DynamicDropdowns />} />
        <Route path='/aboutCandidateList' element={<aboutCandidateList />} />
        <Route path='/GeekGet' element={<GeekGet />} />
        <Route path='/SeekerGet' element={<SeekerGet />} />
        <Route path='/BigImage' element={<BigImage />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/GeeksCorpProfileSet' element={<GeeksCorpProfileSet />} />
      </Routes>
      <Switcher />
    </Router>
  );
}

export default App;
