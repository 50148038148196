import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import bg5 from '../../assets/images/hero/bg5.jpg';
import image from '../../assets/images/team/01.jpg';
import { useSelector } from 'react-redux';
import shree_logo from '../../assets/images/company/shree-logo.png';
import circle_logo from '../../assets/images/company/circle-logo.png';
import Navbar from '../../components/Navbar'
import CandidateDetailComp from '../../components/candidate-detail-comp';
import Footer from '../../components/Footer';
import { Settings, FileText, Gift, Globe, Home, Mail, MapPin, Phone, Server } from 'react-feather';
import { LuMail, BiLogoDribbble, AiOutlineBehance, BiLogoLinkedin, FaFacebookF, IoLogoTwitter, FaInstagram, FiFileText } from "../../assets/icons/vander"
import ExploreJob from '../../components/Explore-job';
import { CandidateList } from '../../data/data';
import axios from 'axios';
import Form from '../../components/Form';
import { Grid } from '@mui/material';
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import {
    BarChart,
    Bar,
    Rectangle,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    PieChart,
    Pie,
    Customized
  } from 'recharts';
// import './CandidateProfile.css'

export default function CandidateProfile() {
    const params = useParams();
    const id = params.id
    const data = CandidateList.find((jobs) => jobs.id === parseInt(id));
    const [barchart1, setBarchart1] = useState([]);

    const { currentUser } = useSelector((state) => state.auth);

    const [details, setDetails] = useState('');

    const GetProfileDetails = () => {
        axios.get(`${process.env.REACT_APP_API_BASEURL}/user/geeks/profile/${id}`, {
            headers: {
                "x-auth-token": currentUser?.token
            }
        })
            .then((res) => {
                console.log(res.data?.data, "hdsfcgshgfjs")
                setDetails(res.data?.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        GetProfileDetails()
    }, [])



    function truncate(source, size) {
        return source?.length > size ? source?.slice(0, size - 1) + "…" : source;
      }




    //   dashboard   
    const [dashboard, setDashboard] = useState("");
   
  
    const GetProfileDashboard = () => {
      axios
        .get(
          `${process.env.REACT_APP_API_BASEURL}/job/geeksDashboard`,
          {
            headers: {
              "x-auth-token": currentUser?.token,
            },
          }
        )
        .then((res) => {
          console.log(res?.data, "qweqwewqe");
          if(res?.data?.data){
            setBarchart1([])
            let data = [];
            let key = Object.keys(res?.data?.data);
            let value = Object.values(res?.data?.data);
            console.log("Key:",key)
            key.forEach((it,index)=>{
              data?.push({
                 name : it,
                 value : value[index]
              })
            })
            setBarchart1(data)
            
          }
          setDashboard(res?.data);
        })
        .catch((err) => {
          console.log(err?.data , "asdasasd");
        });
    };
  
    useEffect(() => {
      GetProfileDashboard();
    }, []);


    // Bar rechart 
    console.log("Dashboard Data:",dashboard?.data);
    const data1 = [
        {
          name: 'pending',
          uv: 4,
        //   pv: 2400,
        //   amt: 2400,
        },
        {
          name: 'accepted',
          uv: 3,
        //   pv: 1398,
        //   amt: 2210,
        },
        {
          name: 'initiate',
          uv: 2,
        //   pv: 9800,
        //   amt: 2290,
        },
        {
          name: 'inprogress',
          uv: 9,
        //   pv: 3908,
        //   amt: 2000,
        },
        {
          name: 'review',
          uv: 8,
        //   pv: 4800,
        //   amt: 2181,
        },
        {
          name: 'completed',
          uv: 5,
        //   pv: 3800,
        //   amt: 2500,
        }
      ];


      const data2 = [
        {
          name: 'Page A',
          uv: 4000,
          pv: 2400,
          amt: 2400,
        },
        {
          name: 'Page B',
          uv: 3000,
          pv: 1398,
          amt: 2210,
        },
        {
          name: 'Page C',
          uv: 2000,
          pv: 9800,
          amt: 2290,
        },
        {
          name: 'Page D',
          uv: 2780,
          pv: 3908,
          amt: 2000,
        },
        {
          name: 'Page E',
          uv: 1890,
          pv: 4800,
          amt: 2181,
        },
        {
          name: 'Page F',
          uv: 2390,
          pv: 3800,
          amt: 2500,
        },
        {
          name: 'Page G',
          uv: 3490,
          pv: 4300,
          amt: 2100,
        },
      ];

    return (
        <>
            <Navbar />
            <section className="relative lg:mt-24 mt-[74px]">
                <div className="lg:container container-fluid">
                    <div className="relative shrink-0">
                        <img src={`${process.env.REACT_APP_IMAGE_BASEURL}/${details?.geeks_details?.personal_details?.cover_pic}`} crossOrigin='anonymous' className="h-64 w-full object-cover lg:rounded-xl shadow dark:shadow-gray-700" alt="" />
                    </div>

                    <div className="md:flex ms-4 -mt-12">
                        <div className="md:w-full">
                            <div className="relative flex items-end justify-between">
                                <div className="relative flex items-end">
                                    <img src={`${process.env.REACT_APP_IMAGE_BASEURL}/${details?.geeks_details?.personal_details?.profile_pic}`} alt="profile" crossOrigin='anonymous' className="h-28 w-28 rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800" />
                                    <div className="ms-4">
                                        <h5 className="text-lg font-semibold">
                                            {details?.first_name + " " + details?.last_name}
                                            {/* MD RAFIUDDIN */}
                                        </h5>
                                        <p className="text-slate-400">
                                            {/* {data?.title ? data?.title : "UI Designer"} */}
                                            {details?.geeks_details?.personal_details?.city},{details?.geeks_details?.personal_details?.state}
                                        </p>
                                    </div>
                                </div>

                                {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}> */}
                                    {/* <div>
                                        <input type="submit" id="submit" name="send" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md cursor-pointer" value="Hire Me" />
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                    <div>
                                        <Link to={`/geeeks/profile/individual/edit/${details.uuid}`} className="btn btn-icon rounded-full bg-emerald-600/5 hover:bg-emerald-600 border-emerald-600/10 hover:border-emerald-600 text-emerald-600 hover:text-white flex justify-center items-center"><Settings className="h-4 w-4" /></Link>
                                    </div>


                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="relative mt-12">
                <div className="container md:pb-24 pb-16 ">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-8 md:col-span-7">
                            <h5 className="text-lg font-semibold">
                                {/* {details?.full_name} */}
                                {/* Specialization in UI Designing with 3 years experience */}
                                {details?.geeks_details?.technical_expertise?.years_of_experience} experience
                            </h5>

                          

                            <p className="text-slate-400 mt-4">
                            {truncate(
                                details?.geeks_details?.about?.bio?.replace(/(<([^>]+)>)/gi, ""),
                                400
                                )}
                                </p>

                            <h4 className="mt-6 text-xl font-semibold">Skills</h4>
                            {/* <div className="grid lg:grid-cols-2 grid-cols-1 mt-6 gap-6">
                                <div>
                                <div>
                                    <label className="form-label font-medium px-2">Title - </label>&nbsp;&nbsp;
                                    <input type="submit" id="submit" name="send" className="btn bg-slate-900  text-white rounded-full" value="Network Engineer"/>
                                </div>
                                </div>
                                <div>
                                    <label className="form-label font-medium px-2">Company - </label>&nbsp;&nbsp;
                                    <input type="submit" id="submit" name="send" className="btn bg-slate-900  text-white rounded-full" value={details?.geeks_details?.company_details?.company_name} />
                                </div>

                                <div>
                                    <label className="form-label font-medium px-2">Location - </label>&nbsp;&nbsp;
                                    <input type="submit" id="submit" name="send" className="btn bg-slate-900  text-white rounded-full" value={details?.geeks_details?.company_details?.city} />
                                </div>

                                <div>
                                    <label className="form-label font-medium px-2">Country - </label>&nbsp;&nbsp;
                                    <input type="submit" id="submit" name="send" className="btn bg-slate-900  text-white rounded-full" value={details?.geeks_details?.company_details?.country} />
                                </div>

                                <div>
                                    <label className="form-label font-medium px-2">Curently work - </label>&nbsp;&nbsp;
                                    <input type="submit" id="submit" name="send" className="btn bg-slate-900  text-white rounded-full" value="June 2023-Present" />
                                </div>

                                <div>
                                    <label className="form-label font-medium px-2">Start date - End date - </label>&nbsp;&nbsp;
                                    <input type="submit" id="submit" name="send" className="btn bg-slate-900  text-white rounded-full" value="01/01/2022 - 01/01/2023" />
                                </div>
                            </div> */}

                            <Grid item container className='mt-5 gap-2'>
                                <Grid xs={12} md={12} >
                                    {details?.geeks_details?.technical_expertise?.skills?.map((skills) => {
                                        return <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full" >
                                            {skills?.label ? skills?.label : skills}&nbsp;&nbsp;
                                        </div>
                                    })}

                                    {/* <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full" >
                                        CSS
                                    </div>&nbsp;&nbsp;
                                    <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full"  >
                                        Bootstrap
                                    </div>&nbsp;&nbsp;
                                    <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full"  >
                                        Javascript
                                    </div>&nbsp;&nbsp;
                                    <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full" >
                                        React JS
                                    </div> */}
                                </Grid>
                                {/* <Grid xs={6} md={3}>
                                <input type="submit" id="submit" name="send" className="btn bg-slate-900  text-white rounded-full" 
                                // value={details?.geeks_details?.company_details?.company_name} 
                                value="CSS"
                                />
                                </Grid>
                                <Grid xs={6} md={3}>
                                <input type="submit" id="submit" name="send" className="btn bg-slate-900  text-white rounded-full"
                                // value={details?.geeks_details?.company_details?.city}
                                value="Bootstrap"
                                 />
                                </Grid>
                                <Grid xs={6} md={3}>
                                <input type="submit" id="submit" name="send" className="btn bg-slate-900  text-white rounded-full"
                                //  value={details?.geeks_details?.company_details?.country} 
                                value="Javascript"
                                 />
                                </Grid>
                                <Grid xs={6} md={3}>
                                <input type="submit" id="submit" name="send" className="btn bg-slate-900  text-white rounded-full" value="Material UI" />
                                </Grid>
                                <Grid xs={6} md={3}>
                                <input type="submit" id="submit" name="send" className="btn bg-slate-900  text-white rounded-full" value="React JS" />
                                </Grid> */}
                            </Grid>

                            {/* <h4 className="mt-6 text-xl font-semibold">Skills :</h4>
                            <div className="grid lg:grid-cols-2 grid-cols-1 mt-6 gap-6">
                                <div>
                                    <div className="flex justify-between mb-2">
                                        <span className="text-slate-400">WordPress</span>
                                        <span className="text-slate-400">84%</span>
                                    </div>
                                    <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                                        <div className="bg-emerald-600 h-[6px] rounded-full" style={{ width: '84%' }}></div>
                                    </div>
                                </div>

                                <div>
                                    <div className="flex justify-between mb-2">
                                        <span className="text-slate-400">JavaScript</span>
                                        <span className="text-slate-400">79%</span>
                                    </div>
                                    <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                                        <div className="bg-emerald-600 h-[6px] rounded-full" style={{ width: '79%' }}></div>
                                    </div>
                                </div>

                                <div>
                                    <div className="flex justify-between mb-2">
                                        <span className="text-slate-400">HTML</span>
                                        <span className="text-slate-400">95%</span>
                                    </div>
                                    <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                                        <div className="bg-emerald-600 h-[6px] rounded-full" style={{ width: '95%' }}></div>
                                    </div>
                                </div>

                                <div>
                                    <div className="flex justify-between mb-2">
                                        <span className="text-slate-400">Figma</span>
                                        <span className="text-slate-400">85%</span>
                                    </div>
                                    <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                                        <div className="bg-emerald-600 h-[6px] rounded-full" style={{ width: '85%' }}></div>
                                    </div>
                                </div>

                                <div>
                                    <div className="flex justify-between mb-2">
                                        <span className="text-slate-400">Photoshop</span>
                                        <span className="text-slate-400">70%</span>
                                    </div>
                                    <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                                        <div className="bg-emerald-600 h-[6px] rounded-full" style={{ width: '70%' }}></div>
                                    </div>
                                </div>

                                <div>
                                    <div className="flex justify-between mb-2">
                                        <span className="text-slate-400">Illustration</span>
                                        <span className="text-slate-400">65%</span>
                                    </div>
                                    <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                                        <div className="bg-emerald-600 h-[6px] rounded-full" style={{ width: '65%' }}></div>
                                    </div>
                                </div>
                            </div> */}

                            {/* <h4 className="mt-6 text-xl font-semibold">Services</h4>
                            <Grid item container className='mt-5 gap-3'>
                                <Grid xs={12} md={12} className='gap-3'>
                                    <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full"  >
                                        All Software Solution
                                    </div>&nbsp;&nbsp;
                                    <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full"  >
                                        Laptop service
                                    </div>&nbsp;&nbsp;
                                    <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full" >
                                        Tablet service
                                    </div>&nbsp;&nbsp;
                                    <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full"  >
                                        Mobile service
                                    </div>&nbsp;&nbsp;
                                    <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full mt-2" >
                                        TV service
                                    </div>
                                </Grid>
                            </Grid> */}
                            {/* <div className="grid lg:grid-cols-2 grid-cols-1 mt-6 gap-6">
                                <div>
                                    <label className="form-label font-medium px-2">School/University - </label>&nbsp;&nbsp;
                                    <input type="submit" id="submit" name="send" className="btn bg-slate-900  text-white rounded-full" value="Amity University" />
                                </div>
                                <div>
                                    <label className="form-label font-medium px-2">Degree - </label>&nbsp;&nbsp;
                                    <input type="submit" id="submit" name="send" className="btn bg-slate-900  text-white rounded-full" value="Bachelore of Technology" />
                                </div>
                                <div>
                                    <label className="form-label font-medium px-2">Field of Study - </label>&nbsp;&nbsp;
                                    <input type="submit" id="submit" name="send" className="btn bg-slate-900  text-white rounded-full" value="Computer Science" />
                                </div>
                                <div>
                                    <label className="form-label font-medium px-2">Joining Date - </label>&nbsp;&nbsp;
                                    <input type="submit" id="submit" name="send" className="btn bg-slate-900  text-white rounded-full" value="02/03/2023" />
                                </div>
                                
                            </div> */}

                            <h4 className="mt-6 text-xl font-semibold">Certified Skills</h4>
                            <Grid item container className='mt-5'>
                                <Grid xs={12} md={12}>
                                    {details?.geeks_details?.technical_expertise?.certified_skills?.map((certifiedskills) => {
                                        return <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full mt-2"  >
                                            {certifiedskills}&nbsp;&nbsp;
                                        </div>
                                    })}

                                    {/* <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full mt-2">
                                        Amazon Web Services Certification
                                    </div>&nbsp;&nbsp;
                                    <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full mt-2" >
                                        International Web Association’s Certified Web Professional Certification
                                    </div>&nbsp;&nbsp;
                                    <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full mt-2"  >
                                        CIW Web Development Professional Certification
                                    </div>&nbsp;&nbsp;
                                    <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full mt-2">
                                        MADAC
                                    </div> */}
                                </Grid>
                            </Grid>

                            <h4 className="mt-6 text-xl font-semibold">Languages</h4>
                            <Grid item container className='mt-5'>
                                <Grid xs={12} md={12}>
                                    {details?.geeks_details?.technical_expertise?.language?.map((languages) => {
                                        return <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full mt-2" >
                                            {languages}&nbsp;&nbsp;
                                        </div>
                                    })}

                                    {/* <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full mt-2" >
                                        Hindi
                                    </div>&nbsp;&nbsp;
                                    <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full mt-2"  >
                                        Telugu
                                    </div> */}

                                </Grid>
                            </Grid>


                            <div className="rounded-md shadow dark:shadow-gray-700 p-6 mt-6">
                                <h5 className="text-xl font-semibold text-center mb-3">Hire Me</h5>

                                <form className='ContainerForm'>
                                    <Form />
                                </form>
                            </div>

                        </div>

                        <div className="lg:col-span-4 md:col-span-5">
                            <div className="bg-slate-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-700 p-6 sticky top-20">
                                <h5 className="text-lg font-semibold">Personal Detail:</h5>
                                <ul className="list-none mt-4">
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Mail className="h-4 w-4 text-slate-400 me-3 inline"></Mail><span className="text-slate-400 me-3">Name  :</span></span>

                                        <span>{details?.first_name + " " + details?.last_name}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Mail className="h-4 w-4 text-slate-400 me-3 inline"></Mail><span className="text-slate-400 me-3">Email  :</span></span>

                                        <span>{details?.email}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Phone className="h-4 w-4 text-slate-400 me-3 inline"></Phone><span className="text-slate-400 me-3">Mobile :</span></span>

                                        <span>{details?.mobile}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Phone className="h-4 w-4 text-slate-400 me-3 inline"></Phone><span className="text-slate-400 me-3">Gender :</span></span>

                                        <span>{details?.geeks_details?.personal_details?.gender}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Gift className="h-4 w-4 text-slate-400 me-3 inline"></Gift><span className="text-slate-400 me-3">D.O.B. :</span></span>

                                        <span>{details?.geeks_details?.personal_details?.dob}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Home className="h-4 w-4 text-slate-400 me-3 inline"></Home><span className="text-slate-400 me-3">Address :</span></span>

                                        <span>{details?.geeks_details?.personal_details?.address}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><MapPin className="h-4 w-4 text-slate-400 me-3 inline"></MapPin><span className="text-slate-400 me-3">City :</span></span>

                                        <span>{details?.geeks_details?.personal_details?.city}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Globe className="h-4 w-4 text-slate-400 me-3 inline"></Globe><span className="text-slate-400 me-3">Country :</span></span>

                                        <span>{details?.geeks_details?.personal_details?.state}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Server className="h-4 w-4 text-slate-400 me-3 inline"></Server><span className="text-slate-400 me-3">Postal Code :</span></span>

                                        <span>{details?.geeks_details?.personal_details?.pincode}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Server className="h-4 w-4 text-slate-400 me-3 inline"></Server><span className="text-slate-400 me-3">Aadhar Card :</span></span>

                                        <span>{details?.geeks_details?.personal_details?.adhar_number}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Server className="h-4 w-4 text-slate-400 me-3 inline"></Server><span className="text-slate-400 me-3">PAN :</span></span>

                                        <span>{details?.geeks_details?.personal_details?.pan_number}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Server className="h-4 w-4 text-slate-400 me-3 inline"></Server><span className="text-slate-400 me-3">Qualification :</span></span>

                                        <span>{details?.geeks_details?.about?.highest_qualification}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Server className="h-4 w-4 text-slate-400 me-3 inline"></Server><span className="text-slate-400 me-3">Certification</span></span>

                                        <span>{details?.geeks_details?.about?.academic_certification_award_testimonials}</span>
                                    </li>


                                    {/* <li className="flex justify-between mt-3">
                                        <span className="text-slate-400 font-medium">Social:</span>

                                        <ul className="list-none ltr:text-right rtl:text-left space-x-0.5">
                                            <li className="inline"><Link to="https://dribbble.com/shreethemes" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-200 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 hover:text-white dark:text-white text-slate-400"><BiLogoDribbble /></Link></li>
                                            <li className="inline"><Link to="https://www.behance.net/shreethemes" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-200 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 hover:text-white dark:text-white text-slate-400"><AiOutlineBehance /></Link></li>
                                            <li className="inline"><Link to="http://linkedin.com/company/shreethemes" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-200 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 hover:text-white dark:text-white text-slate-400"><BiLogoLinkedin /></Link></li>
                                            <li className="inline"><Link to="https://www.facebook.com/shreethemes" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-200 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 hover:text-white dark:text-white text-slate-400"><FaFacebookF /></Link></li>
                                            <li className="inline"><Link to="https://www.instagram.com/shreethemes/" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-200 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 hover:text-white dark:text-white text-slate-400"><FaInstagram /></Link></li>
                                            <li className="inline"><Link to="https://twitter.com/shreethemes" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-200 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 hover:text-white dark:text-white text-slate-400"><IoLogoTwitter /></Link></li>
                                            <li className="inline"><Link to="mailto:support@shreethemes.in" className="btn btn-icon btn-sm border-2 border-gray-200 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 hover:text-white dark:text-white text-slate-400"><LuMail /></Link></li>
                                        </ul>
                                    </li> */}

                                    <li className="mt-3 w-full bg-white dark:bg-slate-900 p-3 rounded-md shadow dark:shadow-gray-700">
                                        <div className="flex items-center mb-3">
                                            <FileText className="h-8 w-8 text-slate-400"></FileText>
                                            <span className="font-medium ms-2">{details?.geeks_details?.about?.cv}</span>
                                        </div>

                                        <Link to="assets/images/calvin-carlo-resume.pdf" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-md w-full flex items-center justify-center" download><FiFileText className='me-2' /> Download CV</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </section>


                                                                     {/* Dashboard area   */}

                                                         
<div className="rounded-xl text-2xl flex align-middle justify-center items-center mx-auto" style={{ fontWeight:"600" }}>Dashboard</div>
<div className="container lg:mt-10 mt-8">

                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
                        <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    <div className='text-slate-icon-400'>
                                    {dashboard?.data?.job_applied}
                                    </div>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Jobs Applied</h5>

                               
                            </div>
                        </div>

                        <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    <div className='text-slate-icon-400'> {dashboard?.data?.job_inprogress}</div> 
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Jobs Inprogress</h5>
                            </div>
                        </div>

                        <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                <div className='text-slate-icon-400'>{dashboard?.data?.job_completed}</div>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Jobs Completed</h5>
                            </div>
                        </div>

                       
                    </div>
                </div>

                <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data2}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="pv" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
          <Bar dataKey="uv" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />
        </BarChart>
      </ResponsiveContainer>
               
                
                {/* Chart bar  */}

                {/* <section className="relative mt-12">
        <div className="container md:pb-24 pb-16 ">
          <div className="grid md:grid-cols-12 grid-cols-1 border">
            <div className="grid-cols-12 lg:col-span-6 md:col-span-12 xs:col-span-12">
                
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={750}
                    height={250}
                    data={data1}
                    margin={{
                    top: 25,
                    right: 0,
                    left: 0,
                    bottom: 5,
                    }}
                >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" interval={'preserveStartEnd'}/>
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="uv" fill="#68712e " activeBar={<Rectangle fill="gold" stroke="purple" />} />
      </BarChart>
    </ResponsiveContainer>

            </div>




            <div className="grid-cols-12 lg:col-span-6 md:col-span-12 xs:col-span-12">
         
            <PieChart>
            <Tooltip />
       
                <Pie data={data1} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#8884d8" />
                <Pie data={data1} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d" label />
</PieChart>
</div>



          </div>
        </div>
      </section> */}


            <Footer />


        </>
    )
}
