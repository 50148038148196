import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer';
import { Link } from "react-router-dom";
import {MdOutlineArrowForward} from "../assets/icons/vander"

export default function Terms() {
    const accordionData = [
        {
            title: 'How does it work ?',
            content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        },
        {
            title: 'Do I need a designer to use Jobstack ?',
            content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        },
        {
            title: 'What do I need to do to start selling ?',
            content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        },
        {
            title: 'What happens when I receive an order ?',
            content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        }
    ]
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    return (
        <>
            <Navbar navClass='justify-end nav-light' />

            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Terms of Service</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Home</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Terms of Service</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="relative lg:py-24 py-16">
                <div className="container">
                    <div className="md:flex justify-center">
                        <div className="md:w-3/4">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                                <h5 className="text-xl font-medium mb-4">Terms and Conditions</h5>
                                <p className="text-slate-400">Welcome to GeekOnDemand, an online platform that aims to bridge the gap between IT service providers and service seekers. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions. Please read them carefully before using our services.</p>

                                <h5 className="text-xl font-medium mb-4 mt-4">Introduction</h5>
                                <p className="text-slate-400">GeekOnDemand is an online marketplace where IT service providers such as freelancers and technical experts can connect with service seekers. Our platform offers a skill discovery landscape where seekers can find solutions to their IT problems, while service providers can engage with seekers online.</p>

                                <h5 className="text-xl font-medium mb-4 mt-4">User Agreement</h5>
                                <p className="text-slate-400">By accessing or using our website, you agree to comply with the terms and conditions outlined herein. If you do not agree to these terms, please do not use our services. Your continued use of the website after any changes to these terms will be deemed as acceptance of those changes.</p>

                                <h5 className="text-xl font-medium mb-4 mt-4">Services</h5>
                                <p className="text-slate-400">GeekOnDemand is an online platform that enables service providers to offer IT services to seekers. We do not provide any IT services ourselves, nor do we employ any service providers. All transactions take place between the service providers and the seekers directly. GeekOnDemand is not responsible for any dispute or loss arising from the use of our services.

</p>

                                <h5 className="text-xl font-medium mb-4 mt-4">Eligibility</h5>
                                <p className="text-slate-400">To use our services, you must be at least 18 years old and capable of forming legally binding contracts under applicable law. By using our services, you represent and warrant that you meet these eligibility requirements. User Conduct</p>

                                <p className="text-slate-400">You are solely responsible for any content or material that you post or transmit through our website. You agree not to use our services for any illegal or unauthorized purpose, including but not limited to:</p>


                                <ul className="list-none text-slate-400 mt-3">
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-emerald-600 align-middle me-2"/>Posting content that is defamatory, obscene, or harmful to minors</li>
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-emerald-600 align-middle me-2"/>Using our services to violate any applicable law or regulation</li>
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-emerald-600 align-middle me-2"/>Posting content that infringes on the intellectual property rights of others</li>
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-emerald-600 align-middle me-2"/>Using our services to transmit any virus, malware, or other harmful code</li>
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-emerald-600 align-middle me-2"/>Using our services to engage in any fraudulent activity</li>
                                </ul>

                                <h5 className="text-xl font-medium mb-4 mt-4">Intellectual Property</h5>
                                <p className="text-slate-400">All content and materials on our website, including but not limited to text, graphics, logos, and images, are the property of GeekOnDemand or its licensors and are protected by copyright and other intellectual property laws. You may not use any of our content or materials without our prior written consent.</p>

                                <h5 className="text-xl font-medium mb-4 mt-4">Liability</h5>
                                <p className="text-slate-400">GeekOnDemand is not responsible for any loss, damage, or injury arising from the use of our services. We do not guarantee the accuracy or completeness of any content or materials on our website, nor do we endorse any service provider or seeker. We shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use of our services.
</p>

                                <h5 className="text-xl font-medium mb-4 mt-4">Indemnification</h5>
                                <p className="text-slate-400">You agree to indemnify, defend, and hold GeekOnDemand and its affiliates, officers, directors, agents, and employees harmless from any claim, demand, or damage, including reasonable attorneys' fees, arising out of or related to your use of our services or your violation of these terms and conditions.</p>

                                <h5 className="text-xl font-medium mb-4 mt-4">Termination</h5>
                                <p className="text-slate-400">GeekOnDemand reserves the right to terminate or suspend your access to our services at any time, without notice, for any reason, including but not limited to your violation of these terms and conditions.</p>

                                <h5 className="text-xl font-medium mb-4 mt-4">Governing Law</h5>
                                <p className="text-slate-400">These terms and conditions shall be governed by and construed in accordance with the laws of India. Any disputes arising from or related to these terms and conditions shall be subject to the exclusive jurisdiction of the courts of India. Contact Information</p>

                                <p className="text-slate-400 mt-5">If you have any questions or comments about these terms and conditions, please contact us at hello@geekondemand.in.</p>

                                <p className="text-slate-400 mt-5">Thank you for using GeekOnDemand!</p>



                                {/* <h5 className="text-xl font-medium mb-4 mt-8">Introduction</h5>
                                <p className="text-slate-400">The most well-known dummy text is the 'Lorem Ipsum', which is said to have <b className="text-danger-600">originated</b> in the 16th century. Lorem Ipsum is <b className="text-danger-600">composed</b> in a pseudo-Latin language which more or less <b className="text-danger-600">corresponds</b> to 'proper' Latin. It contains a series of real Latin words. This ancient dummy text is also <b className="text-danger-600">incomprehensible</b>, but it imitates the rhythm of most European languages in Latin script. The <b className="text-danger-600">advantage</b> of its Latin origin and the relative <b className="text-danger-600">meaninglessness</b> of Lorum Ipsum is that the text does not attract attention to itself or distract the viewer's <b className="text-danger-600">attention</b> from the layout.</p>
                                <p className="text-slate-400 mt-3">There is now an <b className="text-danger-600">abundance</b> of readable dummy texts. These are usually used when a text is <b className="text-danger-600">required purely</b> to fill a space. These alternatives to the classic Lorem Ipsum texts are often amusing and tell short, funny or <b className="text-danger-600">nonsensical</b> stories.</p>
                                <p className="text-slate-400 mt-3">It seems that only <b className="text-danger-600">fragments</b> of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text.</p> */}

                                {/* <h5 className="text-xl font-medium mb-4 mt-8">Restrictions :</h5>
                                <p className="text-slate-400">You are specifically restricted from all of the following :</p>
                                <ul className="list-none text-slate-400 mt-3">
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-emerald-600 align-middle me-2"/>Digital Marketing Solutions for Tomorrow</li>
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-emerald-600 align-middle me-2"/>Our Talented & Experienced Marketing Agency</li>
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-emerald-600 align-middle me-2"/>Create your own skin to match your brand</li>
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-emerald-600 align-middle me-2"/>Digital Marketing Solutions for Tomorrow</li>
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-emerald-600 align-middle me-2"/>Our Talented & Experienced Marketing Agency</li>
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-emerald-600 align-middle me-2"/>Create your own skin to match your brand</li>
                                </ul> */}

                                {/* <h5 className="text-xl font-medium mt-8">Users Question & Answer :</h5>

                                <div id="accordion-collapse" data-accordion="collapse" className="mt-6">

                                    {accordionData.map((item, index) => (
                                        <div key={index} className={`relative shadow dark:shadow-gray-700 rounded-md overflow-hidden mt-4`}>
                                            <h2 className="text-base font-medium">
                                                <button onClick={() => toggleAccordion(index)} type="button" className={`flex justify-between items-center p-5 w-full font-medium text-left ${activeIndex === index ? 'text-emerald-500 bg-gray-50 dark:bg-slate-800' : ''} `} data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                    <span>{item.title}</span>
                                                    <svg data-accordion-icon className={`w-4 h-4 shrink-0 ${activeIndex === index ? 'rotate-180' : ''}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                    </svg>
                                                </button>
                                            </h2>
                                            {activeIndex === index && (
                                                <div aria-labelledby="accordion-collapse-heading-1">
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">{item.content}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}

                                </div>

                                <div className="mt-6">
                                    <Link to="#" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md">Accept</Link>
                                    <Link to="#" className="btn bg-transparent hover:bg-emerald-600 border border-emerald-600 text-emerald-600 hover:text-white rounded-md ms-2">Decline</Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
