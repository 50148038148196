import React, { useState, useEffect } from 'react';
import contact from '../assets/images/svg/contact.svg';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {PiMapPin, BsTelephone, LuMail} from "../assets/icons/vander"
import {MdOutlineArrowForward, FaAtom, FiUserCheck, IoMdHourglass, BsBriefcase, TbUsersGroup,BiLogoLinkedin, FaFacebookF, FaInstagram, IoLogoTwitter} from "../assets/icons/vander";
import { Facebook, GitHub, Gitlab, Instagram, Linkedin, Mail, MessageCircle, Twitter, User, Youtube } from 'react-feather';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import axios from 'axios'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Contact() {


    const dispatch = useDispatch()

    const ServicesComp = [
        {
            icon: BsTelephone,
            title: '24/7 Support',
            data: 'Many desktop publishing now use and a search for job'
        },
        {
            icon: FaAtom,
            title: 'Tech & Startup Jobs',
            data: 'Many desktop publishing now use and a search for job'
        },
        {
            icon: FiUserCheck,
            title: 'Quick & Easy',
            data: 'Many desktop publishing now use and a search for job'
        },
        {
            icon: IoMdHourglass,
            title: 'Save Time',
            data: 'Many desktop publishing now use and a search for job'
        },
        {
            icon: FiUserCheck,
            title: 'Apply with confidence',
            data: 'Many desktop publishing now use and a search for job'
        },
        {
            icon: FiUserCheck,
            title: 'Reduce Hiring Bias',
            data: 'Many desktop publishing now use and a search for job'
        },
        {
            icon: BsBriefcase,
            title: 'Proactive Employers',
            data: 'Many desktop publishing now use and a search for job'
        },
        {
            icon: TbUsersGroup,
            title: 'No Missed Opportunities',
            data: 'Many desktop publishing now use and a search for job'
        },
    ]


    const [value, setValue] = React.useState('')

    const handleChange = (newValue) => {
        setValue(newValue)
      }


      const [phone, setPhone] = useState('');


      const location = useLocation();
      const navigate = useNavigate();

      const [name, setName] = useState('');
      const [question, setQuestion] = useState('');
      const [email, setEmail] = useState('');
      const [mobile, setMobile] = useState('');
      const [comment, setComment] = useState('');
    
      const ContactFormApi = async (event) => {
        event.preventDefault();
        
        // Define payload after the event.preventDefault()
        const payload = {
          name: name,
          email: email,
          mobile: mobile,
          question: question,
          comment: comment,
        };
    
        try {
          const res = await axios.post(`${process.env.REACT_APP_API_BASEURL}/contact`, payload);
          console.log(res, 'gjdsfjdsh');
          toast.success(res?.data?.message);
            // Clear input fields after successful submission
            setName('');
            setEmail('');
            setMobile('');
            setQuestion('');
            setComment('');
            const countryCode = mobile.substring(0, 2); // Assuming the country code is 3 characters
            setMobile(countryCode);

        } catch (err) {
          console.log(err);
          toast.error(err?.response?.data?.message);
        }
      };
    

    //   const clearMobileInput = () => {
    //     const countryCode = mobile.substring(0, 3); // Assuming the country code is 3 characters
    //     setMobile(countryCode);
    //   };


        // useEffect to control when the API call should be made
//   useEffect(() => {
//     if (name !== '' && email !== '' && mobile !== '' && question !== '' && comment !== '') {
//       ContactFormApi();
//     }
//   }, [name, email, mobile, question, comment]);


      const [rawPhone, setRawPhone] = useState('');
      console.log(rawPhone?.length,"dshdfdsgfdsghf")
      const handleOnChange = (value, data, event) => {
        setMobile(value)
      };
    

    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Contact</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Home</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Contact</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            {/* <div className="container-fluid relative mt-20">
                <div className="grid grid-cols-1">
                    <div className="w-full leading-[0] border-0">
                        <iframe title="contact-frame" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin" style={{ border: 0 }} className="w-full h-[500px]" allowFullScreen></iframe>
                    </div>
                </div>
            </div> */}
            <section className="relative lg:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7 md:col-span-6">
                            <img src={contact} alt="" />
                        </div>

                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="lg:ms-5">
                                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                    <h3 className="mb-2 text-2xl leading-normal font-semibold">Get in touch !</h3>

                                    <form onSubmit={ContactFormApi}>
                                        <div className="grid lg:grid-cols-12 lg:gap-6">
                                            <div className="lg:col-span-6 mb-2">
                                                <label htmlFor="name" className="font-semibold">Your Name:</label>
                                                <input name="name" id="name" type="text" className="form-input mt-2" placeholder="Enter :" 
                                              value={name} onChange={(e) => { setName(e.target.value) }}
                                                />
                                            </div>

                                            <div className="lg:col-span-6 mb-2">
                                                <label htmlFor="email" className="font-semibold">Your Email:</label>
                                                <input name="email" id="email" type="email" className="form-input mt-2" placeholder="Enter :"
                                                  value={email} onChange={(e) => { setEmail(e.target.value) }}
                                                />
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1">
                                        <div className="mb-2">
                                                <label htmlFor="email" className="font-semibold">Mobile Number:</label>
                                                {/* <input name="mobile" id="mobile" className="form-input mt-2" placeholder="Enter :" 
                                                value={mobile} onChange={(e) => { setMobile(e.target.value) }}
                                                /> */}
                                                <PhoneInput
                                                value={mobile}
                                                onChange={handleOnChange}
                                                maxLenth={10}
                                                country={'in'}
                                        //    value={phone}
                                                // // onChange={(e) => { setMobile(e.target.value) }}
                                                // // // onlyCountries={['in']}
                                                // // //  enableAreaCodes={true}
                                                // // //  defaultCountry={'in'}
                                                masks={{in: '..........', at: '(....) ...-....'}}
                                                className="mt-2"
                                                // // type="number"
                                               />

                                        </div>
                                            <div className="mb-2">
                                                <label htmlFor="subject" className="font-semibold">Your Question:</label>
                                                <input name="question" id="question" className="form-input mt-2" placeholder="Subject :" 
                                                value={question} onChange={(e) => { setQuestion(e.target.value) }}
                                                />
                                            </div>

                                            <div className="mb-2">
                                                <label htmlFor="comments" className="font-semibold">Your Comment:</label>
                                                <textarea name="comments" id="comments" className="form-input mt-2 textarea" 
                                                placeholder="Message :"
                                                      value={comment} onChange={(e) => { setComment(e.target.value) }}
                                                ></textarea>
                                            </div>
                                        </div>
                                        <button type="submit" id="submit" name="send" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md">Send Message</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container lg:mt-24 mt-16">
                    <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-[30px]">
                        <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    <BsTelephone className='text-slate-icon-400'/>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Phone</h5>

                                <div className="mt-5">
                                    <Link to="tel:+152534-468-854" className="btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 transition duration-500">+91 83748 30119</Link>
                                </div>
                            </div>
                        </div>

                        <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    <LuMail className='text-slate-icon-400'/>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Email</h5>

                                <div className="mt-5">
                                    <Link to="mailto:contact@example.com" className="btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 transition duration-500">hello@geekondemand.in</Link>
                                </div>
                            </div>
                        </div>

                        <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    <PiMapPin className='text-slate-icon-400'/>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Location</h5>
                                <Link to="mailto:contact@example.com" className="btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 transition duration-500 mt-5">Begumpet, Hyderabad-16</Link>

                                {/* <div className="mt-5">
                                    <Link to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                                        data-type="iframe" className="video-play-icon read-more lightbox btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 transition duration-500">View on Google map</Link>
                                </div> */}
                            </div>
                        </div>

                        <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                <Mail className="w-5 h-5 text-slate-icon-400"></Mail>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Support</h5>

                                <div className="mt-5">
                                    <Link to="tel:+152534-468-854" className="btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 transition duration-500">@GeekOnDemand</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>






                <div className="container lg:mt-24 mt-16">
                    {/* <div className="grid grid-cols-1 lg:grid-cols-1 md:grid-cols-1 gap-[30px]"> */}
                    <div className="group p-4 shadow dark:shadow-gray-700 rounded-md bg-white text-center ">
                    <h5 className="title h5 text-xl font-semibold">Follow us</h5>
                    <p className="text-slate-400 mt-3 mb-5">The easiest way to link to your social media pages</p>
                    <div className="grid grid-cols-2 lg:grid-cols-5 md:grid-cols-5 gap-[30px]">
                   
                   
                            <div className="relative text-transparent">
                                <div className="w-24 h-24 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800 cursor-pointer">
                                    <FaFacebookF className="w-8 h-8 text-slate-icon-400"/>
                                </div>
                                <Link to="tel:+152534-468-854" className="btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 transition duration-500 mt-3">Follow us on facebook</Link>
                            </div>
                        

                            <div className="relative text-transparent">
                            <div className="w-24 h-24 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800 cursor-pointer">
                                    <IoLogoTwitter className="w-8 h-8 text-slate-icon-400"/>
                                </div>
                                <Link to="tel:+152534-468-854" className="btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 transition duration-500 mt-3">Follow us on Twitter</Link>
                            </div>

                            <div className="relative text-transparent">
                            <div className="w-24 h-24 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800 cursor-pointer">
                                    <BiLogoLinkedin className="w-8 h-8 text-slate-icon-400"/>
                                </div>
                                <Link to="tel:+152534-468-854" className="btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 transition duration-500 mt-3">Follow us on LinkedIn</Link>
                            </div>

                            <div className="relative text-transparent">
                            <div className="w-24 h-24 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800 cursor-pointer">
                                    <FaInstagram className="w-8 h-8 text-slate-icon-400"/>
                                </div>
                                <Link to="tel:+152534-468-854" className="btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 transition duration-500 mt-3">Follow us on Instagram</Link>
                            </div>

                            <div className="relative text-transparent">
                            <div className="w-24 h-24 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800 cursor-pointer">
                            <Youtube className="w-8 h-8 text-slate-icon-400"></Youtube>
                                </div>
                                <Link to="tel:+152534-468-854" className="btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 transition duration-500 mt-3">Follow us on Youtube</Link>
                            </div>

                            </div>
                           
                        </div>
                    {/* </div> */}
                    </div>
                  
            </section>

            <Footer />
        </>
    )
}
