import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
// import { CandidateList } from '../data/data';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, TbBrandHipchat, AiOutlineStar } from "../assets/icons/vander";
import { candidateListbyService } from '../Redux/services/servicesSlice';
// import { Row, Col, Dropdown, Form, Card, Badge, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
import axios from 'axios'
import Typography from '@mui/material/Typography';
import Form from './Form';
// import Modal from '@mui/material/Modal';
import closeButton from '../assets/images/closeButton.png'

export default function CandidateListComp() {

    const params = useParams();
    const id = params.id

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [page, setPage] = useState(0)

    const { currentUser } = useSelector((state) => state.auth);
    const { candidateList } = useSelector((state) => state.service);

    const [SearchDetails, setSearchDetails] = useState('');

    const SearchFunction = () => {
        axios.get(`${process.env.REACT_APP_API_BASEURL}/user/GeekslistSearch?limit=10&pagenum=${page}&search=${id}`, {
            headers: { "x-auth-token": currentUser?.token }
        })
            .then((res) => {
                console.log(res, "hgdfvcjscfhsj")
                setSearchDetails(res?.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        SearchFunction()
    }, [])

    const CheckRole = (data, id) => {
        if (!currentUser.data) {
            toast.success("Please Login To Continue", {
                position: "top-right",
              })
            navigate('/login')
        }
        if (currentUser.data.type === "geeks") {
            toast.warn("You Cannot access geeks profiles because you are  a geek", {
                position: "top-right",
              })
        }
        else {
            navigate(`/${data}/${id}`)
        }
        console.log(data, "dssfs")
    }


    const [discountModal, setDiscountModal] = useState(false);

    const [discountType, setDiscountType] = useState({ value: 'Fixed Amount', label: 'Fixed Amount' });
    const options = [
        { value: 'Fixed Amount', label: 'Fixed Amount' },
        { value: 'Free Shipping', label: 'Free Shipping' },
        { value: 'Percentage', label: 'Percentage' },
    ];

    const [startDate, setStartDate] = useState(Date.parse('04 Dec 2021 00:12:00 GMT'));
    const [endDate, setEndDate] = useState(Date.parse('11 Dec 2021 00:12:00 GMT'));

    const [open, setOpen] = React.useState(false);
    const [profUuid, setProfUuid] = useState('');

    const handleOpen = (uuid) => {
        setProfUuid(uuid)
        setOpen(true);
    }

    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        width: '50%', // Adjust the width as needed
        height: 'auto', // Adjust the height as needed
    };


    return (
        <>
            <div className="container">
                <h2>Search Results For: </h2>
                <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">

                    {SearchDetails?.data?.map((item, index) => (
                        <>
                         {console.log(item, "gshdvjdshjdgsjdk")}
                        <div className="group bg-white dark:bg-slate-900 relative overflow-hidden rounded-md shadow dark:shadow-gray-700 text-center p-6" key={index}>
                            <img src={`${process.env.REACT_APP_IMAGE_BASEURL}/${item?.geeks_details?.personal_details?.profile_pic}`} crossOrigin='anonymous' className="h-20 w-20 rounded-full shadow dark:shadow-gray-700 mx-auto" alt="" />

                            <div className="mt-2">
                                <Link className="hover:text-emerald-600 font-semibold text-lg">{item?.first_name} {item?.last_name}</Link>
                                <p className="text-sm text-slate-400">{location?.state?.name}</p>
                            </div>

                            <ul className="mt-2 list-none space-x-0.5">
                                {item?.geeks_details?.technical_expertise?.skills?.map((item) => {
                                    return <li className="inline"><span className="bg-emerald-600/10 inline-block text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full">{item}</span></li>
                                })}
                            </ul>

                            <div className="flex justify-between mt-2">
                                <div className="block">
                                    <span className="text-slate-400">Salery</span>
                                    <span className="block text-sm font-semibold">$4k - $4.5k</span>
                                </div>
                                <div className="block">
                                    <span className="text-slate-400">Experience:</span>
                                    <span className="block text-sm font-semibold">{item?.geeks_details?.technical_expertise?.years_of_experience} years</span>
                                </div>
                            </div>

                            <div className="mt-3">
                                <Button onClick={() => CheckRole('seeker/candidate-profile', `${item.uuid}`)} className="btn btn-sm bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-md">Profile</Button>&nbsp;&nbsp;
                                <Button onClick={() => handleOpen(item.uuid)} className="btn btn-sm bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-md">Hire Me</Button>
                            </div>

                            {/* <span className="w-24 text-white p-1 text-center absolute ltr:-rotate-45 rtl:rotate-45 -start-[30px] top-3 bg-yellow-400 flex justify-center"><AiOutlineStar /></span>

                            <span className="absolute top-[10px] end-4">
                                <Link to="#" className="text-slate-100 dark:text-slate-700 focus:text-red-600 dark:focus:text-red-600 hover:text-red-600 dark:hover:text-red-600 text-2xl"><i className="mdi mdi-heart"></i></Link>
                            </span> */}
                        </div>
                        </>
                    ))}
            </div>

            <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                <div className="md:col-span-12 text-center">
                    <nav aria-label="Page navigation example">
                        <ul className="inline-flex items-center -space-x-px">
                            <li>
                                <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">
                                    <MdKeyboardArrowLeft className="text-[20px] rtl:rotate-180 rtl:-mt-1" />
                                </Link>
                            </li>
                            <li>
                                <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">1</Link>
                            </li>
                            <li>
                                <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">2</Link>
                            </li>
                            <li>
                                <Link to="#" aria-current="page" className="z-10 w-[40px] h-[40px] inline-flex justify-center items-center text-white bg-emerald-600 border border-emerald-600">3</Link>
                            </li>
                            <li>
                                <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">4</Link>
                            </li>
                            <li>
                                <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">5</Link>
                            </li>
                            <li>
                                <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">
                                    <MdKeyboardArrowRight className="text-[20px] rtl:rotate-180 rtl:-mt-1" />
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div >

            <div>
                {/* <Button onClick={handleOpen}>Open modal</Button> */}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    dialogClassName="full"

                >
                    <Box sx={style} className='ContainerForm' >
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <img src={closeButton} alt='closeButton' style={{ width: "40px", height: "40px", cursor: "pointer" }} onClick={handleClose} />
                        </div>
                        <Form uuid={profUuid} handleClose={handleClose} />
                    </Box>
                </Modal>
            </div>

        </>
    )
}
