import React, { useEffect, useState } from 'react'
import { ImageGroup, Image } from 'react-fullscreen-image'
import Navbar from '../../components/Navbar'
import Works from '../../components/How-Work'
import WorkDone from '../../components/WorkDone'
import BrowseByCategories from '../../components/BrowseByCategories'
import PopularJobsSeven from '../../components/Popular-Jobs-Seven'
import QuesAnswer from '../../components/Question-Answer'
import Footer from '../../components/Footer'
import { Link, useNavigate } from "react-router-dom";
import ModalVideo from 'react-modal-video'
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import Select from 'react-select'
import ab05 from '../../assets/images/about/ab05.jpg';
import BestCompanies from '../../components/Best-companies';
import { BiBriefcaseAlt2, PiMapPin, BiCheckCircle } from "../../assets/icons/vander"
import MillionsJob from '../../components/Millions-job';
import ExploreJob from '../../components/Explore-job'
import Feature from '../../components/Feature';
import {MdOutlineArrowForward, BsTelephone, FaAtom, FiUserCheck, IoMdHourglass, BsBriefcase, TbUsersGroup} from "../../assets/icons/vander"

import { Grid } from '@mui/material';
import ab03 from '../../assets/images/about/ab03.jpg';
import arrow from '../../assets/images/arrow.png'
import { useSelector, useDispatch } from 'react-redux'
import { featuredServicesURL } from '../../Redux/services/servicesSlice'
import './IndexSeven.css'
import JobGridsFourComp from '../../components/job-grids-four-comp';
import closeButton from "../../assets/images/closeButton.png";
import { Button, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Typography from "@mui/material/Typography";
import NewJobPost from '../../components/NewJobPost'
import { GridList } from '@material-ui/core';
import searchIcon from '../../assets/images/search.png';
import { allJobsList } from '../../Redux/geeks/jobSlice'
import axios from "axios";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import image1 from '../../assets/images/team/01.jpg'
import image2 from '../../assets/images/team/02.jpg'
import image3 from '../../assets/images/team/03.jpg'
import image4 from '../../assets/images/team/04.jpg'
import image5 from '../../assets/images/team/05.jpg'
import image6 from '../../assets/images/team/06.jpg'
import image7 from '../../assets/images/team/07.jpg'





const optionsOne = [
    { value: 'AF', label: 'Afghanistan' },
    { value: 'AZ', label: ' Azerbaijan' },
    { value: 'BS', label: 'Bahamas' },
    { value: 'BH', label: 'Bahrain' },
    { value: 'CA', label: 'Canada' },
    { value: 'CV', label: 'Cape Verde' },
    { value: 'DK', label: 'Denmark' },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'EE', label: 'Estonia' },
    { value: 'GM', label: 'Gambia' },
]

const optionsTwo = [
    { value: '1', label: 'Full Time' },
    { value: '2', label: 'Part Time' },
    { value: '3', label: 'Freelancer' },
    { value: '4', label: 'Remote Work' },
    { value: '5', label: 'Office Work' },

]



const placeholderText = ["one", "two", "three"];

export default function IndexSeven({ deviceType }) {
    // const [isOpen, setOpen] = useState(false)

    const dispatch = useDispatch();
    const { featuredServices } = useSelector((state) => state.service);

    const { currentUser } = useSelector((state) => state.auth);

    const navigate = useNavigate();

    console.log(currentUser, "currentUser1223")

    useEffect(() => {
        dispatch(featuredServicesURL(0, 5, ''))
    }, [])

    const [selectedTab, setSelectedTab] = useState(0);

    const InstructionFunction = () => {
        toast.error("You are a Seeker, You Can't Apply a Job.",{
            position:"top-right"
        })
    }

    const InstructionFunction1 = () => {
        toast.error("You are a Geek, You Can't Post the Job.", {
            position: "top-right",
          })
    }

    const Alertfunction = () => {
        toast.error("Please login and try !",{
            position:"top-right"
        })
    }

    const NavigateCandidate = (data) => {
        console.log(data, "data")
        navigate(`/service/${data?.name}`, {
            state : data
        })
    }

    const [inputValue, setInputValue] = useState('');
    const [values, setValues] = useState([]);
    console.log(values?.toString(), "inputValue")

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputKeyPress = (e) => {
        console.log(e.key, "dfhvgcdsdh")
        if (e.key === 'Enter' || e.key === ',' || e.key === " ") {
            e.preventDefault(); // Prevent Enter key from adding a line break in the input
            const newValues = inputValue
                .split(',')
                .map((value) => value.trim())
                .filter((value) => value !== ''); // Remove empty values

            setValues([...values, ...newValues]);
            setInputValue('');
        }
    };

    const [open, setOpen] = React.useState(false);
    // const [profUuid, setProfUuid] = useState("");
  
    const handleOpen = () => {
      // setProfUuid(uuid)
      setOpen(true);
    };
  
    const handleClose = () => setOpen(false);

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        // width: 400,
        bgcolor: "background.paper",
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        width: "50%", // Adjust the width as needed
        height: "auto", // Adjust the height as needed
      };


      const [autoPlay, setAutoPlay] = useState(deviceType !== "mobile");


      const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3,
          slidesToSlide: 3 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };





      const [isHovered, setIsHovered] = useState(false);

      const handleMouseEnter = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };
      



    //   const [index, setIndex] = useState(0);

    //   useEffect(() => {
    //     const timer = () => {
    //       setIndex(prevIndex => {
    //         if(prevIndex === placeholderText.length - 1){
    //           return 0;
    //         } 
    //         return prevIndex + 1;
    //       })
    //     };
    //     setInterval(timer, 2000);
        
    //     //cleanup function in order clear the interval timer
    //     //when the component unmounts
    //     return () => { clearInterval(timer); }
    //   }, []);



    // const [inputValue, setInputValue] = useState('');
    const [searchKey, setSearchKey] = useState('');
    const [index, setIndex] = useState(0);
  
    const placeholderTexts = ['Printer Issues', 'Email Problems', 'Wifi Connectivity' , 'Antivirus Software' , 'Operation System Errors'];

    useEffect(() => {
            const timer = () => {
              setIndex(prevIndex => {
                if(prevIndex === placeholderTexts?.length - 1){
                  return 0;
                } 
                return prevIndex + 1;
              })
            };
            setInterval(timer, 1000);
            
            //cleanup function in order clear the interval timer
            //when the component unmounts
            return () => { clearInterval(timer); }
          }, []);
  
    const handleChange = (event) => {
      setInputValue(event?.target?.value);
      setSearchKey(event?.target?.value);
    };
  
    const handleSearch = () => {
      // Your search logic here...
      // For simplicity, let's just toggle between the placeholders based on the search key length.
      const newIndex = searchKey.length % placeholderTexts?.length;
      setIndex(newIndex);
    };

    const [fullscreen, setFullscreen] = useState(false);
    const [currentImage, setCurrentImage] = useState('');
  
    const openFullscreen = (Images) => {
      setCurrentImage(Images);
      setFullscreen(true);
    };
  
    const closeFullscreen = () => {
      setCurrentImage('');
      setFullscreen(false);
    };

    

    const Images = [
        'https://unsplash.com/photos/two-bowls-of-soup-with-broccoli-and-other-vegetables-_Y3JtBGF6Qg',
        'https://unsplash.com/photos/hS46bsAASwQ',
        'https://unsplash.com/photos/2VDa8bnLM8c',
        'https://unsplash.com/photos/_LuLiJc1cdo',
        'https://unsplash.com/photos/1Z2niiBPg5A',
        'https://unsplash.com/photos/pHANr-CpbYM',
        'https://unsplash.com/photos/pQMM63GE7fo',
        'https://unsplash.com/photos/2VDa8bnLM8c',
        'https://unsplash.com/photos/MBkQKiH14ng',
      ]



    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            <section className="relative flex md:h-screen h-auto items-center bg-[url('../../assets/images/hero/bg4.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute top-0 start-0 w-full h-full z-0 pointer-events-none overflow-hidden">
                    <iframe title="iframe-1" src="https://player.vimeo.com/video/354421650?background=1&autoplay=1&loop=1&byline=0&title=0" className="absolute top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2  -translate-y-1/2 w-screen h-[56.25vw] min-h-screen min-w-[177.77vw]"></iframe>


                    <iframe title="iframe-2" src="https://www.youtube.com/embed/JsNvHJsufhI?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1" className="absolute top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2  -translate-y-1/2 w-screen h-[56.25vw] min-h-screen min-w-[177.77vw]"></iframe>

                </div>
                {/* <div className="absolute inset-0 bg-emerald-900/80"></div> */}
                <div className="absolute inset-0 bg-emerald-900/90"></div>

                <div className='grid md:grid-cols-2  gap-[100px]' style={{ marginTop: "100px" }}>


            {/* <div style={{ marginLeft: "50px"}} className='IndexSeven'>
                        <div className="grid grid-cols-1 text-center  mt-1 relative" style={{ backgroundColor: "white", borderRadius: "20px", padding: "50px" }}>
                            <h4 className="lg:leading-normal leading-normal text-2xl lg:text-3xl mb-2 font-semibold text-black">Hardware and Software Solution</h4>
                            <p className="text-black/50 text-lg max-w-xl mx-auto">What are you looking for?</p>



                            <section className="relative ">
                                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] mt-5">
                                    {featuredServices?.map((item, index) => {
                                        console.log(`${process.env.REACT_APP_API_BASEURL}/user/images/${item.image}`, "image")
                                        return <div onClick={() => NavigateCandidate(item)} className="group relative overflow-hidden  dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 cursor-pointer" style={{border:"1px solid #059669"}}>
                                              <div style={{ width: "100%", height: "100%" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img src={`${process.env.REACT_APP_IMAGE_BASEURL}/${item.image}`} crossOrigin='anonymous'  alt="" style={{ width: "240px", height: "160px"}} />
                                            </div>
                                            <p className='p-3' style={{fontSize:"14px", fontWeight:"600"}}>{item?.name}</p>
                                            </div>

                                            
                                          
                                        </div>



                                    })}

                                    <Link to='/MoreService'>
                                        <div className="group relative overflow-hidden dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700" style={{border:"1px solid #059669"}}>
                                            <div style={{ width: "100%", height: "100%" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img src={arrow} alt="" style={{ width: "160px", height: "160px"}}  />
                                            </div>
                                            </div>
                                            <p className='p-3' style={{fontSize:"14px", fontWeight:"600"}}>More Service</p>
                                        </div>
                                    </Link>
                                </div>
                            </section>
                    </div>
</div> */}


<div style={{ marginLeft: "50px"}} className='IndexSeven'>
                        <div className="grid grid-cols-1 text-center  mt-1 relative" style={{ backgroundColor: "white", borderRadius: "20px", padding: "30px" }}>
                            {/* <h4 className="lg:leading-normal leading-normal text-lg lg:text-3xl mb-2 font-semibold text-black">Hardware and Software Solution</h4> */}
                            <div style={{ height:"18vh" ,display: "flex", justifyContent: "center", alignItems: "center",}}>
                                <div>
                                <p className="text-black/50 text-lg max-w-xl mx-auto font-semibold">What are you looking for?</p>
                                <p style={{ padding:"5px 20px",fontSize:"12px" }}>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                </div>
                         
                            
                            </div>
                      
                            


                            <section className="relative ">
                                <div className="grid lg:grid-cols-3 md:grid-cols-2  grid-cols-2 gap-[15px]" >
                                    {featuredServices?.map((item, index) => {
                                        console.log(`${process.env.REACT_APP_API_BASEURL}/user/images/${item.image}`, "image")
                                        return <div onClick={() => NavigateCandidate(item)} className="group relative overflow-hidden  cursor-pointer" >
                                              <div >
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center",padding:"8px 30px" ,backgroundColor:"#f5f5f5",borderRadius:"7px", height:"15vh" }}>
                                                <img src={`${process.env.REACT_APP_IMAGE_BASEURL}/${item.image}`} crossOrigin='anonymous'  alt="" style={{ width: "auto", maxHeight: "100%",borderRadius:"5px"}} />
                                            </div>
                                            <p className='p-1' style={{fontSize:"14px", fontWeight:"300"}}>{item?.name}</p>
                                            </div>
                                          
                                            
                                          
                                        </div>



                                    })}

                                    <Link to='/services'>
                                        <div className="group relative overflow-hidden rounded-md " >
                                           
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center",padding:"8px 30px" ,backgroundColor:"#f5f5f5",borderRadius:"10px", height:"15vh"}}>
                                                <img src={arrow} alt="" style={{ width: "70px", height: "70px"}}  />
                                            </div>
                                           
                                            <p className='p-1' style={{fontSize:"14px", fontWeight:"300"}}>More Service</p>
                                        </div>
                                    </Link>
                                </div>
                            </section>
                    </div>
</div>
               
                    <div className=" z-1">
                        <div className="grid grid-cols-1 text-center mt-1 relative">
                            <h4 className="lg:leading-normal leading-normal text-4xl lg:text-3xl mb-5 font-bold text-white">Hire a Geek Today</h4>
                            <p className="text-white/50 text-lg max-w-xl mx-auto">Get tech-savvy with GeekOnDemand, your ultimate hub for hiring certified tech geeks. From software troubleshooting to device setup, our geeks are on standby, ready to solve your tech woes.</p>

                            <div className="d-flex" id="reserve-form">
                                <div className="md:w-5/6 mx-auto">
                                    <div className="lg:col-span-10 mt-8" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <div className="bg-white dark:bg-slate-900 border-0 shadow rounded-md p-3" style={{ width: "75%" }}>
                                            {/* <form action="#"> */}
                                            <div className="registration-form text-dark text-start"  >
                                                <Grid item container>
                                                    <Grid xs={10} md={11} className="filter-search-form relative filter-border">
                                                        <BiBriefcaseAlt2 className="icons" />
                                                        {/* <input name="name" type="text" id="job-keyword" className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" onChange={handleInputChange} placeholder="Search your Keywords" /> */}
                                                        <input
                                                            type="text"
                                                            placeholder={`Search for ${placeholderTexts[index]}`}
                                                            preText= "Eg."
                                                            value={inputValue}
                                                            onChange={handleInputChange}
                                                            onKeyPress={handleInputKeyPress}
                                                            className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0 slow-motion-text"
                                                        />
                                                    </Grid>

                                                    {/* <div className="filter-search-form relative filter-border">
                                                        <PiMapPin className="icons"/>
                                                        <Select className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={optionsOne} />

                                                    </div> */}

                                                    {/* <div className="filter-search-form relative filter-border">
                                                        <BiBriefcaseAlt2 className="icons"/>
                                                        <Select className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={optionsTwo} />

                                                    </div> */}
                                                    <Grid xs={2} md={1} className="filter-search-form relative filter-border" style={{backgroundColor:"#1daa82"}}>
                                                    <Link to={`/CandidateSearch/${values?.length <= 0 ? inputValue : values?.toString()}`}  >
                                                        <img src={searchIcon} alt='searchIcon' style={{width:"55px",height:"55x",marginTop:"8px"}}/>
                                                        {/* <input type="button" id="search" name="search" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white searchbtn submit-btn w-50" value="Search" /> */}
                                                        </Link>
                                                        </Grid>


                                                </Grid>

                                            </div>
                                            {/* </form> */}
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="mt-4">
                                <span className="text-white/60"><span className="text-white">Popular Searches :</span> Email Problems, Wifi Connectivity, Antivirus Software </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <Works />
                
                <div className="container md:mt-24 mt-16">
                    {/* <form >
                                <div >
                                    <div className="grid lg:grid-cols-2 md:grid-cols-6 sm:grid-cols-12 grid-cols-1" style={{ display: "flex", justifyContent: "center", marginBottom: "5%" }}>
                                        <div className=" p-6  text-center border cursor-pointer " style={{borderRadius:"50px", margin: "15px" , backgroundColor : selectedTab == 0 ? 'rgb(5 150 105 / 0.05)' : '#fff'}} onClick={() =>{setSelectedTab(0);}}>
                                            
                                            <div className="mt-4">
                                                <Link to="#" className="text-lg font-semibold hover:text-emerald-600 transition-all duration-500" >Individual</Link>
                                            </div>
                                        </div>
                                    
                                        <div className=" p-6 text-center border cursor-pointer" style={{ borderRadius:"50px",margin: "15px" , backgroundColor : selectedTab == 1 ? 'rgb(5 150 105 / 0.05)' : '#fff'}} onClick={() =>{setSelectedTab(1);}}>
                                           
                                            <div className="mt-4">
                                                <Link to="#" className="text-lg font-semibold hover:text-emerald-600 transition-all duration-500" >Corporate</Link>
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                              
                              
                            </form> */}
                    <div className="grid grid-cols-1">
                        <div className="relative overflow-hidden bg-emerald-600 rounded-md shadow dark:shadow-gray-700">
                            <div className="grid md:grid-cols-2 items-center gap-[30px]">
                                <div className="relative">
                                    <img src={ab05} alt="" />
                                    <div className="absolute md:bottom-1/2 md:translate-y-1/2 md:-end-10 ltr:md:translate-x-0 rtl:md:translate-x-0 -bottom-10 end-1/2 ltr:translate-x-1/2 rtl:-translate-x-1/2 text-center">
                                        <Link to="/"
                                        //  onClick={() => setOpen(true)}
                                            data-type="youtube" data-id="S_CGed6E610" className="lightbox h-20 w-20 rounded-full  inline-flex items-center justify-center " style={{ backgroundColor:"rgb(214, 167, 59)",color:"white" }}>
                                            <i className="mdi mdi-play inline-flex items-center justify-center text-2xl">
                                                {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/68Z4IjIqpcY?si=ZIo2-HW3D2fxTKI0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                                            </i>
                                        </Link>
                                    </div>
                                </div>

                                <div>
                                    <div className="text-white p-4">
                                        <h4 className="leading-normal text-4xl mb-3 font-semibold">Find Your Geek</h4>

                                        <p className="text-white/70 text-lg max-w-xl">Unlock the power of tech expertise with GeekOnDemand! Whether you need an app developer, a website wizard, or a data analyst, we've got a geek for that. Our talented pool of freelancers are ready to help you with your project, big or small.</p>

                                        <ul className="list-none text-white/50 mt-4">
                                            <li className="mb-1 flex items-center"><BiCheckCircle className="text-white text-xl me-2" /> Why wait? Find your perfect match today and bring your tech dreams to life.</li>
                                            <li className="mb-1 flex items-center"><BiCheckCircle className="text-white text-xl me-2" /> Resume the control of your tech projects now! Find Your Geek Now</li>
                                            {/* <li className="mb-1 flex items-center"><BiCheckCircle className="text-white text-xl me-2"/> Create your own skin to match your brand</li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative md:py-10 py-10">
                    <WorkDone />
                    {/* <Feature/> */}
                </div>

                {/* <BrowseByCategories /> */}
            </section>
            <section className="py-24 w-full table relative bg-[url('../../assets/images/hero/bg3.jpg')] bg-center bg-no-repeat bg-cover jarallax" data-jarallax data-speed="0.5">
                <div className="absolute inset-0 bg-slate-900/40"></div>
                <div className="container relative">
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-[30px]">
                        <div>
                            <h5 className="text-xl font-semibold text-white">Register for Candidate or User</h5>

                            <ul className="list-none text-white/50 mt-4">
                                <li className="mb-1 flex items-center"><BiCheckCircle className="text-white text-xl me-2" />  It has survived not only five centuries</li>
                                <li className="mb-1 flex items-center"><BiCheckCircle className="text-white text-xl me-2" />  There are many variations of passages</li>
                            </ul>

                            <div className="mt-4">
                                {currentUser?.data?.type === "geeks" ?
                                    <Link to="/Joinning" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full">Apply Now</Link>
                                    :
                                    <button onClick={InstructionFunction} className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full">Apply Now</button>
                                }
                            </div>
                        </div>

                        <div>
                            <h5 className="text-xl font-semibold text-white">Register for Companies</h5>

                            <ul className="list-none text-white/50 mt-4">
                                <li className="mb-1 flex items-center"><BiCheckCircle className="text-white text-xl me-2" />  Many desktop publishing packages</li>
                                <li className="mb-1 flex items-center"><BiCheckCircle className="text-white text-xl me-2" />  Contrary to popular belief</li>
                                <li className="mb-1 flex items-center"><BiCheckCircle className="text-white text-xl me-2" />  It is a long established fact that a reader</li>
                            </ul>

                            <div className="mt-4">

                                {currentUser?.data?.type === "seeker" ?
                                    <Link 
                                    // to="/Joinning"
                                     className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full"  onClick={() => handleOpen()}>Post Jobs</Link>
                                    :
                                    <button onClick={InstructionFunction1} className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full">Post Jobs</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            
            <section className="relative md:py-24">
                <JobGridsFourComp />
                <div className="container md:mt-24 md:pb-24 pb-16 mt-16">
                    <MillionsJob />
                </div>
                <QuesAnswer />
                <BestCompanies />
                <ExploreJob />
                {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="S_CGed6E610" onClose={() => setOpen(false)} /> */}
            </section>

            <div className="container md:mt-2 mb-8">
                <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    ssr={true}
                    infinite={true}
                    autoPlay={autoPlay}
                    autoPlaySpeed={500}
                    keyBoardControl={false}
                    customTransition="all .3"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    deviceType={deviceType}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    style={{ cursor:"pointer" }}
                    
                    >
      <div>

      <figure class="snip1390"><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/profile-sample6.jpg" alt="profile-sample6" class="profile" />
  <figcaption>
    <h2>Sue Shei</h2>
    <h4>Public Relations</h4>
    <blockquote>The strength to change what I can, the inability to accept what I can't and the incapacity to tell the difference.</blockquote>
  </figcaption>
</figure>


</div>
<div>

    
<figure class="snip1390 hover"><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/profile-sample5.jpg" alt="profile-sample5" class="profile" />
  <figcaption>
    <h2>Gordon Norman</h2>
    <h4>Accountant</h4>
    <blockquote>Wormwood : Calvin, how about you? Calvin : Hard to say ma'am. I think my cerebellum has just fused. </blockquote>
  </figcaption>
</figure>

</div>
<div>

<figure class="snip1390">
  <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/profile-sample3.jpg" alt="profile-sample3" class="profile" />
  <figcaption>
    <h2>Eleanor Crisp</h2>
    <h4>UX Design</h4>
    <blockquote>Dad buried in landslide! Jubilant throngs fill streets! Stunned father inconsolable - demands recount!</blockquote>
  </figcaption>
</figure>

</div>
<div>

<figure class="snip1390">
  <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/profile-sample3.jpg" alt="profile-sample3" class="profile" />
  <figcaption>
    <h2>Eleanor Crisp</h2>
    <h4>UX Design</h4>
    <blockquote>Dad buried in landslide! Jubilant throngs fill streets! Stunned father inconsolable - demands recount!</blockquote>
  </figcaption>
</figure>

</div>

<div>
<figure class="snip1390 hover"><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/profile-sample5.jpg" alt="profile-sample5" class="profile" />
  <figcaption>
    <h2>Gordon Norman</h2>
    <h4>Accountant</h4>
    <blockquote>Wormwood : Calvin, how about you? Calvin : Hard to say ma'am. I think my cerebellum has just fused. </blockquote>
  </figcaption>
</figure>
</div>
<div>
<figure class="snip1390">
  <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/profile-sample3.jpg" alt="profile-sample3" class="profile" />
  <figcaption>
    <h2>Eleanor Crisp</h2>
    <h4>UX Design</h4>
    <blockquote>Dad buried in landslide! Jubilant throngs fill streets! Stunned father inconsolable - demands recount!</blockquote>
  </figcaption>
</figure>
</div>
    </Carousel>

    </div>
            
  

            <Footer />


            <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        dialogClassName="full"
      >
        <Box sx={style} className="ContainerForm">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div></div>
            <div>
              <p style={{ fontSize: "25px", fontWeight: "bold" }}>
                New Job Post
              </p>
            </div>
            <div>
              <img
                src={closeButton}
                alt="closeButton"
                style={{ width: "40px", height: "40px", cursor: "pointer" }}
                onClick={handleClose}
              />
            </div>
          </div>
          <NewJobPost />
        </Box>
      </Modal>


        </>
    )
}
