import React from 'react'
import { Link } from 'react-router-dom';
import {MdOutlineArrowForward, BsTelephone, FaAtom, FiUserPlus, AiOutlineHourglass} from "../assets/icons/vander"

export default function  WorkDone() {
    const featuredata = [
        {
            icon: AiOutlineHourglass,
            title: 'Skill Discovery',
            data: 'If you are on the hunt for a tech whizz to solve your digital dilemmas, "GeekOnDemand Pay Safe" is your knight in shining armor. This extraordinary feature ensures a secure, straightforward and stress-free process of hiring geeks.'
        },
        {
            icon: FaAtom,
            title: 'Direct Engagement',
            data: 'At GeekOnDemand, we pride ourselves on our dedicated support - because we know the importance of swift and reliable help when you are dealing with IT challenges. Our team of geeks are always ready to provide assistance, whether you are facing a minor glitch or a major hurdle.'
        },
        {
            icon: FiUserPlus,
            title: 'Cost Mitigation',
            data: 'MAt GeekOnDemand, we pride ourselves on delivering exceptional service. Our team of skilled tech freelancers are committed to ensuring you are over-the-moon with the results of your project. In fact, we are not satisfied until you are - guaranteed.'
        },
        {
            icon: FiUserPlus,
            title: 'Improved Budget Allocation',
            data: 'MAt GeekOnDemand, we pride ourselves on delivering exceptional service. Our team of skilled tech freelancers are committed to ensuring you are over-the-moon with the results of your project. In fact, we are not satisfied until you are - guaranteed.'
        },
        // {
        //     icon: AiOutlineHourglass,
        //     title: 'Save Time',
        //     data: 'Many desktop publishing now use and a search for job'
        // },
    ];

    return (

        <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center">
                <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">Features</h3>

                {/* <p className="text-slate-400 max-w-xl mx-auto">Search all the open positions on the web. Get your own personalized salary estimate. Read reviews on over 30000+ companies worldwide.</p> */}
            </div>

            <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 mt-2 gap-[30px]" >
                {featuredata.map((item, index) => {
                    let Icon = item.icon
                    return(
                        (

                            <div key={index} className="group p-6 shadow  text-center transition-all duration-500" >
                                <div className="w-16 h-16 flex items-center justify-center mx-auto bg-emerald-600/5 group-hover:bg-emerald-600 dark:bg-emerald-600/10 dark:group-hover:bg-emerald-600 shadow dark:shadow-gray-700 rounded-lg transition-all duration-500">
                                    <Icon className=' text-[30px] text-slate-icon-400'/>
                                </div>
        
                                <div className="mt-4">
                                    <Link to="#" className="text-lg font-semibold  transition-all duration-500" >{item.title}</Link>
        
                                    <p className="text-slate-400 mt-3 mb-2">{item.data}</p>
        
                                    {/* <Link to="#" className="hover:text-emerald-600 font-medium transition-all duration-500 inline-flex items-center">Read More <MdOutlineArrowForward className='ms-1'/></Link> */}
                                </div>
                            </div>
        
                        )
                    )
                })}
            </div>
        </div>
    )
}

