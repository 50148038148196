import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Navbar from "../../components/Navbar";
import bg5 from "../../assets/images/hero/bg5.jpg";
import team1 from "../../assets/images/team/01.jpg";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import Select from 'react-select'
import { Twitter, Facebook, Instagram, Linkedin, Youtube } from "react-feather";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'



export default function SeekersProfileSetting() {
  const [data, setData] = useState("");
  const TextEditor = (editor) => {
    const data = editor.getData();
    setData(data);
  };

  const [profiletype, setProfiletype] = useState("");
  const [firstname, setFirstname] = useState("");
  console.log(firstname, "dffafasda");
  const [lastname, setLastname] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [adharnumber, setAdharnumber] = useState("");
  const [pannumber, setPannumber] = useState("");
  const [profilepic, setProfilepic] = useState("");

  const { currentUser } = useSelector((state) => state.auth);

  console.log(currentUser, "currentUser");

  const handleSubmitSeekersProfileSetting = (e) => {
    e.preventDefault();

    const payload = {
      profile_type: "individual",
      first_name: firstname,
      last_name: lastname,
      gender: gender,
      dob: dob,
      country: country,
      address: address,
      city: city,
      state: state,
      pincode: pincode,
      adhar_number: adharnumber,
      pan_number: pannumber,
      profile_pic: profilepic,
      description: data,
    };
    axios.put(
      `${process.env.REACT_APP_API_BASEURL}/user/seeker/profile/update/${currentUser?.data?.uuid}`,
      payload,
      {
        headers: {
          "x-auth-token": currentUser?.token,
        },
      }
    );
  };

  const options = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
    { value: 'option4', label: 'Option 4' },
  ];

  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (selected) => {
    setSelectedOption(selected);
  };

  //     useEffect(() => {
  //       setServicesSkill(profileData?.geeks_details?.technical_expertise?.services)
  //       getSkillsList();
  //   },[])

  const [Images, setImages] = useState("");
  const [coverimage, setcoverImage] = useState("");

  const ImageUpload = (event, type) => {
    const data1 = event.target.files[0];
    const formData = new FormData();
    formData.append("file", data1);
    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/upload/image`, formData)
      .then((res) => {
        // console.log(res.data.image.filename)
        setImages(res.data.image.filename);
      })
      .catch((err) => {
        console.log(err.response);
        setcoverImage("");
      });
  };


  const [phone, setPhone] = useState('');


  return (
    <>
      <Navbar />
      <section className="relative lg:mt-24 mt-[74px] pb-16">
        <div className="lg:container container-fluid">
          <div className="profile-banner relative text-transparent">
            <input
              id="pro-banner"
              name="profile-banner"
              type="file"
              className="hidden"
            />
            <div className="relative shrink-0">
              <img
                src={bg5}
                className="h-64 w-full object-cover lg:rounded-xl shadow dark:shadow-gray-700"
                id="profile-banner"
                alt=""
              />
              <label
                className="absolute inset-0 cursor-pointer"
                htmlFor="pro-banner"
              ></label>
            </div>
          </div>
          <div className="md:flex mx-4 -mt-12">
            <div className="md:w-full">
              <div className="relative flex items-end">
                <div className="profile-pic text-center">
                  <input
                    id="pro-img"
                    name="profile-image"
                    type="file"
                    className="hidden"
                  />
                  <div>
                    <div className="relative h-28 w-28 max-w-[112px] max-h-[112px] mx-auto">
                      <img
                        src={team1}
                        className="rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800"
                        id="profile-image"
                        alt=""
                      />
                      <label
                        className="absolute inset-0 cursor-pointer"
                        htmlFor="pro-img"
                      ></label>
                    </div>
                  </div>
                </div>
                <div className="ms-4">
                  <h5 className="text-lg font-semibold">
                    {/* {details?.full_name} */}
                    MD RAFIUDDIN
                  </h5>
                  <p className="text-slate-400">
                    {/* {data?.title ? data?.title : "UI Designer"} */}
                    Hyderabad, India
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-16">
          <div className="grid grid-cols-1 gap-[30px]">
            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
              <h5 className="text-lg font-semibold mb-4">Personal Detail :</h5>
              <form onSubmit={handleSubmitSeekersProfileSetting}>
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                  <div>
                    <label className="form-label font-medium">
                      First Name : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="Enter"
                      id="firstname"
                      name="name"
                      required=""
                      onChange={(e) => setFirstname(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Last Name : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="Enter"
                      id="lastname"
                      name="name"
                      required=""
                      onChange={(e) => setLastname(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Your Email : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="Enter"
                      name="email"
                      required=""
                      onChange={(e) => setPannumber(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Phone Number : <span className="text-red-600">*</span>
                    </label>

                    <PhoneInput
                        country={'in'}
                        value={phone}
                        onChange={newPhone => setPhone(newPhone)}
                      className="mt-2"
                        />

                    <input
                      type="number"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="Enter"
                      name="email"
                      required=""
                      onChange={(e) => setFirstname(e.target.value)}
                    />

                  </div>
                  <div>
                    <label className="form-label font-medium">Gender :</label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="Enter"
                      name="dob"
                      required=""
                      onChange={(e) => setGender(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Date of birth : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="Enter"
                      name="dob"
                      required=""
                      onChange={(e) => setDob(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      City : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="Enter"
                      name="City"
                      required=""
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      State : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="Enter"
                      name="State"
                      required=""
                      onChange={(e) => setState(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Country : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="Enter"
                      name="City"
                      required=""
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Address : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="Enter"
                      name="City"
                      required=""
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      ZIP Code : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="Enter"
                      name="zipcode"
                      required=""
                      onChange={(e) => setPincode(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Aadhar Number : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="Enter"
                      name="aadhar"
                      required=""
                      onChange={(e) => setAdharnumber(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      PAN Number : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="Enter"
                      name="aadhar"
                      required=""
                      onChange={(e) => setPannumber(e.target.value)}
                    />
                  </div>
                </div>

                <div className="mt-5">
                  <label className="form-label font-medium">Address : </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={data}
                    onReady={(editor) => {
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => TextEditor(editor)}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                </div>
                <div className="mt-5">
                  <input
                    type="submit"
                    id="submit"
                    name="send"
                    className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md"
                    value="Save Changes"
                  />
                </div>
              </form>
            </div>
            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
              <h5 className="text-lg font-semibold mb-5 text-red-600">
                Delete Account :
              </h5>
              <p className="text-slate-400 mb-4">
                Do you want to delete the account? Please press below "Delete"
                button
              </p>
              <Link
                to="#"
                className="btn bg-red-600 hover:bg-red-700 text-white rounded-md"
              >
                Delete
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
