// home three //

import React from 'react'
import { Link } from 'react-router-dom';
import {MdOutlineArrowForward,FiAirplay, MdShutterSpeed, TbCameraPlus} from "../assets/icons/vander"
export default function Works() {
    const home = [
        {
            icon: FiAirplay,
            title: 'Create a Project',
            deta: 'Begin by clicking on "Post a Project". In this section, detail what you need help with. Be as specific as possible to attract the right geek for the job.',
        },
        {
            icon: MdShutterSpeed,
            title: 'Review Proposals',
            deta: 'Once your project is live, geeks will start sending in their proposals. You can evaluate each based on their skills, experience, and proposed rate.',
        },
        {
            icon: TbCameraPlus,
            title: 'Choose Your Geek',
            deta: 'Found the perfect geek for your task? Great! Simply accept their proposal and your geek is ready to get to work.',
        },
        {
            icon: TbCameraPlus,
            title: 'Get Your Work Done',
            deta: 'With your geek on the job, you can sit back and relax. They will handle the tech stuff while you focus on what you do best.',
        }
    ];

    return (

        <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center">
                <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">How it's Work?</h3>

                <p className="text-slate-400 max-w-xl mx-auto">Imagine you're swamped with tech-related tasks and you're thinking, "If only I could hire a geek on demand." Well, with GeekOnDemand, you can do just that! Here's how:  </p>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 mt-6 gap-[30px]">
                {home.map((item, index) => {
                    let Icon = item.icon
                    return(
                        <div className="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 transition duration-500 rounded-2xl mt-6 text-center" key={index}>
                            <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                <Icon className="text-slate-icon-400"/>
                            </div>
    
                            <div className="content mt-7" >
                                <Link to="#" className="title h5 text-lg font-semibold">{item.title}</Link>
                                <p className="text-slate-400 mt-3">{item.deta}</p>
    
                                {/* <div className="mt-5">
                                    <Link to="#" className="btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 duration-500 ease-in-out inline-flex items-center">Read More <MdOutlineArrowForward className='ms-1'/></Link>
                                </div> */}
                            </div>
    
                        </div>
    
                    )
                })}
            </div>
        </div>

    )
}