import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import bg5 from '../../assets/images/hero/bg5.jpg';
import image from '../../assets/images/team/01.jpg';
import { useSelector } from 'react-redux';
import shree_logo from '../../assets/images/company/shree-logo.png';
import circle_logo from '../../assets/images/company/circle-logo.png';
import Navbar from '../../components/Navbar'
import CandidateDetailComp from '../../components/candidate-detail-comp';
import Footer from '../../components/Footer';
import { Settings, FileText, Gift, Globe, Home, Mail, MapPin, Phone, Server } from 'react-feather';
import { LuMail, BiLogoDribbble, AiOutlineBehance, BiLogoLinkedin, FaFacebookF, IoLogoTwitter, FaInstagram, FiFileText } from "../../assets/icons/vander"
import ExploreJob from '../../components/Explore-job';
import { CandidateList } from '../../data/data';
import axios from 'axios';
import Form from '../../components/Form';
import { Grid } from '@mui/material';
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
// import './CandidateProfile.css'

export default function CandidateProfileforSeeker() {
    const params = useParams();
    const id = params?.id
    const jobUuid = params?.id1
    console.log(params, "params")
    const data = CandidateList.find((jobs) => jobs.id === parseInt(id));

    const { currentUser } = useSelector((state) => state.auth);

    const [details, setDetails] = useState('');
    const [jobDetailes, setJobDetailes] = useState({});


    const GetProfileDetails = () => {
        axios.get(`${process.env.REACT_APP_API_BASEURL}/user/geeks/profile/seeker/${id}`, {
            headers: {
                "x-auth-token": currentUser?.token
            }
        })
            .then((res) => {
                console.log(res.data?.data, "hdsfcgshgfjs")
                setDetails(res.data?.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const JObDetailes = () => {
        axios.get(`${process.env.REACT_APP_API_BASEURL}/jobpost/viewDetailsSeeker/${jobUuid}`, {
            headers: {
                "x-auth-token": currentUser?.token
            }
        })
            .then((res) => {
                console.log(res.data, "response")
                setJobDetailes(res?.data?.data[0])
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        GetProfileDetails()
        JObDetailes()
    }, [])

    const handleClose = () => {
        console.log("Form")
    }

    const staticPart = "${process.env.REACT_APP_IMAGE_BASEURL}/";
    const profilepart = details?.geeks_details?.personal_details?.profile_pic;
    const coverpart = details?.geeks_details?.personal_details?.cover_pic;


    const profile = staticPart + profilepart;
    const cover = staticPart + coverpart;


    function truncate(source, size) {
        return source?.length > size ? source?.slice(0, size - 1) + "…" : source;
      }


        //   dashboard 
    const [state, setState] = useState({
      series: [
        {
          name: "Sales",
          data: [44, 55, 41, 67, 22, 43, 65],
        },
        {
          name: "Revenue",
          data: [13, 23, 20, 8, 13, 27, 15],
        },
      ],
    });
  
    const options = {
      colors: ["#3C50E0", "#80CAEE"],
      chart: {
        fontFamily: "Satoshi, sans-serif",
        type: "bar",
        height: 335,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      responsive: [
        {
          breakpoint: 1536,
          options: {
            plotOptions: {
              bar: {
                borderRadius: 0,
                columnWidth: "25%",
              },
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
          columnWidth: "25%",
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "last",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ["M", "T", "W", "T", "F", "S", "S"],
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        fontFamily: "Satoshi",
        fontWeight: 500,
        fontSize: "14px",
        markers: {
          radius: 99,
        },
      },
      fill: {
        opacity: 1,
      },
    };
  
    const [chartData, setChartData] = useState({
      series: [44, 55, 41, 17, 15],
      options: {
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    });



    return (
        <>
            <Navbar />
            <section className="relative lg:mt-24 mt-[74px]">
                <div className="lg:container container-fluid">
                    <div className="relative shrink-0">
                        <img src={cover} crossOrigin='anonymous' className="h-64 w-full object-cover lg:rounded-xl shadow dark:shadow-gray-700" alt="" />
                    </div>
                    <div className="md:flex ms-4 -mt-12">
                        <div className="md:w-full">
                            <div className="relative flex items-end justify-between">
                                <div className="relative flex items-end">
                                    <img src={profile} alt="profile" crossOrigin='anonymous' className="h-28 w-28 rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800" />
                                    <div className="ms-4">
                                        <h5 className="text-lg font-semibold">
                                            {details?.first_name + " " + details?.last_name}
                                        </h5>
                                        <p className="text-slate-400">
                                            {details?.geeks_details?.personal_details?.city},
                                            {details?.geeks_details?.personal_details?.state}
                                        </p>
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div>
                                        <input type="submit" id="submit" name="send" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md cursor-pointer" value="Hire Me" />
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative mt-12">
                <div className="container md:pb-24 pb-16 ">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-8 md:col-span-7">
                            <h5 className="text-lg font-semibold">
                                {details?.geeks_details?.technical_expertise?.years_of_experience} experience
                            </h5>
                            <p className="text-slate-400 mt-4">
                            {truncate(
                                details?.geeks_details?.about?.bio?.replace(/(<([^>]+)>)/gi, ""),
                                400
                                )}
                                </p>
                            <h4 className="mt-6 text-xl font-semibold">Skills</h4>
                            <Grid item container className='mt-5 gap-2'>
                                <Grid xs={12} md={12} >
                                    {details?.geeks_details?.technical_expertise?.skills?.map((skills) => {
                                        return <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full" >
                                            {skills}&nbsp;&nbsp;
                                        </div>
                                    })}
                                </Grid>
                            </Grid>
                            <h4 className="mt-6 text-xl font-semibold">Services</h4>
                            <Grid item container className='mt-5 gap-3'>
                                <Grid xs={12} md={12} className='gap-3'>
                                    <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full"  >
                                        All Software Solution
                                    </div>&nbsp;&nbsp;
                                    <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full"  >
                                        Laptop service
                                    </div>&nbsp;&nbsp;
                                    <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full" >
                                        Tablet service
                                    </div>&nbsp;&nbsp;
                                    <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full"  >
                                        Mobile service
                                    </div>&nbsp;&nbsp;
                                    <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full mt-2" >
                                        TV service
                                    </div>
                                </Grid>
                            </Grid>
                            <h4 className="mt-6 text-xl font-semibold">Certified Skills</h4>
                            <Grid item container className='mt-5'>
                                <Grid xs={12} md={12}>
                                    {details?.geeks_details?.technical_expertise?.certified_skills?.map((certifiedskills) => {
                                        return <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full mt-2"  >
                                            {certifiedskills}&nbsp;&nbsp;
                                        </div>
                                    })}
                                </Grid>
                            </Grid>
                            <h4 className="mt-6 text-xl font-semibold">Languages</h4>
                            <Grid item container className='mt-5'>
                                <Grid xs={12} md={12}>
                                    {details?.geeks_details?.technical_expertise?.language?.map((languages) => {
                                        return <div className="btn bg-emerald-600/5  border-emerald-600/10  text-emerald-600   text-white rounded-full mt-2" >
                                            {languages}&nbsp;&nbsp;
                                        </div>
                                    })}
                                </Grid>
                            </Grid>
                            <div className="rounded-md shadow dark:shadow-gray-700 p-6 mt-6">
                                <h5 className="text-xl font-semibold text-center mb-3">Hire Me</h5>
                                <form className='ContainerForm'>
                                    <Form uuid={id} jobUuid={jobUuid} handleClose={handleClose} jobDetailes={jobDetailes}/>
                                </form>
                            </div>
                        </div>
                        <div className="lg:col-span-4 md:col-span-5">
                            <div className="bg-slate-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-700 p-6 sticky top-20">
                                <h5 className="text-lg font-semibold">Personal Detail:</h5>
                                <ul className="list-none mt-4">
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Mail className="h-4 w-4 text-slate-400 me-3 inline"></Mail><span className="text-slate-400 me-3">Name  :</span></span>
                                        <span>{details?.first_name + " " + details?.last_name}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Mail className="h-4 w-4 text-slate-400 me-3 inline"></Mail><span className="text-slate-400 me-3">Email  :</span></span>
                                        <span>{details?.email}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Phone className="h-4 w-4 text-slate-400 me-3 inline"></Phone><span className="text-slate-400 me-3">Mobile :</span></span>
                                        <span>{details?.mobile}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Phone className="h-4 w-4 text-slate-400 me-3 inline"></Phone><span className="text-slate-400 me-3">Gender :</span></span>
                                        <span>{details?.geeks_details?.personal_details?.gender}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Gift className="h-4 w-4 text-slate-400 me-3 inline"></Gift><span className="text-slate-400 me-3">D.O.B. :</span></span>
                                        <span>{details?.geeks_details?.personal_details?.dob}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Home className="h-4 w-4 text-slate-400 me-3 inline"></Home><span className="text-slate-400 me-3">Address :</span></span>
                                        <span>{details?.geeks_details?.personal_details?.address}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><MapPin className="h-4 w-4 text-slate-400 me-3 inline"></MapPin><span className="text-slate-400 me-3">City :</span></span>
                                        <span>{details?.geeks_details?.personal_details?.city}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Globe className="h-4 w-4 text-slate-400 me-3 inline"></Globe><span className="text-slate-400 me-3">Country :</span></span>
                                        <span>{details?.geeks_details?.personal_details?.state}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Server className="h-4 w-4 text-slate-400 me-3 inline"></Server><span className="text-slate-400 me-3">Postal Code :</span></span>
                                        <span>{details?.geeks_details?.personal_details?.pincode}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Server className="h-4 w-4 text-slate-400 me-3 inline"></Server><span className="text-slate-400 me-3">Aadhar Card :</span></span>
                                        <span>{details?.geeks_details?.personal_details?.adhar_number}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Server className="h-4 w-4 text-slate-400 me-3 inline"></Server><span className="text-slate-400 me-3">PAN :</span></span>
                                        <span>{details?.geeks_details?.personal_details?.pan_number}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Server className="h-4 w-4 text-slate-400 me-3 inline"></Server><span className="text-slate-400 me-3">Qualification :</span></span>
                                        <span>{details?.geeks_details?.about?.highest_qualification}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Server className="h-4 w-4 text-slate-400 me-3 inline"></Server><span className="text-slate-400 me-3">Certification</span></span>
                                        <span>{details?.geeks_details?.about?.academic_certification_award_testimonials}</span>
                                    </li>
                                    <li className="mt-3 w-full bg-white dark:bg-slate-900 p-3 rounded-md shadow dark:shadow-gray-700">
                                        <div className="flex items-center mb-3">
                                            <FileText className="h-8 w-8 text-slate-400"></FileText>
                                            <span className="font-medium ms-2">{details?.geeks_details?.about?.cv}</span>
                                        </div>
                                        <Link to="assets/images/calvin-carlo-resume.pdf" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-md w-full flex items-center justify-center" download><FiFileText className='me-2' /> Download CV</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



                                                                                 {/* Dashboard area   */}

                                                         
<div className="rounded-xl text-2xl flex align-middle justify-center items-center mx-auto" style={{ fontWeight:"600" }}>Dashboard</div>
<div className="container lg:mt-10 mt-8">

                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
                        <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    {/* <BsTelephone className='text-slate-icon-400'/> */}
                                    <div className='text-slate-icon-400'>5</div>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Jobs Applied</h5>

                               
                            </div>
                        </div>

                        <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    {/* <LuMail className='text-slate-icon-400'/> */}
                                    <div className='text-slate-icon-400'>25</div> 
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Jobs Inprogress</h5>
                            </div>
                        </div>

                        <div className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    {/* <PiMapPin className='text-slate-icon-400'/> */}
                                    <div className='text-slate-icon-400'>45</div>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Jobs Completed</h5>
                            </div>
                        </div>

                       
                    </div>
                </div>


                {/* Chart bar  */}


      <section className="relative mt-12">
        <div className="container md:pb-24 pb-16 ">
          <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px] border p-6">
            <div className="grid-cols-12 lg:col-span-6 md:col-span-12 xs:col-span-12">
            <div className="col-span-12 rounded-sm border border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-4 p-6">
        <div className="mb-4 justify-between gap-4 sm:flex">
          <div>
            <h4 className="text-xl font-semibold text-black dark:text-white">
              Profit this week
            </h4>
          </div>
          <div>
            <div className="relative z-20 inline-block">
              <select
                name="#"
                id="#"
                className="relative z-20 inline-flex appearance-none bg-transparent py-1 pl-3 pr-8 text-sm font-medium outline-none"
              >
                <option value="">This Week</option>
                <option value="">Last Week</option>
              </select>
              <span className="absolute top-1/2 right-3 z-10 -translate-y-1/2">
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.47072 1.08816C0.47072 1.02932 0.500141 0.955772 0.54427 0.911642C0.647241 0.808672 0.809051 0.808672 0.912022 0.896932L4.85431 4.60386C4.92785 4.67741 5.06025 4.67741 5.14851 4.60386L9.09079 0.896932C9.19376 0.793962 9.35557 0.808672 9.45854 0.911642C9.56151 1.01461 9.5468 1.17642 9.44383 1.27939L5.50155 4.98632C5.22206 5.23639 4.78076 5.23639 4.51598 4.98632L0.558981 1.27939C0.50014 1.22055 0.47072 1.16171 0.47072 1.08816Z"
                    fill="#637381"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.22659 0.546578L5.00141 4.09604L8.76422 0.557869C9.08459 0.244537 9.54201 0.329403 9.79139 0.578788C10.112 0.899434 10.0277 1.36122 9.77668 1.61224L9.76644 1.62248L5.81552 5.33722C5.36257 5.74249 4.6445 5.7544 4.19352 5.32924C4.19327 5.32901 4.19377 5.32948 4.19352 5.32924L0.225953 1.61241C0.102762 1.48922 -4.20186e-08 1.31674 -3.20269e-08 1.08816C-2.40601e-08 0.905899 0.0780105 0.712197 0.211421 0.578787C0.494701 0.295506 0.935574 0.297138 1.21836 0.539529L1.22659 0.546578ZM4.51598 4.98632C4.78076 5.23639 5.22206 5.23639 5.50155 4.98632L9.44383 1.27939C9.5468 1.17642 9.56151 1.01461 9.45854 0.911642C9.35557 0.808672 9.19376 0.793962 9.09079 0.896932L5.14851 4.60386C5.06025 4.67741 4.92785 4.67741 4.85431 4.60386L0.912022 0.896932C0.809051 0.808672 0.647241 0.808672 0.54427 0.911642C0.500141 0.955772 0.47072 1.02932 0.47072 1.08816C0.47072 1.16171 0.50014 1.22055 0.558981 1.27939L4.51598 4.98632Z"
                    fill="#637381"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>

        <div>
          <div id="chartTwo" className="-ml-5 -mb-9">
            <ReactApexChart
              options={options}
              series={state.series}
              type="bar"
              height={350}
            />
          </div>
        </div>
      </div>
            </div>
            <div className="grid-cols-12 lg:col-span-6 md:col-span-12 xs:col-span-12">
            <div id="chart">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="donut"
        />
      </div>
</div>
          </div>
        </div>
      </section>
            <Footer />
        </>
    )
}
